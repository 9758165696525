import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { PagedSearchResult } from 'src/app/providers/models/paged-search-result';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { BrokerOperationViewModel } from '../../../../services/brokers/models/view-models/broker-operation-view-model';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { BrokerOperationTypeEnum } from 'src/app/enums/broker-operation-type-enum';
import { LiabilityService } from 'src/app/services/liabilities/liability-service';
import { LiabilityOperationViewModel } from 'src/app/services/liabilities/models/view-models/liability-operation-view-model';
import { LiabilityOperationStatusEnum } from 'src/app/enums/liability-operation-status-enum';
import { AnalysisResultViewModel } from 'src/app/services/liabilities/models/view-models/analysis-result-view-model';


@Component({
    templateUrl: './liability-operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LiabilityOperationListComponent implements OnInit {

    requestLiabilityOperationCancel = null;
    liabilityOperationsOnGoingResult: PagedSearchResult<LiabilityOperationViewModel>;
    liabilityOperationsOnGoing: LiabilityOperationViewModel[] = [];

    liabilityOperationsFinishedResult: PagedSearchResult<LiabilityOperationViewModel>;
    liabilityOperationsFinished: LiabilityOperationViewModel[] = [];

    liabilityOperationsCanceledResult: PagedSearchResult<LiabilityOperationViewModel>;
    liabilityOperationsCanceled: LiabilityOperationViewModel[] = [];

    displayLiabilityOperationDetail: boolean = false;
    displayLiabilityOperationInit: boolean = false;
    displayLiabilityOperationCancel: boolean = false;
    displayReasonLiabilityOperation: boolean = false;

    selectedLiabilityOperation: LiabilityOperationViewModel = null;
    selectedLiabilityOperationCancel: LiabilityOperationViewModel = null;
    selectedLiabilityOperationDisplay: LiabilityOperationViewModel = null;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;

    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    liabilityOperationInfo: AnalysisResultViewModel[] = [];
    administratorsInfo: AnalysisResultViewModel[] = [];
    managerInfo: AnalysisResultViewModel[] = [];

    public operationStatusEnum = OperationStatusEnum;
    public brokerOperationTypeEnum = BrokerOperationTypeEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private liabilityService: LiabilityService,
        private contextService: ContextService,
        private messageService: MessageService) {
    }


    setFormCancelLiabilityOperation() {
        this.requestLiabilityOperationCancel = {
            notes: null,
        }
    }

    async ngOnInit() {
        this.setFormCancelLiabilityOperation();
        this.loadingService.show();
        await this.verifyUserPermissions();

        await this.getLiabilityOperationsFinishedData(null);
        await this.getLiabilityOperationsOnGoingData(null);
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getLiabilityOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
        this.liabilityOperationsFinishedResult = await this.liabilityService.getLiabilityOperationsFinishedPaged(pagedSearchRequest);
        this.liabilityOperationsFinished = this.liabilityOperationsFinishedResult.searchResult;
        this.liabilityOperationsFinished = this.liabilityOperationsFinished.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });
    }

    async getLiabilityOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {
        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
        this.liabilityOperationsOnGoingResult = await this.liabilityService.getLiabilityOperationsOnGoingSearch(pagedSearchRequest);
        this.liabilityOperationsOnGoing = this.liabilityOperationsOnGoingResult.searchResult;
        this.liabilityOperationsOnGoing = this.liabilityOperationsOnGoing.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });
    }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsFinishedData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.liabilityOperationsFinishedResult = await this.liabilityService.getLiabilityOperationsFinishedPaged(pagedSearchRequest);
        this.liabilityOperationsFinished = this.liabilityOperationsFinishedResult.searchResult;

        this.liabilityOperationsFinished = this.liabilityOperationsFinished.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsFinishedData()', 'operationsFinishedResult', this.brokerOperationsFinishedResult);

    }

    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsCanceledData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.liabilityOperationsCanceledResult = await this.liabilityService.getLiabilityOperationsCanceledSearch(pagedSearchRequest);
        this.liabilityOperationsCanceled = this.liabilityOperationsCanceledResult.searchResult;

        this.liabilityOperationsCanceled = this.liabilityOperationsCanceled.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsCanceledData()', 'operationsCanceledResult', this.brokerOperationsCanceledResult);

    }

    async changeTab(index: number) {

    }

    close() {
        this.displayLiabilityOperationDetail = false;
        this.displayLiabilityOperationInit = false;
        this.displayLiabilityOperationCancel = false;
        this.displayReasonLiabilityOperation = false;
    }

    reasonLiabilityOperation(liabilityOperation: LiabilityOperationViewModel) {
        this.displayReasonLiabilityOperation = true;
        this.selectedLiabilityOperationDisplay = liabilityOperation;
    }

    async viewLiabilityOperation(liabilityOperation: LiabilityOperationViewModel) {
        let response = await this.liabilityService.getLiabilityOperationById(liabilityOperation.id);
        this.liabilityOperationInfo = response.results;
        this.administratorsInfo = response.results.filter(c => c.entityChanged == "Administradora");
        this.managerInfo = response.results.filter(c => c.entityChanged == "Gestora");
        this.displayLiabilityOperationDetail = true;
    }

    getMenuItems(liabilityOperation: LiabilityOperationViewModel): MenuItem[] {
        if (liabilityOperation.currentStatus.status.value == LiabilityOperationStatusEnum.ErroCadastral) {
            return [
                { label: 'Ver Motivo', command: e => this.reasonLiabilityOperation(liabilityOperation) },
            ]
        } else if (liabilityOperation.results.length == 0) {
            return []
        } else {
            return [
                { label: 'Ver', command: async e => await this.viewLiabilityOperation(liabilityOperation) },
            ]
        }

    }

}