import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { UpdateFundAdministratorRequest } from 'src/app/services/fund-administrators/models/requests/update-fund-administrator-request';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';


@Component({
    templateUrl: './fund-administrator-view.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundAdministratorViewComponent implements OnInit {

    request: UpdateFundAdministratorRequest = null;
    requestRepresentations: any[] = [];
    selectedFundAdministrator: FundAdministratorViewModel = null;
    requestValidateDate: Date = null;
    allRepresentatives: RepresentativeViewModel[] = [];
    fundAdministrator: FundAdministratorViewModel = null;
    displayEditFundAdministrator: boolean = false;

    constructor(
        private fundAdministratorService: FundAdministratorService,
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.loadingService.show();
        const id = this.route.snapshot.paramMap.get('id');
        this.setForms();
        await this.getData(id);
        await this.getRepresentativesInFundAdministrator();
        this.loadingService.hide();
    }

    setForms() {
        this.request = {
            fundAdministratorId: null,
            name: null,
            addressStreet: null,
            addressNumber: null,
            addressComplement: null,
            addressNeighborhood: null,
            addressZipCode: null,
            addressCity: null,
            addressState: null,
            addressCountry: null,
            email: null,
            phoneNumber: null,
            giinNumber: null,
        }
    }

    async getData(id: string) {
        this.fundAdministrator = await this.fundAdministratorService.getById(id);   
        this.fundAdministrator.operationSetups = this.fundAdministrator.operationSetups.sort((a, b) => {
            if(a.operationType.friendlyName > b.operationType.friendlyName) {
                return 1
            } else {
                return -1
            }
        });
        this.request.fundAdministratorId = this.fundAdministrator.id;
        this.request.name = this.fundAdministrator.name;
        this.request.addressStreet = this.fundAdministrator.addressStreet;
        this.request.addressNumber = this.fundAdministrator.addressNumber;
        this.request.addressComplement = this.fundAdministrator.addressComplement;
        this.request.addressNeighborhood = this.fundAdministrator.addressNeighborhood;
        this.request.addressZipCode = this.fundAdministrator.addressZipCode;
        this.request.addressCity = this.fundAdministrator.addressCity;
        this.request.addressState = this.fundAdministrator.addressState;
        this.request.addressCountry = this.fundAdministrator.addressCountry;
        this.request.email = this.fundAdministrator.email;
        this.request.phoneNumber = this.fundAdministrator.phoneNumber; 
    }

    async getRepresentativesInFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundAdministratorService.getRepresentativesInFundAdministrator(fundAdministratorId);
    }

    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-administrator/list`)
    }

    openEditFundAdministrator() {
        this.displayEditFundAdministrator = true;
    }

    async onSaveEditFundAdministrator(editForm: NgForm) {
        this.displayEditFundAdministrator = false;
        this.loadingService.show();
        try {
            await this.fundAdministratorService.updateFundAdministrator(this.request);
            this.messageService.add({
                severity: 'success',
                summary: 'Cadastro alterado com sucesso',
                detail: `Os dados foram alterados com sucesso!`,
                life: 5000
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            const id = this.route.snapshot.paramMap.get('id');
            await this.getData(id);
        }
    }

    close() {
        this.displayEditFundAdministrator = false;
    }

}