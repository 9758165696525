import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMapTitle } from './models/sitemap-title';
import { LocalStorageService } from 'ngx-localstorage';
import { CryptoService } from './crypto-service';
import { EventHandlerService } from './event-handler-service';
import { LoginWhiteLabelContextResponse } from '../services/white-label-context/models/response/login-white-label-context-response';
import { LoginResponseViewModel } from '../services/users/models/view-models/login-response-view-model';
import { ApplicationResponseViewModel } from '../services/users/models/view-models/application-response-view-model';

@Injectable({
    providedIn: 'root'
})
export class ContextService {

    USER_ID = 'userId';
    USER_INFO = 'userInfo';
    USER_COMPANIES = 'userCompanies';
    SELECTED_COMPANY = 'userSelectedCustomer';
    SELECTED_APPLICATION = 'userSelectedApplication';
    IS_SINGLE_SIGN_ON = 'is_single_sign_on';
    LIZA_CONTEXT = 'lizaContext';

    // Context Settings - Devem ser iguais ao ContextSettings do projeto do BackEnd
    SellerDefaultComissionChargedPercentage = 5;
    BuyerDefaultComissionChargedPercentage = 5;

    constructor(
        private router: Router,
        private storage: LocalStorageService,
        private cryptoService: CryptoService,
        private eventHandlerService: EventHandlerService
    ) { }

    setWhiteLabelContext(response: LoginWhiteLabelContextResponse) {
        this.storage.set(this.LIZA_CONTEXT, this.cryptoService.encryptMessage(response));
    }

    getWhiteLabelContext(): LoginWhiteLabelContextResponse {
        const encryptedInfo = this.storage.get(this.LIZA_CONTEXT);
        return this.cryptoService.decryptMessage(encryptedInfo);
    }

    clearUserInfo() {
        const userId = this.storage.get(this.USER_ID);
        this.storage.remove(`${this.USER_INFO}_${userId}`);
        this.storage.remove(`${this.USER_COMPANIES}_${userId}`);
        this.storage.remove(`${this.SELECTED_COMPANY}_${userId}`);
        this.storage.remove(`${this.SELECTED_APPLICATION}_${userId}`);
    }

    logout() {
        this.clearUserInfo();
        const whitelabel = this.getWhiteLabelContext();
        const host = window.location.host;
        const subDomain = host.split('.')[0];
        const newSubDomain = `${whitelabel.style.name}`;

        let newHost = host.replace(subDomain, newSubDomain);
        window.location.href = `https://${newHost}/#/login`;
        // this.router.navigateByUrl('login');
    }

    setLoginResponse(response: LoginResponseViewModel) {
        const userId = response.user.id;
        this.storage.set(this.USER_ID, userId);
        this.storage.set(`${this.USER_INFO}_${userId}`, this.cryptoService.encryptMessage(response));
        this.getUserApplications();
    }

    getIsSingleSignOn(): boolean {
        return this.storage.get(this.IS_SINGLE_SIGN_ON);
    }

    setIsSingleSignOn(value: boolean) {
        return this.storage.set(this.IS_SINGLE_SIGN_ON, value);
    }

    getUserId(): string {
        return this.storage.get(this.USER_ID);
    };

    getUsername(): string {
        const userInfo = this.getUserInfo();
        if (userInfo != null) {
            return `${userInfo.user.firstName} ${userInfo.user.lastName}`;
        }
        return null;
    }

    getUserApplications(): ApplicationResponseViewModel[] {
        let userInfo = this.getUserInfo();
        if (userInfo == null) {
            return null;
        }
        return userInfo.applications;
    }

    getUserApplicationSelected(): ApplicationResponseViewModel {
        const userId = this.storage.get(this.USER_ID);
        const encryptedInfo = this.storage.get(`${this.SELECTED_APPLICATION}_${userId}`);
        if (encryptedInfo != null && encryptedInfo !== undefined) {
            let application: ApplicationResponseViewModel = this.cryptoService.decryptMessage(encryptedInfo);
            return application;
        } else {
            return null;
        }
    }

    setUserApplication(application: ApplicationResponseViewModel) {
        const userId = this.storage.get(this.USER_ID);
        this.storage.set(`${this.SELECTED_APPLICATION}_${userId}`, this.cryptoService.encryptMessage(application));
        this.eventHandlerService.publish(this.SELECTED_APPLICATION);
    }

    getUserSitemap(): SiteMapTitle[] {
        let userInfo = this.getUserInfo();
        const selectedApplication = this.getUserApplicationSelected();
        if (userInfo != null) {
            return selectedApplication?.profiles[0]?.siteMap;
        }
        return null;
    }

    isLoggedIn(): boolean {
        let userInfo = this.getUserInfo();
        return userInfo != null && userInfo.token != null && new Date(userInfo.expirationDate) > new Date();
    }

    getUserInfo(): LoginResponseViewModel {
        const userId = this.storage.get(this.USER_ID);
        const encryptedUserInfo = this.storage.get(`${this.USER_INFO}_${userId}`);
        if (encryptedUserInfo != null && encryptedUserInfo !== undefined) {
            let userInfo: LoginResponseViewModel = this.cryptoService.decryptMessage(encryptedUserInfo);
            return userInfo;
        } else {
            return null;
        }
    }
}
