import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './attachment-create.component.html',
    styleUrls: ['../../../../assets/layout/customized/datatable.scss', '../../../../assets/layout/customized/custom-card.scss'],
    selector: `app-attachment-create`
})
export class AttachmentCreateComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    @Input() hasFundRegulationFile: boolean;
    @Input() hasBrokerFundRegulationFile: boolean;
    @Output() openAddFileEmitter = new EventEmitter();
    @Output() removeFileEmitter = new EventEmitter<AttachmentViewModel | BrokerOperationAttachmentViewModel>();
    @Output() openArtefactUrlEmitter = new EventEmitter<AttachmentViewModel | BrokerOperationAttachmentViewModel>();
    @Output() onOperationUploadFileFundRegulationEmitter = new EventEmitter();
    @Output() onBrokerUploadFileFundRegulationEmitter = new EventEmitter();

    constructor(
    ) { }

    async ngOnInit() {
    }

    openArtefactUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        this.openArtefactUrlEmitter.emit(file)
    }

    removeFile(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        this.removeFileEmitter.emit(file)
    }

    openAddFile() {
        this.openAddFileEmitter.emit();
    }

    onOperationUploadFileFundRegulation() {
        this.onOperationUploadFileFundRegulationEmitter.emit();
    }

    onBrokerUploadFileFundRegulation() {
        this.onBrokerUploadFileFundRegulationEmitter.emit();
    }
}
