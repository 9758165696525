<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Setup de Operações
            </div>
        </div>
        <div class="customCard" *ngIf="fundAdministrator">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined" (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Administrador de Fundo</div>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8 ">
                    <div class="borderOutlined heightFix100">
                        <p><b class="text-bold">Nome:</b> <span> {{fundAdministrator.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{fundAdministrator.documentNumber |
                                documentNumber:'CNPJ'}}</span></p>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="fundAdministrator?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="fundAdministrator.registrationFormTemplate.fileUrl" target="_blank"
                            class="linkPrimary">
                            Ver Template
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="!fundAdministrator?.registrationFormTemplate">
                        <div class="text-bold">
                           Modelo Ficha Cadastral
                        </div>
                        <a class="linkPrimary" (click)="updateRegistrationFormTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-mt-3">
                    <div class="cardHeader text-bold">Setups Cadastrados</div>
                    <div class="borderOutlined">
                        <div *ngIf="fundAdministrator.operationSetups.length == 0">
                            <span>Não há setups cadastrados</span>
                        </div>
                        <div class="p-grid" *ngIf="fundAdministrator.operationSetups.length > 0" style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold ">
                                Tipo
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Ficha Cadastral
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Termo de Adesão
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Boletim de Subscrição
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Carta
                            </div>
                            <div class="p-col-12 p-md-1 p-lg-1 text-bold text-center">
                                Template Extra
                                <!-- Planilha ICVM -->
                            </div>
                            <!-- <div class="p-col-1 p-md-1 p-lg-1 text-bold">
                                Excluir
                            </div> -->
                        </div>
                        <div *ngFor="let operation of fundAdministrator.operationSetups" class="p-grid" style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2">
                                {{ operation.operationType.friendlyName}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    {{ operation.hasFundAdministratorRegistrationFormTemplate ? 'Sim' : 'Não'}}
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasFundAdministratorRegistrationFormTemplate')"
                                    [(ngModel)]="operation.hasFundAdministratorRegistrationFormTemplate" name="hasFundAdministratorRegistrationFormTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    {{(operation.hasFundAdministratorRegistrationFormTemplate &&
                                    operation.isFundAdministratorRegistrationFormTemplateSignable ? '(Assinável)' : '')}}
                                    {{(operation.hasFundAdministratorRegistrationFormTemplate &&
                                    !operation.isFundAdministratorRegistrationFormTemplateSignable ? '(Não Assinável)' :'')}}
                                </div>
                                <div [hidden]="!operation.hasFundAdministratorRegistrationFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isFundAdministratorRegistrationFormTemplateSignable')"
                                    [(ngModel)]="operation.isFundAdministratorRegistrationFormTemplateSignable" name="isFundAdministratorRegistrationFormTemplateSignable"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    {{ operation.hasFundAdhesionTermTemplate ? 'Sim' : 'Não'}}
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasFundAdhesionTermTemplate')"
                                    [(ngModel)]="operation.hasFundAdhesionTermTemplate" name="hasFundAdhesionTermTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    {{(operation.hasFundAdhesionTermTemplate &&
                                    operation.isFundAdhesionTermTemplateSignable ? '(Assinável)' : '')}}
                                    {{(operation.hasFundAdhesionTermTemplate &&
                                    !operation.isFundAdhesionTermTemplateSignable ? '(Não Assinável)' : '')}}
                                </div>
                                <div [hidden]="!operation.hasFundAdhesionTermTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isFundAdhesionTermTemplateSignable')"
                                    [(ngModel)]="operation.isFundAdhesionTermTemplateSignable" name="isFundAdhesionTermTemplateSignable"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    {{ operation.hasFundSubscriptionFormTemplate ? 'Sim' : 'Não'}}
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasFundSubscriptionFormTemplate')"
                                    [(ngModel)]="operation.hasFundSubscriptionFormTemplate" name="hasFundSubscriptionFormTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    {{(operation.hasFundSubscriptionFormTemplate &&
                                    operation.isFundSubscriptionFormTemplateSignable ? '(Assinável)' : '')}}
                                    {{(operation.hasFundSubscriptionFormTemplate &&
                                    !operation.isFundSubscriptionFormTemplateSignable ? '(Não Assinável)' : '')}}
                                </div>
                                <div [hidden]="!operation.hasFundSubscriptionFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isFundSubscriptionFormTemplateSignable')"
                                    [(ngModel)]="operation.isFundSubscriptionFormTemplateSignable" name="isFundSubscriptionFormTemplateSignable"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.letterTemplate != null ? 'Sim' : 'Não'}}
                                        {{(operation.letterTemplate && operation.isLetterTemplateSignable ? '(Assinável)' : '')}}
                                        {{(operation.letterTemplate && !operation.isLetterTemplateSignable ? '(Não Assinável)' : '')}}
                                    </p>
                                    <div *ngIf="operation.letterTemplate != null">
                                        <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isLetterTemplateSignable')"
                                        [(ngModel)]="operation.isLetterTemplateSignable" name="isLetterTemplateSignable"></p-inputSwitch>
                                    </div>
                                    <div style="margin-top: 1rem;">
                                        <a class="textPrimary" [href]="operation.letterTemplate?.fileUrl">{{ operation.letterTemplate?.fileUrl != null ? 'Ver template' : ''}}</a>
                                    </div>
                                    <div *ngIf="operation.letterTemplate != null" style="margin-top: 1rem;">
                                        <p class="text-bold">Data de inclusão:</p>
                                        <p>{{operation?.letterTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-1 p-lg-1 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.icvmTemplate != null ? 'Sim' : 'Não'}}
                                        {{(operation.icvmTemplate && operation.isICVMTemplateSignable ? '(Assinável)' : '')}}
                                        {{(operation.icvmTemplate && !operation.isICVMTemplateSignable ? '(Não Assinável)' :'')}}
                                    </p>
                                    <div *ngIf="operation.icvmTemplate != null">
                                        <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isICVMTemplateSignable')"
                                        [(ngModel)]="operation.isICVMTemplateSignable" name="isICVMTemplateSignable"></p-inputSwitch>
                                    </div>
                                    <div style="margin-top: 1rem;">
                                    <a class="textPrimary" [href]="operation.icvmTemplate?.fileUrl">{{ operation.icvmTemplate?.fileUrl != null ? 'Ver template' : ''}}</a>
                                    </div>
                                    <div *ngIf="operation.icvmTemplate != null" style="margin-top: 1rem;">
                                        <p class="text-bold">Data de inclusão:</p>
                                        <p>{{operation?.icvmTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-1 p-md-1 p-lg-1" style="margin-top: 8px;">
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    class="p-button-rounded p-button-outlined-blue"
                                    (click)="removeOperationSetup(operation)"></button>
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">

        <div class="titleCustomCard">
            <div class="titlePage">
                Novo Setup
            </div>
        </div>

        <div class="customCard" *ngIf="requestModel != null">
            <form #setupOperationForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="operationTypesOptions" placeholder="Tipo"
                                    [(ngModel)]="requestModel.operationTypeId" optionLabel="friendlyName"
                                    #operationTypeId="ngModel" optionValue="value" name="operationTypeId">
                                </p-dropdown>
                                <!-- <label for="operationTypeId">Tipo</label> -->
                            </span>
                        </div>
                        <mark *ngIf="operationTypeId.invalid && operationTypeId.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Ficha Cadastral</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundAdministratorResistrationSignable($event.checked)"
                            [(ngModel)]="requestModel.hasFundAdministratorRegistrationFormTemplate"
                            name="hasFundAdministratorRegistrationFormTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável</div>
                            <br>
                            <p-inputSwitch
                            [(ngModel)]="requestModel.isFundAdministratorRegistrationFormTemplateSignable" [disabled]="!requestModel.hasFundAdministratorRegistrationFormTemplate"
                            name="isFundAdministratorRegistrationFormTemplateSignable"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Termo de Adesão</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundAdhesionSignable($event.checked)" [(ngModel)]="requestModel.hasFundAdhesionTermTemplate"
                            name="hasFundAdhesionTermTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável</div>
                            <br>
                            <p-inputSwitch [(ngModel)]="requestModel.isFundAdhesionTermTemplateSignable" [disabled]="!requestModel.hasFundAdhesionTermTemplate"
                            name="isFundAdhesionTermTemplateSignable"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Boletim de Subscrição</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundSubscriptionSignable($event.checked)" [(ngModel)]="requestModel.hasFundSubscriptionFormTemplate"
                            name="hasFundSubscriptionFormTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável</div>
                            <br>
                            <p-inputSwitch [(ngModel)]="requestModel.isFundSubscriptionFormTemplateSignable" [disabled]="!requestModel.hasFundSubscriptionFormTemplate"
                            name="isFundSubscriptionFormTemplateSignable"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-12 p-lg-12">
                        <div class="p-grid customCardOutlined" style="display: flex; flex-direction: row; align-items: center;">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="text-bold">Possui Template de Carta da Operação</div>
                                <br>
                                <p-inputSwitch (onChange)="changeOperationLetter($event.checked)" [(ngModel)]="operationLetter" name="operationLetter"></p-inputSwitch>
                                <br>
                                <br>
                                <div class="text-bold">Assinável</div>
                                <br>
                                <p-inputSwitch [(ngModel)]="requestModel.isLetterTemplateSignable" name="isLetterTemplateSignable" [disabled]="!operationLetter"></p-inputSwitch>
                            </div>
                            <div class="p-col-12 p-md-9 p-lg-9">
                                <div [hidden]="!operationLetter">
                                    <app-artifact-template-create (onArtifactFilled)="letterTemplateFilled($event)"
                                        #letterArtifactTemplate>
                                    </app-artifact-template-create>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-12 p-lg-12">
                        <div class="p-grid customCardOutlined" style="display: flex; flex-direction: row; align-items: center;">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="text-bold">Possui Template Extra</div>
                                <br>
                                <p-inputSwitch (onChange)="changeIcvmSignable($event.checked)" [(ngModel)]="icvmSpreadsheet" name="icvmSpreadsheet"></p-inputSwitch>
                                <br>
                                <br>
                                <div class="text-bold">Assinável</div>
                                <br>
                                <p-inputSwitch [(ngModel)]="requestModel.isICVMTemplateSignable" name="isICVMTemplateSignable" [disabled]="!icvmSpreadsheet"></p-inputSwitch>
                            </div>
                            <div class="p-col-12 p-md-9 p-lg-9">
                                <div [hidden]="!icvmSpreadsheet">
                                    <app-artifact-template-create (onArtifactFilled)="icvmTemplateFilled($event)"
                                        #iCVMArtifactTemplate>
                                    </app-artifact-template-create>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton class="p-button-primary" label="Salvar"
                            (click)="saveOperationSetup(setupOperationForm)"></button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>