<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Consulta
            </div>
        </div>
        <div class="customCard">
            <form #confultForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="contentButtonActions">
                            <button *ngIf="!existOperationParams()" pButton pRipple type="button"
                                label="Exportação Completa" (click)="openFullConsult()"></button>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" class="inputLiza" name="id"
                                    [(ngModel)]="request.brokerOperationSequentialId" #id="ngModel" pInputText>
                                <label for="id">ID</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="operationTypes" optionValue="value" optionLabel="friendlyName"
                                    [showClear]="true" [(ngModel)]="request.operationTypeId"
                                    placeholder="Tipo de Operação" name="label" #operationType="ngModel">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="operationStatus" optionValue="value" optionLabel="label"
                                    [showClear]="true" [(ngModel)]="selectedStatusOperation"
                                    [(ngModel)]="request.operationStatusId" placeholder="Status da Operação"
                                    name="label" #operationType="ngModel">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="OptionBrokers" optionValue="value" optionLabel="label"
                                    [showClear]="true" [(ngModel)]="request.brokerId" placeholder="Corretoras"
                                    name="label" #operationType="ngModel">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.startDate" name="startDate" yearRange="1920:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" #startDate="ngModel">
                                </p-calendar>
                                <label for="startDate">Data inicial do status</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.endDate" name="endDate" yearRange="1920:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" #endDate="ngModel">
                                </p-calendar>
                                <label for="endDate">Data final do status</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3 contentDisplayFlex">
                        <p class="text-bold">Fundo Cotista</p>
                        <div class="contentRowStartItems">
                            <div class="field-radiobutton" style="margin-bottom:5px;">
                                <p-radioButton name="shareholderFund" value="name"
                                    [(ngModel)]="selectedShareholderFundType" (ngModelChange)="handleTypeFund($event)">
                                </p-radioButton>
                                <label for="shareholderFund" style="margin-left: 5px;">Nome</label>
                            </div>
                            <div class="field-radiobutton" style="margin-bottom:5px;">
                                <p-radioButton name="shareholderFund" value="document"
                                    [(ngModel)]="selectedShareholderFundType" (ngModelChange)="handleTypeFund($event)">
                                </p-radioButton>
                                <label for="shareholderFund" style="margin-left: 5px;">CNPJ</label>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6 p-lg-6 p-fluid itemEnd">
                        <p-autoComplete *ngIf="selectedShareholderFundType == 'name'" (onClear)="clearFunds()"
                            [suggestions]="shareholderFundsResult" [(ngModel)]="selectedShareholderFund"
                            [ngModelOptions]="{standalone: true}" (completeMethod)="searchShareholderFund($event.query)"
                            placeholder="Fundo Cotista" (onSelect)="onShareholderFund($event)"></p-autoComplete>

                        <p-autoComplete *ngIf="selectedShareholderFundType == 'document'" (onClear)="clearFunds()"
                            [suggestions]="shareholderFundsDocumentResult" [(ngModel)]="selectedShareholderFundDocument"
                            [ngModelOptions]="{standalone: true}"
                            (completeMethod)="searchShareholderFundDocument($event.query)"
                            placeholder="CNPJ Fundo Cotista" (onSelect)="onShareholderFundDocument($event)">
                        </p-autoComplete>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2">
                    </div>
                    
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Limpar" class="p-button-outlined"
                            (click)="onClear()"></button>
                        <button pButton pRipple type="button" label="Consultar" class="action-button p-ml-2"
                            (click)="consult()"></button>
                    </div>

                </div>
            </form>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12" [hidden]="!operations">
        <div class="titleCustomCard">
            <div class="titlePage">
                Operações
            </div>
        </div>
        <div class="customCard">
            <p-table [responsive]="true" #dt [value]="operations" dataKey="id" [(first)]="firstPage"
                [(selection)]="selectedBrokerOperation" styleClass="p-datatable-customized" selectionMode="single"
                [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'shareholderFund.documentNumber', 'investedFund.name', 'investedFund.documentNumber', 'currentStatus.status.friendlyName']">
                <ng-template pTemplate="caption">
                    <div class="contentButtonActions p-fluid">

                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid" [hidden]="!operations.length">
                            <button pButton type="button" class="p-button-outlined" label="Exportar operações"
                                (click)="exportExcel()"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-operations>
                    <tr>
                        <th pSortableColumn="sequentialId">
                            <div>
                                ID
                                <p-sortIcon field="sequentialId"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="createDate">
                            <div>
                                Data da Entrada
                                <p-sortIcon field="createDate"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="currentStatus.createDate">
                            <div>
                                Data Encerramento
                                <p-sortIcon field="createDate"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="type.friendlyName">
                            <div>
                                Operação
                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.name">
                            <div>
                                Fundo Cotista
                                <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.documentNumber">
                            <div>
                                CNPJ F. Cotista
                                <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="fund.sac">
                            <div>
                                SIGLA SAC
                                <p-sortIcon field="fund.sac"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="cblc">
                            <div>
                                CBLC
                                <p-sortIcon field="cblc"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="addtionNumber">
                            <div>
                                ADITAMENTO
                                <p-sortIcon field="addtionNumber"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.name">
                            <div>
                                Corretora
                                <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.documentNumber">
                            <div>
                                CNPJ Corretora
                                <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="currentStatus.createDate">
                            <div>
                                Data Status
                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="currentStatus.status.friendlyName">
                            <div>
                                Status
                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-operation let-index>
                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                        <td>
                            {{operation?.sequentialId}}
                        </td>
                        <td>
                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                            <span>
                                {{ (operation?.currentStatus?.status?.value == 8 ||
                                operation?.currentStatus?.status?.value == operationStatusEnum.Cancelada) ?
                                (operation?.currentStatus?.createDate | date: 'dd/MM/yyyy') : ''}}</span>
                        </td>
                        <td>
                            {{operation?.type?.friendlyName}}
                        </td>
                        <td>
                            {{operation?.fund?.name}}
                        </td>
                        <td>
                            {{operation?.fund?.documentNumber | documentNumber: 'cnpj' }}
                        </td>
                        <td>
                            {{operation?.fund?.sac}}
                        </td>
                        <td>
                            {{operation?.cblc}}
                        </td>
                        <td>
                            {{operation?.addtionNumber}}
                        </td>
                        <td>
                            {{operation?.broker?.name}}
                        </td>
                        <td>
                            {{operation?.broker?.documentNumber | documentNumber: 'cnpj' }}
                        </td>
                        <td>
                            {{operation?.currentStatus?.createDate | date: 'dd/MM/yyyy' : ''}}
                        </td>
                        <td>
                            {{operation?.currentStatus?.status?.friendlyName}}
                        </td>
                        <td>
                            <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text"
                                (click)="menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Nenhuma operação encontrada.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>


<p-dialog header="Detalhes" [(visible)]="displayOperationDetail" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 textPrimary contentCenterColumn">
            Público Alvo ANBIMA: {{selectedBrokerOperation?.fund?.currentCustomProperty?.ambimaTargetGroup}}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 textPrimary contentCenterColumn">
            Operação: {{selectedBrokerOperation?.type?.friendlyName}}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Fundo Cotista:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.fund?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.fund?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Corretora:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.broker?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <h5 class="text-bold">Histórico:</h5>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-scrollPanel [style]="{width: '100%', height: '500px'}">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab *ngFor="let item of selectedBrokerOperation?.history; let i = index" [attr.data-index]="i"
                        expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up"
                        header="{{item?.createDate | date : 'dd/MM/yyyy HH:mm'}} - {{item?.status?.friendlyName}}" class="p-mt-3">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <p><span [innerHTML]="breakTextLine(item?.notes)"></span></p>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </p-scrollPanel>
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Cancelar Operação" [(visible)]="displayOperationCancel" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <form #cancelForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <h4>Deseja cancelar a operação com o id {{selectedOperationCancel?.sequentialId}}?</h4>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                        <textarea id="float-input" pInputTextarea name="notes" [style]="{'width': '100%'}"
                            placeholder="Motivo" [(ngModel)]="requestOperationCancel.notes" required
                            #notes="ngModel"></textarea>
                    </div>
                </div>
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Salvar"
                        (click)="confirmCancelOperation(cancelForm)"></button>
                </div>
            </form>
        </div>

    </div>

    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Motivo" [(visible)]="displayReasonOperation" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 2 p-lg-12">
            <p class="text-bold">Motivo:</p> <span>{{selectedOperationDisplay?.currentStatus?.notes}}</span>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>


<p-dialog header="Exportação Completa" [(visible)]="displayExportFull" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <h4 class="subTitle">Selecione a situação.</h4>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="progress" label="Em andamento"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="completed" label="Concluídos"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="cancelled" label="Cancelados"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="all" label="Todos" [(ngModel)]="selectedOperationSituation">
            </p-radioButton>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" class="p-button-outlined" label="Fechar" (click)="close()"></button>
        <button pButton pRipple type="button" label="Exportar" (click)="exportAllSelectedOperation()"
            [disabled]="!selectedOperationSituation"></button>
    </div>

</p-dialog>