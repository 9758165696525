import { Component, Input, OnInit } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { ContextService } from 'src/app/providers/context-service';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { LoginWhiteLabelContextResponse } from 'src/app/services/white-label-context/models/response/login-white-label-context-response';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './assessment-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/datatable.scss', '../../../../assets/layout/customized/custom-card.scss', './assessment-list.component.scss'],
    selector: `app-assessment-list`
})
export class AssessmentListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel;
    @Input() selectedBrokerOperation: BrokerOperationViewModel;
    @Input() assessments: AssessmentSummaryViewModel[] = [];
    @Input() brokerAssessments: BrokerOperationAssessmentSummaryViewModel[] = [];

    whitelabelUser: LoginWhiteLabelContextResponse = null;

    constructor(private contextService: ContextService) { }

    ngOnInit() {
        this.getInfoWhitelabel();
    }

    getInfoWhitelabel(): void {
        this.whitelabelUser = this.contextService.getWhiteLabelContext();
    }
}
