
<form #entryForm="ngForm">
    <div *ngIf="isRepresentativeA" class="content">
        <p-dropdown [options]="allRepresentativesA" optionLabel="name" dataKey="id" #representativeOne="ngModel" (onChange)="changeRepresentativeA($event.value)" [required]="true"
            [(ngModel)]="selectedRepresentativeA" placeholder="Selecione" [ngModelOptions]="{standalone: true}"></p-dropdown>
    </div>
    
    <div *ngIf="isRepresentativeB" class="content">
        <p-dropdown [options]="allRepresentativesB" optionLabel="name" dataKey="id" #representativeTwo="ngModel" (onChange)="changeRepresentativeB($event.value)" [required]="true"
            [(ngModel)]="selectedRepresentativeB" placeholder="Selecione" [ngModelOptions]="{standalone: true}"></p-dropdown>
    </div>
</form>
