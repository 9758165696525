import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { DefaultEnumViewModel } from "src/app/providers/models/default-enum-view-model";
import { PagedSearchRequest } from "src/app/providers/models/paged-search-request";
import { PagedSearchResult } from "src/app/providers/models/paged-search-result";
import { ArtifactViewModel, OperationViewModelOperationViewModel, RecipientViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { AddAdministratorSignersRequest } from "./models/request/add-administrator-signers-request";
import { AddAttachmentRequest } from "./models/request/add-attachment-request";
import { AddFundRegulationAsAttachmentRequest } from "./models/request/add-fund-regulation-as-attachment-request";
import { AddManagerSignersRequest } from "./models/request/add-manager-signers-request";
import { AddRecipientRequest } from "./models/request/add-recipient-request";
import { AddSignersRequest } from "./models/request/add-signers-request";
import { AssessOperationByManagerRequest } from "./models/request/assess-operation-by-manager-request";
import { CancelOperationRequest } from "./models/request/cancel-operation-request";
import { DismissOperationAssessmentRequest } from "./models/request/dismiss-operation-assessment-request";
import { FillOperationArtifactRequest } from "./models/request/fill-operation-artifact-request";
import { GetOperationsByFilterRequest } from "./models/request/get-operations-by-filter-request";
import { ReplyOperationAssessmentRequest } from "./models/request/reply-operation-assessment-request";
import { ResendContactManagerNotificationRequest } from "./models/request/resend-contact-manager-notification-request";
import { SetSetupAsDoneRequest } from "./models/request/set-setup-as-done-request";
import { GetDoneOperationsTimeframeByDateRangeResponse } from "./models/responses/get-done-operations-timeframe-by-date-range-response";
import { GetFinishedOperationsByDateRangeResponse } from "./models/responses/get-finished-operations-by-date-range-response";
import { GetFinishedOperationsYearlyReportResponse } from "./models/responses/get-finished-operations-yearly-report-response";
import { GetOperantionsPendingSummaryResponse } from "./models/responses/get-operantions-pending-summary-response";
import { GetOperationsHomeReportResponse } from "./models/responses/GetOperationsHomeReportResponse";
import { AssessmentSummaryViewModel } from "./models/view-models/assessment-summary-view-model";
import { EntityOperationHistoryViewModel } from "./models/view-models/entity-operation-history-view-model";
import { OperationViewModel } from "./models/view-models/operation-view-model";

@Injectable({
    providedIn: 'root'
})

export class OperationService {
    constructor(private httpClient: HttpClientService) { }

    async getOperationTypes(): Promise<DefaultEnumViewModel[]> {
        return this.httpClient.get(`/operations`, `/types`);
    }

    async getAllOperations(isActive: boolean): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/operations`, `/all/${isActive}`);
    }

    async getOperationsOnGoing(): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/operations`, `/on-going`);
    }

    async getOperationsHomeReports(): Promise<GetOperationsHomeReportResponse> {
        return this.httpClient.get(`/operations`, `/home-reports`);
    }

    async getOperationsOnGoingSearch(request: PagedSearchRequest): Promise<PagedSearchResult<OperationViewModel>> {
        return this.httpClient.post(`/operations`, `/on-going/search`, request);
    }

    async getOperationsFinished(): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/operations`, `/finished`);
    }

    async getOperationsFinishedPaged(request: PagedSearchRequest): Promise<PagedSearchResult<OperationViewModel>> {
        return this.httpClient.post(`/operations`, `/finished/search`, request);
    }

    async getOperationsCanceled(): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/operations`, `/canceled`);
    }

    async getOperationsCanceledSearch(request: PagedSearchRequest): Promise<PagedSearchResult<OperationViewModel>> {
        return this.httpClient.post(`/operations`, `/canceled/search`, request);
    }

    async getOperationById(operationId: string): Promise<OperationViewModel> {
        return this.httpClient.get(`/operations`, `/${operationId}`);
    }

    async retriesOperation(operationId: string): Promise<OperationViewModel> {
        return this.httpClient.path(`/operations`, `/${operationId}/retries`);
    }

    async operationSigners(request: AddSignersRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/signers`, request);
    }

    async operationRecipients(request: AddRecipientRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/recipients`, request);
    }

    async operationAttachments(request: AddAttachmentRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/attachments`, request);
    }

    async operationAttachmentsOnFundRegulation(request: AddFundRegulationAsAttachmentRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/attachments/fund-regulations`, request);
    }

    async updateArtifacts(request: FillOperationArtifactRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/artifacts`, request);
    }

    async setupDone(request: SetSetupAsDoneRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/setup-done`, request);
    }

    async signatureReady(request: SetSetupAsDoneRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/signature-ready`, request, true);
    }

    async deleteAttachment(attachmentId: string): Promise<void> {
        return this.httpClient.delete(`/operations`, `/attachments/${attachmentId}`);
    }

    async deleteRecipients(recipientId: string): Promise<void> {
        return this.httpClient.delete(`/operations`, `/recipients/${recipientId}`);
    }

    async changeSingatures(artifactId: string): Promise<ArtifactViewModel> {
        return this.httpClient.path(`/operations/artifacts`, `/${artifactId}/signatures`, true);
    }

    async reprocessArtifact(artifactId: string): Promise<ArtifactViewModel> {
        return this.httpClient.path(`/operations`, `/artifacts/${artifactId}/reprocesses`, true);
    }

    async getOperationAssessments(operationId: string): Promise<AssessmentSummaryViewModel[]> {
        return this.httpClient.get(`/operations/${operationId}`, `/contacts/assessments`);
    }

    async getOperationAssessmentsByContact(operationId: string, contactId: string): Promise<RecipientViewModel> {
        return this.httpClient.get(`/operations/${operationId}`, `/contacts/${contactId}/assessments`);
    }

    async createAssessment(request: AssessOperationByManagerRequest): Promise<OperationViewModel> {
        return this.httpClient.post(`/operations`, `/recipients/assessments`, request);
    }

    async replyAssesment(request: ReplyOperationAssessmentRequest): Promise<OperationViewModel> {
        return this.httpClient.post(`/operations/recipients`, `/assessments/replies`, request);
    }

    async dismissAssessment(request: DismissOperationAssessmentRequest): Promise<OperationViewModel> {
        return this.httpClient.post(`/operations/recipients`, `/assessments/dismiss`, request);
    }

    async cancelOperation(request: CancelOperationRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/cancelation`, request);
    }

    async resendMailAssesment(request: ResendContactManagerNotificationRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/recipients/assessments/resend-emails`, request);
    }

    async resendEmailAllPendings(): Promise<OperationViewModelOperationViewModel> {
        return this.httpClient.put(`/operations`, `/recipients/assessments/resend-emails/all-pendings`, {})
    }

    async finishedReportDates(startDate: string, endDate: string): Promise<GetFinishedOperationsByDateRangeResponse> {
        return this.httpClient.get(`/operations`, `/reports/finisheds/start/${startDate}/end/${endDate}`);
    }

    async finishedReportYearly(year: string): Promise<GetFinishedOperationsYearlyReportResponse[]> {
        return this.httpClient.get(`/operations`, `/reports/finisheds/yearly/${year}`);
    }

    async getOperationsByFilter(request: GetOperationsByFilterRequest): Promise<OperationViewModel[]> {
        return this.httpClient.put(`/operations`, `/reports/filter`, request);
    }

    async finishedReportTimeFrames(startDate: string, endDate: string, operationTypeId: string | null): Promise<GetDoneOperationsTimeframeByDateRangeResponse[]> {
        return this.httpClient.get(`/operations`, `/reports/finisheds/type/${operationTypeId}/start/${startDate}/end/${endDate}/timeframes`);
    }

    async removeContactManagerSignersOperation(operationId: string, contactId: string): Promise<void> {
        return this.httpClient.delete(`/operations`, `/${operationId}/manager-signers/${contactId}`);
    }

    async removeContactAdminSignersOperation(operationId: string, representativeId: string): Promise<void> {
        return this.httpClient.delete(`/operations`, `/${operationId}/adminsitrator-signers/${representativeId}`);
    }

    async updateManagerSignersOperation(request: AddManagerSignersRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/manager-signers`, request);
    }

    async updateAdminSignersOperation(request: AddAdministratorSignersRequest): Promise<OperationViewModel> {
        return this.httpClient.put(`/operations`, `/adminsitrator-signers`, request);
    }

    async chargesReport(year: number, month: number): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/operations`, `/reports/charges/year/${year}/month/${month}`);
    }

    async ManagerPendingsReport(year: number, month: number): Promise<GetOperantionsPendingSummaryResponse[]> {
        return this.httpClient.get(`/operations`, `/reports/manager-pendings/year/${year}/month/${month}`);
    }

    async operationHistoryByPeriod(startDate: string, endDate: string): Promise<EntityOperationHistoryViewModel[]> {
        return this.httpClient.get(`/entity-operation-histories`, `/start/${startDate}/end/${endDate}/timeframes`);
    }
}
