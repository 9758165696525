import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typesBrokerOperation'
})

export class TypesBrokerOperationPipe implements PipeTransform {
    constructor() { }

    transform(value: string) {
        switch (value) {
            case 'CadastroDeFundo':
                return `Cadastro de Fundo`;
            case 'AtualizacaodeFundo':
                return `Atualização`;
            case 'CadastroDeCarteira':
                return `Cadastro de Carteira`;
            case 'CadastroDeInstituicao':
                return 'Cadastro de Instituição'
            case 'AtualizacaoDeInstituicao':
                return 'Atualização de Instituição'
            default:
                break;
        }
    }
}




