<div class="p-grid">
    <div class="titleCustomCard overview">
        Painel de Controle
    </div>
    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-6">
                <div class="customCardOutlined text-center">
                    <h4 class="text-bold">Total de Fundos Processados</h4>
                    <h1>{{liabilityOperationProcess}}</h1>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
                <div class="customCardOutlined text-center">
                    <h4 class="text-bold">Alterações Identificadas</h4>
                    <h1>{{liabilityOperationChanges}}</h1>
                </div>
            </div>
        </div>
    </div>
</div>