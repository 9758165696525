<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Processamentos
            </div>
        </div>
        <div class="customCard">
            <div class="p-col-12 p-md-12 p-lg-12">
                <p-tabView (onChange)="changeTab($event.index)" [(activeIndex)]="tabView">
                    <p-tabPanel header="Concluídos">
                        <p-table [responsive]="true" #dt [value]="liabilityOperationsFinished" dataKey="id"
                            styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                            [autoLayout]="true" [paginator]="true" [lazy]="true"
                            (onLazyLoad)="getLiabilityOperationsOnGoingData($event)"
                            [rows]="liabilityOperationsOnGoingResult?.pageSize"
                            [totalRecords]="liabilityOperationsOnGoingResult?.totalRecords"
                            [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                            [globalFilterFields]="['sequentialId','type.friendlyName', 'fund', 'fund.sac', 'cblc', 'addtionNumber', 'broker', 'currentStatus.status.friendlyName']">
                            <ng-template pTemplate="caption">
                                <div class="contentButtonActions p-fluid">

                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-operation>
                                <tr>
                                    <th pSortableColumn="sequentialId">
                                        <div>
                                            ID
                                            <p-sortIcon field="sequentialId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="createDate">
                                        <div>
                                            Data da Entrada
                                            <p-sortIcon field="createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.status.friendlyName">
                                        <div>
                                            Status
                                            <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.name">
                                        <div>
                                            Fundo Investido
                                            <p-sortIcon field="fund.name"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.documentNumber">
                                        <div>
                                            CNPJ F. Investido
                                            <p-sortIcon field="fund.documentNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="shareholderFund.name">
                                        <div>
                                            Fundo Investidor
                                            <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="shareholderFund.documentNumber">
                                        <div>
                                            CNPJ F. Investidor
                                            <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="contract">
                                        <div>
                                            Contrato
                                            <p-sortIcon field="contract"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-operation let-index>
                                <tr style="cursor: pointer" [pSelectableRow]="operation">
                                    <td>
                                        {{operation?.sequentialId}}
                                    </td>
                                    <td>
                                        {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{operation?.currentStatus?.status?.friendlyName}}
                                    </td>
                                    <td>
                                        {{operation?.fund?.name}}
                                    </td>
                                    <td>
                                        {{operation?.fund?.documentNumber | documentNumber: 'cnpj'}}
                                    </td>
                                    <td>
                                        {{operation?.shareholderFund?.name}}
                                    </td>
                                    <td>
                                        {{operation?.shareholderFund?.documentNumber | documentNumber: 'cnpj'}}

                                    </td>
                                    <td>
                                        {{operation?.contract}}
                                    </td>
                                    <div *ngIf="operation?.results.length > 0">
                                        <td>
                                            <button type="button" pButton pRipple class="p-button-text" label="Ver" (click)="viewLiabilityOperation(operation)"></button>
                                        </td>
                                    </div>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Nenhuma operação encontrada.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Erro Cadastral">
                        <p-table [responsive]="true" #dt [value]="liabilityOperationsOnGoing" dataKey="id"
                            styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                            [autoLayout]="true" [paginator]="true" [lazy]="true"
                            (onLazyLoad)="getLiabilityOperationsOnGoingData($event)"
                            [rows]="liabilityOperationsOnGoingResult?.pageSize"
                            [totalRecords]="liabilityOperationsOnGoingResult?.totalRecords"
                            [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                            [globalFilterFields]="['sequentialId','type.friendlyName', 'fund', 'fund.sac', 'cblc', 'addtionNumber', 'broker', 'currentStatus.status.friendlyName']">
                            <ng-template pTemplate="caption">
                                <div class="contentButtonActions p-fluid">

                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-operation>
                                <tr>
                                    <th pSortableColumn="sequentialId">
                                        <div>
                                            ID
                                            <p-sortIcon field="sequentialId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="createDate">
                                        <div>
                                            Data da Entrada
                                            <p-sortIcon field="createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.status.friendlyName">
                                        <div>
                                            Status
                                            <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.name">
                                        <div>
                                            Fundo Investido
                                            <p-sortIcon field="fund.name"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.documentNumber">
                                        <div>
                                            CNPJ F. Investido
                                            <p-sortIcon field="fund.documentNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="shareholderFund.name">
                                        <div>
                                            Fundo Investidor
                                            <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="shareholderFund.documentNumber">
                                        <div>
                                            CNPJ F. Investidor
                                            <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="contract">
                                        <div>
                                            Contrato
                                            <p-sortIcon field="contract"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-operation let-index>
                                <tr style="cursor: pointer" [pSelectableRow]="operation">
                                    <td>
                                        {{operation?.sequentialId}}
                                    </td>
                                    <td>
                                        {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{operation?.currentStatus?.status?.friendlyName}}
                                    </td>
                                    <td>
                                        {{operation?.fund?.name}}
                                    </td>
                                    <td>
                                        {{operation?.fund?.documentNumber | documentNumber: 'cnpj'}}
                                    </td>
                                    <td>
                                        {{operation?.shareholderFund?.name}}
                                    </td>
                                    <td>
                                        {{operation?.shareholderFund?.documentNumber | documentNumber: 'cnpj'}}

                                    </td>
                                    <td>
                                        {{operation?.contract}}
                                    </td>
                                    <div>
                                        <td>
                                            <!-- <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu> -->
                                            <button type="button" pButton pRipple class="p-button-text" label="Ver motivo" (click)="reasonLiabilityOperation(operation)"></button>
                                        </td>
                                    </div>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Nenhuma operação encontrada.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Detalhes" [(visible)]="displayLiabilityOperationDetail" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-col-12">
        <p-divider></p-divider>
    </div>
    <div class="p-grid" *ngIf="administratorsInfo?.length > 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Administrador: </p>
            <div *ngFor="let administrator of administratorsInfo; let i = index;">
                <p *ngIf="administrator?.propertyChanged == 'CNPJ'">{{ administrator?.oldValue ? (administrator?.oldValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="administrator?.propertyChanged !== 'CNPJ'">{{ administrator?.entityChanged == 'Administradora' ? administrator.oldValue : "Não houve alteração" }}</p>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Administrador: </p>
            <div *ngFor="let administrator of administratorsInfo; let i = index;">
                <p *ngIf="administrator?.propertyChanged == 'CNPJ'">{{ administrator?.currentValue ? (administrator?.currentValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="administrator?.propertyChanged !== 'CNPJ'">{{ administrator?.currentValue ? administrator?.currentValue : "Não houve alteração" }}</p>
            </div>
        </div>
    </div>
    <div class="p-grid" *ngIf="administratorsInfo?.length == 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Administrador: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Administrador: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
    </div>
    <div class="p-col-12">
        <p-divider></p-divider>
    </div>
    <div class="p-grid" *ngIf="managerInfo?.length > 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Gestor: </p>
            <div *ngFor="let manager of managerInfo; let i = index;">
                <p *ngIf="manager?.propertyChanged == 'CNPJ'">{{ manager?.oldValue ? (manager?.oldValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="manager?.propertyChanged !== 'CNPJ'">{{ manager?.oldValue ? manager?.oldValue : "Não houve alteração" }}</p>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Gestor: </p>
            <div *ngFor="let manager of managerInfo; let i = index;">
                <p *ngIf="manager?.propertyChanged == 'CNPJ'">{{ manager?.currentValue ? (manager?.currentValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="manager?.propertyChanged !== 'CNPJ'">{{ manager?.currentValue ? manager?.currentValue : "Não houve alteração" }}</p>
            </div>
        </div>
    </div>
    <div class="p-grid" *ngIf="managerInfo?.length == 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Gestor: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Gestor: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Motivo" [(visible)]="displayReasonLiabilityOperation" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 2 p-lg-12">
            <p class="text-bold">Motivo:</p> <span>{{selectedLiabilityOperationDisplay?.currentStatus?.notes}}</span>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>