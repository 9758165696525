<div class="p-grid">
    <div class="titleCustomCard overview">
        Painel de Controle
    </div>
    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div class="contentBetween">
            <div>
                <div class="cardHeader text-bold">Operação</div>
            </div>
            <div class="contentButtonActions">
                <button pButton type="button" label="Enviar email para Gestoras" class="p-button-outlined" (click)="sendMailtoManagers()"></button>
                <button pButton type="button" label="Sincronizar Arquivos" (click)="syncOperations()"></button>
            </div>

        </div>
        <!-- <div class="contentButtonActions">
            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
            </div>
        </div> -->
        <div style="border: 1px solid #ccc;" class="content">
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBuyBox">
                    <div class="text-bold">
                        <p>Atualização</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Não iniciados</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{updateOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Preenchendo Formulários</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{updateOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em processo de assinatura</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em análise pelo gestor</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Pendenciado pelo Gestor</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Concluídos</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{updateOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Compra</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{buyOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{buyOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{buyOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Movimentação Diária</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{dailyOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{dailyOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{dailyOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{dailyOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{dailyOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{dailyOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Transferência de Administrador</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{transferAdminOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{transferAdminOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{transferAdminOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{transferAdminOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{transferAdminOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{transferAdminOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Cisão</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{splitOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{splitOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{splitOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{splitOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{splitOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{splitOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Incorporação</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{incorporationOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{incorporationOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{incorporationOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{incorporationOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{incorporationOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{incorporationOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Integralização</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{integrationOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{integrationOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{integrationOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{integrationOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{integrationOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{integrationOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Masterização</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{masteringOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{masteringOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{masteringOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{masteringOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{masteringOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{masteringOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Boletim de Subscrição</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{subscriptionOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{subscriptionOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{subscriptionOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{subscriptionOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{subscriptionOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{subscriptionOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Alteração de Distribuidor</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{distributorChangeOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{distributorChangeOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{distributorChangeOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{distributorChangeOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{distributorChangeOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{distributorChangeOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Resgate Come-Cotas</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{ransomOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{ransomOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{ransomOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{ransomOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{ransomOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{ransomOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Cessão de Cotas - Compra/Venda</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{cessionBuyOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{cessionBuyOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionBuyOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionBuyOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionBuyOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{cessionBuyOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>


                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Cessão de Cotas - Doação</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{cessionDonationOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{cessionDonationOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionDonationOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionDonationOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{cessionDonationOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{cessionDonationOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-divider></p-divider>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Compra de Carteira</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{buyWalletOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{buyWalletOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyWalletOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyWalletOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{buyWalletOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{buyWalletOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>





    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div class="cardHeader text-bold">Total</div>
        <div style="border: 1px solid #ccc;" class="content">
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBuyBox">
                    <div class="text-bold">
                        <p>Total</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Não iniciado</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{allOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Preenchendo Formulário</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{allOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em processo de assinatura</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em Análise Pelo Gestor</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationManagerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Pendenciado pelo Gestor</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationManagerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Concluídos</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{allOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>