import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { CreateContactRequest } from "./models/requests/create-contact-request";
import { CreateFundManagerRequest } from "./models/requests/create-fund-manager-request";
import { UpdateContactRequest } from "./models/requests/update-contact-request";
import { UpdateFundManagerRequest } from "./models/requests/update-fund-manager-request";
import { ContactViewModel } from "./models/view-models/contact-view-model";
import { FundManagerViewModel } from "./models/view-models/fund-manager-view-model";

@Injectable({
    providedIn: 'root'
})

export class FundManagerService {
    constructor(private httpClient: HttpClientService) { }


    async createFundManager(request: CreateFundManagerRequest): Promise<FundManagerViewModel> {
        return this.httpClient.post(`/fund-managers`, `/`, request);
    }

    async getFundManagers(isActive: boolean): Promise<FundManagerViewModel[]> {
        return this.httpClient.get(`/fund-managers`, `/all/${isActive}`);
    }

    async getFundManagerById(fundManagerId): Promise<FundManagerViewModel> {
        return this.httpClient.get(`/fund-managers`, `/${fundManagerId}`);
    }

    async getContactsByFundManagerId(fundManagerId): Promise<ContactViewModel[]> {
        return this.httpClient.get(`/fund-managers/`, `${fundManagerId}/contacts`);
    }

    async createContactsFundManager(request: CreateContactRequest): Promise<ContactViewModel[]> {
        return this.httpClient.post(`/fund-managers`, `/contacts`, request);
    }

    async updateFundManager(request: UpdateFundManagerRequest) : Promise<FundManagerViewModel> {
        return this.httpClient.put(`/fund-managers`, `/`, request);
    }

    async deleteContactInFunManager(contactId: string): Promise<void> {
        return this.httpClient.delete(`/fund-managers`, `/contacts/${contactId}`)
    }

    async deleteFundManager(fundManagerId: string): Promise<void> {
        return this.httpClient.delete(`/fund-managers`, `/${fundManagerId}`);
    }

    async updateContactsFundManager(request: UpdateContactRequest): Promise<ContactViewModel[]> {
        return this.httpClient.put(`/fund-managers`, `/contacts`, request);
    }

    async decryptedDocumentContactFundManager(contactId: string) : Promise<any> {
        return this.httpClient.get(`/fund-managers`, `/contacts/${contactId}/document-number/decrypted`)
    }

}
