import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { AssessmentPendingReasonEnum } from 'src/app/enums/assessment-pending-reason-Enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { AssessOperationByManagerRequest } from 'src/app/services/operations/models/request/assess-operation-by-manager-request';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel, RecipientViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { LoginResponseViewModel } from 'src/app/services/users/models/view-models/login-response-view-model';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerOperationRecipientViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-recipient-view-model';
import { AssessBrokerOperationByManagerRequest } from 'src/app/services/brokers/models/request/assess-broker-operation-by-manager-request';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';

type RepresentativeViewModel = {
    id: string;
}

@Component({
    templateUrl: './broker-analysis.component.html',
    styleUrls: ['./broker-analysis.component.scss'
        , '../../../assets/layout/customized/custom-card.scss']
})
export class BrokerAnalysisComponent implements OnInit {

    selectedBrokerOperation: BrokerOperationViewModel = null;
    assesments: BrokerOperationAssessmentSummaryViewModel[] = [];
    token: string = null;
    tokenExpiration: Date = null;
    representative: string = null;
    operationId: string = null;
    requestAssesment = null;
    requestApprovedAssesment = null;
    displayAvailableAssesment: boolean = false;
    displayApprovedAssesment: boolean = false;
    selectedAssesment = null;
    assesmentReasons: number = null;
    request = null;
    notApproved: boolean = null;

    public operationStatusEnum = OperationStatusEnum;
    public artifactStatusEnum = ArtifactStatusEnum;
    displayBrokerOperationFiles: boolean = false;
    requestFiles = null;

    constructor(
        private contextService: ContextService,
        private route: ActivatedRoute,
        private brokerOperationService: BrokerService,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private fileUploadService: FileUploadService,
    ) { }

    async ngOnInit() {
        this.setForms();
        this.setFormUpload();
        await this.verifyParams();
    }

    setFormUpload() {
        this.requestFiles = {
            description: null,
            files: []
        }
    }

    setForms() {
        this.requestAssesment = {
            notes: null,
        }
        this.requestApprovedAssesment = {
            notes: null,
        }
        this.request = {
            brokerAccountInfo: null,
            operationExpirationDate: null,
            code: null,
        }
    }

    async verifyParams() {
        if (this.route.snapshot.queryParams.token &&
            this.route.snapshot.queryParams.expirationDate &&
            this.route.snapshot.queryParams.operation &&
            this.route.snapshot.queryParams.representative) {
            this.token = this.route.snapshot.queryParams.token;
            const expirationParts: string[] = this.route.snapshot.queryParams.expirationDate.split('-');
            console.log(this.route.snapshot.queryParams.expirationDate)
            this.tokenExpiration = new Date(+expirationParts[0], +expirationParts[1], +expirationParts[2], +expirationParts[3], +expirationParts[4], +expirationParts[5]);
            this.representative = this.route.snapshot.queryParams.representative;
            this.operationId = this.route.snapshot.queryParams.operation;
            await this.setToken();
        } else {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'O link não é válido.', life: 5000 });
            this.contextService.logout();
        }
    }

    async setToken() {
        this.contextService.clearUserInfo();
        // const user = this.contextService.getUserInfo().user;
        var user: RepresentativeViewModel | UserViewModel = {
            id: this.representative
        };
        const request: LoginResponseViewModel = {
            token: this.token,
            expirationDate: this.tokenExpiration,
            user: user,
            applications: [{
                application: {
                    value: 2,
                    displayName: "Broker",
                    friendlyName: "Corretoras",
                    redirectSufix: "funds"
                },
                profiles: []
                // siteMap: [],
            }],
            // siteMap: [],
        }
        this.contextService.setLoginResponse(request);
        await this.loadData();
        await this.loadAssesments();
        // this.operationNotApproved();
    }

    async loadData() {
        this.selectedBrokerOperation = await this.brokerOperationService.getBrokerOperationById(this.operationId);
        // console.log(this.selectedBrokerOperation);        
        if (this.selectedBrokerOperation == null || this.selectedBrokerOperation.currentStatus.status.value != this.operationStatusEnum.EmAnalisePeloGestor) {
            this.messageService.add({ severity: 'warn', summary: 'Operação Expirada', detail: 'Esta operação já foi analisada por outro contato.', life: 5000 });
            this.contextService.logout();
        }
    }

    async loadAssesments() {
        const brokerOperationId = this.selectedBrokerOperation.id;
        this.assesments = await this.brokerOperationService.getBrokerOperationAllAssessments(brokerOperationId);
        this.assesments = this.assesments.sort((a, b) => {
            if (new Date(a.createDate) < new Date(b.createDate)) {
                return 1;
            } else {
                return -1
            }
        });
    }

    openApprovedAssesment() {
        this.displayApprovedAssesment = true;
    }

    async approvedAssesments(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            const request: AssessBrokerOperationByManagerRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                notes: `Aprovado. Conta: ${formComponent.form.value?.brokerAccountInfo}. Data de expiração: ${formComponent.form.value?.operationExpirationDate?.toLocaleDateString('pt-BR')}. Código na Corretora: ${formComponent.form.value?.code}`,
                isApproved: true,
                brokerAccountInfo: this.request.brokerAccountInfo,
                operationExpirationDate: this.request.operationExpirationDate,
                assessmentPendingReasonId: null,
                code: this.request.code,
            }
            this.loadingService.show();
            this.displayApprovedAssesment = false;
            await this.brokerOperationService.createAssessment(request);
            await this.loadAssesments();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.displayApprovedAssesment = false;
            this.setForms();
            this.loadingService.hide();
        }
    }

    openAvailableAssesments() {
        this.displayAvailableAssesment = true
    }

    async onAvailableAssesment(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        if (this.selectedAssesment == 'Preechimento Incorreto') {
            this.assesmentReasons = AssessmentPendingReasonEnum.PreenchimentoIncorreto;
        } else if (this.selectedAssesment == 'Documento Faltante') {
            this.assesmentReasons = AssessmentPendingReasonEnum.DocumentoFaltante;
        }
        try {
            const request: AssessBrokerOperationByManagerRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                notes: this.requestAssesment.notes,
                isApproved: false,
                brokerAccountInfo: this.request.brokerAccountInfo,
                operationExpirationDate: this.request.operationExpirationDate,
                assessmentPendingReasonId: this.assesmentReasons,
                code: null,
            }
            // console.log(request)
            this.loadingService.show();
            this.displayAvailableAssesment = false;
            await this.brokerOperationService.createAssessment(request);
            await this.loadAssesments();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setForms();
            this.loadingService.hide();
        }
    }

    fillInfoTemplate(artifact: ArtifactViewModel) {
        let url = artifact.template?.fileUrl;

        if (artifact.signedFile != null) {
            url = artifact.signedFile;
        } else if (artifact?.originalFile != null) {
            url = artifact.originalFile;
        }
        window.open(url, '_blank');
    }

    openAttachementUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        window.open(file.fileUrl, '_blank');
    }

    downloadArtefact(artefact: ArtifactViewModel) {
        window.open(artefact.signedFile);
    }

    openAddFile() {
        this.displayBrokerOperationFiles = true;
    }

    async uploadFiles(event: File) {
        try {
            this.displayBrokerOperationFiles = false;
            this.loadingService.show();
            const response = await this.fileUploadService.upload(event[0]);
            const request: AddAttachmentRequest = {
                operationId: this.selectedBrokerOperation.id,
                description: this.requestFiles.description,
                fileUrl: response.fileUrl
            }
            this.selectedBrokerOperation = await this.brokerOperationService.brokerOperationAttachments(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayBrokerOperationFiles = false;
            this.setFormUpload();
        } finally {
            this.setFormUpload();
            this.loadingService.hide();
        }
    }

}
