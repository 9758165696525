import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { ContactProperties } from 'src/app/services/fund-manager/models/view-models/contact-properties';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { RecipientViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './manager-contacts-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-manager-contacts-list',
})
export class ManagerContactsListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    // @Input() actionButton: boolean = true;
    @Input() managerContacts: ContactProperties[];
    @Input() recipients: RecipientViewModel[];
    @Input() typeList: string | `Select`;
    @Input() operationHiddenButtons: boolean = false;

    @Output() checkboxToggleEmitter = new EventEmitter<ContactProperties>();
    @Output() unCheckboxToggleEmitter = new EventEmitter<ContactProperties>();
    @Output() onHeaderCheckboxToggledEmitter = new EventEmitter<boolean>();
    @Output() removeContactEmitter = new EventEmitter<ContactProperties>();
    @Output() resendEmailEmitter = new EventEmitter();

    selecetedManagerContact: ContactProperties[] = [];
    responseManager: AssessmentSummaryViewModel = null;
    assessments: AssessmentSummaryViewModel[] = [];

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private router: Router,
        private loadingService: LoadingService,
        private route: ActivatedRoute,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        await this.getAssesments();
        if (this.recipients !== null) {
            for (let index = 0; index < this.recipients.length; index++) {
                const element = this.recipients[index];
                // console.log(element);
                this.selecetedManagerContact.push(element.contact);
            }
            // this.selecetedManagerContact = this.recipients.map(c => c.contact);
        }
    }

    async getAssesments() {
        const operationId = this.route.snapshot.params.id;
        this.assessments = await this.operationService.getOperationAssessments(operationId);
        this.responseManager = this.assessments.length > 0 ? this.assessments[0] : null;
    }

    selectedCheckbox(event: ContactProperties) {
        this.checkboxToggleEmitter.emit(event)
    }

    unSelectCheckbox(event: ContactProperties) {
        this.unCheckboxToggleEmitter.emit(event)
    }

    onHeaderCheckboxToggled(event: boolean) {
        this.onHeaderCheckboxToggledEmitter.emit(event);
    }

    removeContact(contact: ContactProperties) {
        this.removeContactEmitter.emit(contact);
    }

    resendEmail(contact) {
        this.resendEmailEmitter.emit(contact);
    }


}