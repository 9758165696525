import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateFundAdministratorRequest, RepresentativeProperties } from 'src/app/services/fund-administrators/models/requests/create-fund-administrator-request';
import { CreateRepresentativeRequest } from 'src/app/services/fund-administrators/models/requests/create-representative-request';
import { UpdateRepresentativeRequest } from 'src/app/services/fund-administrators/models/requests/update-representative-request';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';

@Component({
    templateUrl: './fund-administrator-edit.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundAdministratorEditComponent implements OnInit {

    requestModel: CreateFundAdministratorRequest = null;
    requestRepresentative: CreateRepresentativeRequest = null;
    representations: RepresentativeProperties[] = [];
    selectedFundAdministrator: FundAdministratorViewModel = null;
    requestExpireDate: Date = null;
    displayRegistrationFormTemplate: boolean = false;
    allRepresentatives: RepresentativeViewModel[] = null;
    selectedRepresentative: RepresentativeViewModel = null;
    displayEditRep: boolean = false;
    selectedExpirateDateRep: Date = null;
    selectedDocumentEncryptedRep: string = null;

    constructor(
        private fundAdministratorService: FundAdministratorService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setMainRequestForm();
        await this.getFundAdministrator();
        await this.getRepresentativesInFundAdministrator();
    }

    setMainRequestForm() {
        this.requestModel = {
            name: null,
            documentNumber: null,
            registrationFormTemplateRequest: null,
            phoneNumber: null,
            email: null,
            giinNumber: null,
            representatives: this.representations
        };
    }

    async getFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundAdministratorId).then(() => {
            this.selectedFundAdministrator.documentNumber = this.parseCNPJ(this.selectedFundAdministrator.documentNumber)
        });
        // console.log(this.selectedFundAdministrator)
    }

    async getFundAdministratorById(fundAdministratorId: string) {
        this.selectedFundAdministrator = await this.fundAdministratorService.getById(fundAdministratorId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    async addRepresentations(form: NgForm) {

        if (!this.formValidationService.validateForm(form.form)) return;

        const request: CreateRepresentativeRequest = {
            representativeId: this.selectedFundAdministrator.id,
            name: form.value.name,
            personalDocumentNumber: form.value.personalDocumentNumber,
            email: form.value.email,
            expireDate: form.value.expireDate,
        }
        try {
            this.loadingService.show();
            await this.fundAdministratorService.createRepresentative(request);
            await this.getRepresentativesInFundAdministrator();
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            form.reset();
            this.loadingService.hide();
        }
    }

    // removeRepresentation(representation: any) {
    //     this.representations = this.representations.filter(item => {
    //         return item !== representation
    //     })
    // }

    async removeCurrentRepresentation(representation: RepresentativeViewModel) {

        if (this.allRepresentatives.length == 1) {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: `É necessário ter ao menos um representante.`, life: 5000 });
            return;
        } else {
            // this.allRepresentatives = this.allRepresentatives.filter(item => {
            //     return item !== representation;
            // });
            try {
                this.loadingService.show();
                const representativeId = representation.id;
                await this.fundAdministratorService.deleteRepresentative(representativeId);
                await this.getRepresentativesInFundAdministrator();
                this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            } finally {
                this.loadingService.hide();
            }
        }


    }

    openRegistrationFormTemplate() {
        this.displayRegistrationFormTemplate = true;
    }

    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }

    async getRepresentativesInFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundAdministratorService.getRepresentativesInFundAdministrator(fundAdministratorId);
        // console.log(this.allRepresentatives)
    }


    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-administrator/list`)
    }

    onEditCancel() {
        this.displayEditRep = false;
    }


    async onEditRepresentative(form: NgForm) {

        if (!this.formValidationService.validateForm(form.form)) return;

        this.loadingService.show();
        const request: UpdateRepresentativeRequest = {
            representativeId: this.selectedRepresentative.id,
            name: form.value.name,
            email: form.value.email,
            personalDocumentNumber: form.value.maskedPersonalDocumentNumber,
            expireDate: this.selectedExpirateDateRep,
        }
        try {
            await this.fundAdministratorService.updateRepresentativeFundAdmin(request);
            await this.getRepresentativesInFundAdministrator();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.displayEditRep = false;
            this.loadingService.hide();
        }
    }


    updateRegistrationFormTemplate() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-administrator/edit-form-template/${fundAdministratorId}`)
    }

    async editCurrentRepresentation(representation: RepresentativeViewModel) {

        this.selectedRepresentative = representation;
        this.selectedExpirateDateRep = new Date(this.selectedRepresentative?.expireDate)
        if(this.selectedRepresentative.maskedPersonalDocumentNumber) {
            await this.fundAdministratorService.decryptedDocumentRepresentativeFund(representation.id).then((response) => {
                this.selectedDocumentEncryptedRep = response.decryptedDocumentNumber
            });
        } else {
            this.selectedDocumentEncryptedRep = this.selectedRepresentative.maskedPersonalDocumentNumber
        }
        this.displayEditRep = true;
    }

}