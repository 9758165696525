import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../modules/loading/app.loading.service';
import { ContextService } from './context-service';
import { HttpContext } from './models/http-context';
import { HttpHandledError } from './models/http-handled-error';

@Injectable({
    providedIn: 'root'
})
export class HttpClientService {
    constructor(
        private messageService: MessageService,
        private loadingService: LoadingService,
        private http: HttpClient,
        private contextService: ContextService) { }

    async path(servicePath: string, endpoint: string, mustHaveToken: boolean = true, showErrorMessage = true, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, true, isAuth);
        return new Observable(observer => {
            this.http.patch(httpContext.url, null, { headers: httpContext.headers }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });
        }).toPromise();
    }

    async get(servicePath: string, endpoint: string, mustHaveToken: boolean = true, showErrorMessage = true, params?: any, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, true, isAuth);
        return new Observable(observer => {
            this.http.get(httpContext.url, { headers: httpContext.headers, params: params }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });

        }).toPromise();
    }

    async delete(servicePath: string, endpoint: string, mustHaveToken: boolean = true, showErrorMessage = true, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, true, isAuth);
        return new Observable(observer => {
            this.http.delete(httpContext.url, { headers: httpContext.headers }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });
        }).toPromise();
    }

    async post(servicePath: string, endpoint: string, data: any, mustHaveToken: boolean = true, showErrorMessage = true, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, true, isAuth);
        return new Observable(observer => {
            this.http.post(httpContext.url, data, { headers: httpContext.headers }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });
        }).toPromise();
    }

    async put(servicePath: string, endpoint: string, data: any, mustHaveToken: boolean = true, showErrorMessage = true, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, true, isAuth);
        return new Observable(observer => {
            this.http.put(httpContext.url, data, { headers: httpContext.headers }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });
        }).toPromise();
    }

    async postFile(servicePath: string, endpoint: string, file: File, mustHaveToken: boolean = true, showErrorMessage = true, isAuth: boolean = false): Promise<any> {
        const httpContext = this.buildHttpContext(servicePath, endpoint, mustHaveToken, false, isAuth);
        return new Observable(observer => {
            let formData: FormData = new FormData();
            formData.append('File', file, file.name);
            this.http.post(httpContext.url, formData, { headers: httpContext.headers }).pipe().subscribe((response: any) => {
                observer.next(response);
                observer.complete();
            }, async (errorResponse: HttpErrorResponse) => {
                const handledError = this.handleError(errorResponse, showErrorMessage);
                observer.error(handledError);
                observer.complete();
            });
        }).toPromise();
    }

    private handleError(errorResponse: HttpErrorResponse, showErrorMessage = true): HttpHandledError {
        console.error(errorResponse);
        let messageError = errorResponse.message;
        if (errorResponse.error != null && errorResponse.error.errors != null && errorResponse.error.errors.length > 0) {
            messageError = errorResponse.error.errors[0].message;
        } else if (errorResponse.error != null) {
            messageError = errorResponse.error;
        }

        if (showErrorMessage) {
            //this.messageService.add({ severity: (errorResponse.status == 404 ? 'warn' : 'error'), summary: (errorResponse.status == 404 ? 'Atenção' : 'Erro'), detail: messageError, life: 5000 });
            if (errorResponse.status == 401) {
                this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Seu perfil de usuário não permite executar essa ação.', life: 5000 });
            }
        }
        this.loadingService.hide(3000);

        const handledError: HttpHandledError = {
            statusCode: errorResponse.status,
            messageError: messageError
        };

        return handledError;
    }

    private buildHttpContext(servicePath: string, endpoint: string, mustHaveToken: boolean, isJson: boolean, isAuth: boolean): HttpContext {

        const baseUrl = isAuth ? environment.authBaseUrl : environment.baseApiUrl;
        let httpContext = {
            url: baseUrl + servicePath + endpoint,
            headers: null
        };

        let headers = new HttpHeaders();
        if (isJson) {
            headers = headers.append('Content-Type', 'application/json');
        }

        if (mustHaveToken) {
            const userInfo = this.contextService.getUserInfo();
            if (userInfo == null) {
                const whitelabelContext = this.contextService.getWhiteLabelContext();
                console.log("whitelabelContext", whitelabelContext)
                if (whitelabelContext?.token) {
                    headers = headers.append('authorization', 'Bearer ' + whitelabelContext.token);
                }
            } else if ((new Date(userInfo.expirationDate) <= new Date())) {
                // if (userInfo?.token == null) {
                this.messageService.add({ severity: 'error', summary: 'Token Expirado', detail: 'O seu token expirou, faça o login novamente', life: 5000 });
                this.loadingService.hide();
                this.contextService.logout();
                return;
                // }
            } else {
                headers = headers.append('authorization', 'Bearer ' + userInfo.token);
            }
            // Trying to inject whiteLabelContextToken
        }

        httpContext.headers = headers;
        return httpContext;
    }
}
