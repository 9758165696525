<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Instituição
            </div>

        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button *ngIf="!isUserOperator" pButton pRipple type="button" label="Editar"
                        class="p-button-primary" (click)="onEdit()"></button>
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
            </div>
            <div class="p-grid">

                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Razão Social</p>
                    <p>{{selectedInstitution?.name ? selectedInstitution?.name: "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedInstitution?.documentNumber ? selectedInstitution?.documentNumber : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Patrimônio Líquido </p>
                    <p *ngIf="!isNumberPatrimony">{{selectedInstitution?.patrimony}}</p>
                    <p *ngIf="isNumberPatrimony">{{selectedInstitution?.patrimony ? (selectedInstitution?.patrimony | currency: 'BRL') : "-" }}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">NIRE </p>
                    <p>{{selectedInstitution?.nire ? selectedInstitution?.nire : "-" }}
                    </p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Faturamento últimos 12 meses</p>
                    <p *ngIf="!isNumberLastYearInvoicing">{{selectedInstitution?.lastYearInvoicing}}</p>
                    <p *ngIf="isNumberLastYearInvoicing">{{selectedInstitution?.lastYearInvoicing ? (selectedInstitution?.lastYearInvoicing | currency:'BRL') : "-" }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Capital Social</p>
                    <p *ngIf="!isNumberShareCapital">{{selectedInstitution?.shareCapital}}</p>
                    <p *ngIf="isNumberShareCapital">{{selectedInstitution?.shareCapital ? (selectedInstitution?.shareCapital | currency: 'BRL') : "-"}}</p>
                </div>


                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Data de constituição </p>
                    <p>{{selectedInstitution?.establishmentDate | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">CNAE</p>
                    <p>{{selectedInstitution?.cnaeCode ? selectedInstitution?.cnaeCode : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Descrição CNAE</p>
                    <p>{{selectedInstitution?.cnaeDescription ? selectedInstitution?.cnaeDescription : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Natureza Legal</p>
                    <p>{{selectedInstitution?.legalNatureCode ? selectedInstitution?.legalNatureCode : "-"}}
                        {{selectedInstitution?.legalNatureDescription ? selectedInstitution?.legalNatureDescription :
                        "-"}}</p>
                </div>



                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Nome do banco</p>
                    <p>{{selectedInstitution?.bankName ? selectedInstitution?.bankName : "-"}}
                    </p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Número do banco</p>
                    <p>{{selectedInstitution?.bankCode ? selectedInstitution?.bankCode : "-"}}
                    </p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Agência</p>
                    <p>{{selectedInstitution?.agencyNumber ? selectedInstitution?.agencyNumber
                        : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Conta corrente</p>
                    <p>{{selectedInstitution?.accountNumber ?
                        selectedInstitution?.accountNumber :
                        "-"}}-{{selectedInstitution?.accountDigit ?
                        selectedInstitution?.accountDigit : "-"}}
                    </p>
                </div>
            </div>
        </div>
    </div>

<!-- 
    <p-dialog [(visible)]="displayAdhesionTermTemplate" [modal]="true" [responsive]="true" [style]="{width: '40vw'}">
        <div class="cardHeader text-bold">Termo de Adesão</div>
        <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledAdhesion($event)">
        </app-artifact-template-create>
    </p-dialog> -->

    <!-- <p-dialog [(visible)]="displaySubscriptionFormTemplate" [modal]="true" [responsive]="true"
        [style]="{width: '40vw'}">
        <div class="cardHeader text-bold">Boletim de Subscrição</div>
        <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledSubscription($event)">
        </app-artifact-template-create>
    </p-dialog> -->