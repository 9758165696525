<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Operações Recebidas
            </div>
        </div>
        <div class="customCard">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div style="display: flex;">
                    <span class="p-float-label">
                        <p-dropdown [options]="companyOptions" optionLabel="name" appendTo="body" [showClear]="true"
                            [(ngModel)]="selectedCompany" (onChange)="changeCompany($event.value)"
                            placeholder="Selecione a Empresa" name="label" #company="ngModel">
                        </p-dropdown>
                    </span>
                </div>
            </div>
            <div *ngIf="selectedCompany && selectedApplication?.value == applicationTypeEnum.FoF">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p-tabView (onChange)="changeTab($event.index)" [(activeIndex)]="tabView">
                        <p-tabPanel header="Em Andamento">
                            <p-table [responsive]="true" #dt [value]="integratedOperationsPending" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'investedFund.name', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.name">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-admin let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="admin">
                                        <td>
                                            {{admin?.sequentialId}}
                                        </td>
                                        <td>
                                            {{admin?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{admin?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            {{admin?.shareholderFund?.name}}
                                        </td>
                                        <td>
                                            {{admin?.investedFund?.name}}
                                        </td>
                                        <td>
                                            {{admin?.source}}
                                        </td>
                                        <td>
                                            {{admin?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(admin)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Concluídos">
                            <p-table [responsive]="true" #dtFinish [value]="integratedOperationsFinished" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'investedFund.name', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Conclusão
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.name">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-admin let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="admin">
                                        <td>
                                            {{admin?.sequentialId}}
                                        </td>
                                        <td>
                                            {{admin?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{admin?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{admin?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            {{admin?.shareholderFund?.name}}
                                        </td>
                                        <td>
                                            {{admin?.investedFund?.name}}
                                        </td>
                                        <td>
                                            {{admin?.source}}
                                        </td>
                                        <td>
                                            {{admin?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(admin)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Cancelados">
                            <p-table [responsive]="true" #dtCanceled1 [value]="integratedOperationsCanceled" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'investedFund.name', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Cancelamento
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.name">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-admin let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="admin">
                                        <td>
                                            {{admin?.sequentialId}}
                                        </td>
                                        <td>
                                            {{admin?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{admin?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{admin?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            {{admin?.shareholderFund?.name}}
                                        </td>
                                        <td>
                                            {{admin?.investedFund?.name}}
                                        </td>
                                        <td>
                                            {{admin ?.source}}
                                        </td>
                                        <td>
                                            {{admin?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(admin)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
            <div *ngIf="selectedCompany && selectedApplication?.value == applicationTypeEnum.Broker">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p-tabView (onChange)="changeTab($event.index)" [(activeIndex)]="tabView">
                        <p-tabPanel header="Em Andamento">
                            <p-table [responsive]="true" #dt [value]="integratedOperationsPending" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'fund', 'fund.sac', 'cblc', 'addtionNumber', 'broker', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
    
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operation>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="fund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund.sac">
                                            <div>
                                                SIGLA SAC
                                                <p-sortIcon field="fund.sac"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="cblc">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="cblc"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="addtionNumber">
                                            <div>
                                                ADITAMENTO
                                                <p-sortIcon field="addtionNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeFundo || 
                                                operation?.type?.value == brokerOperationTypeEnum.AtualizacaodeFundo">
                                                {{operation?.fund?.name}}
                                            </div>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeInstituicao || 
                                            operation?.type?.value == brokerOperationTypeEnum.AtualizacaoDeInstituicao">
                                                {{operation?.financialInstitution?.name}}
                                            </div>
                                        </td>
                                        <td>
                                            {{operation?.frozenFundSAC}}
                                        </td>
                                        <td>
                                            {{operation?.cblc}}
                                        </td>
                                        <td>
                                            {{operation?.addtionNumber}}
                                        </td>
                                        <td>
                                            {{operation?.broker?.name}}
                                        </td>
                                        <td>
                                            {{operation?.source}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Concluídos">
                            <p-table [responsive]="true" #dtFinish [value]="integratedOperationsFinished" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'fund.name', 'fund.sac', 'cblc', 'addtionNumber', 'broker.name', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Conclusão
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="fund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund.sac">
                                            <div>
                                                SIGLA SAC
                                                <p-sortIcon field="fund.sac"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="cblc">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="cblc"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="addtionNumber">
                                            <div>
                                                ADITAMENTO
                                                <p-sortIcon field="addtionNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeFundo || 
                                                operation?.type?.value == brokerOperationTypeEnum.AtualizacaodeFundo">
                                                {{operation?.fund?.name}}
                                            </div>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeInstituicao || 
                                            operation?.type?.value == brokerOperationTypeEnum.AtualizacaoDeInstituicao">
                                                {{operation?.financialInstitution?.name}}
                                            </div>
                                        </td>
                                        <td>
                                            {{operation?.frozenFundSAC}}
                                        </td>
                                        <td>
                                            {{operation?.cblc}}
                                        </td>
                                        <td>
                                            {{operation?.addtionNumber}}
                                        </td>
                                        <td>
                                            {{operation?.broker?.name}}
                                        </td>
                                        <td>
                                            {{operation?.source}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Cancelados">
                            <p-table [responsive]="true" #dtCanceled [value]="integratedOperationsCanceled" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [rows]="10" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'fund.name', 'fund.sac', 'cblc', 'addtionNumber', 'broker.name', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
    
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Cancelamento
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="fund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="fund.sac">
                                            <div>
                                                SIGLA SAC
                                                <p-sortIcon field="fund.sac"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="cblc">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="cblc"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="addtionNumber">
                                            <div>
                                                ADITAMENTO
                                                <p-sortIcon field="addtionNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="source">
                                            <div>
                                                Origem
                                                <p-sortIcon field="source"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.type?.friendlyName}}
                                        </td>
                                        <td>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeFundo || 
                                                operation?.type?.value == brokerOperationTypeEnum.AtualizacaodeFundo">
                                                {{operation?.fund?.name}}
                                            </div>
                                            <div
                                                *ngIf="operation?.type?.value === brokerOperationTypeEnum.CadastroDeInstituicao || 
                                            operation?.type?.value == brokerOperationTypeEnum.AtualizacaoDeInstituicao">
                                                {{operation?.financialInstitution?.name}}
                                            </div>
                                        </td>
                                        <td>
                                            {{operation?.frozenFundSAC}}
                                        </td>
                                        <td>
                                            {{operation?.cblc}}
                                        </td>
                                        <td>
                                            {{operation?.addtionNumber}}
                                        </td>
                                        <td>
                                            {{operation?.broker?.name}}
                                        </td>
                                        <td>
                                            {{operation?.source}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.status?.friendlyName}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Detalhes" [(visible)]="displayOperationDetail" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">
    <div class="p-grid">
        <div class="p-col-12 textPrimary contentCenterColumn">
            Público Alvo ANBIMA: {{selectedOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup}}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 textPrimary contentCenterColumn">
            Operação: {{selectedOperation?.type?.friendlyName}}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Fundo Cotista:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedOperation?.shareholderFund?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedOperation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Fundo investido:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedOperation?.investedFund?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedOperation?.investedFund?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Distribuidor do fundo que receberá o rebate da aplicação:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedOperation?.operationDistributorName}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedOperation?.operationDistributorDocumentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Administrador do Fundo investido:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedOperation?.investedFund?.administrator?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedOperation?.investedFund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Gestora do Fundo investido:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedOperation?.investedFund?.manager?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedOperation?.investedFund?.manager?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <h5 class="text-bold">Histórico:</h5>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-scrollPanel [style]="{width: '100%', height: '500px'}">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab *ngFor="let item of selectedOperation?.history; let i = index" [attr.data-index]="i"
                        expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up"
                        header="{{item?.createDate | date : 'dd/MM/yyyy HH:mm'}} - {{item?.status?.friendlyName}}"
                        class="p-mt-3">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <p><span [innerHTML]="breakTextLine(item?.notes)"></span></p>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </p-scrollPanel>
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Erro ao iniciar operação" [(visible)]="displayOperationInit" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="cardHeader text-bold">{{selectedOperation?.currentStatus?.notes}}</div>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Motivo" [(visible)]="displayReasonOperation" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 2 p-lg-12">
            <p class="text-bold">Motivo:</p> <span>{{selectedOperationDisplay?.currentStatus?.notes}}</span>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>


<p-dialog header="Operação" [(visible)]="displayViewOperation" [modal]="true" [responsive]="true" [style]="{'width': '80%'}">
    <div *ngIf="selectedApplication?.value == applicationTypeEnum.Broker">
        <div class="p-grid">
            <div class="p-col-12" *ngIf="
            selectedBrokerOperation?.type?.value == brokerOperationTypeEnum.CadastroDeFundo ||
            selectedBrokerOperation?.type?.value == brokerOperationTypeEnum.AtualizacaodeFundo">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        {{selectedBrokerOperation?.type?.friendlyName}}
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">ID</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.sequentialId}}
                        </div>
    
                        <div class="p-col-12 p-md-1 p-lg-1">
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                        </div>
    
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Fundo:</p>
                        </div>
    
                        <div class="p-col-12 col-md-6 p-lg-6">
                            {{selectedBrokerOperation?.fund?.name}}
                            <a class="linkPrimary leftSpacingText" target="_blank"
                                [routerLink]="['/app/customer/fund/view/', selectedBrokerOperation?.fund?.id]">Ver</a>
                        </div>
    
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.fund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold"> Corretora:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.broker?.name}}
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Administrador do Fundo:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedBrokerOperation?.fund?.administrator?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedBrokerOperation?.fund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
    
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Gestor do Fundo:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedBrokerOperation?.fund?.manager?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedBrokerOperation?.fund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                        selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="contentAlignItemsCenter">
                                <p class="textPrimary">Consulta CVM:</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            <div class="contentRowStartItems">
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                                    target="_blank">Site CVM</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="p-col-12" *ngIf="
                selectedBrokerOperation?.type?.value == brokerOperationTypeEnum.CadastroDeInstituicao ||
                selectedBrokerOperation?.type?.value == brokerOperationTypeEnum.AtualizacaoDeInstituicao">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        {{selectedBrokerOperation?.type?.friendlyName}}
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">ID</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.sequentialId}}
                        </div>
    
                        <div class="p-col-12 p-md-1 p-lg-1">
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                        </div>
    
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Instituição:</p>
                        </div>
    
                        <div class="p-col-12 col-md-6 p-lg-6">
                            {{selectedBrokerOperation?.financialInstitution?.name}}
                            <a class="linkPrimary leftSpacingText" target="_blank"
                                [routerLink]="['/app/customer/financial-institution/view/', selectedBrokerOperation?.financialInstitution?.id]">Ver</a>
                        </div>
    
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.financialInstitution?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold"> Corretora:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.broker?.name}}
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                        selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="contentAlignItemsCenter">
                                <p class="textPrimary">Consulta CVM:</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            <div class="contentRowStartItems">
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                                    target="_blank">Site CVM</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        Etapas
                    </div>
                </div>
                <div class="customCard">
                    <form #entryForm="ngForm" novalidate>
                        <div class="p-col-12 p-md-12 p-lg-12">
    
                            <p-steps class="contentSteps" #pStep [model]="steps" [readonly]="true"
                                [activeIndex]="currentStep">
                            </p-steps>
    
                            <div class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12 text-center">
                                    <div class="cardHeader">
                                        <span class="text-bold">Status: </span>
                                        <span
                                            [ngClass]="{
                                            'textPrimary': selectedBrokerOperation?.currentStatus?.status?.value <= 6,
                                            'linkSecondary': selectedBrokerOperation?.currentStatus?.status?.value == 8}">{{
                                            selectedBrokerOperation?.currentStatus?.status?.friendlyName}}</span>
                                    </div>
                                </div>
                            </div>
    
                            <div *ngIf="pStep.activeIndex == 1" class="p-grid">
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [brokerHiddenButtom1]="true" [selectedBrokerOperation]="selectedBrokerOperation">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list
                                            [selectedBrokerOperation]="selectedBrokerOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Rep. Legais/Procuradores da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-signers-list [brokerHiddenButtons]="true" [signers]="selectedBrokerOperation?.brokerSigners"
                                            [typeList]="'None'">
                                        </app-broker-signers-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-contacts-list [brokerHiddenButtons]="true" [recipients]="selectedBrokerOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-broker-contacts-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation" [canRemoveFile]="false"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
                            </div>
    
                            <div *ngIf="pStep.activeIndex == 2" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [brokerHiddenButtom2]="true" [selectedBrokerOperation]="selectedBrokerOperation"
                                            [step]="2">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list
                                            [selectedBrokerOperation]="selectedBrokerOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Rep. Legais/Procuradores da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-signers-list [brokerHiddenButtons]="true" [signers]="selectedBrokerOperation?.brokerSigners"
                                            [typeList]="'None'">
                                        </app-broker-signers-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-contacts-list [brokerHiddenButtons]="true" [recipients]="selectedBrokerOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-broker-contacts-list>
                                    </div>
                                </div>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation" [canRemoveFile]="false"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
    
                                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
    
                                </div>
                            </div>
    
                            <div *ngIf="pStep.activeIndex == 3" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos 
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [brokerHiddenButtom3]="true" [selectedBrokerOperation]="selectedBrokerOperation"
                                            [step]="3">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list
                                            [selectedBrokerOperation]="selectedBrokerOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Rep. Legais/Procuradores da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-signers-list [brokerHiddenButtons]="true" [signers]="selectedBrokerOperation?.brokerSigners"
                                            [typeList]="'None'">
                                        </app-broker-signers-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-contacts-list [brokerHiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation"
                                            [recipients]="selectedBrokerOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-broker-contacts-list>
                                    </div>
                                    <br>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation" [canRemoveFile]="false"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)">
                                        </app-attachment-list>
                                    </div>
                                </div>
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedBrokerOperation?.assessments">
                                    <div class="cardHeader text-bold">
                                        Mensagens
                                    </div>
                                    <div class="borderOutlined">
                                        <app-assessment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                            [brokerAssessments]="selectedBrokerOperation?.assessments"></app-assessment-list>
                                    </div>
                                    <br>
                                </div>
                                <br>
                            </div>
    
                            <div *ngIf="pStep.activeIndex == 4 || pStep.activeIndex == 5" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [brokerHiddenButtom4]="true" [selectedBrokerOperation]="selectedBrokerOperation"
                                            [step]="4">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list
                                            [selectedBrokerOperation]="selectedBrokerOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Rep. Legais/Procuradores da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-signers-list [brokerHiddenButtons]="true" [signers]="selectedBrokerOperation?.brokerSigners"
                                            [typeList]="'None'">
                                        </app-broker-signers-list>
                                    </div>
                                </div>
    
                                <br>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Corretora
                                    </div>
                                    <div class="borderOutlined">
                                        <app-broker-contacts-list [brokerHiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation"
                                            [recipients]="selectedBrokerOperation?.recipients" [typeList]="'None'">
                                        </app-broker-contacts-list>
                                    </div>
                                </div>
    
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [selectedBrokerOperation]="selectedBrokerOperation" [canRemoveFile]="false"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
    
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedBrokerOperation?.assessments">
                                    <div class="cardHeader text-bold">
                                        Mensagens
                                    </div>
                                    <div class="borderOutlined">
                                        <app-assessment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                            [brokerAssessments]="selectedBrokerOperation?.assessments"></app-assessment-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedApplication?.value == applicationTypeEnum.FoF">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        {{selectedOperation?.type?.friendlyName}}
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <!-- <div class="line"></div> -->
                        <!-- <div class="p-col-9 p-md-9 p-lg-9"></div> -->
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">ID</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedOperation?.sequentialId}}
                        </div>
        
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">Público Alvo ANBIMA</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup }}
                        </div>
        
                        <!-- <div class="line"></div> -->
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Fundo Cotista:</p>
                        </div>
        
                        <div class="p-col-12 col-md-6 p-lg-6">
                            {{selectedOperation?.shareholderFund?.name}}
                            <a class="linkPrimary leftSpacingText" target="_blank"
                                [routerLink]="['/app/customer/fund/view/', selectedOperation?.shareholderFund?.id]">Ver</a>
                        </div>
        
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold"> Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedOperation?.investedFund?.name}}
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.investedFund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Distribuidor do fundo que receberá o rebate da aplicação:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.operationDistributorName}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedOperation?.operationDistributorDocumentNumber| documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Administrador do Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.investedFund?.administrator?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedOperation?.investedFund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
        
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Gestora do Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.investedFund?.manager?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedOperation?.investedFund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                        selectedOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="contentAlignItemsCenter">
                                <p class="textPrimary">Consulta CVM:</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            <div class="contentRowStartItems">
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                                    target="_blank">Site CVM</a>
                            </div>
                        </div>
        
                        <!-- <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Público Anbima:</p>
                        </div>
                        <div class="p-col-12 p-md-5 p-lg-5 contentAlignItemsCenter">
                            <span>{{selectedOperation?.shareholderFund?.ambimaTargetGroup}}</span>
                        </div> -->
        
                    </div>
                </div>
            </div>
        </div>
        
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        Etapas
                    </div>
                </div>
                <div class="customCard">
                    <form #entryForm="ngForm" novalidate>
                        <div class="p-col-12 p-md-12 p-lg-12">
        
                            <p-steps class="contentSteps" #pStep [model]="steps" [readonly]="true" [activeIndex]="currentStep">
                            </p-steps>
        
                            <div class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12 text-center">
                                    <div class="cardHeader">
                                        <span class="text-bold">Status: </span>
                                        <span [ngClass]="{
                                            'textPrimary': selectedOperation?.currentStatus?.status?.value <= 6,
                                            'linkSecondary': selectedOperation?.currentStatus?.status?.value == 8}">{{
                                            selectedOperation?.currentStatus?.status?.friendlyName}}</span>
                                    </div>
                                </div>
                            </div>
        
                            <div *ngIf="pStep.activeIndex == 1" class="p-grid">
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [operationHiddenButtom1]="true" [selectedOperation]="selectedOperation" [step]="1" >
                                        </app-artifact-template-list>
                                    </div>
                                </div>
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
        
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12"
                                    *ngIf="selectedOperation?.administratorSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Administradora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-admin-contacts-list [typeList]="'None'"
                                            [administratorSigners]="selectedOperation?.administratorSigners">
                                        </app-fund-admin-contacts-list>
                                    </div>
                                </div>
        
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-manager-contacts-list [typeList]="'None'"
                                            [managerSigners]="selectedOperation?.managerSigners">
                                        </app-fund-manager-contacts-list>
                                    </div>
                                </div>
        
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Gestora do fundo investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-manager-contacts-list [operationHiddenButtons]="true" [recipients]="selectedOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-manager-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [canRemoveFile]="false" [selectedOperation]="selectedOperation"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                                </div>
                            </div>
        
                            <div *ngIf="pStep.activeIndex == 2" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [operationHiddenButtom2]="true" [selectedOperation]="selectedOperation" [step]="2">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
        
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12"
                                    *ngIf="selectedOperation?.administratorSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Administradora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-admin-contacts-list [typeList]="'None'"
                                            [administratorSigners]="selectedOperation?.administratorSigners">
                                        </app-fund-admin-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-manager-contacts-list [typeList]="'None'"
                                            [managerSigners]="selectedOperation?.managerSigners">
                                        </app-fund-manager-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Gestora do fundo investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-manager-contacts-list [operationHiddenButtons]="true" [recipients]="selectedOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-manager-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [canRemoveFile]="false" [selectedOperation]="selectedOperation"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
        
                                </div>
                            </div>
        
                            <div *ngIf="pStep.activeIndex == 3" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [operationHiddenButtom3]="true" [selectedOperation]="selectedOperation" [step]="3">
                                        </app-artifact-template-list>
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12"
                                    *ngIf="selectedOperation?.administratorSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Administradora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-admin-contacts-list [typeList]="'None'"
                                            [administratorSigners]="selectedOperation?.administratorSigners">
                                        </app-fund-admin-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procurador da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-manager-contacts-list [typeList]="'None'"
                                            [managerSigners]="selectedOperation?.managerSigners">
                                        </app-fund-manager-contacts-list>
                                    </div>
                                </div>
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-manager-contacts-list [operationHiddenButtons]="true" [recipients]="selectedOperation?.recipients"
                                            [selectedOperation]="selectedOperation" [typeList]="'None'">
                                        </app-manager-contacts-list>
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [canRemoveFile]="false" [selectedOperation]="selectedOperation"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)">
                                        </app-attachment-list>
                                    </div>
                                </div>
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.assessments.length > 0">
                                    <div class="cardHeader text-bold">
                                        Mensagens
                                    </div>
                                    <div class="borderOutlined">
                                        <app-assessment-list [selectedOperation]="selectedOperation"
                                            [assessments]="selectedOperation?.assessments"></app-assessment-list>
                                    </div>
                                    <br>
                                </div>
                                <br>
                            </div>
        
                            <div *ngIf="pStep.activeIndex == 4 || pStep.activeIndex == 5" class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Documentos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-artifact-template-list (fillInfoTemplateEmitter)="fillInfoTemplate($event)" [operationHiddenButtom4]="true" [selectedOperation]="selectedOperation" [step]="4">
                                        </app-artifact-template-list>
        
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="borderOutlined">
                                        <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                        </app-legal-representative-fund-list>
                                    </div>
                                </div>
        
                                <br>
        
                                <div class="p-col-12 p-md-12 p-lg-12"
                                    *ngIf="selectedOperation?.administratorSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procuradores da Administradora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-admin-contacts-list [typeList]="'None'"
                                            [administratorSigners]="selectedOperation?.administratorSigners">
                                        </app-fund-admin-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                                    <div class="cardHeader text-bold">
                                        Rep. Legal/Procuradores da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-fund-manager-contacts-list [typeList]="'None'"
                                            [managerSigners]="selectedOperation?.managerSigners">
                                        </app-fund-manager-contacts-list>
                                    </div>
                                </div>
        
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Contatos da Gestora do Fundo Investido
                                    </div>
                                    <div class="borderOutlined">
                                        <app-manager-contacts-list [operationHiddenButtons]="true" [recipients]="selectedOperation?.recipients"
                                            [typeList]="'None'">
                                        </app-manager-contacts-list>
                                    </div>
                                </div>
        
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">
                                        Anexos
                                    </div>
                                    <div class="borderOutlined">
                                        <app-attachment-list [hiddenButtons]="true" [canRemoveFile]="false" [selectedOperation]="selectedOperation"
                                            (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                                    </div>
                                </div>
        
                                <br>
                                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.assessments">
                                    <div class="cardHeader text-bold">
                                        Mensagens
                                    </div>
                                    <div class="borderOutlined">
                                        <app-assessment-list [selectedOperation]="selectedOperation"
                                            [assessments]="selectedOperation?.assessments"></app-assessment-list>
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</p-dialog>