import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { BrokerOperationTypeEnum } from 'src/app/enums/broker-operation-type-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { IntegratedOperationService } from 'src/app/services/integrated-operations/integrated-operation-service';
import { GetContextCompanyIntegrationResponse } from 'src/app/services/integrated-operations/responses/get-context-company-integration-response';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { ApplicationOptionViewModel } from 'src/app/services/users/models/view-models/application-option-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';


@Component({
    templateUrl: './integrated-operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class IntegratedOperationListComponent implements OnInit {

    steps: MenuItem[] = [{ label: 'Setup' }, { label: 'Documentos' }, { label: 'Assinatura' }, { label: 'Análise da Corretora' }, { label: 'Concluído' }];
    displayOperationDetail: boolean = false;
    displayOperationInit: boolean = false;
    displayOperationCancel: boolean = false;
    requestOperationCancel = null;
    selectedOperationCancel: OperationViewModel = null;
    selectedOperationDisplay: OperationViewModel = null;
    displayReasonOperation: boolean = false;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    displayCancelOperations: boolean = false;
    selectedNoteCancelOperations: string = null;
    selectedCancelOperations: SelectItem[] = [];
    selectedCancelOperation: string = null;


    public operationStatusEnum = OperationStatusEnum;
    public applicationTypeEnum = ApplicationTypeEnum;
    public brokerOperationTypeEnum = BrokerOperationTypeEnum;

    selectedCompany: GetContextCompanyIntegrationResponse = null;
    companyOptions: GetContextCompanyIntegrationResponse[] = [];
    integratedOperationsOnGoing: OperationViewModel[] | BrokerOperationViewModel[] = [];
    integratedOperationsPending: OperationViewModel[] | BrokerOperationViewModel[] = [];
    integratedOperationsFinished: OperationViewModel[] | BrokerOperationViewModel[] = [];
    integratedOperationsCanceled: OperationViewModel[] | BrokerOperationViewModel[] = [];
    selectedApplication: ApplicationOptionViewModel = null;
    selectedOperation: OperationViewModel = null;

    selectedBrokerOperation: BrokerOperationViewModel = null;
    displayViewOperation: boolean = false;
    currentStep: number = 0;
    stepDescription: string = null;
    assesments: BrokerOperationAssessmentSummaryViewModel[] = [];
    allIntegratedPendingsPending: { documentNumber: string, operations: OperationViewModel[] | BrokerOperationViewModel[] }[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private integratedOperationService: IntegratedOperationService,
        private contextService: ContextService,
        private brokerOperationService: BrokerService,
        private messageService: MessageService) {
    }


    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }


    async changeCompany(event: { documentNumber: string, name: string }) {
        switch (this.tabView) {
            case 0:
                const selectedCompany = this.allIntegratedPendingsPending.find(item => item.documentNumber == this.selectedCompany.documentNumber);
                this.integratedOperationsPending = selectedCompany.operations;
                break;
            case 1:
                await this.getOperationsFinishedData(null);
                break;
            case 2:
                await this.getOperationsCanceledData(null);
            default:
                break;
        }
    }

    async getCompanyOptions() {
        this.companyOptions = [];
        this.loadingService.show();
        if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
            const companyOptions = await this.integratedOperationService.getIntegratedOperationsInBrokers();
            await this.getCompanyOptionQuantity(companyOptions, this.selectedApplication.value);
        } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
            const companyOptions = await this.integratedOperationService.getIntegratedOperationsInManagers();
            await this.getCompanyOptionQuantity(companyOptions, this.selectedApplication.value);
        }
        this.loadingService.hide();
    }

    async getCompanyOptionQuantity(companyOptions: GetContextCompanyIntegrationResponse[], selectedApplication: ApplicationTypeEnum) {
        this.integratedOperationsPending = [];
        this.allIntegratedPendingsPending = [];
        try {
            this.loadingService.show();

            const updatedCompanyOptions = await Promise.all(companyOptions.map(async (item) => {
                try {
                    let operations: OperationViewModel[] | BrokerOperationViewModel[] = [];
                    if (selectedApplication === ApplicationTypeEnum.Broker) {
                        operations = await this.integratedOperationService.getIntegratedOperationsInBrokerByDocumentNumber(item.documentNumber, "Pending");
                    } else if (selectedApplication === ApplicationTypeEnum.FoF) {
                        operations = await this.integratedOperationService.getIntegratedOperationsInManagerByDocumentNumber(item.documentNumber, "Pending");
                    }

                    this.allIntegratedPendingsPending.push({ documentNumber: item.documentNumber, operations });

                    return {
                        ...item,
                        name: `${item.name} - (${String(operations.length)})`
                    };
                } catch (error) {
                    console.error('Erro ao buscar operações:', error);
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Não existem operações em Andamento',
                        detail: `Para o documento ${item.documentNumber}`,
                        life: 5000
                    });
                    return item;
                }
            }));

            this.companyOptions = updatedCompanyOptions;
        } catch (error) {
            console.error('Erro geral:', error);
        } finally {
            this.loadingService.hide();
        }
    }

    async ngOnInit() {
        this.setFormCancelOperation();
        this.loadingService.show();
        await this.getApplication();
        await this.verifyUserPermissions();
        await this.getCompanyOptions();
        this.loadingService.hide();
    }

    async getApplication() {
        this.selectedApplication = this.contextService.getUserApplicationSelected().application;
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    // async getOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {
    //     const documentNumber = this.selectedCompany.documentNumber;
    //     try {
    //         this.loadingService.show();
    //         if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
    //             this.integratedOperationsOnGoing = await this.integratedOperationService.getIntegratedOperationsInBrokerByDocumentNumber(documentNumber, "OnGoing");
    //         } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
    //             this.integratedOperationsOnGoing = await this.integratedOperationService.getIntegratedOperationsInManagerByDocumentNumber(documentNumber, "OnGoing");
    //         }
    //     } catch (error) {
    //         this.messageService.add({ severity: 'warn', summary: 'Não existem operações em Andamento', life: 5000 });
    //     } finally {
    //         this.loadingService.hide();
    //     }
    // }

    // async getOperationsPendingData(lazyLoadEvent: LazyLoadEvent) {
    //     const documentNumber = this.selectedCompany.documentNumber;
    //     try {
    //         this.loadingService.show();
    //         if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
    //             this.integratedOperationsPending = await this.integratedOperationService.getIntegratedOperationsInBrokerByDocumentNumber(documentNumber, "Pending");
    //         } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
    //             this.integratedOperationsPending = await this.integratedOperationService.getIntegratedOperationsInManagerByDocumentNumber(documentNumber, "Pending");
    //         }
    //     } catch (error) {
    //         this.messageService.add({ severity: 'warn', summary: 'Não existem operações em Andamento', life: 5000 });
    //     } finally {
    //         this.loadingService.hide();
    //     }
    // }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        const documentNumber = this.selectedCompany.documentNumber;
        this.integratedOperationsFinished = [];
        try {
            this.loadingService.show();
            if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
                this.integratedOperationsFinished = await this.integratedOperationService.getIntegratedOperationsInBrokerByDocumentNumber(documentNumber, "Finished");
            } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
                this.integratedOperationsFinished = await this.integratedOperationService.getIntegratedOperationsInManagerByDocumentNumber(documentNumber, "Finished");
            }
        } catch (error) {
            this.messageService.add({ severity: 'warn', summary: 'Não existem operações Finalizadas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }
    }


    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        const documentNumber = this.selectedCompany.documentNumber;
        this.integratedOperationsCanceled = [];
        try {
            this.loadingService.show();
            if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
                this.integratedOperationsCanceled = await this.integratedOperationService.getIntegratedOperationsInBrokerByDocumentNumber(documentNumber, "Canceled");
            } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
                this.integratedOperationsCanceled = await this.integratedOperationService.getIntegratedOperationsInManagerByDocumentNumber(documentNumber, "Canceled");
            }
        } catch (error) {
            this.messageService.add({ severity: 'warn', summary: 'Não existem operações Canceladas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }
    }

    async changeTab(index: number) {
        this.loadingService.show();
        this.tabView = index;
        if (index == 0) {
            // await this.getOperationsPendingData(null);
        } else if (index == 1) {
            await this.getOperationsFinishedData(null);
        } else if (index == 2) {
            await this.getOperationsCanceledData(null);
        }
        this.loadingService.hide();
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationInit = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
    }

    operationErrorInit(operation: OperationViewModel) {
        this.selectedOperation = operation;
    }

    operationSetup(operation) {
        if (operation.currentStatus.status.displayName == 'ErroCadastral') {
            this.displayOperationInit = true;
            this.operationErrorInit(operation);
            return;
        }
        return this.router.navigateByUrl(`app/customer/operation/setup/${operation.id}`);
    }

    reasonOperation(operation: OperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedOperationDisplay = operation;
    }

    getMenuItems(operation: OperationViewModel | BrokerOperationViewModel): MenuItem[] {

        let items: MenuItem[] = [];
        if (operation.currentStatus?.status?.value == OperationStatusEnum.EmAnalisePeloGestor) {
            items.push(
                { label: 'Analisar', command: (e) => this.analyzeIntegratedOperation(operation) },
                { label: 'Ver', command: (e) => this.viewIntegratedOperation(operation) },
            );
        } else {
            items.push(
                { label: 'Ver', command: (e) => this.viewIntegratedOperation(operation) },
            );
        }


        return items;

    }

    async viewIntegratedOperation(operation: BrokerOperationViewModel | OperationViewModel) {
        try {
            this.loadingService.show();
            if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
                const documentNumber = this.selectedCompany.documentNumber;
                const operationId = operation.id;
                const source = operation.source;
                const response = await this.integratedOperationService.getIntegratedOperationsDetailInBrokerBySource(documentNumber, operationId, source);
                this.selectedBrokerOperation = response;
                this.selectedBrokerOperation.assessments = this.selectedBrokerOperation?.assessments?.sort((a, b) => {
                    if (new Date(a.createDate) < new Date(b.createDate)) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                this.selectedBrokerOperation?.artifacts.map((res) => {
                    const format = new Date(res.createDate);
                    format.setHours(format.getHours() - 3);
                    res.createDate = format.toISOString();
                });
                this.brokerDefineStep();
                this.loadingService.hide();
                this.displayViewOperation = true;
            } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
                const documentNumber = this.selectedCompany.documentNumber;
                const operationId = operation.id;
                const source = operation.source;
                const response = await this.integratedOperationService.getIntegratedOperationsDetailInManagerBySource(documentNumber, operationId, source);
                this.selectedOperation = response;
                this.selectedOperation.assessments = this.selectedOperation?.assessments?.sort((a, b) => {
                    if (new Date(a.createDate) < new Date(b.createDate)) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                // this.selectedOperation?.artifacts.map((res) => {
                //     const format = new Date(res.createDate);
                //     format.setHours(format.getHours() - 3);
                //     res.createDate = format.toISOString();
                // });
                this.operationDefineStep();
                this.loadingService.hide();
                this.displayViewOperation = true;
            }
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async analyzeIntegratedOperation(operation: OperationViewModel | BrokerOperationViewModel) {
        this.loadingService.show();
        const documentNumber = this.selectedCompany.documentNumber;
        const operationId = operation.id;
        const source = operation.source;
        try {
            if (this.selectedApplication.value == ApplicationTypeEnum.Broker) {
                const response = await this.integratedOperationService.getIntegratedOperationsInBrokerBySource(documentNumber, operationId, source);
                window.open(`${response.link}`, "_blank");
            } else if (this.selectedApplication.value == ApplicationTypeEnum.FoF) {
                const response = await this.integratedOperationService.getIntegratedOperationsInManagerBySource(documentNumber, operationId, source);
                window.open(`${response.link}`, "_blank");
            }
            this.loadingService.hide();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    createOperation() {
        this.router.navigate([`app/customer/integration/create`], { queryParams: { integration: 'operation' } })
    }

    cancelOperation(operation: OperationViewModel) {
        this.displayOperationCancel = true;
        this.selectedOperationCancel = operation;
    }

    openCancelOperations() {
        this.selectedCancelOperations = [];
        this.displayCancelOperations = true;
    }

    removeSelectedCancelOperation(operation: SelectItem) {
        const indexOperation = this.selectedCancelOperations.findIndex(c => c.value == operation.value);
        this.selectedCancelOperations.splice(indexOperation, 1);
    }

    breakTextLine(notes: string) {
        // if (notes.includes('/n') || notes.includes('\n\n')) {
        //     notes = notes.split('\n' || '\n\n').join('<br>');
        //     return notes;
        // }
        // return notes;
        if (notes.includes('\n')) {
            notes = notes.split(/\n+/).join('<br>');
            return notes;
        }
        return notes;
    }

    brokerDefineStep() {
        switch (this.selectedBrokerOperation.currentStatus.status.value) {
            case OperationStatusEnum.EmSetup:
                this.currentStep = 0;
                this.stepDescription = 'Em Setup'
                break;
            case OperationStatusEnum.PreenchimentoManualNecessario:
            case OperationStatusEnum.PreenchimentoConcluido:
                this.currentStep = 1;
                this.stepDescription = 'Documentos';
                break;
            case OperationStatusEnum.AguardandoAssinatura:
                this.currentStep = 2;
                this.stepDescription = 'Assinatura'
                break;
            case OperationStatusEnum.EmAnalisePeloGestor:
            case OperationStatusEnum.PendenciadaPeloGestor:
                this.currentStep = 3;
                this.stepDescription = 'Análise da Corretora'
                break;
            case OperationStatusEnum.Concluída:
                this.currentStep = 4;
                this.stepDescription = 'Concluído'
                break;
            case OperationStatusEnum.Cancelada:
                this.currentStep = 5;
                this.stepDescription = 'Cancelado'
                break;
            default:
                this.messageService.add({ severity: 'warn', summary: 'Status não permitido', detail: 'O status atual da operação não permite operação', life: 5000 });
                break;
        }
    }

    operationDefineStep() {
        switch (this.selectedOperation.currentStatus.status.value) {
            case OperationStatusEnum.EmSetup:
                this.currentStep = 0;
                this.stepDescription = 'Em Setup'
                break;
            case OperationStatusEnum.PreenchimentoManualNecessario:
            case OperationStatusEnum.PreenchimentoConcluido:
                this.currentStep = 1;
                this.stepDescription = 'Documentos';
                break;
            case OperationStatusEnum.AguardandoAssinatura:
                this.currentStep = 2;
                this.stepDescription = 'Assinatura'
                break;
            case OperationStatusEnum.EmAnalisePeloGestor:
            case OperationStatusEnum.PendenciadaPeloGestor:
                this.currentStep = 3;
                this.stepDescription = 'Análise do Gestor'
                break;
            case OperationStatusEnum.Concluída:
                this.currentStep = 4;
                this.stepDescription = 'Concluído'
                break;
            case OperationStatusEnum.Cancelada:
                this.currentStep = 5;
                this.stepDescription = 'Cancelado'
                break;
            default:
                this.messageService.add({ severity: 'warn', summary: 'Status não permitido', detail: 'O status atual da operação não permite operação', life: 5000 });
                break;
        }
    }

    openArtefactUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        window.open(file.fileUrl, '_blank');
    }

    fillInfoTemplate(artifact: ArtifactViewModel) {
        if (artifact.signedFile !== null) {
            window.open(`${artifact.signedFile}`, '_blank');
        } else if (artifact.signedFile !== null && artifact.originalFile !== null) {
            window.open(`${artifact.signedFile}`, '_blank');
        } else if (artifact.signedFile == null && artifact.originalFile !== null) {
            window.open(`${artifact.originalFile}`, '_blank');
        }
    }

}