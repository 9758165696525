import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { PagedSearchResult } from 'src/app/providers/models/paged-search-result';
import { CancelOperationRequest } from 'src/app/services/operations/models/request/cancel-operation-request';
import { GetOperationsByFilterRequest } from 'src/app/services/operations/models/request/get-operations-by-filter-request';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';


@Component({
    templateUrl: './operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class OperationListComponent implements OnInit {

    operationsOnGoingResult: PagedSearchResult<OperationViewModel>;
    operationsOnGoing: OperationViewModel[] = [];

    operationsFinishedResult: PagedSearchResult<OperationViewModel>;
    operationsFinished: OperationViewModel[] = [];

    operationsCanceledResult: PagedSearchResult<OperationViewModel>;
    operationsCanceled: OperationViewModel[] = [];

    displayOperationDetail: boolean = false;
    displayOperationInit: boolean = false;
    displayOperationCancel: boolean = false;
    selectedOperation: OperationViewModel = null;
    requestOperationCancel = null;
    selectedOperationCancel: OperationViewModel = null;
    selectedOperationDisplay: OperationViewModel = null;
    displayReasonOperation: boolean = false;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    displayCancelOperations: boolean = false;
    selectedNoteCancelOperations: string = null;
    selectedCancelOperations: SelectItem[] = [];
    selectedCancelOperation: string = null;

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private operationService: OperationService,
        private contextService: ContextService,
        private messageService: MessageService) {
    }


    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }

    async ngOnInit() {
        this.setFormCancelOperation();
        this.loadingService.show();
        await this.verifyUserPermissions();

        // await this.getOperationsOnGoingData();

        if (this.route.snapshot.queryParams.tab) {
            await this.changeTab(this.route.snapshot.queryParams.tab)
        } else {
            await this.getOperationsOnGoingData(null);
        }
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsOnGoingData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.operationsOnGoingResult = await this.operationService.getOperationsOnGoingSearch(pagedSearchRequest);
            this.operationsOnGoing = this.operationsOnGoingResult.searchResult;
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações em Andamento', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsOnGoingData()', 'operationsOnGoingResult', this.operationsOnGoingResult);
    }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsFinishedData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.operationsFinishedResult = await this.operationService.getOperationsFinishedPaged(pagedSearchRequest);
            this.operationsFinished = this.operationsFinishedResult.searchResult;
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações Finalizadas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsFinishedData()', 'operationsFinishedResult', this.operationsFinishedResult);
    }

    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsCanceledData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.operationsCanceledResult = await this.operationService.getOperationsCanceledSearch(pagedSearchRequest);
            this.operationsCanceled = this.operationsCanceledResult.searchResult;
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações Canceladas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsCanceledData()', 'operationsCanceledResult', this.operationsCanceledResult);
    }

    async changeTab(index: number) {
        this.loadingService.show();
        this.tabView = index;
        if (index == 0) {
            await this.getOperationsOnGoingData(null);
        } else if (index == 1) {
            await this.getOperationsFinishedData(null);
        } else if (index == 2) {
            await this.getOperationsCanceledData(null);
        }
        this.loadingService.hide();
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationInit = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
    }


    async operationDetails(operation: OperationViewModel) {
        console.log(operation)
        this.displayOperationDetail = true;
        // this.selectedOperation = operation;
        try {
            // this.loadingService.show();
            const operationId = operation.id;
            this.selectedOperation = await this.operationService.getOperationById(operationId);
            // this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationDetail = false;
        } finally {
            // await this.getOperationsData();
            // this.loadingService.hide();
        }
    }

    operationErrorInit(operation: OperationViewModel) {
        this.selectedOperation = operation;
    }

    operationSetup(operation) {
        if (operation.currentStatus.status.displayName == 'ErroCadastral') {
            this.displayOperationInit = true;
            this.operationErrorInit(operation);
            return;
        }
        return this.router.navigateByUrl(`app/customer/operation/setup/${operation.id}`);
    }

    async retryOperation(operation: OperationViewModel) {
        try {
            this.loadingService.show();
            const operationId = operation.id;
            await this.operationService.retriesOperation(operationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.getOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    reasonOperation(operation: OperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedOperationDisplay = operation;
    }

    getMenuItems(operation): MenuItem[] {
        if (operation.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral &&
            !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.ErroDePreenchimento &&
            !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Concluída &&
            !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                // { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Cancelada &&
            !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.EmSetup &&
            !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Iniciar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido ||
            operation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (!this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) }
            ]
        }

    }

    createOperation() {
        this.router.navigate([`app/customer/integration/create`], { queryParams: { integration: 'operation' } })
    }

    cancelOperation(operation: OperationViewModel) {
        this.displayOperationCancel = true;
        this.selectedOperationCancel = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayOperationCancel = false;
            this.loadingService.show();
            const request: CancelOperationRequest = {
                operationId: this.selectedOperationCancel.id,
                notes: this.requestOperationCancel.notes,
            };
            this.selectedOperation = await this.operationService.cancelOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelOperation();
            await this.getOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    openCancelOperations() {
        this.selectedCancelOperations = [];
        this.displayCancelOperations = true;
    }

    async handleSaveCancelOperations() {
        if (this.selectedNoteCancelOperations == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `Informe um motivo`,
                life: 5000
            });
            return;
        }
        this.displayCancelOperations = false;
        this.loadingService.show();
        const uniqueId = new Set();
        const cancelOperations = this.selectedCancelOperations.filter(obj => {
            if (uniqueId.has(obj.value)) {
                return false;
            }
            uniqueId.add(obj.value);
            return true;
        });
        for (let item of cancelOperations) {
            try {
                const request: CancelOperationRequest = {
                    operationId: item.value,
                    notes: this.selectedNoteCancelOperations
                };
                this.selectedOperation = await this.operationService.cancelOperation(request);
                this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
                this.selectedCancelOperations = [];
                this.selectedCancelOperation = null;
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        }
        await this.getOperationsOnGoingData(null);
        this.loadingService.hide();

    }


    async handleSearchOperation() {
        const request: GetOperationsByFilterRequest = {
            operationSequentialId: Number(this.selectedCancelOperation),
            operationTypeId: null,
            operationStatusId: null,
            shareholderFundId: null,
            investedFundId: null,
            investedFundManagerId: null,
            investedFundAdministradorId: null,
            investedFundDistributorDocumentNumber: null,
            startDate: null,
            endDate: null,
            destinationShareholderFundId: null
        }
        const response = await this.operationService.getOperationsByFilter(request);
        if (response.length == 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Houve um erro',
                detail: `ID da operação não existe!`,
                life: 5000
            });
            return;
        }
        response.forEach(c => {
            if (c.currentStatus.status.value == OperationStatusEnum.Cancelada || c.currentStatus.status.value == OperationStatusEnum.Concluída) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Houve um erro',
                    detail: `A operação já está com o status ${c.currentStatus.status.friendlyName}`,
                    life: 5000
                });
                return;
            }
            this.selectedCancelOperations.push({
                label: `ID LIZA: ${c.sequentialId} - Operação: ${c.type.friendlyName} - Status: ${c.currentStatus.status.friendlyName}`,
                value: c.id
            });
        });
        this.selectedCancelOperation = null;
    }

    removeSelectedCancelOperation(operation: SelectItem) {
        const indexOperation = this.selectedCancelOperations.findIndex(c => c.value == operation.value);
        this.selectedCancelOperations.splice(indexOperation, 1);
    }

    handleEnterKeyPress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            this.handleSearchOperation();
        }
    }

    breakTextLine(notes: string) {
        if (notes.includes('\n') || notes.includes('\n\n')) {
            return notes.replace(/\n+/g, '<br>');
        }
        return notes;
    }



}