import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { BrokerOperationTypeEnum } from 'src/app/enums/broker-operation-type-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { PagedSearchResult } from 'src/app/providers/models/paged-search-result';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { CancelBrokerOperationRequest } from 'src/app/services/brokers/models/request/cancel-broker-operation-request';
import { GetBrokerOperationsByFilterRequest } from 'src/app/services/brokers/models/request/get-broker-operations-by-filter-request';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { BrokerOperationViewModel } from '../../../../services/brokers/models/view-models/broker-operation-view-model';


@Component({
    templateUrl: './broker-operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class BrokerOperationListComponent implements OnInit {

    requestBrokerOperationCancel = null;
    brokerOperationsOnGoingResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsOnGoing: BrokerOperationViewModel[] = [];

    brokerOperationsFinishedResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsFinished: BrokerOperationViewModel[] = [];

    brokerOperationsCanceledResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsCanceled: BrokerOperationViewModel[] = [];

    displayBrokerOperationDetail: boolean = false;
    displayBrokerOperationInit: boolean = false;
    displayBrokerOperationCancel: boolean = false;
    displayReasonBrokerOperation: boolean = false;

    selectedBrokerOperation: BrokerOperationViewModel = null;
    selectedBrokerOperationCancel: BrokerOperationViewModel = null;
    selectedBrokerOperationDisplay: BrokerOperationViewModel = null;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;

    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    displayCancelBrokerOperations: boolean = false;
    selectedNoteCancelBrokerOperations: string = null;
    allBrokerOperationsOnGoing: SelectItem[] = [];
    selectedCancelBrokerOperations: SelectItem[] = [];
    selectedCancelBrokerOperation: string = null;
    
    public operationStatusEnum = OperationStatusEnum;
    public brokerOperationTypeEnum = BrokerOperationTypeEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private operationService: OperationService,
        private brokerService: BrokerService,
        private contextService: ContextService,
        private messageService: MessageService) {
    }


    setFormCancelBrokerOperation() {
        this.requestBrokerOperationCancel = {
            notes: null,
        }
    }

    async ngOnInit() {
        this.setFormCancelBrokerOperation();
        this.loadingService.show();
        await this.verifyUserPermissions();

        //await this.getOperationsOnGoingData();

        if (this.route.snapshot.queryParams.tab) {
            await this.changeTab(this.route.snapshot.queryParams.tab)
        } else {
            await this.getBrokerOperationsOnGoingData(null);
        }
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getBrokerOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsOnGoingData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.brokerOperationsOnGoingResult = await this.brokerService.getBrokerOperationsOnGoingSearch(pagedSearchRequest);
            this.brokerOperationsOnGoing = this.brokerOperationsOnGoingResult.searchResult;
            this.brokerOperationsOnGoing.forEach(it => {
                if (it.frozenFundSAC == null) {
                    it.frozenFundSAC = "-"
                }
            });
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações em Andamento', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsOnGoingData()', 'operationsOnGoingResult', this.brokerOperationsOnGoingResult);
    }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsFinishedData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.brokerOperationsFinishedResult = await this.brokerService.getBrokerOperationsFinishedPaged(pagedSearchRequest);
            this.brokerOperationsFinished = this.brokerOperationsFinishedResult.searchResult;            
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações Finalizadas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsFinishedData()', 'operationsFinishedResult', this.brokerOperationsFinishedResult);
    }

    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsCanceledData()', 'lazyLoadEvent', lazyLoadEvent);
        try {
            this.loadingService.show();
            let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);
            this.brokerOperationsCanceledResult = await this.brokerService.getBrokerOperationsCanceledSearch(pagedSearchRequest);
            this.brokerOperationsCanceled = this.brokerOperationsCanceledResult.searchResult;
        } catch (error) {
            // this.messageService.add({ severity: 'warn', summary: 'Não existem operações Canceladas', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

        // console.log('operation-list', 'getOperationsCanceledData()', 'operationsCanceledResult', this.brokerOperationsCanceledResult);
    }

    async changeTab(index: number) {
        this.loadingService.show();
        this.tabView = index;
        if (index == 0) {
            await this.getBrokerOperationsOnGoingData(null);
        } else if (index == 1) {
            await this.getOperationsFinishedData(null);
        } else if (index == 2) {
            await this.getOperationsCanceledData(null);
        }
        this.loadingService.hide();
    }

    close() {
        this.displayBrokerOperationDetail = false;
        this.displayBrokerOperationInit = false;
        this.displayBrokerOperationCancel = false;
        this.displayReasonBrokerOperation = false;
    }


    async operationDetails(operation: BrokerOperationViewModel) {
        console.log(operation)
        this.displayBrokerOperationDetail = true;
        try {
            const operationId = operation.id;
            this.selectedBrokerOperation = await this.brokerService.getBrokerOperationById(operationId);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayBrokerOperationDetail = false;
        } finally {
        }
    }

    operationErrorInit(operation: BrokerOperationViewModel) {
        this.selectedBrokerOperation = operation;
    }

    operationSetup(operation) {
        if (operation.currentStatus.status.displayName == 'ErroCadastral') {
            this.displayBrokerOperationInit = true;
            this.operationErrorInit(operation);
            return;
        }
        return this.router.navigateByUrl(`app/customer/broker-operation/setup/${operation.id}`);
    }

    async retryOperation(brokerOperation: BrokerOperationViewModel) {
        try {
            this.loadingService.show();
            const brokerOperationId = brokerOperation.id;
            await this.brokerService.retriesBrokerOperation(brokerOperationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.getBrokerOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    reasonOperation(operation: BrokerOperationViewModel) {
        this.displayReasonBrokerOperation = true;
        this.selectedBrokerOperationDisplay = operation;
    }

    getMenuItems(operation): MenuItem[] {
        if (operation.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.ErroDePreenchimento &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Concluída &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Cancelada &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.EmSetup &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Iniciar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido || 
            operation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (this.user.userProfiles.filter(c => c.roles.filter(e => e.value !== RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (this.user.userProfiles.filter(c => c.roles.filter(e => e.value == RoleOptionEnum.CustomerBrokerOperator))) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
            ]
        }

    }

    // createOperation() {
    //     this.router.navigate([`app/customer/integration/create`], { queryParams: { integration: 'operation' } })
    // }

    cancelOperation(operation: BrokerOperationViewModel) {
        this.displayBrokerOperationCancel = true;
        this.selectedBrokerOperationCancel = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayBrokerOperationCancel = false;
            this.loadingService.show();
            const request: CancelBrokerOperationRequest = {
                brokerOperationId: this.selectedBrokerOperationCancel.id,
                notes: this.requestBrokerOperationCancel.notes,
            };
            this.selectedBrokerOperation = await this.brokerService.cancelBrokerOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelBrokerOperation();
            await this.getBrokerOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    openCancelBrokerOperations() {
        this.selectedCancelBrokerOperations = [];
        this.displayCancelBrokerOperations = true;
    }

    async handleSaveCancelBrokerOperations() {
        if(this.selectedNoteCancelBrokerOperations == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `Informe um motivo`,
                life: 5000
            });
            return;
        }
        this.displayCancelBrokerOperations = false;
        this.loadingService.show();
        const uniqueId = new Set();
        const cancelOperations = this.selectedCancelBrokerOperations.filter(obj => {
            if (uniqueId.has(obj.value)) {
                return false;
            }
            uniqueId.add(obj.value);
            return true;
        });
        for (let item of cancelOperations) {
            try {
                this.displayBrokerOperationCancel = false;
                this.loadingService.show();
                const request: CancelBrokerOperationRequest = {
                    brokerOperationId: item.value,
                    notes: this.selectedNoteCancelBrokerOperations
                };
                this.selectedBrokerOperation = await this.brokerService.cancelBrokerOperation(request);
                this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
                this.selectedCancelBrokerOperations = [];
                this.selectedCancelBrokerOperation = null;
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        }
        this.loadingService.hide();
        this.getBrokerOperationsOnGoingData(null);
    }

    async handleSearchBrokerOperation() {
        const request: GetBrokerOperationsByFilterRequest = {
            brokerOperationSequentialId: Number(this.selectedCancelBrokerOperation),
            operationTypeId: null,
            operationStatusId: null,
            brokerId: null,
            fundId: null,
            fundManagerId: null,
            fundAdministradorId: null,
            startDate: null,
            endDate: null,
        }
        const response = await this.brokerService.getOperationsByFilter(request);
        if (response.length == 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Houve um erro',
                detail: `ID da operação não existe!`,
                life: 5000
            });
            return;
        }
        response.forEach(c => {
            if (c.currentStatus.status.value == OperationStatusEnum.Cancelada || c.currentStatus.status.value == OperationStatusEnum.Concluída) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Houve um erro',
                    detail: `A operação já está com o status ${c.currentStatus.status.friendlyName}`,
                    life: 5000
                });
                return;
            }
            this.selectedCancelBrokerOperations.push({
                label: `ID: ${c.sequentialId} - Operação: ${c.type.friendlyName} - Status: ${c.currentStatus.status.friendlyName}`,
                value: c.id
            });
        });
        this.selectedCancelBrokerOperation = null;
    }

    removeSelectedCancelBrokerOperation(operation: SelectItem) {
        const indexBrokerOperation = this.selectedCancelBrokerOperations.findIndex(c => c.value == operation.value);
        this.selectedCancelBrokerOperations.splice(indexBrokerOperation, 1);
    }

    handleEnterKeyPress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            this.handleSearchBrokerOperation();
        }
    }

    breakTextLine(notes: string) {
        if (notes.includes('\n') || notes.includes('\n\n')) {
            return notes.replace(/\n+/g, '<br>');
        }
        return notes;
    }


}