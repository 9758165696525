import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { ContactViewModel } from 'src/app/services/fund-manager/models/view-models/contact-view-model';
import { FundManagerViewModel } from 'src/app/services/fund-manager/models/view-models/fund-manager-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './fund-manager-view.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundManagerViewComponent implements OnInit {

    contatcsOfManager: ContactViewModel[] = [];
    selectedFundManager: FundManagerViewModel = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    // typeUser: string = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fundManagerService: FundManagerService,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private confirmationService: ConfirmationService,
    ) { }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.getManager();
        this.loadingService.hide();
    }

    async getManager() {
        const fundManagerId = this.route.snapshot.params.id;
        this.selectedFundManager = await this.fundManagerService.getFundManagerById(fundManagerId);
        this.contatcsOfManager = await this.fundManagerService.getContactsByFundManagerId(fundManagerId);
        console.log(this.contatcsOfManager);
        this.selectedFundManager.documentNumber = this.parseCNPJ(this.selectedFundManager.documentNumber);
        this.contatcsOfManager = this.contatcsOfManager.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    onCancel() {
        const search = this.route.snapshot.queryParams.search;
        if (search) {
            this.router.navigate([`app/customer/fund-manager/list`], { queryParams: { search: search } });
        } else if (!search) {
            this.router.navigate([`app/customer/fund-manager/list`]);
        }
    }

    confirm(manager: FundManagerViewModel) {
        this.confirmationService.confirm({
            header: `Gestora ${manager.name}`,
            message: `Tem certeza que deseja excluir ${manager.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeManager(manager);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async removeManager(manager: FundManagerViewModel) {
        const fundManagerId = manager.id;
        try {
            this.loadingService.show();
            await this.fundManagerService.deleteFundManager(fundManagerId);
            this.router.navigateByUrl(`app/customer/fund-manager/list`);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async removeContact(contact: ContactViewModel) {
        // console.log(contact)
        const contactId = contact.id;
        try {
            this.loadingService.show();
            await this.fundManagerService.deleteContactInFunManager(contactId);
            await this.getManager();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

}