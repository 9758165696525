import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/providers/http-client-service';
import { LoginWhiteLabelContextRequest } from './models/requests/login-white-label-context-request';
import { LoginWhiteLabelContextResponse } from './models/response/login-white-label-context-response';


@Injectable({
  providedIn: 'root'
})
export class WhiteLabelContextService {
  constructor(
    private httpClient: HttpClientService) { }

  async whiteLabelContextLogin(request: LoginWhiteLabelContextRequest): Promise<LoginWhiteLabelContextResponse> {
    return this.httpClient.post(`/white-label-contexts`, '/login', request, false, true, true);
  }

}
