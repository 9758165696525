import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { PagedSearchRequest } from "src/app/providers/models/paged-search-request";
import { PagedSearchResult } from "src/app/providers/models/paged-search-result";
import { LiabilityOperationViewModel } from "./models/view-models/liability-operation-view-model";
import { CancelLiabilityOperationRequest } from "./models/request/cancel-liability-operation-request";
import { GetFinishedOperationsByDateRangeResponse } from "../operations/models/responses/get-finished-operations-by-date-range-response";
import { GetFinishedOperationsYearlyReportResponse } from "../operations/models/responses/get-finished-operations-yearly-report-response";
import { GetDoneOperationsTimeframeByDateRangeResponse } from "../operations/models/responses/get-done-operations-timeframe-by-date-range-response";
import { GetLiabilityOperationsByFilterRequest } from "./models/request/get-liability-operations-by-filter-request";
import { GetLiabilityOperationsWhitChangesReportRequest } from "./models/request/get-liability-operations-whit-changes-report-request";
import { GetLiabilityOperationsWithChangesReportResponse } from "./models/responses/get-liability-operations-with-changes-report-response";
import { DefaultEnumViewModel } from "src/app/providers/models/default-enum-view-model";

@Injectable({
    providedIn: 'root'
})

export class LiabilityService {
    constructor(private httpClient: HttpClientService) { }

    async getLiabilityOperations(isActive: boolean): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.get(`/liability-operations`, `/all/${isActive}`, true);
    }

    async getLiabilityOperationById(operationId: string): Promise<LiabilityOperationViewModel> {
        return this.httpClient.get(`/liability-operations`, `/${operationId}`);
    }

    async getAllLiabilityOperations(): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.get(`/liability-operations`, `/on-going`, true);
    }

    async getAllLiabilityOperationsFinished(): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.get(`/liability-operations`, `/finished`, true);
    }

    async getAllLiabilityOperationsCanceled(): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.get(`/liability-operations`, `/canceled`, true);
    }

    async getLiabilityOperationsOnGoingSearch(request: PagedSearchRequest): Promise<PagedSearchResult<LiabilityOperationViewModel>> {
        return this.httpClient.post(`/liability-operations`, `/on-going/search`, request, true);
    }

    async getLiabilityOperationsFinishedPaged(request: PagedSearchRequest): Promise<PagedSearchResult<LiabilityOperationViewModel>> {
        return this.httpClient.post(`/liability-operations`, `/finished/search`, request, true);
    }

    async getLiabilityOperationsCanceledSearch(request: PagedSearchRequest): Promise<PagedSearchResult<LiabilityOperationViewModel>> {
        return this.httpClient.post(`/liability-operations`, `/canceled/search`, request, true);
    }

    async retriesLiabilityOperation(liabilityOperationId: string): Promise<LiabilityOperationViewModel> {
        return this.httpClient.path(`/liability-operations`, `/${liabilityOperationId}/retries`);
    }

    async cancelLiabilityOperation(request: CancelLiabilityOperationRequest): Promise<LiabilityOperationViewModel> {
        return this.httpClient.put(`/liability-operations`, `/cancelation`, request);
    }

    async finishedReportDates(startDate: string, endDate: string): Promise<GetFinishedOperationsByDateRangeResponse> {
        return this.httpClient.get(`/liability-operations`, `/reports/finisheds/start/${startDate}/end/${endDate}`);
    }

    async finishedReportYearly(year: string): Promise<GetFinishedOperationsYearlyReportResponse[]> {
        return this.httpClient.get(`/liability-operations`, `/reports/finisheds/yearly/${year}`);
    }

    async finishedReportTimeFrames(startDate: string, endDate: string, liabilityOperationTypeId: string | null): Promise<GetDoneOperationsTimeframeByDateRangeResponse[]> {
        return this.httpClient.get(`/liability-operations`, `/reports/finisheds/type/${liabilityOperationTypeId}/start/${startDate}/end/${endDate}/timeframes`);
    }

    async getOperationsByFilter(request: GetLiabilityOperationsByFilterRequest): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.put(`/liability-operations`, `/reports/filter`, request);
    }

    async chargesReport(year: number, month: number): Promise<LiabilityOperationViewModel[]> {
        return this.httpClient.get(`/liability-operations`, `/reports/charges/year/${year}/month/${month}`);
    }

    async getReportWithChanges(request: GetLiabilityOperationsWhitChangesReportRequest): Promise<GetLiabilityOperationsWithChangesReportResponse[]> {
        return this.httpClient.put(`/liability-operations`, `/reports/changes`, request);
    }

}
