import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, } from 'primeng/api';
import { AddressInformation } from 'src/app/modules/address-form/address-form.component';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { CreateFundManagerRequest } from 'src/app/services/fund-manager/models/requests/create-fund-manager-request';
import { ContactProperties } from 'src/app/services/fund-manager/models/view-models/contact-properties';


@Component({
    templateUrl: './fund-manager-create.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundManagerCreateComponent implements OnInit {

    request: CreateFundManagerRequest = null;
    requestContacts: ContactProperties = null;
    allConacts: ContactProperties[] = [];


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private fundManagerService: FundManagerService,
        private formValidationService: FormValidationService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.setForms();
        this.setFormContacts();
    }

    setForms() {
        this.request = {
            name: null,
            documentNumber: null,
            phoneNumber: null,
            addressStreet: null,
            addressNumber: null,
            addressComplement: null,
            addressNeighborhood: null,
            addressZipCode: null,
            addressCity: null,
            addressState: null,
            addressCountry: null,
            giinNumber: null,
            contacts: null
        }
    }

    setFormContacts() {
        this.requestContacts = {
            name: null,
            email: null,
        }
    }

    addContact(contactForm: NgForm) {
        if (!this.formValidationService.validateForm(contactForm.form)) return;
        const contacts = {
            name: this.requestContacts.name,
            email: this.requestContacts.email,
        }
        this.allConacts.push(contacts);
        this.setFormContacts();
        this.formValidationService.resetFormFields(contactForm.form);
    }

    removeManager(manager) {
        this.allConacts = this.allConacts.filter(fill => {
            return fill !== manager
        })
    }

    onCancel() {
        this.setForms();
        this.setFormContacts();
        this.router.navigateByUrl(`app/customer/fund-manager/list`);
    }

    async onSave(managerForm: NgForm) {

        if (!this.formValidationService.validateForm(managerForm.form)) return;

        this.loadingService.show();
        try {
            let request: CreateFundManagerRequest = {
                name: managerForm.value.name,
                documentNumber: managerForm.value.documentNumber,
                phoneNumber: managerForm.value.phoneNumber,
                addressStreet: managerForm.value.addressStreet,
                addressNumber: managerForm.value.addressNumber,
                addressComplement: managerForm.value.addressComplement,
                addressNeighborhood: managerForm.value.addressNeighborhood,
                addressZipCode: managerForm.value.addressZipCode,
                addressCity: managerForm.value.addressCity,
                addressState: managerForm.value.addressState,
                addressCountry: managerForm.value.addressCountry,
                giinNumber: managerForm.value.giinNumber,
                contacts: this.allConacts
            }
            await this.fundManagerService.createFundManager(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.router.navigateByUrl(`app/customer/fund-manager/list`);
        }
    }

    onAddressSelect(event: AddressInformation) {
        this.request.addressStreet = event.street;
        this.request.addressNumber = event.addressNumber;
        this.request.addressComplement = event.complement;
        this.request.addressNeighborhood = event.neighborhood;
        this.request.addressZipCode = event.postalCode;
        this.request.addressCity = event.city;
        this.request.addressState = event.province;
    }

}