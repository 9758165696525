<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Fundo Cotista
            </div>
        </div>
        <div class="customCard">
            <form #fundEditForm="ngForm" novalidate [hidden]="selectedFund?.type?.value == fundTypeEnum.Investido">
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações</div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{selectedFund?.name}} name="name" #name="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.documentNumber | documentNumber: 'cnpj' }}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Patrimônio Líquido</p>
                        <p>{{selectedFund?.currentInformation?.patrimony | currency: 'BRL' }}</p>
                    </div>


                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="establishmentDate" name="dateEstablishment"
                                    yearRange="1920:2099" (onSelect)="changeEstablishmentDate($event)" dataType="string"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yyyy"
                                    [yearNavigator]="true" [required]="true" #dateEstablishment="ngModel"
                                    [required]="true">
                                </p-calendar>
                                <label for="dateEstablishment">Data de constituição</label>
                            </span>
                        </div>
                        <mark *ngIf="dateEstablishment.invalid && dateEstablishment.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">CNAE</p>
                        <p>{{selectedFund?.cnaeCode}}</p>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="ambimaTargetGroup" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.ambimaTargetGroup}}
                                    name="ambimaTargetGroup" #ambimaTargetGroup="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="ambimaTargetGroup">Público Alvo ANBIMA</label>
                            </span>
                        </div>
                        <mark *ngIf="ambimaTargetGroup.invalid && ambimaTargetGroup.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="stiCode" type="text" ngModel={{selectedFund?.currentCustomProperty?.stiCode}}
                                    name="stiCode" #stiCode="ngModel" class="inputLiza" required pInputText>
                                <label for="stiCode">Código STI/ Galgo</label>
                            </span>
                        </div>
                        <mark *ngIf="stiCode.invalid && stiCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="giinNumber" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.giinNumber}} name="giinNumber"
                                    #giinNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                        <mark *ngIf="giinNumber.invalid && giinNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="cetipAccount" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.cetipAccount}} name="cetipAccount"
                                    #cetipAccount="ngModel" class="inputLiza" required pInputText>
                                <label for="cetipAccount">Conta CETIP</label>
                            </span>
                        </div>
                        <mark *ngIf="cetipAccount.invalid && cetipAccount.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>



                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankName" type="text" ngModel={{selectedFund?.bankName}}
                                    name="bankName" #bankName="ngModel" class="inputLiza" required pInputText>
                                <label for="bankName">Nome do banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankName.invalid && bankName.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{selectedFund?.bankCode}}
                                    name="bankCode" #bankCode="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Número do banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankCode.invalid && bankCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="agencyNumber" type="text"
                                    ngModel={{selectedFund?.agencyNumber}} name="agencyNumber"
                                    #agencyNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="agencyNumber">Agência</label>
                            </span>
                        </div>
                        <mark *ngIf="agencyNumber.invalid && agencyNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountNumber" type="text"
                                    ngModel={{selectedFund?.accountNumber}} name="accountNumber"
                                    #accountNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="accountNumber">Conta corrente</label>
                            </span>
                        </div>
                        <mark *ngIf="accountNumber.invalid && accountNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-1 p-lg-1">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountDigit" type="text"
                                    ngModel={{selectedFund?.accountDigit}} name="accountDigit"
                                    #accountDigit="ngModel" class="inputLiza" required pInputText>
                                <label for="accountDigit">Dígito</label>
                            </span>
                        </div>
                        <mark *ngIf="accountDigit.invalid && accountDigit.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>


                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Administrador</p>
                        <p>{{selectedFund?.administrator?.name}}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.administrator?.documentNumber }}</p>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="fundOrManagerReversion" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.fundOrManagerReversion}}
                                    name="fundOrManagerReversion" #fundOrManagerReversion="ngModel" class="inputLiza"
                                    required pInputText>
                                <label for="fundOrManagerReversion">Reversão do fundo</label>
                            </span>
                        </div>
                        <mark *ngIf="fundOrManagerReversion.invalid && fundOrManagerReversion.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2"
                        *ngIf="selectedApplication?.application?.value == applicationTypeEnum.Broker">

                        <p class="text-bold">Bovespa</p>
                        <div *ngFor="let bovespa of optionsBovespa" class="field-checkbox">
                            <p-radioButton [inputId]="bovespa.label" [name]="bovespa.label" [value]="bovespa"
                                [(ngModel)]="selectedBovespa" (ngModelChange)="handleBovespa($event)"
                                [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label class="labelCheckbox" [for]="bovespa.label">{{bovespa.label}}</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2"
                        *ngIf="selectedApplication?.application?.value == applicationTypeEnum.Broker">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="sac" type="text" ngModel={{selectedFund?.sac}} name="sac" #sac="ngModel"
                                    class="inputLiza" pInputText>
                                <label for="sac">SAC</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-4"
                        *ngIf="selectedApplication?.application?.value == applicationTypeEnum.Broker">
                        <p class="text-bold">BM&F</p>
                        <div *ngFor="let bmf of optionsBMF" class="field-checkbox">
                            <p-radioButton [inputId]="bmf.label" [name]="bmf.label" [value]="bmf"
                                [(ngModel)]="selectedBMF" (ngModelChange)="handleBMF($event)"
                                [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label class="labelCheckbox" [for]="bmf.label">{{bmf.label}}</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2"
                        *ngIf="selectedApplication?.application?.value == applicationTypeEnum.Broker">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="deskConfig" type="text" [(ngModel)]="selectedDeskConfig" name="deskConfig" #deskConfig="ngModel"
                                    class="inputLiza" pInputText>
                                <label for="deskConfig">MESA</label>
                            </span>
                        </div>
                    </div>
                </div>
                <br>
                <br>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Prestadores de Serviço</div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Distribuidor</p>
                        <p>{{selectedFund?.fundDistributorName}}</p>
                    </div>
                    <div class="p-col-12 p-md-8 p-lg-8">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.fundDistributorDocumentNumber}}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Gestora</p>
                        <p>{{selectedFund?.manager?.name}}</p>
                    </div>
                    <div class="p-col-12 p-md-8 p-lg-8">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.manager?.documentNumber}}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Custodiante</p>
                        <p>{{selectedFund?.custodianName}}</p>
                    </div>
                    <div class="p-col-12 p-md-8 p-lg-8">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.custodianDocumentNumber}}</p>
                    </div>
                </div>

                <br>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                            (click)="onSave(fundEditForm)"></button>
                    </div>
                </div>
            </form>

            <form #investedFundForm="ngForm" novalidate [hidden]="selectedFund?.type?.value !== fundTypeEnum.Investido">
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações</div>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-7">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{selectedFund?.name}} name="name" #name="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-5 p-lg-5">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.documentNumber}}</p>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-7">
                        <p class="text-bold">Administradora</p>
                        <p>{{selectedFund?.administrator?.name}}</p>
                    </div>
                    <div class="p-col-12 p-md-5 p-lg-5">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.administrator?.documentNumber }}</p>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-7">
                        <p class="text-bold">Gestora</p>
                        <p>{{selectedFund?.manager?.name}}</p>
                    </div>
                    <div class="p-col-12 p-md-5 p-lg-5">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.manager?.documentNumber }}</p>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-7">
                        <p class="text-bold">Custodiante</p>
                        <p>{{selectedFund?.custodianName}}</p>
                    </div>
                    <div class="p-col-12 p-md-5 p-lg-5">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.custodianDocumentNumber}}</p>
                    </div>
                    <div class="p-col-12 p-md-7 p-lg-7">
                        <p class="text-bold">Distribuidor</p>
                        <p>{{selectedFund?.fundDistributorName }}</p>
                    </div>
                    <div class="p-col-12 p-md-5 p-lg-5">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedFund?.fundDistributorDocumentNumber}}</p>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                            (click)="onSaveInvestedFund(investedFundForm)"></button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>