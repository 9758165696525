// Esse enum deve corresponder ao Enum PlayerType do backend
export enum IntegrationFileTypeEnum {
    AdministratorEntry = 1,
    FundEntry = 2,
    ManagerEntry = 3,
    FoFOperation = 4,
    RepresentationEntry = 5,
    FundClousure = 6,
    BrokerOperation = 7,
    LiabilityOperation = 8,
}