import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { CreateShareholderFundRequest } from "./models/request/create-shareholder-fund-request";
import { UpdateAdhesionTermTemplateRequest } from "./models/request/update-adhesion-term-template-request";
import { UpdateFundBasicInfoRequest } from "./models/request/update-fund-basic-info-request";
import { UpdateShareholderFundRequest } from "./models/request/update-shareholder-fund-request";
import { UpdateSubscriptionFormTemplateRequest } from "./models/request/update-subscription-form-template-request";
import { GetFundBrokerEntriesByDateRangeResponse } from "./models/responses/get-fund-broker-entries-by-date-range-response";
import { FundViewModel } from "./models/view-models/fund-view-model";

@Injectable({
    providedIn: 'root'
})

export class FundService {

    constructor(private httpClient: HttpClientService) { }


    async getAllFunds(isActive: boolean): Promise<FundViewModel[]> {
        return this.httpClient.get(`/funds`, `/all/${isActive}`);
    }

    async getFundById(fundId: string): Promise<FundViewModel> {
        return this.httpClient.get(`/funds`, `/${fundId}`);
    }

    async updateInvestedFund(request: UpdateFundBasicInfoRequest): Promise<FundViewModel> {
        return this.httpClient.put(`/funds`, `/`, request);
    }

    async create(request: CreateShareholderFundRequest): Promise<FundViewModel> {
        return this.httpClient.post(`/funds`, `/sharedholders`, request);
    }

    async createAdhesionTermTemplates(request: UpdateAdhesionTermTemplateRequest): Promise<FundViewModel> {
        return this.httpClient.put(`/funds`, `/adhesion-term-templates`, request);
    }

    async createSubscriptionFormTemplates(request: UpdateSubscriptionFormTemplateRequest): Promise<FundViewModel> {
        return this.httpClient.put(`/funds`, `/subscription-form-templates`, request);
    }

    async updateShareholderFund(request: UpdateShareholderFundRequest): Promise<FundViewModel> {
        return this.httpClient.put(`/funds`, `/sharedholders`, request);
    }

    async getFundReports(startDate: string, endDate: string): Promise<GetFundBrokerEntriesByDateRangeResponse[]> {
        return this.httpClient.get(`/funds`, `/reports/entries/start/${startDate}/end/${endDate}`);
    }

    async updateCVM(fundId: string): Promise<FundViewModel> {
        return this.httpClient.put(`/funds`, `/${fundId}/stock-market-syncs`, null);
    }

}