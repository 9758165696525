import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { GetOperationsHomeReportResponse } from 'src/app/services/operations/models/responses/GetOperationsHomeReportResponse';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss', '../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewInit {

    buyOperationSetup: number = 0;
    buyOperationFillForm: number = 0;
    buyOperationSignatureProcess: number = 0;
    buyOperationManagerAnalysis: number = 0;
    buyOperationManagerPending: number = 0;
    buyOperationFinished: number = 0;

    buyWalletOperationSetup: number = 0;
    buyWalletOperationFillForm: number = 0;
    buyWalletOperationSignatureProcess: number = 0;
    buyWalletOperationManagerAnalysis: number = 0;
    buyWalletOperationManagerPending: number = 0;
    buyWalletOperationFinished: number = 0;

    dailyOperationSetup: number = 0;
    dailyOperationFillForm: number = 0;
    dailyOperationSignatureProcess: number = 0;
    dailyOperationManagerAnalysis: number = 0;
    dailyOperationManagerPending: number = 0;
    dailyOperationFinished: number = 0;

    updateOperationSetup: number = 0;
    updateOperationFillForm: number = 0;
    updateOperationSignatureProcess: number = 0;
    updateOperationManagerAnalysis: number = 0;
    updateOperationManagerPending: number = 0;
    updateOperationFinished: number = 0;

    transferAdminOperationSetup: number = 0;
    transferAdminOperationFillForm: number = 0;
    transferAdminOperationSignatureProcess: number = 0;
    transferAdminOperationManagerAnalysis: number = 0;
    transferAdminOperationManagerPending: number = 0;
    transferAdminOperationFinished: number = 0;

    splitOperationSetup: number = 0;
    splitOperationFillForm: number = 0;
    splitOperationSignatureProcess: number = 0;
    splitOperationManagerAnalysis: number = 0;
    splitOperationManagerPending: number = 0;
    splitOperationFinished: number = 0;

    incorporationOperationSetup: number = 0;
    incorporationOperationFillForm: number = 0;
    incorporationOperationSignatureProcess: number = 0;
    incorporationOperationManagerAnalysis: number = 0;
    incorporationOperationManagerPending: number = 0;
    incorporationOperationFinished: number = 0;

    integrationOperationSetup: number = 0;
    integrationOperationFillForm: number = 0;
    integrationOperationSignatureProcess: number = 0;
    integrationOperationManagerAnalysis: number = 0;
    integrationOperationManagerPending: number = 0;
    integrationOperationFinished: number = 0;

    masteringOperationSetup: number = 0;
    masteringOperationFillForm: number = 0;
    masteringOperationSignatureProcess: number = 0;
    masteringOperationManagerAnalysis: number = 0;
    masteringOperationManagerPending: number = 0;
    masteringOperationFinished: number = 0;

    subscriptionOperationSetup: number = 0;
    subscriptionOperationFillForm: number = 0;
    subscriptionOperationSignatureProcess: number = 0;
    subscriptionOperationManagerAnalysis: number = 0;
    subscriptionOperationManagerPending: number = 0;
    subscriptionOperationFinished: number = 0;

    distributorChangeOperationSetup: number = 0;
    distributorChangeOperationFillForm: number = 0;
    distributorChangeOperationSignatureProcess: number = 0;
    distributorChangeOperationManagerAnalysis: number = 0;
    distributorChangeOperationManagerPending: number = 0;
    distributorChangeOperationFinished: number = 0;

    ransomOperationSetup: number = 0;
    ransomOperationFillForm: number = 0;
    ransomOperationSignatureProcess: number = 0;
    ransomOperationManagerAnalysis: number = 0;
    ransomOperationManagerPending: number = 0;
    ransomOperationFinished: number = 0;

    cessionBuyOperationSetup: number = 0;
    cessionBuyOperationFillForm: number = 0;
    cessionBuyOperationSignatureProcess: number = 0;
    cessionBuyOperationManagerAnalysis: number = 0;
    cessionBuyOperationManagerPending: number = 0;
    cessionBuyOperationFinished: number = 0;

    cessionDonationOperationSetup: number = 0;
    cessionDonationOperationFillForm: number = 0;
    cessionDonationOperationSignatureProcess: number = 0;
    cessionDonationOperationManagerAnalysis: number = 0;
    cessionDonationOperationManagerPending: number = 0;
    cessionDonationOperationFinished: number = 0;

    allOperationSetup: number = 0;
    allOperationFillForm: number = 0;
    allOperationSignatureProcess: number = 0;
    allOperationManagerAnalysis: number = 0;
    allOperationManagerPending: number = 0;
    allOperationFinished: number = 0;
    // typeUser: string = null;
    user: UserViewModel = null;

    public operationTypeEnum = OperationTypeEnum;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private integrationFileService: IntegrationFilesService,
        private elementRef: ElementRef,
    ) {
    }


    ngAfterViewInit() {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.loadData();
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        const user = this.contextService.getUserInfo().user;

        user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async loadData() {
        let allOperations: GetOperationsHomeReportResponse = await this.operationService.getOperationsHomeReports();

        // Buy
        const operationTypeBuy = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Compra');
        this.buyOperationSetup = operationTypeBuy[0]?.notInitalizedCount;
        this.buyOperationFillForm = operationTypeBuy[0]?.fillingFormCount;
        this.buyOperationSignatureProcess = operationTypeBuy[0]?.inSignatureProcessCount;
        this.buyOperationManagerAnalysis = operationTypeBuy[0]?.inManagerAnalysisCount;
        this.buyOperationManagerPending = operationTypeBuy[0]?.pendingCount;
        this.buyOperationFinished = operationTypeBuy[0]?.doneCount;

        // daily movement
        const operationTypeDaily = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Movimentação Diária');
        this.dailyOperationSetup = operationTypeDaily[0]?.notInitalizedCount;
        this.dailyOperationFillForm = operationTypeDaily[0]?.fillingFormCount;
        this.dailyOperationSignatureProcess = operationTypeDaily[0]?.inSignatureProcessCount;
        this.dailyOperationManagerAnalysis = operationTypeDaily[0]?.inManagerAnalysisCount;
        this.dailyOperationManagerPending = operationTypeDaily[0]?.pendingCount;
        this.dailyOperationFinished = operationTypeDaily[0]?.doneCount;

        // update
        const operationTypeUpdate = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Atualização');
        this.updateOperationSetup = operationTypeUpdate[0]?.notInitalizedCount;
        this.updateOperationFillForm = operationTypeUpdate[0]?.fillingFormCount;
        this.updateOperationSignatureProcess = operationTypeUpdate[0]?.inSignatureProcessCount;
        this.updateOperationManagerAnalysis = operationTypeUpdate[0]?.inManagerAnalysisCount;
        this.updateOperationManagerPending = operationTypeUpdate[0]?.pendingCount;
        this.updateOperationFinished = operationTypeUpdate[0]?.doneCount;

        // transferAdmin
        const operationTypeTransferAdmin = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Transferência de Administrador');
        this.transferAdminOperationSetup = operationTypeTransferAdmin[0]?.notInitalizedCount;
        this.transferAdminOperationFillForm = operationTypeTransferAdmin[0]?.fillingFormCount;
        this.transferAdminOperationSignatureProcess = operationTypeTransferAdmin[0]?.inSignatureProcessCount;
        this.transferAdminOperationManagerAnalysis = operationTypeTransferAdmin[0]?.inManagerAnalysisCount;
        this.transferAdminOperationManagerPending = operationTypeTransferAdmin[0]?.pendingCount;
        this.transferAdminOperationFinished = operationTypeTransferAdmin[0]?.doneCount;

        // // split
        const operationTypeSplit = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Cisão');
        this.splitOperationSetup = operationTypeSplit[0]?.notInitalizedCount;
        this.splitOperationFillForm = operationTypeSplit[0]?.fillingFormCount;
        this.splitOperationSignatureProcess = operationTypeSplit[0]?.inSignatureProcessCount;
        this.splitOperationManagerAnalysis = operationTypeSplit[0]?.inManagerAnalysisCount;
        this.splitOperationManagerPending = operationTypeSplit[0]?.pendingCount;
        this.splitOperationFinished = operationTypeSplit[0]?.doneCount;

        // // incorporation
        const operationTypeIncorporation = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Incorporação');
        this.incorporationOperationSetup = operationTypeIncorporation[0]?.notInitalizedCount;
        this.incorporationOperationFillForm = operationTypeIncorporation[0]?.fillingFormCount;
        this.incorporationOperationSignatureProcess = operationTypeIncorporation[0]?.inSignatureProcessCount;
        this.incorporationOperationManagerAnalysis = operationTypeIncorporation[0]?.inManagerAnalysisCount;
        this.incorporationOperationManagerPending = operationTypeIncorporation[0]?.pendingCount;
        this.incorporationOperationFinished = operationTypeIncorporation[0]?.doneCount;

        // // integration
        const operationTypeIntegration = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Integralização');
        this.integrationOperationSetup = operationTypeIntegration[0]?.notInitalizedCount;
        this.integrationOperationFillForm = operationTypeIntegration[0]?.fillingFormCount;
        this.integrationOperationSignatureProcess = operationTypeIntegration[0]?.inSignatureProcessCount;
        this.integrationOperationManagerAnalysis = operationTypeIntegration[0]?.inManagerAnalysisCount;
        this.integrationOperationManagerPending = operationTypeIntegration[0]?.pendingCount;
        this.integrationOperationFinished = operationTypeIntegration[0]?.doneCount;

        // // mastering
        const operationTypeMastering = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Masterização');
        this.masteringOperationSetup = operationTypeMastering[0]?.notInitalizedCount;
        this.masteringOperationFillForm = operationTypeMastering[0]?.fillingFormCount;
        this.masteringOperationSignatureProcess = operationTypeMastering[0]?.inSignatureProcessCount;
        this.masteringOperationManagerAnalysis = operationTypeMastering[0]?.inManagerAnalysisCount;
        this.masteringOperationManagerPending = operationTypeMastering[0]?.pendingCount;
        this.masteringOperationFinished = operationTypeMastering[0]?.doneCount;

        // // subscription
        const operationTypeSubscription = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Boletim de Subscrição');
        this.subscriptionOperationSetup = operationTypeSubscription[0]?.notInitalizedCount;
        this.subscriptionOperationFillForm = operationTypeSubscription[0]?.fillingFormCount;
        this.subscriptionOperationSignatureProcess = operationTypeSubscription[0]?.inSignatureProcessCount;
        this.subscriptionOperationManagerAnalysis = operationTypeSubscription[0]?.inManagerAnalysisCount;
        this.subscriptionOperationManagerPending = operationTypeSubscription[0]?.pendingCount;
        this.subscriptionOperationFinished = operationTypeSubscription[0]?.doneCount;


        // // distributorChange
        const operationTypeDistributorChange = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Alteração de Distribuidor');
        this.distributorChangeOperationSetup = operationTypeDistributorChange[0]?.notInitalizedCount;
        this.distributorChangeOperationFillForm = operationTypeDistributorChange[0]?.fillingFormCount;
        this.distributorChangeOperationSignatureProcess = operationTypeDistributorChange[0]?.inSignatureProcessCount;
        this.distributorChangeOperationManagerAnalysis = operationTypeDistributorChange[0]?.inManagerAnalysisCount;
        this.distributorChangeOperationManagerPending = operationTypeDistributorChange[0]?.pendingCount;
        this.distributorChangeOperationFinished = operationTypeDistributorChange[0]?.doneCount;

        // // ransom
        const operationTypeRansom = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Resgate de Come Cotas');
        this.ransomOperationSetup = operationTypeRansom[0]?.notInitalizedCount;
        this.ransomOperationFillForm = operationTypeRansom[0]?.fillingFormCount;
        this.ransomOperationSignatureProcess = operationTypeRansom[0]?.inSignatureProcessCount;
        this.ransomOperationManagerAnalysis = operationTypeRansom[0]?.inManagerAnalysisCount;
        this.ransomOperationManagerPending = operationTypeRansom[0]?.pendingCount;
        this.ransomOperationFinished = operationTypeRansom[0]?.doneCount;

        // // cessionBuy
        const operationTypecessionBuy = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Cessão de Cotas - Compra / Venda');
        this.cessionBuyOperationSetup = operationTypecessionBuy[0]?.notInitalizedCount;
        this.cessionBuyOperationFillForm = operationTypecessionBuy[0]?.fillingFormCount;
        this.cessionBuyOperationSignatureProcess = operationTypecessionBuy[0]?.inSignatureProcessCount;
        this.cessionBuyOperationManagerAnalysis = operationTypecessionBuy[0]?.inManagerAnalysisCount;
        this.cessionBuyOperationManagerPending = operationTypecessionBuy[0]?.inManagerAnalysisCount;
        this.cessionBuyOperationFinished = operationTypecessionBuy[0]?.doneCount;

        // // cessionDonation
        const operationTypecessionDonation = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Cessão de Cotas - Doação');
        this.cessionDonationOperationSetup = operationTypecessionDonation[0]?.notInitalizedCount;
        this.cessionDonationOperationFillForm = operationTypecessionDonation[0]?.fillingFormCount;
        this.cessionDonationOperationSignatureProcess = operationTypecessionDonation[0]?.inSignatureProcessCount;
        this.cessionDonationOperationManagerAnalysis = operationTypecessionDonation[0]?.inManagerAnalysisCount;
        this.cessionDonationOperationManagerPending = operationTypecessionDonation[0]?.pendingCount;
        this.cessionDonationOperationFinished = operationTypecessionDonation[0]?.doneCount;

        // // Buy Wallet
        const operationTypeBuyWallet = allOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Compra de Carteira');
        this.buyWalletOperationSetup = operationTypeBuyWallet[0]?.notInitalizedCount;
        this.buyWalletOperationFillForm = operationTypeBuyWallet[0]?.fillingFormCount;
        this.buyWalletOperationSignatureProcess = operationTypeBuyWallet[0]?.inSignatureProcessCount;
        this.buyWalletOperationManagerAnalysis = operationTypeBuyWallet[0]?.inManagerAnalysisCount;
        this.buyWalletOperationManagerPending = operationTypeBuyWallet[0]?.pendingCount;
        this.buyWalletOperationFinished = operationTypeBuyWallet[0]?.doneCount;

        // All operations
        this.allOperationSetup = allOperations.totalNotInitalizedCount;
        this.allOperationFillForm = allOperations.totalFillingFormCount;
        this.allOperationSignatureProcess = allOperations.totalInSignatureProcessCount;
        this.allOperationManagerAnalysis = allOperations.totalInManagerAnalysisCount;
        this.allOperationManagerPending = allOperations.totalPendingCount;
        this.allOperationFinished = allOperations.totalDoneCount;
    }

    async syncOperations() {
        try {
            this.loadingService.show();
            await this.integrationFileService.sftp();
            this.messageService.add({ severity: 'success', summary: 'Arquivos sincronizados', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            if (!this.isUserAnalist && !this.isUserOperator) {
                return;
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        } finally {
            this.loadingService.hide();
        }
    }

    async sendMailtoManagers() {
        try {
            this.loadingService.show();
            await this.operationService.resendEmailAllPendings()
            this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Emails enviados com sucesso.`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }
}

// BoletimSubscricao