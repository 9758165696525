<div class="titleCustomCard">
    Editar Procurador
</div>
<div class="p-grid">
    <div class="p-col-12">
        <form #representationForm="ngForm" novalidate>
            <div class="customCard">
                <div class="p-grid">
                    <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Excluir" (click)="confirm(procuratorSeleceted)"
                            class="p-button-outlined-blue"></button>
                    </div>
                </div>
                <div class="cardHeader text-bold">Validade</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="selectedExpireDate" name="expireDate"
                                    (onChange)="changeExpireDate($event)" yearRange="2022:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    [required]="true" #expireDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="expireDate" class="labelOcta">Validade</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customCard">
                <div class="cardHeader text-bold">Informações</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{procuratorSeleceted?.name}} name="name"
                                    #name="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Nome</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" class="inputLiza" name="maskedPersonalDocumentNumber"
                                    ngModel={{procuratorSeleceted?.maskedPersonalDocumentNumber}}
                                    name="maskedPersonalDocumentNumber" #maskedPersonalDocumentNumber="ngModel"
                                    pInputText readonly>
                                <label for="maskedPersonalDocumentNumber">CPF</label>
                            </span>
                        </div>
                        <mark *ngIf="maskedPersonalDocumentNumber.invalid && maskedPersonalDocumentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="selectedBirthDate" name="birthDate" yearRange="1920:2099"
                                    (onChange)="changeBirthDate($event)" [showIcon]="true" [monthNavigator]="true"
                                    dateFormat="dd/mm/yy" [yearNavigator]="true" [required]="true" #birthDate="ngModel"
                                    [required]="true">
                                </p-calendar>
                                <label for="birthDate">Data de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthDate.invalid && birthDate.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="genderOptions" [(ngModel)]="selectedGender" placeholder="Sexo"
                                    (onChange)="changeGender($event)" name="gender" #gender="ngModel" [required]="true">
                                </p-dropdown>
                                <!-- <label for="gender">
                                    sexo
                                </label> -->
                            </span>
                        </div>
                        <mark *ngIf="gender.invalid && gender.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressStreet" type="text" ngModel={{procuratorSeleceted?.addressStreet}}
                                    name="addressStreet" #addressStreet="ngModel" class="inputLiza" required pInputText>
                                <label for="addressStreet">Endereço Residencial</label>
                            </span>
                        </div>
                        <mark *ngIf="addressStreet.invalid && addressStreet.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressNumber" type="text" ngModel={{procuratorSeleceted?.addressNumber}}
                                    name="addressNumber" #addressNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="addressNumber">Número</label>
                            </span>
                        </div>
                        <mark *ngIf="addressNumber.invalid && addressNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressComplement" type="text"
                                    ngModel={{procuratorSeleceted?.addressComplement}} name="addressComplement"
                                    #addressComplement="ngModel" class="inputLiza" pInputText>
                                <label for="addressComplement">Complemento</label>
                            </span>
                        </div>
                        <mark *ngIf="addressComplement.invalid && addressComplement.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressNeighborhood" type="text"
                                    ngModel={{procuratorSeleceted?.addressNeighborhood}} name="addressNeighborhood"
                                    #addressNeighborhood="ngModel" class="inputLiza" required pInputText>
                                <label for="addressNeighborhood">Bairro</label>
                            </span>
                        </div>
                        <mark *ngIf="addressNeighborhood.invalid && addressNeighborhood.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressZipCode" type="text" ngModel={{procuratorSeleceted?.addressZipCode}}
                                    name="addressZipCode" #addressZipCode="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="addressZipCode">Cep</label>
                            </span>
                        </div>
                        <mark *ngIf="addressZipCode.invalid && addressZipCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressCity" type="text" ngModel={{procuratorSeleceted?.addressCity}}
                                    name="addressCity" #addressCity="ngModel" class="inputLiza" required pInputText>
                                <label for="addressCity">Cidade</label>
                            </span>
                        </div>
                        <mark *ngIf="addressCity.invalid && addressCity.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allAddressState" [(ngModel)]="selectedAddressState"
                                    (onChange)="changeAddressState($event)" placeholder="UF" name="addressState"
                                    #addressState="ngModel" [required]="true">
                                </p-dropdown>
                                <label for="uf">
                                    UF
                                </label>
                            </span>
                        </div>
                        <mark *ngIf="addressState.invalid && addressState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressCountry" type="text" ngModel={{procuratorSeleceted?.addressCountry}}
                                    name="addressCountry" #addressCountry="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="addressCountry">País</label>
                            </span>
                        </div>
                        <mark *ngIf="addressCountry.invalid && addressCountry.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="birthCity" type="text" ngModel={{procuratorSeleceted?.birthCity}}
                                    name="birthCity" #birthCity="ngModel" class="inputLiza" required pInputText>
                                <label for="birthCity">Cidade de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthCity.invalid && birthCity.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allBirthState" [(ngModel)]="selectedBirthState"
                                    (onChange)="changeBirthState($event)" name="birthState" #birthState="ngModel"
                                    [required]="true">
                                </p-dropdown>
                                <label for="birthState">
                                    UF de Nascimento
                                </label>
                            </span>
                        </div>
                        <mark *ngIf="birthState.invalid && birthState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="birthCountry" type="text" ngModel={{procuratorSeleceted?.birthCountry}}
                                    name="birthCountry" #birthCountry="ngModel" class="inputLiza" required pInputText>
                                <label for="birthCountry">País de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthCountry.invalid && birthCountry.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="nationality" type="text" ngModel={{procuratorSeleceted?.nationality}}
                                    name="nationality" #nationality="ngModel" class="inputLiza" required pInputText>
                                <label for="nationality">Nacionalidade</label>
                            </span>
                        </div>
                        <mark *ngIf="nationality.invalid && nationality.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                                ngModel={{procuratorSeleceted?.phoneNumber}} name="phoneNumber" #phoneNumber="ngModel"
                                    [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="phoneNumber" class="labelLiza">Telefone</label>
                            </span>
                        </div>
                        <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="email" type="text" ngModel={{procuratorSeleceted?.email}} name="email"
                                    #email="ngModel" class="inputLiza" required pInputText>
                                <label for="email">E-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="email.invalid && email.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="cardHeader text-bold">Documento</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="typeDocuments" [(ngModel)]="selectedAdditionalDocumentType"
                                    name="label" (onChange)="changeTypeDocument($event)"
                                    #additionalDocumentType="ngModel" [required]="true">
                                </p-dropdown>
                                <label for="additionalDocumentType">
                                    Tipo de Documento
                                </label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentType.invalid && additionalDocumentType.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="additionalDocumentNumber" type="text"
                                    ngModel={{procuratorSeleceted?.additionalDocumentNumber}}
                                    name="additionalDocumentNumber" #additionalDocumentNumber="ngModel" class="inputLiza"
                                    required pInputText>
                                <label for="additionalDocumentNumber">Número Documento</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentNumber.invalid && additionalDocumentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="selectedAdditionalDocumentIssueDate"
                                    (onChange)="changeDocumentIssueDate($event)" name="additionalDocumentIssueDate"
                                    yearRange="1920:2099" [showIcon]="true" [monthNavigator]="true"
                                    dateFormat="dd/mm/yy" [yearNavigator]="true" [required]="true"
                                    #additionalDocumentIssueDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="additionalDocumentIssueDate" class="labelOcta">Data de Emissão</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentIssueDate.invalid && additionalDocumentIssueDate.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="additionalDocumentIssuingBody" type="text"
                                    ngModel={{procuratorSeleceted?.additionalDocumentIssuingBody}}
                                    name="additionalDocumentIssuingBody" #additionalDocumentIssuingBody="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="additionalDocumentIssuingBody">Orgão Emissor</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentIssuingBody.invalid && additionalDocumentIssuingBody.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allAdditionalDocumentIssuingBodyState"
                                    (onChange)="changeDocumentIssuingBodyState($event)"
                                    [(ngModel)]="selectedAdditionalDocumentIssuingBodyState" name="additionalDocumentIssuingBodyState"
                                    #additionalDocumentIssuingBodyState="ngModel" [required]="true">
                                </p-dropdown>
                                <label for="additionalDocumentIssuingBodyState">
                                    UF orgão emissor
                                </label>
                            </span>
                        </div>
                        <mark
                            *ngIf="additionalDocumentIssuingBodyState.invalid && additionalDocumentIssuingBodyState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="cardHeader text-bold">Anexos</div>
                <div class="p-grid">
                    <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate p-fluid w-full">
                        <div class="customCardOutlined">
                            <h5 class="text-center">Procuração</h5>
                            <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.procurationFileUrl !== null">
                                <a class="textPrimary" [href]="procuratorSeleceted?.procurationFileUrl" target="_blank">Ver</a>
                                <a class="textPrimary" style="cursor: pointer;" (click)="openDisplayEditProcurationFile()">Editar</a>
                            </div>
                            <div *ngIf="procuratorSeleceted?.procurationFileUrl == null || displayEditProcurationFile">
                                <app-fileuploader [isMultipleFiles]="false" [label]="''"
                                    (onUploadResponse)="onUploadProcurationFile($event)">
                                </app-fileuploader>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate p-fluid w-full">
                        <div class="customCardOutlined">
                            <h5 class="text-center">Documento do Procurador</h5>
                            <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.documentFileUrl !== null">
                                <a class="textPrimary" [href]="procuratorSeleceted?.documentFileUrl" target="_blank">Ver</a>
                                <a class="textPrimary" style="cursor: pointer;" (click)="openDisplayEditUploadDocumentFile()">Editar</a>
                            </div>
                            <div *ngIf="procuratorSeleceted?.documentFileUrl == null || displayEditUploadDocumentFile">
                                <app-fileuploader [isMultipleFiles]="false" [label]="''"
                                    (onUploadResponse)="onUploadDocumentFile($event)">
                                </app-fileuploader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Cancelar" class=" p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                        (click)="onSave(representationForm)"></button>
                </div>
            </div>
        </form>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>