import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { EntityOperationHistoryViewModel } from 'src/app/services/operations/models/view-models/entity-operation-history-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserService } from 'src/app/services/users/user-service';

@Component({
    templateUrl: 'audit-list.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/datatable.scss',
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class AuditListComponent implements OnInit {

    audits: SelectItem[] = [];
    selectedAudit: SelectItem = null;
    selectedMonth: SelectItem = null;
    selectedYear: SelectItem = null;
    auditMonths: SelectItem[] = null;
    auditYears: SelectItem[] = null;
    allAudits: EntityOperationHistoryViewModel[] = [];
    auditsExport: any[] = [];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private loadingService: LoadingService,
        private userService: UserService,
        private datePipe: DatePipe,
        private messageService: MessageService,
        private operationService: OperationService,
    ) {
    }

    async ngOnInit() {
        this.setAudits();
    }

    setAudits() {
        this.audits = [
            { label: 'Exportar relatorio de Auditoria por mês', value: 'month' },
            // { label: 'Exportar relatorio de auditoria completa', value: 'all' },
        ]
    }

    setAllMonthsAndYear() {
        this.auditMonths = [
            { label: "Janeiro", value: 1 },
            { label: "Fevereiro", value: 2 },
            { label: "Março", value: 3 },
            { label: "Abril", value: 4 },
            { label: "Maio", value: 5 },
            { label: "Junho", value: 6 },
            { label: "Julho", value: 7 },
            { label: "Agosto", value: 8 },
            { label: "Setembro", value: 9 },
            { label: "Outubro", value: 10 },
            { label: "Novembro", value: 11 },
            { label: "Dezembro", value: 12 },
        ]
        this.auditYears = [
            { label: "2022", value: 2022 },
            { label: "2023", value: 2023 },
            { label: "2024", value: 2024 },
            { label: "2025", value: 2025 },
        ]
    }

    changeAudit(event: SelectItem) {
        switch (event.value) {
            // case event.value:
            //     "all"
            //     this.change();
            //     break;
            case event.value:
                "month"
                this.changeMonth();
                break;
            default:
                break;
        }
    }

    changeMonth() {
        this.setAllMonthsAndYear();
    }

    async exportByMonth() {
        if(!this.selectedMonth || !this.selectedYear) {
            this.messageService.add({ severity: 'error', summary: 'Não foi possível efetuar a alteração', detail: 'Selecione ano e mês', life: 5000 });
            return
        }
        this.loadingService.show();
        let month = this.selectedMonth.value;
        let year = this.selectedYear.value;
        let firstDayMonth = new Date(year, month, 1).getDate();
        let lastDayMonthd = new Date(year, month, 0).getDate();

        let startDate = `${year}-${month}-${firstDayMonth}`
        let endDate = `${year}-${month}-${lastDayMonthd}`
        try {
            this.allAudits = await this.operationService.operationHistoryByPeriod(startDate, endDate);
            this.auditsExport = this.allAudits.map(audit => {
                return {
                    'ID': audit?.id,
                    'ID Entidade': audit?.entityId,
                    'Tipo da Operacão': audit?.operationType,
                    'Data': this.datePipe.transform(audit?.createDate, 'dd/MM/yyyy - HH:mm'),
                    'IP de Origem': audit?.originIP,
                    'Ativo': audit.isActive ? "Sim" : "Não",
                    'Usuário': audit?.userIdentification,
                    'Informações': audit?.requestPayload,
                    'Identificação': audit?.requestClassIdentification,
                    'Identificação Entidade': audit?.entityClassIdentification,
                }
            });
            this.exportExcel();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async exportAllAudits() {

    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.auditsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Auditoria");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }


}
