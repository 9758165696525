<div *ngIf="typeList == 'Select'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="brokerSigners" dataKey="id" [hidden]="verifySigner" 
            (onRowSelect)="selectedCheckbox($event.data)" (onRowUnselect)="unSelectCheckbox($event.data)"
            (onHeaderCheckboxToggle)="onHeaderCheckboxToggled($event.checked)" styleClass="p-datatable-customized"
            [(selection)]="selecetedBrokerSigners" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem" >
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-signer let-index>
                <tr style="cursor: pointer" [pSelectableRow]="signer">
                    <td>
                        <p-tableCheckbox [value]="signer"></p-tableCheckbox>
                    </td>
                    <td>
                        {{signer.name}}
                    </td>
                    <td>
                        {{signer.email}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="typeList == 'None'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="signers" dataKey="id" styleClass="p-datatable-customized"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th *ngIf="selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                        <div>
                            Ações
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-signer let-index>
                <tr style="cursor: pointer" [pSelectableRow]="signer">
                    <td>
                        {{signer.representativeSigner.name}}
                    </td>
                    <td>
                        {{signer.representativeSigner.email}}
                    </td>
                    <td>
                        <div
                            *ngIf="selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <a class="linkPrimary" [hidden]="brokerHiddenButtons" (click)="resendEmail()">Reenviar Email</a>
                        </div>
                        <div
                            *ngIf="selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <a class="linkPrimary" [hidden]="brokerHiddenButtons" (click)="removeSigner(signer)">Remover</a>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>