<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                {{selectedOperation?.type?.friendlyName}}
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <!-- <div class="line"></div> -->
                <!-- <div class="p-col-9 p-md-9 p-lg-9"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">ID</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedOperation?.sequentialId}}
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">Público Alvo ANBIMA</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup }}
                </div>

                <ng-container *ngIf="
                        selectedOperation?.type?.value == operationTypeEnum.CessaoDeCotasCompra || 
                        selectedOperation?.type?.value == operationTypeEnum.CessaoDeCotasDoacao ||
                        selectedOperation?.type?.value == operationTypeEnum.Incorporacao || 
                        selectedOperation?.type?.value == operationTypeEnum.Cisao || 
                        selectedOperation?.type?.value == operationTypeEnum.Integralizacao ||
                        selectedOperation?.type?.value == operationTypeEnum.Masterizacao">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <p class="text-bold">Fundo de Origem:</p>
                    </div>

                    <div class="p-col-12 col-md-6 p-lg-6">
                        {{selectedOperation?.destinationShareholderFund?.name}}
                    </div>

                    <div class="p-col-12 p-md-1 p-lg-1">
                        <p class="text-bold">CNPJ</p>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        {{selectedOperation?.destinationShareholderFund?.documentNumber| documentNumber: 'cnpj'}}
                    </div>
                </ng-container>

                <!-- <div class="line"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Fundo Cotista:</p>
                </div>

                <div class="p-col-12 col-md-6 p-lg-6">
                    {{selectedOperation?.shareholderFund?.name}}
                    <a class="linkPrimary leftSpacingText" target="_blank"
                        [routerLink]="['/app/customer/fund/view/', selectedOperation?.shareholderFund?.id]">Ver</a>
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedOperation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold"> Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedOperation?.investedFund?.name}}
                </div>
                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedOperation?.investedFund?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Distribuidor do fundo que receberá o rebate da aplicação:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedOperation?.operationDistributorName}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedOperation?.operationDistributorDocumentNumber| documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Administrador do Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedOperation?.investedFund?.administrator?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedOperation?.investedFund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                </div>

                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Gestora do Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedOperation?.investedFund?.manager?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedOperation?.investedFund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                selectedOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                    <div class="contentAlignItemsCenter">
                        <p class="textPrimary">Consulta CVM:</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    <div class="contentRowStartItems">
                        <p class="linkSecondary">OK</p>
                        <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                            target="_blank">Site CVM</a>
                    </div>
                </div>

                <!-- <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Público Anbima:</p>
                </div>
                <div class="p-col-12 p-md-5 p-lg-5 contentAlignItemsCenter">
                    <span>{{selectedOperation?.shareholderFund?.ambimaTargetGroup}}</span>
                </div> -->

            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Etapas
            </div>
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-col-12 p-md-12 p-lg-12">

                    <p-steps class="contentSteps" #pStep [model]="steps" [readonly]="true" [activeIndex]="currentStep">
                    </p-steps>

                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12 text-center">
                            <div class="cardHeader">
                                <span class="text-bold">Status: </span>
                                <span [ngClass]="{
                                    'textPrimary': selectedOperation?.currentStatus?.status?.value <= 6,
                                    'linkSecondary': selectedOperation?.currentStatus?.status?.value == 8}">{{
                                    selectedOperation?.currentStatus?.status?.friendlyName}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pStep.activeIndex == 0" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Selecione os Rep. Legais/Procuradores do fundo cotista
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid" *ngIf="selectedOperation">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante A</h5>
                                        <app-legal-representative-fund-select [isRepresentativeA]="true"
                                            [selectedRepresentativeA]="selectedOperation.shareholderFundSignerOne"
                                            [allRepresentativesA]="allRepresentativesA"
                                            (representativeAEmitter)="changedRepresentativeA($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante B</h5>
                                        <app-legal-representative-fund-select [isRepresentativeB]="true"
                                            [selectedRepresentativeB]="selectedOperation.shareholderFundSignerTwo"
                                            [allRepresentativesB]="allRepresentativesB"
                                            (representativeBEmitter)="changedRepresentativeB($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Outras Assinaturas
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div>
                                            <p-inputSwitch name="administratorSigners"
                                                [(ngModel)]="switchRepresentativeAdminInvestedFund"
                                                (onChange)="changeSwitchAdmin($event.checked)">
                                            </p-inputSwitch>
                                            <span class="text-bold"> Selecione os Rep. Legais/Procuradores da
                                                Administradora do fundo investido</span>
                                        </div>
                                        <div *ngIf="switchRepresentativeAdminInvestedFund">
                                            <app-fund-admin-contacts-list [typeList]="'Select'"
                                                [adminContacts]="allRepresentativesAdminInvestedFund"
                                                [representativesAdmin]="selectedOperation.administratorSigners"
                                                (checkboxAdminToggleEmitter)="selectedAdminCheckbox($event)"
                                                (unCheckboxAdminToggleEmitter)="unSelectAdminCheckbox($event)"
                                                (onHeaderAdminCheckboxToggledEmitter)="toggleAdminOnHeader($event)">
                                            </app-fund-admin-contacts-list>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div>
                                            <p-inputSwitch name="managerSigners"
                                                [(ngModel)]="switchRepresentativeManagerInvestedFund"
                                                (onChange)="changeSwitchManager($event.checked)">
                                            </p-inputSwitch>
                                            <span class="text-bold"> Selecione os Rep. Legais/Procuradores da Gestora do
                                                fundo investido</span>
                                        </div>
                                        <div *ngIf="switchRepresentativeManagerInvestedFund">
                                            <app-fund-manager-contacts-list [typeList]="'Select'"
                                                [managerContacts]="allRepresentativesManagerInvestedFund"
                                                [representativesManager]="selectedOperation.managerSigners"
                                                (checkboxManagerToggleEmitter)="selectedManagerCheckbox($event)"
                                                (unCheckboxManagerToggleEmitter)="unSelectManagerCheckbox($event)"
                                                (onHeaderManagerCheckboxToggledEmitter)="toggleManagerOnHeader($event)">
                                            </app-fund-manager-contacts-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation">
                            <div class="cardHeader text-bold">
                                Selecione os contatos da Gestora do fundo investido <small
                                    style="color: var(--primary-color);">(Selecione ao menos um contato)</small>
                            </div>
                            <div class="borderOutlined">
                                <app-manager-contacts-list [managerContacts]="managerContacts"
                                    [recipients]="selectedOperation.recipients" [typeList]="'Select'"
                                    (checkboxToggleEmitter)="selectedCheckbox($event)"
                                    (unCheckboxToggleEmitter)="unSelectCheckbox($event)"
                                    (onHeaderCheckboxToggledEmitter)="toggleOnHeader($event)">
                                </app-manager-contacts-list>
                                <button pButton pRipple type="button" label="Adicionar contato" class="p-button-primary"
                                    (click)="addManagerContact()"></button>
                            </div>
                        </div>
                        <br>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-create [selectedOperation]="selectedOperation"
                                    [hasFundRegulationFile]="hasFundRegulationFile"
                                    (onOperationUploadFileFundRegulationEmitter)="onOperationUploadFundRegulation()"
                                    (openAddFileEmitter)="openAttachments()" (removeFileEmitter)="removeFile($event)"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-create>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                            <span style="color: var(--primary-color)">Clique no botão "Adicionar anexo" para avançar
                                para a próxima etapa.</span>
                            <button (click)="setAsDone()" pButton pRipple type="button" label="Próximo"
                                [disabled]="selectedOperation?.recipients.length == 0"
                                class="action-button p-ml-2"></button>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 1" class="p-grid">

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedOperation]="selectedOperation" [step]="1"
                                    (fillInfoTemplateEmitter)="fillInfoTemplate($event)" (updateOperation)="updateUrl()"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)">
                                </app-artifact-template-list>

                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12"
                            *ngIf="selectedOperation?.administratorSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Administradora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-admin-contacts-list [typeList]="'None'"
                                    [administratorSigners]="selectedOperation?.administratorSigners">
                                </app-fund-admin-contacts-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-manager-contacts-list [typeList]="'None'"
                                    [managerSigners]="selectedOperation?.managerSigners">
                                </app-fund-manager-contacts-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Gestora do fundo investido
                            </div>
                            <div class="borderOutlined">
                                <app-manager-contacts-list [recipients]="selectedOperation?.recipients"
                                    [typeList]="'None'">
                                </app-manager-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedOperation]="selectedOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">

                            <button (click)="sendToSign()" pButton pRipple type="button"
                                [disabled]="selectedOperation.currentStatus.status.value !== operationStatusEnum.PreenchimentoConcluido || disabledButtonSignature"
                                label="Enviar para assinatura" class="action-button p-ml-2"></button>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 2" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedOperation]="selectedOperation" [step]="2"
                                    (fillInfoTemplateEmitter)="fillInfoTemplate($event)" (updateOperation)="updateUrl()"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)">
                                </app-artifact-template-list>

                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12"
                            *ngIf="selectedOperation?.administratorSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Administradora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-admin-contacts-list [typeList]="'None'"
                                    [administratorSigners]="selectedOperation?.administratorSigners">
                                </app-fund-admin-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-manager-contacts-list [typeList]="'None'"
                                    [managerSigners]="selectedOperation?.managerSigners">
                                </app-fund-manager-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Gestora do fundo investido
                            </div>
                            <div class="borderOutlined">
                                <app-manager-contacts-list [recipients]="selectedOperation?.recipients"
                                    [typeList]="'None'">
                                </app-manager-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedOperation]="selectedOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">

                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 3" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedOperation]="selectedOperation" [step]="3"
                                    (fillInfoTemplateEmitter)="fillInfoTemplate($event)" (updateOperation)="updateUrl()"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)">
                                </app-artifact-template-list>

                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12"
                            *ngIf="selectedOperation?.administratorSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Administradora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-admin-contacts-list [typeList]="'None'"
                                    [administratorSigners]="selectedOperation?.administratorSigners">
                                </app-fund-admin-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procurador da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-manager-contacts-list [typeList]="'None'"
                                    [managerSigners]="selectedOperation?.managerSigners">
                                </app-fund-manager-contacts-list>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-manager-contacts-list [recipients]="selectedOperation?.recipients"
                                    [selectedOperation]="selectedOperation" [typeList]="'None'"
                                    (resendEmailEmitter)="resendEmail($event)"
                                    (removeContactEmitter)="removeContact($event)">
                                </app-manager-contacts-list>
                            </div>
                        </div>
                        <div class="manager-create"
                            *ngIf="selectedOperation?.currentStatus?.status?.value == operationStatusEnum.EmAnalisePeloGestor">
                            <button pButton pRipple type="button" label="Adicionar Contato da Gestora"
                                class="action-button p-ml-2" (click)="createManagerContact()"></button>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedOperation]="selectedOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (onOperationUploadFileFundRegulationEmitter)="onOperationUploadFundRegulation()"
                                    (removeArtefactUrlEmitter)="confirm($event)"
                                    (openAddFileEmitter)="openAttachments()">
                                </app-attachment-list>
                            </div>
                        </div>
                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="recipients.length > 0">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedOperation]="selectedOperation"
                                    [assessments]="recipients"></app-assessment-list>

                            </div>
                            <br>
                            <div class="p-grid"
                                *ngIf="selectedOperation?.currentStatus?.status.value == operationStatusEnum.PendenciadaPeloGestor">
                                <div class="p-col-12 p-md-10 p-lg-10 p-fluid">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <input id="name" type="text" [(ngModel)]="requestAssesmentReply.reply"
                                                name="reply" #reply="ngModel" class="inputLiza" required pInputText>
                                            <label for="name">Digite aqui a resposta</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                    <button pButton pRipple type="button" label="Enviar" class="action-button p-ml-2"
                                        (click)="sendReplyAssesment()"></button>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="recipients?.length > 0">
                            <div class="cardHeader text-bold">
                                Ações
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="contentCenterColumn">
                                            <button pButton pRipple type="button" label="Aprovar"
                                                class="p-button-primary" (click)="openDissmisAssesment()"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 4 || pStep.activeIndex == 5" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedOperation]="selectedOperation" [step]="4"
                                    (fillInfoTemplateEmitter)="fillInfoTemplate($event)" (updateOperation)="updateUrl()"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)">
                                </app-artifact-template-list>

                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedOperation]="selectedOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12"
                            *ngIf="selectedOperation?.administratorSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procuradores da Administradora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-admin-contacts-list [typeList]="'None'"
                                    [administratorSigners]="selectedOperation?.administratorSigners">
                                </app-fund-admin-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners?.length > 0">
                            <div class="cardHeader text-bold">
                                Rep. Legal/Procuradores da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-fund-manager-contacts-list [typeList]="'None'"
                                    [managerSigners]="selectedOperation?.managerSigners">
                                </app-fund-manager-contacts-list>
                            </div>
                        </div>


                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Gestora do Fundo Investido
                            </div>
                            <div class="borderOutlined">
                                <app-manager-contacts-list [recipients]="selectedOperation?.recipients"
                                    [typeList]="'None'">
                                </app-manager-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedOperation]="selectedOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (openAddFileEmitter)="openAttachments()"></app-attachment-list>
                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="recipients">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedOperation]="selectedOperation"
                                    [assessments]="recipients"></app-assessment-list>

                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>

<p-dialog header="Adicionar arquivo" [(visible)]="displatOperationFiles" [modal]="true" [responsive]="true"
    (onHide)="closeDialodFiles()" [style]="{width: '50vw'}">
    <form #filesForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <input id="name" type="text" [(ngModel)]="requestFiles.description" name="description"
                    #description="ngModel" placeholder="Descrição" class="inputLiza" required pInputText>

            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" style="display: flex; justify-content: center;">
                <p-fileUpload [files]="requestFiles.files" [multiple]="false" [customUpload]="true" [auto]="true"
                    (uploadHandler)="uploadFiles($event.files, filesForm)"></p-fileUpload>
            </div>
        </div>
    </form>

</p-dialog>

<p-dialog header="Adicionar Contato da Gestora" [(visible)]="displayCreateManagerContacts" [modal]="true"
    [responsive]="true" [style]="{width: '50vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="field-radiobutton">
                <p-radioButton name="existCopntact" value="existContact" [(ngModel)]="switchContact"
                    inputId="existContact"></p-radioButton>
                <label for="existContact"> Selecionar contato existente</label>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="field-radiobutton">
                <p-radioButton name="newContact" value="newContact" [(ngModel)]="switchContact"
                    inputId="newContact"></p-radioButton>
                <label for="newContact"> Adicionar novo contato</label>
            </div>
        </div>
    </div>

    <div class="p-grid" *ngIf="switchContact == 'existContact'">
        <!-- <div class="p-col-12 p-md-12 p-lg-12">
            <h3>Selecione um contato existente</h3>
        </div> -->
        <div class="p-col-12 p-md-12 p-lg-12">
            <span class="p-float-label">
                <p-dropdown [options]="allContacts" placeholder="Selecione um contato existente" [required]="true"
                    [(ngModel)]="selectedContatIncludeOperation" optionLabel="label" #ambimaTargetGroup="ngModel"
                    optionValue="value" appendTo="body" name="targetAudience">
                </p-dropdown>
            </span>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class=" p-button-outlined"
                    (click)="onCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onSaveExistContactInOperation(selectedContatIncludeOperation)"></button>
            </div>
        </div>
    </div>

    <div class="p-grid" *ngIf="switchContact == 'newContact'">
        <div class="p-col-12 p-md-12 p-lg-12">
            <app-manager-contacts-create (managerContactEmitter)="saveManagerContact($event, $event)"
                (buttonCancelEmitter)="onCancel()" [displayCreateManagerContacts]="displayCreateManagerContacts">
            </app-manager-contacts-create>
        </div>
    </div>

</p-dialog>

<p-dialog header=" " [(visible)]="displayFillInfoTemplate" [modal]="true" [responsive]="true" [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <app-fill-template-create [selectedOperation]="selectedOperation" [artefactObservable]="artefactObservable"
                (requestEmitter)="saveFillTemplate($event)"></app-fill-template-create>
        </div>
    </div>

</p-dialog>

<p-dialog header="Aprovar" [(visible)]="displyDismissAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <form #dismissForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <textarea id="float-input" pInputTextarea name="notes" [style]="{'width': '100%'}" placeholder="Motivo"
                    [(ngModel)]="requestDissmisAssesment.notes" required #notes="ngModel"></textarea>
            </div>
        </div>
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Aprovar" class="action-button p-ml-2"
                (click)="dismissAssesments(dismissForm)"></button>
        </div>
    </form>

</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>