import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { IntegrationFileTypeEnum } from 'src/app/enums/integration-file-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { ContextService } from './../../../../providers/context-service';

@Component({
    templateUrl: './integration-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss',
        './integration-create.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class IntegrationCreateComponent implements OnInit {

    integrationTypesOptions: DefaultEnumViewModel[] = [];
    selectedIntegrationTypeId: number = null;
    componentFiles: File[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private integrationFileService: IntegrationFilesService,
        private messageService: MessageService,
        private contextService: ContextService) {
    }

    async ngOnInit() {
        await this.loadOptions();
    }

    async loadOptions() {
        const integrationRouter = this.route.snapshot.queryParams.integration ? this.route.snapshot.queryParams.integration : null
        this.loadingService.show();
        let selectedApplication = this.contextService.getUserApplicationSelected();
        switch (selectedApplication?.application?.value) {
            case ApplicationTypeEnum.FoF:
                this.integrationTypesOptions = await this.integrationFileService.getIntegrationFileTypes("FoF");
                break;
            case ApplicationTypeEnum.Broker:
                this.integrationTypesOptions = await this.integrationFileService.getIntegrationFileTypes("Corretoras");
                break;
            case ApplicationTypeEnum.Liability:
                this.integrationTypesOptions = await this.integrationFileService.getIntegrationFileTypes("PassivoFundos");
                break;
            default:
                break;
        }
        if (integrationRouter) {
            this.selectedIntegrationTypeId = 4
        }
        this.loadingService.hide();
    }

    async uploadFiles(files: File[]) {
        // console.log('files', files);
        this.componentFiles = files;
        // this.loadingService.show();
        // try {
        //     this.componentFiles = files;
        //     this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Upload efetuado com sucesso!`, life: 5000 });
        // } catch (error) {
        //     this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Tente novamente mais tarde.', life: 5000 });
        // } finally {
        //     this.loadingService.hide();
        // }
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/integration/list');
    }

    async onSave() {

        // console.log('request', this.selectedIntegrationTypeId, this.componentFiles[0]);

        this.loadingService.show();
        try {

            switch (this.selectedIntegrationTypeId) {
                case IntegrationFileTypeEnum.AdministratorEntry:
                    await this.integrationFileService.uploadAdministratorFile(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.FundEntry:
                    await this.integrationFileService.uploadFundFile(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.ManagerEntry:
                    await this.integrationFileService.uploadManagerFile(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.FoFOperation:
                    await this.integrationFileService.uploadOperationFile(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.RepresentationEntry:
                    await this.integrationFileService.uploadRepresentationFile(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.FundClousure:
                    await this.integrationFileService.uploadFundClousure(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.BrokerOperation:
                    await this.integrationFileService.uploadBrokerOperation(this.componentFiles[0]);
                    break;
                case IntegrationFileTypeEnum.LiabilityOperation:
                    await this.integrationFileService.uploadLiabilityOperation(this.componentFiles[0]);
                    break;
            }

            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/integration/list');
        } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: `${error.messageError}`, life: 5000 });
        } finally {
            this.loadingService.hide();
        }
    }
}