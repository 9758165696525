<!-- Operation -->

<!-- <p-scrollPanel [style]="{width: '100%', height: '200px'}" *ngIf="selectedOperation"> -->
    <div class="box-messages" *ngIf="selectedOperation">
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12"
                *ngFor="let asessment of assessments">
                    <h5 class="text-bold" *ngIf="asessment?.user">[{{asessment?.createDate  | dateTimeFormat | date: 'HH:mm, dd/MM/yyyy'}}] {{whitelabelUser?.style?.name.charAt(0).toUpperCase() + whitelabelUser?.style?.name.slice(1)}} FoF goLiza: <span class="text-default">{{asessment?.notes}}</span></h5>
                    <h5 class="text-bold" *ngIf="!asessment?.user">[{{asessment?.createDate | dateTimeFormat  | date: 'HH:mm, dd/MM/yyyy'}}] Gestor {{asessment?.contact?.email}}: <span class="text-default">{{asessment?.notes}}</span></h5>
            </div>
        </div>
    </div>
<!-- </p-scrollPanel> -->

<!-- Broker Operation -->

<!-- <p-scrollPanel [style]="{width: '100%', height: '200px'}" *ngIf="selectedBrokerOperation"> -->
    <div class="box-messages" *ngIf="selectedBrokerOperation">
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12" 
                *ngFor="let asessment of brokerAssessments">
                    <h5 class="text-bold" *ngIf="!asessment?.user">[{{asessment?.createDate  | dateTimeFormat | date: 'HH:mm, dd/MM/yyyy'}}] Corretora <span class="text-default">{{asessment?.notes}}</span></h5>
                    <h5 class="text-bold" *ngIf="asessment?.user">[{{asessment?.createDate | dateTimeFormat  | date: 'HH:mm, dd/MM/yyyy'}}] {{whitelabelUser?.style?.name.charAt(0).toUpperCase() + whitelabelUser?.style?.name.slice(1)}} Corretoras goLiza: <span class="text-default">{{asessment?.notes}}</span></h5>
            </div>
        </div>
    </div>
<!-- </p-scrollPanel> -->