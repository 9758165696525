<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Novo Administrador de Fundo
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="cardHeader text-bold">Dados do Administrador</div>
                <div class="p-grid p-fluid p-formgrid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="requestModel.name" name="name" #name="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="documentNumber" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" [(ngModel)]="requestModel.documentNumber"
                                    name="documentNumber" #documentNumber="ngModel" [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                        </div>
                        <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 p-sm-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                                    [(ngModel)]="requestModel.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                                    [unmask]="true" >
                                </p-inputMask>
                                <label for="phoneNumber" class="labelLiza">Telefone</label>
                            </span>
                        </div>
                        <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 p-sm-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="email" type="email" [(ngModel)]="requestModel.email" name="email" #email="ngModel"
                                    class="inputLiza" pInputText appValidateEmail>
                                <label for="email">E-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="email.invalid && email.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="giinNumber" type="text" [(ngModel)]="requestModel.giinNumber"
                                    name="giinNumber" #giinNumber="ngModel" class="inputLiza" pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="cardHeader text-bold">Template da Ficha Cadastral</div>
                <app-artifact-template-create (onArtifactFilled)="registrationFormTemplateFilled($event)"></app-artifact-template-create>

                <div class="cardHeader text-bold">Representantes</div>
                <form #representativeForm="ngForm" novalidate>
                    
                        <div class="p-grid customCardAdjust">
                            <div class="p-col-12 p-md-2 p-lg-2 inputItem customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="representativeName" type="text"
                                            [(ngModel)]="representativeRequestForm.name" name="representativeName"
                                            #representativeName="ngModel" class="inputLiza" required pInputText>
                                        <label for="representativeName">Nome Completo</label>
                                    </span>
                                </div>
                                <mark *ngIf="representativeName.invalid && representativeName.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 inputItem customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="representativeEmail" type="text"
                                            [(ngModel)]="representativeRequestForm.email" name="representativeEmail"
                                            #representativeEmail="ngModel" class="inputLiza" required pInputText>
                                        <label for="representativeEmail">Email</label>
                                    </span>
                                </div>
                                <mark *ngIf="representativeEmail.invalid && representativeEmail.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 inputItem customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <p-calendar [(ngModel)]="representativeRequestForm.expireDate"
                                            name="representativeExpireDate" yearRange="2022:2099"
                                            [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                            [required]="true" #representativeExpireDate="ngModel" [required]="true">
                                        </p-calendar>
                                        <label for="representativeExpireDate" class="labelOcta">Data de Expiração
                                        </label>
                                    </span>
                                </div>
                                <mark *ngIf="representativeExpireDate.invalid && representativeExpireDate.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="inputItem customCardOutlined">
                                <button pButton class="p-button-primary" label="Adicionar"
                                    (click)="addRepresentativeRequest(representativeRequestForm, representativeForm)"></button>
                            </div>
                        </div>
                </form>
                <div *ngIf="requestModel != null && requestModel.representatives.length > 0">
                    <!-- <p-scrollPanel [style]="{width: '100%', height: '200px'}"> -->
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2 p-lg-2">
                                <p style="padding: 1%;" class="text-bold">Nome</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2">
                                <p style="padding: 1%;" class="text-bold">E-mail</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2">
                                <p style="padding: 1%;" class="text-bold">Data de Expiração</p>
                            </div>
                        </div>
                        <div class="p-grid p-formgrid"
                            *ngFor="let representative of requestModel.representatives">
                            <div style="padding: 1%;" class="p-col-12 p-md-2 p-lg-2 inputItem">
                                <span>
                                    {{representative.name}}
                                </span>
                            </div>
                            <div style="padding: 1%;" class="p-col-12 p-md-2 p-lg-2 inputItem">
                                <p>
                                    {{representative.email}}
                                </p>
                            </div>
                            <div style="padding: 1%;" class="p-col-12 p-md-2 p-lg-2 inputItem">
                                <p>
                                    {{representative.expireDate | date: 'dd/MM/yyyy' }}
                                </p>
                            </div>
                            <div style="padding: 1%;" class="p-col-12 p-md-3 p-lg-3 inputItem">
                                <button pButton class="p-button-outlined-blue p-button-rounded" icon="pi pi-trash"
                                    (click)="removeRepresentativeRequest(representative)"></button>
                            </div>
                            <div class="line"></div>
                        </div>
                    <!-- </p-scrollPanel> -->
                </div>

                <div class="p-mt-3">
                    <div class="p-d-flex p-jc-end">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" iconPos="right"
                            class="action-button p-ml-2" (click)="onSave(entryForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>