<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Relatório
            </div>
        </div>
        <div class="customCard">
            <div class="cardHeader text-bold">Tipo de relatório</div>
            <div class="p-grid">

                <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="typeReportOptions" placeholder="Selecione o tipo de relatório"
                                (onChange)="changeOperation($event.value)" [required]="true" [showClear]="true"
                                [(ngModel)]="selectedReport" optionLabel="label" #report="ngModel">
                            </p-dropdown>
                        </span>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 1">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="periods" placeholder="Selecione o período" [required]="true"
                                        [showClear]="true" [(ngModel)]="filteredPeriod" [resetFilterOnHide]="true"
                                        optionLabel="label" (onChange)="changePeriod($event.value)" optionValue="value"
                                        #period="ngModel" name="period">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid" *ngIf="startPeriod && endPeriod">
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">De: {{startPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">Até: {{endPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="customCardOutlined" *ngIf="selectedPeriod?.summaries.length > 0">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Operações Processadas</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12"
                                            *ngFor="let sumary of selectedPeriod.summaries">
                                            <p>{{sumary.operationType | typesBrokerOperation}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">Valor</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12">
                                            <p>{{selectedPeriod?.summaries[0]?.doneCount}}</p>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-lg-12">
                                            <p>{{selectedPeriod?.summaries[1]?.doneCount}}</p>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-lg-12">
                                            <p>{{selectedPeriod?.summaries[2]?.doneCount}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Total</h5>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">{{selectedPeriod.totalDone}}</h5>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="liabilityOperationsReport.length > 0">
                            <div class="contentButtonActions">
                                <button pButton pRipple type="button"
                                    label="Exportar" (click)="exportLiabilityOperations()"></button>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-table [responsive]="true" #dt [value]="liabilityOperationsReport" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [lazy]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">

                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operation>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFundDocumentNumber">
                                            <div>
                                                CNPJ F. Investido
                                                <p-sortIcon field="investedFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Investidor
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFundDocumentNumber">
                                            <div>
                                                CNPJ F. Investidor
                                                <p-sortIcon field="shareholderFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="party">
                                            <div>
                                                Prestador de serviço
                                                <p-sortIcon field="party"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="oldValue">
                                            <div>
                                                Razão Social Antigo
                                                <p-sortIcon field="oldValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentValue">
                                            <div>
                                                Razão Social Novo
                                                <p-sortIcon field="currentValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="oldValue">
                                            <div>
                                                CNPJ Antigo
                                                <p-sortIcon field="oldValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentValue">
                                            <div>
                                                CNPJ Novo
                                                <p-sortIcon field="currentValue"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.investedFund}}
                                        </td>
                                        <td>
                                            {{operation?.investedFundDocumentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFund }}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFundDocumentNumber | documentNumber: 'cnpj' }}
                                        </td>
                                        <td>
                                            {{operation?.party}}
                                        </td>
                                        <td>
                                            {{operation?.oldReason}}
                                        </td>
                                        <td>
                                            {{operation?.currentReason}}
                                        </td>
                                        <td>
                                            {{operation?.oldCNPJ | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.currentCNPJ | documentNumber: 'cnpj'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 3">

                    <div class="p-grid">
                        <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="liabilityOperationOptions" placeholder="Selecione o Fundo"
                                        [required]="true" [showClear]="true" [(ngModel)]="selectedLiabilityOption"
                                        [resetFilterOnHide]="true" optionLabel="label" optionValue="value"
                                        #liabilityOption="ngModel" name="liabilityOption">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12"></div>
                        <div class="p-col-12 p-md-12 p-lg-2">
                            <label for="start">Data Início</label>
                            <p-calendar inputId="start" [(ngModel)]="startPeriod" appendTo="body"
                                (onSelect)="changeStartPeriod($event)" dateFormat="yyy/mm/dd"></p-calendar>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-2">
                            <label for="end">Data Final</label>
                            <p-calendar inputId="end" [(ngModel)]="endPeriod" appendTo="body"
                                (onSelect)="changeEndDatePeriod($event)" dateFormat="yyy/mm/dd"
                                [disabled]="!startPeriod"></p-calendar>
                        </div>
                        <div class="p-col-2 p-md-3 p-lg-3"
                            style="justify-content: flex-start;display: flex;align-items: flex-end;">
                            <button pButton pRipple type="button" label="Buscar" class="p-button-primary"
                                [disabled]="startPeriod == null || endPeriod == null"
                                (click)="getReportsWithByFund()"></button>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="liabilityOperationsReport.length > 0">
                            <div class="contentButtonActions">
                                <button pButton pRipple type="button"
                                    label="Exportar" (click)="exportLiabilityOperations()"></button>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-table [responsive]="true" #dt [value]="liabilityOperationsReport" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true"
                                [autoLayout]="true" [paginator]="true" [lazy]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">

                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operation>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFundDocumentNumber">
                                            <div>
                                                CNPJ F. Investido
                                                <p-sortIcon field="investedFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Investidor
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFundDocumentNumber">
                                            <div>
                                                CNPJ F. Investidor
                                                <p-sortIcon field="shareholderFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="party">
                                            <div>
                                                Prestador de serviço
                                                <p-sortIcon field="party"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="oldValue">
                                            <div>
                                                Razão Social Antigo
                                                <p-sortIcon field="oldValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentValue">
                                            <div>
                                                Razão Social Novo
                                                <p-sortIcon field="currentValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="oldValue">
                                            <div>
                                                CNPJ Antigo
                                                <p-sortIcon field="oldValue"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentValue">
                                            <div>
                                                CNPJ Novo
                                                <p-sortIcon field="currentValue"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.investedFund}}
                                        </td>
                                        <td>
                                            {{operation?.investedFundDocumentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFund }}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFundDocumentNumber | documentNumber: 'cnpj' }}
                                        </td>
                                        <td>
                                            {{operation?.party}}
                                        </td>
                                        <td>
                                            {{operation?.oldReason}}
                                        </td>
                                        <td>
                                            {{operation?.currentReason}}
                                        </td>
                                        <td>
                                            {{operation?.oldCNPJ | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.currentCNPJ | documentNumber: 'cnpj'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Selecione o período" [(visible)]="displayPeriod" [modal]="true" [responsive]="true">
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="start">Data Início</label>
            <p-calendar inputId="start" [(ngModel)]="startPeriod" appendTo="body" (onSelect)="changeStartPeriod($event)"
                dateFormat="yyy/mm/dd"></p-calendar>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="end">Data Final</label>
            <p-calendar inputId="end" [(ngModel)]="endPeriod" appendTo="body" (onSelect)="changeEndPeriod($event)"
                dateFormat="yyy/mm/dd" [disabled]="!startPeriod"></p-calendar>
        </div>
    </div>
</p-dialog>

<p-dialog header="Selecione o período" [(visible)]="displayFundReports" appendTo="body" [modal]="true"
    [responsive]="true">
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="start">Data Início</label>
            <p-calendar inputId="start" [(ngModel)]="fundReportStartPeriod" appendTo="body"
                dateFormat="yyy/mm/dd"></p-calendar>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="end">Data Final</label>
            <p-calendar inputId="end" [(ngModel)]="fundReportEndPeriod" appendTo="body"
                (onSelect)="changeFundReportsEndPeriod()" dateFormat="yyy/mm/dd"
                [disabled]="!fundReportStartPeriod"></p-calendar>
        </div>
    </div>
</p-dialog>