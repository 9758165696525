import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { UserViewModel } from '../../../services/users/models/view-models/user-view-model';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { BrokerOperationTypeEnum } from 'src/app/enums/broker-operation-type-enum';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { BrokerEntryViewModel } from 'src/app/services/funds/models/view-models/broker-entry-view-model';
import { DocumentNumberPipe } from '../../../pipes/number-formatting/document-number-pipe';
import { environment } from 'src/environments/environment';
import { LiabilityService } from './../../../services/liabilities/liability-service';

@Component({
    templateUrl: './liability-home.component.html',
    styleUrls: ['./liability-home.component.scss', '../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LiabilityHomeComponent implements OnInit, AfterViewInit {

    liabilityOperationProcess: number = null;
    liabilityOperationChanges: number = null;

    constructor(
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private router: Router,
        private liabilityService: LiabilityService,
        private elementRef: ElementRef,
    ) { }


    ngAfterViewInit(): void {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.loadData();
        this.loadingService.hide();
    }

    async loadData() {
        const response = await this.liabilityService.getAllLiabilityOperationsFinished();
        this.liabilityOperationProcess = response.length;
        this.liabilityOperationChanges = response.filter(it => it.results.length > 0).length;
    }
    
}