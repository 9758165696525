import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { BrokerOperationSetupViewModel } from "../brokers/models/view-models/broker-operation-setup-view-Model";
import { BrokerRepresentativeViewModel } from "../brokers/models/view-models/broker-representative-view-model";
import { FundBrokerViewModel } from './../brokers/models/view-models/fund-broker-view-model';
import { CreateBrokerRepresentativeRequest } from "./models/requests/create-broker-representative-request";
import { CreateFundBrokerOperationSetupRequest } from "./models/requests/create-fund-broker-operation-setup-request";
import { CreateFundBrokerRequest } from "./models/requests/create-fund-broker-request";
import { UpdateBrokerAdditionTemplateRequest } from "./models/requests/update-broker-addition-template-request";
import { UpdateBrokerOperationTemplateRequest } from "./models/requests/update-broker-operation-template-request";
import { UpdateBrokerRegistrationFormTemplateRequest } from "./models/requests/update-broker-registration-form-template-request";
import { UpdateBrokerRepresentativeRequest } from "./models/requests/update-broker-representative-request";
import { UpdateBrokerUpdateFormTemplateRequest } from "./models/requests/update-broker-update-form-template-request";
import { UpdateFundBrokerOperationSetupRequest } from "./models/requests/update-fund-broker-operation-setup-request";
import { UpdateFundBrokerRequest } from "./models/requests/update-fund-broker-request";

@Injectable({
    providedIn: 'root'
})

export class FundBrokerService {
    constructor(private httpClient: HttpClientService) { }

    async getById(fundBrokerId: string): Promise<FundBrokerViewModel> {
        return this.httpClient.get(`/fund-brokers`, `/${fundBrokerId}`);
    }

    async update(request: UpdateFundBrokerRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put(`/fund-brokers`, `/`, request);
    }

    async getAll(isActive: boolean): Promise<FundBrokerViewModel[]> {
        return this.httpClient.get(`/fund-brokers`, `/all/${isActive}`);
    }

    async createFundBroker(request: CreateFundBrokerRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.post('/fund-brokers', '/', request);
    }

    async updateFundBroker(request: UpdateFundBrokerRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put('/fund-brokers', '/', request);
    }

    async createOperationSetup(request: CreateFundBrokerOperationSetupRequest): Promise<BrokerOperationSetupViewModel> {
        return this.httpClient.post('/fund-brokers', '/operation-setups', request);
    }

    async deleteOperationSetup(operationSetupId: string): Promise<BrokerOperationSetupViewModel> {
        return this.httpClient.delete(`/fund-brokers`, `/operation-setups/${operationSetupId}`);
    }

    async updateRegistrationFormTemplate(request: UpdateBrokerRegistrationFormTemplateRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put('/fund-brokers', '/registration-form-templates', request);
    }

    async updateFormTemplate(request: UpdateBrokerUpdateFormTemplateRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put('/fund-brokers', '/update-form-templates', request);
    }

    async additionFormTemplate(request: UpdateBrokerAdditionTemplateRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put('/fund-brokers', '/addition-form-templates', request);
    }

    async operationFormTemplate(request: UpdateBrokerOperationTemplateRequest): Promise<FundBrokerViewModel> {
        return this.httpClient.put('/fund-brokers', '/operation-form-templates', request);
    }

    async createRepresentative(request: CreateBrokerRepresentativeRequest): Promise<any> {
        return this.httpClient.post(`/fund-brokers`, `/representatives`, request);
    }

    async deleteRepresentative(representativeId: string): Promise<void> {
        return this.httpClient.delete(`/fund-brokers`, `/representatives/${representativeId}`);
    }

    async deleteFundBroker(fundBrokerId: string): Promise<void> {
        return this.httpClient.delete(`/fund-brokers`, `/${fundBrokerId}`, true);
    }

    async getRepresentativesInFundBroker(fundBrokerId: string): Promise<BrokerRepresentativeViewModel[]> {
        return this.httpClient.get(`/fund-brokers/`, `${fundBrokerId}/representatives`);
    }

    async updateRepresentativeFundAdmin(request: UpdateBrokerRepresentativeRequest): Promise<any> {
        return this.httpClient.put(`/fund-brokers`, `/representatives`, request)
    }

    async decryptedDocumentRepresentativeFund(representativeId: string): Promise<any> {
        return this.httpClient.get(`/fund-brokers`, `/representatives/${representativeId}/document-number/decrypted`);
    }

    async createDeskConfigs(fundBrokerId: string, file): Promise<FundBrokerViewModel> {
        return this.httpClient.postFile(`/fund-brokers`, `/${fundBrokerId}/desk-configs/imports`, file);
    }

    async operationSetups(request: UpdateFundBrokerOperationSetupRequest): Promise<BrokerOperationSetupViewModel> {
        return this.httpClient.put(`/fund-brokers`, `/operation-setups`, request);
    }
}
