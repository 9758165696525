import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { FinancialInstitutionService } from 'src/app/services/financial-institutions/financial-institution-service';
import { UpdateFinancialInstitutionRequest } from 'src/app/services/financial-institutions/models/request/update-financial-institution-request';
import { FinancialInstitutionViewModel } from 'src/app/services/financial-institutions/models/view-models/financial-institution-view-model';
import { FundService } from 'src/app/services/funds/fund-service';
import { UpdateShareholderFundRequest } from 'src/app/services/funds/models/request/update-shareholder-fund-request';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';

@Component({
    templateUrl: './financial-institution-edit.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FinancialInstitutionEditComponent implements OnInit {

    selectedInstitution: FinancialInstitutionViewModel = null;
    establishmentDate: Date = null;
    selectedApplication: ApplicationResponseViewModel = null;
    // optionsBovespa: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    // optionsBMF: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    // selectedBovespa: SelectItem = null;
    // selectedBMF: SelectItem = null;
    // selectedDeskConfig: string = null;
    // optionsDeskConfig: SelectItem[] = [];

    public applicationTypeEnum = ApplicationTypeEnum;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private messageService: MessageService,
        private contextService: ContextService,
        private financialInstitutionService: FinancialInstitutionService,
    ) { }

    async ngOnInit() {
        this.loadingService.show();
        this.verifyUserPermissions();
        // this.getDeskConfigOptions();
        await this.getData();
        this.loadingService.hide();
    }

    // getDeskConfigOptions() {
    //     this.optionsDeskConfig = [
    //         { label: "Nenhuma das alternativas", value: "" },
    //         { label: "ALOC", value: "ALOC" },
    //         { label: "ARTAX", value: "ARTAX" },
    //         { label: "ASGARD", value: "ASGARD" },
    //         { label: "CHARLIE", value: "CHARLIE" },
    //         { label: "CRÉDITO ESTRUTURADO", value: "CRÉDITO ESTRUTURADO" },
    //         { label: "DELTA", value: "DELTA" },
    //         { label: "DUNAMIS", value: "DUNAMIS" },
    //         { label: "EXTERNA", value: "EXTERNA" },
    //         { label: "FOX", value: "FOX" },
    //         { label: "GIS", value: "GIS" },
    //         { label: "GOLF", value: "GOLF" },
    //         { label: "INDEXADOS / EXTERNA", value: "INDEXADOS / EXTERNA" },
    //         { label: "INDEXADOS / SFC / SOLUTIONS", value: "INDEXADOS / SFC / SOLUTIONS" },
    //         { label: "INDEXADOS / SOLUTIONS", value: "INDEXADOS / SOLUTIONS" },
    //         { label: "INDEXADOS", value: "INDEXADOS" },
    //         { label: "MAC", value: "MAC" },
    //         { label: "MOMENTO", value: "MOMENTO" },
    //         { label: "OPTIMUS / EXTERNA", value: "OPTIMUS / EXTERNA" },
    //         { label: "OPTIMUS", value: "OPTIMUS" },
    //         { label: "QUANTAMENTAL", value: "QUANTAMENTAL" },
    //         { label: "SFC / SOLUTIONS", value: "SFC / SOLUTIONS" },
    //         { label: "SFC / SRF", value: "SFC / SRF" },
    //         { label: "SFC", value: "SFC" },
    //         { label: "SFF", value: "SFF" },
    //         { label: "SHA / EXTERNA", value: "SHA / EXTERNA" },
    //         { label: "SHA", value: "SHA" },
    //         { label: "SOLUTIONS / EXTERNA", value: "SOLUTIONS / EXTERNA" },
    //         { label: "SOLUTIONS / SHA", value: "SOLUTIONS / SHA" },
    //         { label: "SOLUTIONS / SRF", value: "SOLUTIONS / SRF" },
    //         { label: "SOLUTIONS", value: "SOLUTIONS" },
    //         { label: "SRF", value: "SRF" },
    //         { label: "SRT", value: "SRT" },
    //         { label: "SRV / EXTERNA", value: "SRV / EXTERNA" },
    //     ]
    // }

    verifyUserPermissions() {
        this.selectedApplication = this.contextService.getUserApplicationSelected();
    }

    // handleBovespa(bovespa: SelectItem) {
    //     this.selectedBovespa = bovespa;
    // }

    // handleBMF(bmf: SelectItem) {
    //     this.selectedBMF = bmf
    // }

    async getData() {
        const fundId = this.route.snapshot.params.id;
        this.selectedInstitution = await this.financialInstitutionService.getById(fundId);
        this.establishmentDate = new Date(this.selectedInstitution.establishmentDate);
    }


    onCancel() {
        // this.router.navigateByUrl('app/customer/fund/list');
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            this.router.navigate([`app/customer/financial-institution/list`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            this.router.navigate([`app/customer/financial-institution/list`]);
        }
    }

    changeEstablishmentDate(event: Date) {
        this.establishmentDate = event;
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;
        const request: UpdateFinancialInstitutionRequest = {

            documentNumber: this.selectedInstitution?.documentNumber,
            name: componentForm.controls?.name?.value,
            establishmentDate: this.establishmentDate,
            bankAccount: {
                bankCode: componentForm.controls.bankCode.value,
                bankName: componentForm.controls.bankName.value,
                agencyNumber: componentForm.controls.agencyNumber.value,
                accountNumber: componentForm.controls.accountNumber.value,
                accountDigit: componentForm.controls.accountDigit.value,
            },
            patrimony: String(componentForm.controls.patrimony.value),
            lastYearInvoicing: String(componentForm.controls.lastYearInvoicing.value),
            shareCapital: String(componentForm.controls.shareCapital.value),
            nire: String(componentForm.controls.nire.value),
        }

        try {
            this.loadingService.show();
            await this.financialInstitutionService.update(request);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/financial-institution/list');
        } catch (err) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: err.messageError, life: 5000 });
        } finally {
            this.loadingService.hide();
        }
    }

}