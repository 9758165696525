import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { GetDoneOperationsTimeframeByDateRangeResponse } from 'src/app/services/operations/models/responses/get-done-operations-timeframe-by-date-range-response';
import { GetFinishedOperationsByDateRangeResponse } from 'src/app/services/operations/models/responses/get-finished-operations-by-date-range-response';
import { GetFinishedOperationsYearlyReportResponse } from 'src/app/services/operations/models/responses/get-finished-operations-yearly-report-response';
import * as FileSaver from 'file-saver';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerOperationTypeEnum } from 'src/app/enums/broker-operation-type-enum';
import { FundService } from 'src/app/services/funds/fund-service';
import { GetFundBrokerEntriesByDateRangeResponse } from 'src/app/services/funds/models/responses/get-fund-broker-entries-by-date-range-response';
import { LiabilityService } from 'src/app/services/liabilities/liability-service';
import { LiabilityOperationViewModel } from 'src/app/services/liabilities/models/view-models/liability-operation-view-model';
import { GetLiabilityOperationsWhitChangesReportRequest } from 'src/app/services/liabilities/models/request/get-liability-operations-whit-changes-report-request';
import { GetLiabilityOperationsWithChangesReportResponse } from 'src/app/services/liabilities/models/responses/get-liability-operations-with-changes-report-response';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';

@Component({
    templateUrl: './liability-operation-report.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class LiabilityOperationReportComponent implements OnInit {

    allTypesOperation: any[] = [];
    allYears: SelectItem[] = [];
    selectedYear: SelectItem = null;
    typeOperation: SelectItem[] = [];
    typeOperationGraphic: SelectItem[] = [];
    statusOperation: SelectItem[] = [];
    selectedStatusOperation: SelectItem = null;
    selectedTypeOperation: any = null;
    typeReportOptions: SelectItem[] = [];
    selectedReport: SelectItem = null;
    periods: SelectItem[] = [];

    stackedData: any = null;
    stackedOptions: any = null;

    stackedReportTimeFrames: any = null;
    stackedReportTimeFramesOptions: any = null;

    selectedPeriod: GetFinishedOperationsByDateRangeResponse = null;
    selectedPeriodYearly: GetFinishedOperationsYearlyReportResponse[] = [];
    selectedPeriodTimeFrames: GetDoneOperationsTimeframeByDateRangeResponse[] = []

    displayPeriod: boolean = false;

    startPeriod: Date = null;
    endPeriod: Date = null;
    startDate: string = null;
    endDate: string = null;

    filteredPeriod: SelectItem = null;
    amountTimeFrames: number = 0;
    daysAmountTimeFrames: number = 0;

    operations: LiabilityOperationViewModel[] = [];
    firstPage: number = 0;
    operationsExport: any[] = [];
    consultMonth: number = null
    consultYear: number = null

    allTypesStatus: any[] = [];
    totalDone: number = 0;
    totalCancel: number = 0;
    allYearsCharges: SelectItem[] = [];
    allMonthsCharges: SelectItem[] = [];
    fundReports: GetFundBrokerEntriesByDateRangeResponse[] = [];
    fundReportsExport: any[] = [];
    displayFundReports: boolean = false;
    fundReportStartPeriod: Date = null;
    fundReportEndPeriod: Date = null;

    liabilityOperationsReport: GetLiabilityOperationsWithChangesReportResponse[] = [];
    liabilityOperationOptions: SelectItem[] = [];
    selectedLiabilityOption: LiabilityOperationViewModel = null;

    public operationStatusEnum = OperationStatusEnum;
    public brokerOperationTypeEnum = BrokerOperationTypeEnum;
    hasLiabilityOperations: boolean = null;

    constructor(
        private liabilityService: LiabilityService,
        private messageService: MessageService,
        private datePipe: DatePipe,
        private fundService: FundService,
        private loadingService: LoadingService,
    ) {
    }

    ngOnInit() {
        this.getTypeReports();
    }

    allMonthsAndYearsCharges() {
        this.allMonthsCharges = [
            { label: "Janeiro", value: 1 },
            { label: "Fevereiro", value: 2 },
            { label: "Março", value: 3 },
            { label: "Abril", value: 4 },
            { label: "Maio", value: 5 },
            { label: "Junho", value: 6 },
            { label: "Julho", value: 7 },
            { label: "Agosto", value: 8 },
            { label: "Setembro", value: 9 },
            { label: "Outubro", value: 10 },
            { label: "Novembro", value: 11 },
            { label: "Dezembro", value: 12 },
        ]
        this.allYearsCharges = [
            { label: "2022", value: 2022 },
            { label: "2023", value: 2023 },
            { label: "2024", value: 2024 },
        ]
    }

    allTypeStatusOperation() {
        this.allTypesStatus = [
            {
                operationType: "CadastroDeFundo",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "AtualizacaodeFundo",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "CadastroDeInstituicao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "AtualizacaoDeInstituicao",
                canceledCount: 0,
                doneCount: 0,
            },
        ]
    }

    getTypeReports() {
        this.typeReportOptions = [
            { label: 'Relatório Simples', value: 1 },
            { label: 'Relatório De mudanças por data', value: 2 },
            { label: 'Relatório Analítico por fundo', value: 3 },
            // { label: 'Relatório de Faturamento', value: 4 },
            // { label: 'Relatório de Operações Concluídas', value: 5 },
        ];
    }

    getStatusOfGrapichReport() {
        this.statusOperation = [
            { label: 'Concluídos', value: 1 },
            { label: 'Cancelados', value: 2 },
            { label: 'Todos', value: 3 },
        ];
    }

    allOperations() {
        this.allTypesOperation = [
            { label: 'Cadastro de Fundo', labelResponse: 'CadastroDeFundo', value: BrokerOperationTypeEnum.CadastroDeFundo },
            { label: 'Atualização de Fundo', labelResponse: 'AtualizacaodeFundo', value: BrokerOperationTypeEnum.AtualizacaodeFundo },
            { label: 'Cadastro de Instituição', labelResponse: 'CadastroDeInstituicao', value: BrokerOperationTypeEnum.CadastroDeInstituicao },
            { label: 'Atualização de Instituição', labelResponse: 'AtualizacaoDeInstituicao', value: BrokerOperationTypeEnum.AtualizacaoDeInstituicao },
            { label: 'Todos', value: 100 },
        ];
    }

    getPeriods() {
        this.periods = [];
        this.periods = [
            { label: 'Hoje', value: 1 },
            { label: 'Essa semana', value: 2 },
            { label: 'Este mês', value: 3 },
            { label: 'Últimos 30 dias', value: 4 },
            { label: 'Escolha o período', value: 5 },
        ];
    }

    getPeriodDuration() {
        this.periods = [];
        this.periods = [
            { label: 'Este mês', value: 1 },
            { label: 'Mês anterior', value: 2 },
            { label: 'Este ano', value: 3 },
            { label: 'Escolha o período', value: 4 },
        ];
    }

    async changeOperation(event: SelectItem) {
        this.selectedPeriod = null;
        switch (event.value) {
            case 1:
                this.getPeriods();
                this.getTypesOperation();
                break;
            case 2:
                this.openDisplayPeriod();
                break;
            case 3:
                this.loadingService.show();
                await this.getLiabilityOperationsFinishedData();
                this.loadingService.hide();
                break;
            default:
                break;
        }
    }

    openDisplayPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        this.displayPeriod = true;
        this.liabilityOperationsReport = [];
    }

    exportLiabilityOperations() {
        this.operationsExport = this.liabilityOperationsReport.map(operation => {
            return {
                'ID': operation?.sequentialId,
                'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                'Data Conclusão/Cancelamento': operation?.finishDate ? this.datePipe.transform(operation?.finishDate, 'dd/MM/yyyy') : '',
                'Fundo Investido': `${operation?.investedFund}`,
                'CNPJ F. Investido': `${operation?.investedFundDocumentNumber}`,
                'Fundo Investidor': `${operation?.shareholderFund}`,
                'CNPJ F. Investidor': `${operation?.shareholderFundDocumentNumber}`,
                'Prestador de serviço': `${operation?.party}`,
                'Razão Social Antigo': `${operation?.oldReason}`,
                'Razão Social Novo': `${operation?.currentReason}`,
                'CNPJ Antigo': `${operation?.oldCNPJ}`,
                'CNPJ Novo': `${operation?.currentCNPJ}`,
            }
        });
        this.exportExcel();
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.operationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }


    getTypesOperation() {
        this.typeOperation = this.allTypesOperation;
        this.typeOperation.pop();
    }

    async changeReportDuration() {

        const selectedTypeOperation = this.selectedTypeOperation ? this.selectedTypeOperation?.value : null;
        const start = this.startDate
        const end = this.endDate
        this.amountTimeFrames = 0;
        this.daysAmountTimeFrames = 0;

        if (this.selectedTypeOperation.value == 100) {
            this.selectedPeriodTimeFrames = await this.liabilityService.finishedReportTimeFrames(start, end, null);
        } else if (this.selectedTypeOperation.value !== 100) {
            this.selectedPeriodTimeFrames = await this.liabilityService.finishedReportTimeFrames(start, end, selectedTypeOperation);
        }
        const quantityOfOperations = this.selectedPeriodTimeFrames.map(item => item.quantityOfOperations);
        const daysToComplete = this.selectedPeriodTimeFrames.map(item => item.daysToComplete);
        this.amountTimeFrames = quantityOfOperations.reduce((prev, curr) => prev + curr, 0);
        this.daysAmountTimeFrames = daysToComplete.reduce((prev, curr) => prev + curr, 0);


        this.stackedReportTimeFrames = {
            labels: daysToComplete,
            datasets: [{
                type: 'bar',
                label: "Concluídos",
                backgroundColor: '#FE7100',
                data: quantityOfOperations
            }]
        };

        this.stackedReportTimeFramesOptions = {
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: () => null,
                }
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

    }

    filterPeriod() {

        if (this.selectedStatusOperation.value == 1 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonths: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonths.push(count.doneCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        backgroundColor: '#FE7100',
                        data: countMonths,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 2 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonths: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonths.push(count.canceledCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        backgroundColor: '#FE7100',
                        data: countMonths,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 3 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonthsDone: number[] = [];
            let countMonthsCancel: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonthsDone.push(count.doneCount);
            }
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonthsCancel.push(count.canceledCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        // label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        label: `Concluídos`,
                        backgroundColor: 'var(--primary-color)',
                        data: countMonthsDone,
                    },
                    {
                        // type: 'horizontalBar',
                        // label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        label: `Cancelados`,
                        backgroundColor: '#ababab',
                        data: countMonthsCancel,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 3 && this.selectedTypeOperation.value == 100) {
            let fund: GetFinishedOperationsYearlyReportResponse[] = [];
            let wallet: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];

            fund = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeFundo');
            wallet = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeCarteira');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AtualizacaodeFundo');

            let countFund: number[] = [];
            for (let count of fund[0].monthlyOccurence) {
                countFund.push(count.totalCount);
            }

            let countWallet: number[] = [];
            for (let count of wallet[0].monthlyOccurence) {
                countWallet.push(count.totalCount);
            }

            let countUpdate: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUpdate.push(count.totalCount);
            }

            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: fund.map(item => item.operationType) ? 'Cadastro de Fundo' : null,
                        backgroundColor: '#FFE399',
                        data: countFund,
                    },
                    {
                        // type: 'horizontalBar',
                        label: wallet.map(item => item.operationType) ? `Cadastro de Carteira` : null,
                        backgroundColor: '#FFAB64',
                        data: countWallet,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? 'Atualização' : null,
                        backgroundColor: '#FFC179',
                        data: countUpdate,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 1 && this.selectedTypeOperation.value == 100) {
            let fund: GetFinishedOperationsYearlyReportResponse[] = [];
            let wallet: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];

            fund = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeFundo');
            wallet = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeCarteira');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AtualizacaodeFundo');

            let countFund: number[] = [];
            for (let count of fund[0].monthlyOccurence) {
                countFund.push(count.doneCount);
            }

            let countWallet: number[] = [];
            for (let count of wallet[0].monthlyOccurence) {
                countWallet.push(count.doneCount);
            }

            let countUpdate: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUpdate.push(count.doneCount);
            }

            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: fund.map(item => item.operationType) ? 'Cadastro de Fundo' : null,
                        backgroundColor: '#FFE399',
                        data: countFund,
                    },
                    {
                        // type: 'horizontalBar',
                        label: wallet.map(item => item.operationType) ? `Cadastro de Carteira` : null,
                        backgroundColor: '#FFAB64',
                        data: countWallet,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? 'Atualização' : null,
                        backgroundColor: '#FFC179',
                        data: countUpdate,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 2 && this.selectedTypeOperation.value == 100) {
            let fund: GetFinishedOperationsYearlyReportResponse[] = [];
            let wallet: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];

            fund = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeFundo');
            wallet = this.selectedPeriodYearly.filter(fill => fill.operationType == 'CadastroDeCarteira');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AtualizacaodeFundo');

            let countFund: number[] = [];
            for (let count of fund[0].monthlyOccurence) {
                countFund.push(count.canceledCount);
            }

            let countWallet: number[] = [];
            for (let count of wallet[0].monthlyOccurence) {
                countWallet.push(count.canceledCount);
            }

            let countUpdate: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUpdate.push(count.canceledCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: fund.map(item => item.operationType) ? 'Cadastro de Fundo' : null,
                        backgroundColor: '#FFE399',
                        data: countFund,
                    },
                    {
                        // type: 'horizontalBar',
                        label: wallet.map(item => item.operationType) ? 'Cadastro de Carteira' : null,
                        backgroundColor: '#FFC179',
                        data: countWallet,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? `Atualização` : null,
                        backgroundColor: '#FFAB64',
                        data: countUpdate,
                    },
                ]
            };
        }

        this.stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

    }

    async changeReport() {
        this.selectedPeriodYearly = null;
        const year = String(this.selectedYear);
        this.selectedPeriodYearly = await this.liabilityService.finishedReportYearly(year);
        if (this.selectedTypeOperation.value !== 100) {
            this.selectedPeriodYearly = this.selectedPeriodYearly.filter(fill => fill.operationType == this.selectedTypeOperation.labelResponse);
        }
        this.filterPeriod();
    }

    changePeriod(value: number) {
        this.selectedPeriod = null;
        if (this.selectedReport.value == 1) {
            switch (value) {
                case 1:
                    this.changeTodayPeriod();
                    break;
                case 2:
                    this.changeWeekPeriod();
                    break;
                case 3:
                    this.changeMonthPeriod();
                    break;
                case 4:
                    this.changeThirtyDaysPeriod();
                    break;
                case 5:
                    this.changeBetweenPeriod();
                    break;
                default:
            }
        }
        // else if (this.selectedReport.value == 2) {
        //     switch (value) {
        //         case 1:
        //             this.changeMonthPeriodTimeFrames();
        //             break;
        //         case 2:
        //             this.changeLastMonthPeriodTimeFrames();
        //             break;
        //         case 3:
        //             this.changeFirstDayCurrentYearPeriodTimeFrames();
        //             break;
        //         case 4:
        //             this.changeBetweenPeriod();
        //             break;
        //         default:
        //     }
        // }

    }

    async changeMonthPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        let start = firstDayCurrentMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;
    }

    async changeLastMonthPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        const today = new Date()
        const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1
        const year = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear()

        let firstDayLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        const lastDayOfLastMonth = new Date(year, lastMonth + 1, 0)

        let start = firstDayLastMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = lastDayOfLastMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;
    }

    async changeFirstDayCurrentYearPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayOfCurrentYear = new Date(date.getFullYear(), 0, 1);

        let start = firstDayOfCurrentYear.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;

    }

    async changeTodayPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        let period = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.liabilityService.finishedReportDates(period, period);
    }

    async changeWeekPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        date.setDate(date.getDate() - 7);
        let start = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.liabilityService.finishedReportDates(start, end);
    }

    async changeMonthPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        let start = firstDayCurrentMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.liabilityService.finishedReportDates(start, end);
    }

    async changeThirtyDaysPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        date.setDate(date.getDate() - 30);
        let start = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.liabilityService.finishedReportDates(start, end);
    }

    changeBetweenPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        this.displayPeriod = true;
    }

    changeStartPeriod(date: Date) {
        this.startPeriod = date;
    }

    changeEndDatePeriod(date: Date) {
        this.endPeriod = date;
    }

    async changeEndPeriod(date: Date) {

        if (this.startPeriod > this.endPeriod) {
            this.startPeriod = null;
            this.endPeriod = null;
            this.selectedPeriod = null;
            this.displayPeriod = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data início não pode ser maior que a data final.`, life: 5000 });
            return;
        }
        if (this.endPeriod > new Date()) {
            this.startPeriod = null;
            this.endPeriod = null;
            this.selectedPeriod = null;
            this.displayPeriod = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data final não pode ser maior que hoje.`, life: 5000 });
            return;
        }
        this.endPeriod = date;

        const start = this.startPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = this.endPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.displayPeriod = false;

        this.startDate = start;
        this.endDate = end;

        if (this.selectedReport.value == 1) {
            this.selectedPeriod = await this.liabilityService.finishedReportDates(start, end);
            return;
        }
        if (this.selectedReport.value == 2) {
            const request: GetLiabilityOperationsWhitChangesReportRequest = {
                fundId: null,
                shareholderFundId: null,
                startDate: this.startDate,
                endDate: this.endDate
            }
            await this.getReportWithChangesTransform(request);
        }
    }

    async getReportWithChangesTransform(request: GetLiabilityOperationsWhitChangesReportRequest) {
        
        const transformedData = [];
        let response = await this.liabilityService.getReportWithChanges(request);
        
        response.forEach(item => {
            const existingItem = transformedData.find(transformedItem => transformedItem.sequentialId === item.sequentialId);
        
            if (existingItem) {
                if (item.property === "CNPJ") {
                    existingItem.oldCNPJ = item.oldValue;
                    existingItem.currentCNPJ = item.currentValue;
                } else if (item.property === "Razão Social") {
                    existingItem.oldReason = item.oldValue;
                    existingItem.currentReason = item.currentValue;
                }
            } else {
                const newItem: any = {
                    id: item.id,
                    sequentialId: item.sequentialId,
                    createDate: item.createDate,
                    finishDate: item.finishDate,
                    shareholderFund: item.shareholderFund,
                    shareholderFundDocumentNumber: item.shareholderFundDocumentNumber,
                    investedFund: item.investedFund,
                    investedFundDocumentNumber: item.investedFundDocumentNumber,
                    party: item.party
                };
        
                if (item.property === "CNPJ") {
                    newItem.oldCNPJ = item.oldValue;
                    newItem.currentCNPJ = item.currentValue;
                } else if (item.property === "Razão Social") {
                    newItem.oldReason = item.oldValue;
                    newItem.currentReason = item.currentValue;
                }
        
                transformedData.push(newItem);
            }
        });
        this.liabilityOperationsReport = transformedData;
    }

    async getReportsWithByFund() {
        const start = this.startPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = this.endPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');


        this.startDate = start;
        this.endDate = end;

        const request: GetLiabilityOperationsWhitChangesReportRequest = {
            fundId: this.selectedLiabilityOption.fund.id,
            shareholderFundId: this.selectedLiabilityOption.shareholderFund.id,
            startDate: this.startDate,
            endDate: this.endDate
        }
        await this.getReportWithChangesTransform(request);
    }

    async changeFundReportsEndPeriod() {
        if (this.fundReportStartPeriod > this.fundReportEndPeriod) {
            this.fundReportStartPeriod = null;
            this.fundReportEndPeriod = null;
            this.fundReports = [];
            this.displayFundReports = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data início não pode ser maior que a data final.`, life: 5000 });
            return;
        }
        if (this.fundReportEndPeriod > new Date()) {
            this.fundReportStartPeriod = null;
            this.fundReportEndPeriod = null;
            this.fundReports = [];
            this.displayFundReports = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data final não pode ser maior que hoje.`, life: 5000 });
            return;
        }

        const startDate = this.fundReportStartPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const endDate = this.fundReportEndPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.displayFundReports = false;

        this.fundReports = await this.fundService.getFundReports(startDate, endDate);
    }

    fundReportExportExcel() {
        this.fundReportsExport = this.fundReports.map(operation => {
            return {
                'Razão Social': operation?.fundName,
                'CNPJ Fundo': this.parseCNPJ(operation?.fundDocumentNumber),
                'Corretora': operation?.brokerName,
                'CNPJ Corretora': this.parseCNPJ(operation?.brokerDocumentNumber),
                'Tipo da Operação': operation?.operationType,
                'CBLC': operation?.cblc,
                'Conta': operation?.notes,
                'Data de Expiração': operation?.expirationDate ? this.datePipe.transform(operation?.expirationDate, 'dd/MM/yyyy') : '',
            }
        });
        this.exportDataToExcel(this.fundReportsExport);
    }

    exportDataToExcel(data: any) {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }


    async getLiabilityOperationsFinishedData() {
        const set = new Set();
        this.liabilityOperationsReport = [];
        this.liabilityOperationOptions = [];
        this.startPeriod = null;
        this.endPeriod = null;
        let pagedSearchRequest = CreatePagedSearchRequest(null);
        const response = await this.liabilityService.getLiabilityOperationsFinishedPaged(pagedSearchRequest);
        response.searchResult.forEach(c => {
            if (!set.has(c.fund.name)) {
                set.add(c.fund.name);
                this.liabilityOperationOptions.push({ label: c.fund.name, value: c });
            }
        })

    }

}