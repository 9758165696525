import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { ExtractDocumentVariablesRequest } from "./models/requests/extract-document-variables-request";
import { ExtractDocumentVariablesResponse } from "./models/view-models/extract-document-variables-response";
import { FieldTypeOptionViewModel } from "./models/view-models/field-type-option-view-model";
import { MappedEntityOptionViewModel } from "./models/view-models/mapped-entity-option-view-model";

@Injectable({
    providedIn: 'root'
})

export class ArtifactTemplateService {
    constructor(private httpClient: HttpClientService) { }

    async getFieldTypeOptions(): Promise<FieldTypeOptionViewModel[]> {
        return this.httpClient.get(`/artifact-templates`, `/field-types`);
    }

    // async getMappedEntityOptions(): Promise<MappedEntityOptionViewModel[]> {
    //     return this.httpClient.get(`/artifact-templates`, `/mapped-entities`);
    // }

    async getMappedEntityOptions(applicationName: string): Promise<MappedEntityOptionViewModel[]> {
        return this.httpClient.get(`/artifact-templates`, `/mapped-entities?applicationName=${applicationName}`,);
    }

    async getArtifactTemplateVariables(request: ExtractDocumentVariablesRequest): Promise<ExtractDocumentVariablesResponse> {
        return this.httpClient.post('/artifact-templates', '/variables', request);
    }
}
