import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateFinancialInstitutionRequest } from 'src/app/services/financial-institutions/models/request/create-financial-institution-request';
import { FundService } from 'src/app/services/funds/fund-service';
import { CreateShareholderFundRequest } from 'src/app/services/funds/models/request/create-shareholder-fund-request';
import { FinancialInstitutionService } from './../../../../services/financial-institutions/financial-institution-service';

@Component({
    templateUrl: './financial-institution-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FinancialInstitutionCreateComponent implements OnInit {

    requestFinancialInstitution: CreateFinancialInstitutionRequest = null;
    allTypeFund: SelectItem[] = [];
    allTargetAudience: SelectItem[] = [];

    constructor(
        private router: Router,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private financialInstitutionService: FinancialInstitutionService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setForms();
        this.getAllTargetAudienceANBIMA();
    }

    setForms() {
        this.requestFinancialInstitution = {
            name: null,
            documentNumber: null,
            establishmentDate: null,
            patrimony: null,
            lastYearInvoicing: null,
            shareCapital: null,
            nire: null,
            bankAccount: {
                bankCode: null,
                bankName: null,
                agencyNumber: null,
                accountNumber: null,
                accountDigit: null,
            },
        }
    }

    getAllTargetAudienceANBIMA() {
        this.allTargetAudience = [
            { label: 'Todos', value: 'Todos' },
            { label: 'Restritos', value: 'Restritos' },
            { label: 'Exclusivo', value: 'Exclusivo' },
            { label: 'Previdenciário', value: 'Previdenciário' },
        ]
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/financial-institution/list');
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;

        const request: CreateFinancialInstitutionRequest = {
            name: this.requestFinancialInstitution.name,
            documentNumber: this.requestFinancialInstitution.documentNumber,
            establishmentDate: this.requestFinancialInstitution.establishmentDate,
            patrimony: String(this.requestFinancialInstitution.patrimony),
            lastYearInvoicing: String(this.requestFinancialInstitution.lastYearInvoicing),
            shareCapital: String(this.requestFinancialInstitution.shareCapital),
            nire: this.requestFinancialInstitution.nire,
            bankAccount: {
                bankCode: this.requestFinancialInstitution.bankAccount.bankCode,
                bankName: this.requestFinancialInstitution.bankAccount.bankName,
                agencyNumber: this.requestFinancialInstitution.bankAccount.agencyNumber,
                accountNumber: this.requestFinancialInstitution.bankAccount.accountNumber,
                accountDigit: this.requestFinancialInstitution.bankAccount.accountDigit,
            },
        }

        try {
            this.loadingService.show();
            await this.financialInstitutionService.create(request);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/financial-institution/list');
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

}