import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { ContextService } from 'src/app/providers/context-service';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { FundBrokerViewModel } from './../../../../services/brokers/models/view-models/fund-broker-view-model';
import { FundBrokerService } from './../../../../services/fund-brokers/fund-broker-service';

@Component({
    templateUrl: './fund-broker-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FundBrokerListComponent implements OnInit {

    fundBrokers: FundBrokerViewModel[] = [];
    filterFundAdministrator: FundAdministratorViewModel[] = [];
    // typeUser: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;

    public roleOptionEnum = RoleOptionEnum;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private contextService: ContextService,
        private documentNumberPipe: DocumentNumberPipe,
        private fundBrokerService: FundBrokerService) {
    }

    async ngOnInit() {
        await this.verifyUserPermissions();
        await this.loadData();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async loadData() {
        let isActive = true;
        this.loadingService.show();
        this.fundBrokers = await this.fundBrokerService.getAll(isActive);
        // console.log(this.fundBrokers)
        this.fundBrokers = this.fundBrokers.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
        this.loadingService.hide();
    }

    addAdministrator() {
        return this.router.navigateByUrl(`app/customer/fund-broker/create`);
    }

    viewAdministrator(fundAdministrator: FundBrokerViewModel) {
        return this.router.navigateByUrl(`app/customer/fund-broker/view/${fundAdministrator.id}`);
    }

    procuratorAdministrator(fundAdministrator: FundBrokerViewModel) {
        return this.router.navigateByUrl(`app/customer/fund-broker/procurator/${fundAdministrator.id}`);
    }

    async removeFundABroker(fundBroker: FundBrokerViewModel) {
        try {
            this.loadingService.show();
            await this.fundBrokerService.deleteFundBroker(fundBroker.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            await this.loadData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    confirm(fund: FundBrokerViewModel) {
        this.confirmationService.confirm({
            header: `${fund.name}`,
            message: `Tem certeza que deseja excluir ${fund.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeFundABroker(fund);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    getMenuItems(fundBroker: FundBrokerViewModel): MenuItem[] {

        const items: MenuItem[] = [];

        if ((fundBroker.registrationFormTemplate !== null ||
            fundBroker.updateFormTemplate !== null ||
            fundBroker.additionTemplate !== null ||
            fundBroker.operationTemplate !== null) && this.isUserAdministrator) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundBroker) });
            items.push({ label: 'Procuradores', command: e => this.procuratorAdministrator(fundBroker) });
            items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-broker/operation-setup/${fundBroker.id}`) });
            items.push({ label: 'Editar Templates', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-form-template/${fundBroker.id}`) });
            items.push({ label: 'Email Finalização', command: e => this.router.navigateByUrl(`app/customer/fund-broker/email-completion-view/${fundBroker.id}`) });
            // items.push({ label: 'Editar Data de validade', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-validation/${fundBroker.id}`) });
            items.push({ label: 'Mesa de Operações', command: e => this.router.navigateByUrl(`app/customer/fund-broker/desk-configs/${fundBroker.id}`) });
            items.push({ label: 'Excluir', command: e => this.confirm(fundBroker) });

        } else if (fundBroker.operationSetups.length == 0 && this.isUserAdministrator) {
            items.push({ label: 'Procuradores', command: e => this.procuratorAdministrator(fundBroker) });
            items.push({ label: 'Editar Templates', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-form-template/${fundBroker.id}`) });
            items.push({ label: 'Email Finalização', command: e => this.router.navigateByUrl(`app/customer/fund-broker/email-completion-view/${fundBroker.id}`) });
            // items.push({ label: 'Editar Data de validade', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-validation/${fundBroker.id}`) });
            items.push({ label: 'Mesa de Operações', command: e => this.router.navigateByUrl(`app/customer/fund-broker/desk-configs/${fundBroker.id}`) });
            items.push({ label: 'Excluir', command: e => this.confirm(fundBroker) });

        } else if (this.isUserOperator) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundBroker) });

        } else if (
            (fundBroker.registrationFormTemplate !== null ||
                fundBroker.updateFormTemplate !== null ||
                fundBroker.additionTemplate !== null ||
                fundBroker.operationTemplate !== null) && this.isUserAnalist) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundBroker) });
            items.push({ label: 'Procuradores', command: e => this.procuratorAdministrator(fundBroker) });
            items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-broker/operation-setup/${fundBroker.id}`) });
            items.push({ label: 'Editar Templates', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-form-template/${fundBroker.id}`) });
            items.push({ label: 'Email Finalização', command: e => this.router.navigateByUrl(`app/customer/fund-broker/email-completion-view/${fundBroker.id}`) });
            // items.push({ label: 'Editar Data de validade', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-validation/${fundBroker.id}`) });
            items.push({ label: 'Mesa de Operações', command: e => this.router.navigateByUrl(`app/customer/fund-broker/desk-configs/${fundBroker.id}`) });

        } else if (fundBroker.operationSetups.length == 0 && this.isUserAnalist) {
            items.push({ label: 'Procuradores', command: e => this.viewAdministrator(fundBroker) });
            items.push({ label: 'Editar Templates', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-form-template/${fundBroker.id}`) });
            items.push({ label: 'Email Finalização', command: e => this.router.navigateByUrl(`app/customer/fund-broker/email-completion-view/${fundBroker.id}`) });
            // items.push({ label: 'Editar Data de validade', command: e => this.router.navigateByUrl(`app/customer/fund-broker/edit-validation/${fundBroker.id}`) });
            items.push({ label: 'Mesa de Operações', command: e => this.router.navigateByUrl(`app/customer/fund-broker/desk-configs/${fundBroker.id}`) });
        }

        return items;
    }

    async filter(filtered: string) {
        let filterFundBrokers = [];
        if (filtered) {
            this.fundBrokers.forEach(item => {
                if (item.name.toUpperCase().includes(filtered) ||
                    item.name.toLowerCase().includes(filtered) ||
                    item.documentNumber.includes(filtered) ||
                    this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                    filterFundBrokers.push(item)
                    this.fundBrokers = filterFundBrokers;
                }
            })
        } else if (!filtered) {
            await this.loadData();
        }
    }

}