import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerRepresentativeViewModel } from 'src/app/services/brokers/models/view-models/broker-representative-view-model';
import { BrokerSignerViewModel } from 'src/app/services/brokers/models/view-models/broker-signer-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './broker-signers-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-broker-signers-list',
})
export class BrokerSignersListComponent implements OnInit, AfterViewInit {

    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    @Input() verifySigner: boolean;
    // @Input() actionButton: boolean = true;
    @Input() brokerSigners: BrokerRepresentativeViewModel[];
    @Input() signers: BrokerSignerViewModel[];
    @Input() typeList: string | `Select`;
    @Input() brokerHiddenButtons: boolean = false;

    selecetedBrokerSigners: BrokerRepresentativeViewModel[] = [];

    @Output() checkboxToggleEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() unCheckboxToggleEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() onHeaderCheckboxToggledEmitter = new EventEmitter<boolean>();
    @Output() removeContactEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() resendEmailEmitter = new EventEmitter();

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private router: Router,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService) {
    }



    ngAfterViewInit() {
    }

    async ngOnInit() {
        if(this.signers !== null) {
            for (let index = 0; index < this.signers.length; index++) {
                const element = this.signers[index];
                // console.log(element);
                this.selecetedBrokerSigners.push(element.representativeSigner);
            }
            // this.selecetedManagerContact = this.recipients.map(c => c.contact);
        }
    }

    selectedCheckbox(event: BrokerRepresentativeViewModel) {
        this.checkboxToggleEmitter.emit(event)
    }

    unSelectCheckbox(event: BrokerRepresentativeViewModel) {
        this.unCheckboxToggleEmitter.emit(event)
    }

    onHeaderCheckboxToggled(event: boolean) {
        this.onHeaderCheckboxToggledEmitter.emit(event);
    }

    removeSigner(contact: BrokerRepresentativeViewModel) {
        this.removeContactEmitter.emit(contact);
    }

    resendEmail() {
        this.resendEmailEmitter.emit();
    }

    loadBrokerSignableRepresentatives(): boolean {
        console.log(this.selectedBrokerOperation)
        let existRepresentative = false;
        this.selectedBrokerOperation?.artifacts?.forEach(setup => {
            if (setup.isBrokerRepresentativeSignatureRequired == true) {
                existRepresentative = true;
            }
        })
        return existRepresentative;
    }


}