import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { FinancialInstitutionViewModel } from './../../../../services/financial-institutions/models/view-models/financial-institution-view-model';
import { FinancialInstitutionService } from 'src/app/services/financial-institutions/financial-institution-service';

@Component({
    templateUrl: './financial-institution-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FinancialInstitutionListComponent implements OnInit {

    financialInstitutions: FinancialInstitutionViewModel[] = [];
    investedFunds: FundViewModel[] = [];
    wallet: FundViewModel[] = [];
    search: string = null;
    searchInvestedFund: string = null;
    searchWallet: string = null;
    tabIndex: number = 0;
    // typeUser: string = null;
    selectedApplication: ApplicationResponseViewModel = null;

    public applicationTypeEnum = ApplicationTypeEnum;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private loadingService: LoadingService,
        private route: ActivatedRoute,
        private contextService: ContextService,
        private financialInstitutionService: FinancialInstitutionService,
        private router: Router) {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        this.getRolesByUser();
        await this.getFinancialInstitution();
        // let filtered = this.route.snapshot.queryParams.search;
        // let tab = this.route.snapshot.queryParams.tab;
        // if (filtered && tab == 0) {
        //     this.filter(filtered);
        // } else if (filtered && tab == 1) {
        //     this.tabIndex = tab;
        //     this.filter(filtered);
        // }
        this.loadingService.hide();
    }

    getRolesByUser() {
        this.selectedApplication = this.contextService.getUserApplicationSelected();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getFinancialInstitution() {
        this.financialInstitutions = await this.financialInstitutionService.getAll(null);
        this.financialInstitutions = this.financialInstitutions.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
    }



    viewInstitution(institution: FundViewModel) {
        return this.router.navigateByUrl(`app/customer/financial-institution/view/${institution.id}`);
        // if (this.tabIndex == 0 || institution.type.value == FundTypeEnum.Carteira) {
        //     return this.router.navigate([`app/customer/fund/view/${institution.id}`], { queryParams: { search: this.search, tab: this.tabIndex } });
        // } else if (this.tabIndex == 1) {
        //     return this.router.navigate([`app/customer/fund/view/${institution.id}`], { queryParams: { search: this.searchInvestedFund, tab: this.tabIndex } });
        // }
    }

    editInstitution(institution: FundViewModel) {
        return this.router.navigateByUrl(`app/customer/financial-institution/edit/${institution.id}`);
        // if (this.tabIndex == 0 || fund.type.value == FundTypeEnum.Carteira) {
        //     return this.router.navigate([`app/customer/fund/edit/${fund.id}`], { queryParams: { search: this.search, tab: this.tabIndex } });
        // } else if (this.tabIndex == 1) {
        //     return this.router.navigate([`app/customer/fund/edit/${fund.id}`], { queryParams: { search: this.searchInvestedFund, tab: this.tabIndex } });
        // }
    }

    getMenuItems(fund: FundViewModel): MenuItem[] {
        if (this.tabIndex == 0 && this.isUserAdministrator || this.isUserAnalist && !this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewInstitution(fund) },
                { label: 'Editar', command: (e) => this.editInstitution(fund) },
            ]
        } else if (this.tabIndex == 0 && this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewInstitution(fund) },
            ]
        } else if (this.tabIndex == 1) {
            return [
                { label: 'Ver', command: (e) => this.viewInstitution(fund) },
            ]
        }
    }

    getMenuWallet(fund: FundViewModel) {
        if (this.isUserAdministrator || this.isUserAnalist && !this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewInstitution(fund) },
                { label: 'Editar', command: (e) => this.editInstitution(fund) },
            ]
        }
    }

    async changeTab(event: number) {
        this.loadingService.show();
        this.tabIndex = event;
        this.loadingService.hide();
    }

    // async filter(filtered: string) {
    //     switch (this.tabIndex) {
    //         case 0:
    //             this.search = filtered;
    //             let filterShareholderFund = [];
    //             if (this.search) {
    //                 this.shareholderFunds.forEach(item => {
    //                     if (item.name.toUpperCase().includes(filtered)) {
    //                         filterShareholderFund.push(item)
    //                         this.shareholderFunds = filterShareholderFund;
    //                     } else if (item.name.toLowerCase().includes(filtered)) {
    //                         filterShareholderFund.push(item)
    //                         this.shareholderFunds = filterShareholderFund;
    //                     } else if (item.documentNumber.includes(filtered)) {
    //                         filterShareholderFund.push(item)
    //                         this.shareholderFunds = filterShareholderFund;
    //                     }
    //                 })
    //             } else if (!this.search) {
    //                 await this.getFunds();
    //             }
    //             break;
    //         case 1:
    //             this.searchInvestedFund = filtered;
    //             let filterInvestedFund = [];
    //             if (this.searchInvestedFund) {
    //                 this.investedFunds.forEach(item => {
    //                     if (item.name.toUpperCase().includes(filtered)) {
    //                         filterInvestedFund.push(item)
    //                         this.investedFunds = filterInvestedFund;
    //                     } else if (item.name.toLowerCase().includes(filtered)) {
    //                         filterInvestedFund.push(item)
    //                         this.investedFunds = filterInvestedFund;
    //                     } else if (item.documentNumber.includes(filtered)) {
    //                         filterInvestedFund.push(item)
    //                         this.investedFunds = filterInvestedFund;
    //                     }
    //                 })
    //             } else if (!this.searchInvestedFund) {
    //                 await this.getFunds();
    //             }
    //             break;
    //         case 2:
    //             this.searchWallet = filtered;
    //             let filterWalletFund = [];
    //             if (this.searchWallet) {
    //                 this.shareholderFunds.forEach(item => {
    //                     if (item.name.toUpperCase().includes(filtered)) {
    //                         filterWalletFund.push(item)
    //                         this.wallet = filterWalletFund;
    //                     } else if (item.name.toLowerCase().includes(filtered)) {
    //                         filterWalletFund.push(item)
    //                         this.wallet = filterWalletFund;
    //                     } else if (item.documentNumber.includes(filtered)) {
    //                         filterWalletFund.push(item)
    //                         this.wallet = filterWalletFund;
    //                     }
    //                 })
    //             } else if (!this.search) {
    //                 await this.getFunds();
    //             }
    //             break;

    //         default:
    //             break;
    //     }
    // }


}