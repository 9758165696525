import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { ContextService } from 'src/app/providers/context-service';
import { UnblockEntityUserRequest } from 'src/app/services/users/models/requests/unblock-entity-user-request';
import { UpdateUserContactRequest } from 'src/app/services/users/models/requests/update-user-contact-request';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { UserService } from 'src/app/services/users/user-service';
Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: 'user-view.component.html',
    styleUrls: ['../../../../../../assets/layout/customized/datatable.scss', '../../../../../../assets/layout/customized/custom-card.scss']
})
export class UserViewComponent implements OnInit {

    accountId: string = null;
    user: UserViewModel = null;
    userId: string = null;
    // verifyUser: boolean = false;
    newEmail: string = null;
    displayChangeUserEmail: boolean = false;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserService,
        private messageService: MessageService,
        private contextService: ContextService,
    ) {
        this.breadcrumbService.setItems([
            { label: 'User', routerLink: ['/app/customer/user'] },
            { label: 'List' }
        ]);
    }

    async ngOnInit() {
        this.loadingService.show();
        this.accountId = this.contextService.getUserInfo().user.userProfiles[0].playerEntityId;
        this.userId = this.route.snapshot.params.id;
        this.user = await this.userService.get(this.userId);
        this.verifyUserPermissions();
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        const user = this.contextService.getUserInfo().user;
        
        user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    editUser() {
        this.router.navigateByUrl(`/app/customer/user/edit/${this.user.id}`);
    }

    async removeUser() {
        this.loadingService.show()
        try {
            await this.userService.inactive(this.user.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            this.router.navigateByUrl(`/app/customer/user/list`);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    updateEmailUser() {
        this.displayChangeUserEmail = true;
    }

    async changeEmailUser(userForm: NgForm) {
        const request: UpdateUserContactRequest = {
            userId: this.user.id,
            emailOrCellPhone: userForm.value.newEmail,
            isSendNotification: true
        }
        this.displayChangeUserEmail = false;
        this.loadingService.show();
        try {
            await this.userService.resendEmailUser(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Email enviado para o usuário para ativação.`, life: 5000 });
        } catch (e) {
            this.messageService.add({ severity: 'error', summary: 'Não foi possível efetuar a alteração', detail: 'Ocorreu algum erro na atualização, entre em contato com o administrador', life: 5000 });
        } finally {
            this.loadingService.hide();
        }

    }

    async activeUserAccount() {
        const user = this.user
        this.loadingService.show()
        try {
            const objUser: UnblockEntityUserRequest = {
                userId: user.id
            }
            await this.userService.unblocks(objUser);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.updateUrl();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }


}
