<div class="titleCustomCard">
    Visualizar Procurador
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button *ngIf="!isUserOperator" pButton pRipple type="button" label="Editar" class="p-button" (click)="onEdit(procuratorSeleceted)" ></button>
                    <div *ngIf="procuratorSeleceted?.isActive && !isUserOperator">
                        <button pButton pRipple type="button" label="Inativar" class="p-button-outlined-blue" (click)="confirm(procuratorSeleceted)"></button>
                    </div>
                    <div *ngIf="!procuratorSeleceted?.isActive && !isUserOperator">
                        <button pButton pRipple type="button" label="Ativar" class="p-button"></button>
                    </div>
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined" (click)="onCancel()"></button>
                </div>
            </div>
            <div class="cardHeader text-bold">Validade</div>
            <div class="p-grid">
                <div class="p-col-12 p-md-4 p-lg-4">
                    <div class="text-bold">
                        Validade
                    </div>
                    <p>{{procuratorSeleceted?.expireDate | date : 'dd/MM/yyyy'}}</p>
                </div>
            </div>
        </div>

        <div class="customCard">
            <div class="cardHeader text-bold">Informações</div>
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Nome
                    </div>
                    <p>{{procuratorSeleceted?.name}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        CPF
                    </div>
                    <p>{{procuratorSeleceted?.maskedPersonalDocumentNumber}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Data de Nascimento
                    </div>
                    <p>{{procuratorSeleceted?.birthDate | date : 'dd/MM/yyyy'}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Sexo
                    </div>
                    <p>{{procuratorSeleceted?.gender}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Endereço Residencial
                    </div>
                    <p>{{procuratorSeleceted?.addressStreet}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Número
                    </div>
                    <p>{{procuratorSeleceted?.addressNumber}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Complemento
                    </div>
                    <p>{{procuratorSeleceted?.addressComplement}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Bairro
                    </div>
                    <p>{{procuratorSeleceted?.addressNeighborhood}}</p>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Cep
                    </div>
                    <p>{{procuratorSeleceted?.addressZipCode}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Cidade
                    </div>
                    <p>{{procuratorSeleceted?.addressCity}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        UF
                    </div>
                    <p>{{procuratorSeleceted?.addressState}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        País
                    </div>
                    <p>{{procuratorSeleceted?.addressCountry}}</p>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Cidade de Nascimento
                    </div>
                    <p>{{procuratorSeleceted?.birthCity}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        UF de Nascimento
                    </div>
                    <p>{{procuratorSeleceted?.birthState}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        País de Nascimento
                    </div>
                    <p>{{procuratorSeleceted?.birthCountry}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Nacionalidade
                    </div>
                    <p>{{procuratorSeleceted?.nationality}}</p>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Telefone
                    </div>
                    <p>{{procuratorSeleceted?.phoneNumber}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        E-mail
                    </div>
                    <p>{{procuratorSeleceted?.email}}</p>
                </div>
            </div>
        </div>
        <div class="customCard">
            <div class="cardHeader text-bold">Documento</div>
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Tipo de Documento
                    </div>
                    <p>{{procuratorSeleceted?.additionalDocumentType}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Número Documento
                    </div>
                    <p>{{procuratorSeleceted?.additionalDocumentNumber}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Data de Emissão
                    </div>
                    <p>{{procuratorSeleceted?.additionalDocumentIssueDate | date : 'dd/MM/yyyy'}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        Orgão Emissor
                    </div>
                    <p>{{procuratorSeleceted?.additionalDocumentIssuingBody}}</p>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="text-bold">
                        UF orgão emissor
                    </div>
                    <p>{{procuratorSeleceted?.additionalDocumentIssuingBodyState}}</p>
                </div>
            </div>
        </div>
        <div class="customCard">
            <div class="cardHeader text-bold">Anexos</div>
            <div class="p-grid">
                <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate p-fluid w-full">
                    <div class="customCardOutlined">
                        <h5 class="text-center">Procuração</h5>
                        <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.procurationFileUrl !== null">
                            <a class="textPrimary" [href]="procuratorSeleceted?.procurationFileUrl" target="_blank">Ver</a>
                        </div>
                        <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.procurationFileUrl == null">
                            <p>Sem anexo</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate p-fluid w-full">
                    <div class="customCardOutlined">
                        <h5 class="text-center">Documento do Procurador</h5>
                        <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.documentFileUrl !== null">
                            <a class="textPrimary" [href]="procuratorSeleceted?.documentFileUrl" target="_blank">Ver</a>
                        </div>
                        <div style="display: flex; gap: 1rem; align-items: center; justify-content: center;" *ngIf="procuratorSeleceted?.documentFileUrl == null">
                            <p>Sem anexo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>