import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { ArtifactTemplateService } from 'src/app/services/artifact-templates/artifact-template-service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationArtifactViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-artifact-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './artifact-template-list.component.html',
    styleUrls: [
        './artifact-template-list.component.scss',
        './../../../../assets/layout/customized/custom-card.scss',
    ],
    selector: 'app-artifact-template-list',
    encapsulation: ViewEncapsulation.None,
})
export class ArtifactTemplateListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    @Input() step: number = null;
    @Input() operationHiddenButtom1: boolean = false;
    @Input() operationHiddenButtom2: boolean = false;
    @Input() operationHiddenButtom3: boolean = false;
    @Input() operationHiddenButtom4: boolean = false;
    @Input() brokerHiddenButtom1: boolean = false;
    @Input() brokerHiddenButtom2: boolean = false;
    @Input() brokerHiddenButtom3: boolean = false;
    @Input() brokerHiddenButtom4: boolean = false;

    @Output() fillInfoTemplateEmitter = new EventEmitter<ArtifactViewModel>();
    @Output() updateArtifactByIdEmitter = new EventEmitter<ArtifactViewModel>();
    @Output() updateOperation = new EventEmitter<void>();
    @Output() updateBrokerOperation = new EventEmitter<void>();

    public artifactStatusEnum = ArtifactStatusEnum;
    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private artifactTemplateService: ArtifactTemplateService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private messageService: MessageService,
        private operationService: OperationService,
        private brokerService: BrokerService,
    ) {
    }

    async ngOnInit() {
        this.formatArtifactDate();
    }

    formatArtifactDate() {
        if (this.selectedOperation?.artifacts.length > 0) {
            this.selectedOperation?.artifacts.map((res: ArtifactViewModel) => {
                const format = new Date(res.createDate);
                format.setHours(format.getHours() - 3);
                res.createDate = format.toISOString();
            });
        }
        if (this.selectedBrokerOperation?.artifacts.length > 0) {
            this.selectedBrokerOperation?.artifacts.map((res: BrokerOperationArtifactViewModel) => {
                const format = new Date(res.createDate);
                format.setHours(format.getHours() - 3);
                res.createDate = format.toISOString();
            });
        }
    }

    fillInfoTemplate(artefact: ArtifactViewModel) {
        this.fillInfoTemplateEmitter.emit(artefact);
    }

    updateArtifactById(artefact: ArtifactViewModel) {
        this.updateArtifactByIdEmitter.emit(artefact);
    }

    downloadArtefact(artefact: ArtifactViewModel) {
       window.open(artefact.signedFile);
    }

    async reprocessArtifact(artifact: ArtifactViewModel) {
        this.loadingService.show();
        try {
            const artifactId: string = artifact.id;
            await this.operationService.reprocessArtifact(artifactId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.updateOperation.emit();
            this.loadingService.hide();
        }
    }

    async reprocessBrokerArtifact(artifact: ArtifactViewModel) {
        this.loadingService.show();
        try {
            const artifactId: string = artifact.id;
            await this.brokerService.reprocessArtifact(artifactId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.updateBrokerOperation.emit();
            this.loadingService.hide();
        }
    }


}
