import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Subject } from 'rxjs';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { FillBrokerOperationArtifactRequest } from 'src/app/services/brokers/models/request/fill-broker-pperation-artifact-request';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerSignerViewModel } from 'src/app/services/brokers/models/view-models/broker-signer-view-model';
import { FieldRequest, FillOperationArtifactRequest } from 'src/app/services/operations/models/request/fill-operation-artifact-request';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './fill-template-create.component.html',
    styleUrls: [
        '../../../../assets/layout/customized/datatable.scss',
        './fill-template-create.component.scss',
        '../../../../assets/layout/customized/custom-card.scss'],
    selector: `app-fill-template-create`
})
export class FillTemplateCreateComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    @Input() artefactObservable = new Subject<ArtifactViewModel>();

    @Output() requestEmitter = new EventEmitter();
    @Output() requestBrokerEmitter = new EventEmitter();

    selectedArtifact: ArtifactViewModel = null;
    safeDocumentUrl: string = null;
    urlSafe: SafeResourceUrl;
    signedFile: SafeResourceUrl;
    booleanOptions: SelectItem[] = [{ label: 'Sim', value: 'Sim' }, { label: 'Não', value: 'Não' }];
    activeState: boolean = true;
    request: any = null;
    requestFields: FieldRequest[] = [];
    public artifactStatusEnum = ArtifactStatusEnum;

    extensionFile: string = null;

    constructor(
        private sanitizer: DomSanitizer) { }

    async ngOnInit() {
        this.artefactObservable.subscribe(response => {
            this.selectedArtifact = response;
            this.setFormArtefacts();
            this.request.fields = this.selectedArtifact.fields;
            this.loadDocuments();
        })
    }

    async loadDocuments() {
        const codec = new HttpUrlEncodingCodec();

        this.safeDocumentUrl = this.selectedArtifact?.template?.fileUrl;
        const url = codec.encodeValue(this.safeDocumentUrl);

        if (this.selectedArtifact?.signedFile != null) {
            this.safeDocumentUrl = this.selectedArtifact?.signedFile;
        } else if (this.selectedArtifact?.originalFile != null) {
            this.safeDocumentUrl = this.selectedArtifact?.originalFile;
        }

        if (this.safeDocumentUrl.includes('.xlsx') || this.safeDocumentUrl.includes('.docx')) {
            this.extensionFile = this.safeDocumentUrl;
            this.urlSafe = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
        } else if (this.safeDocumentUrl.includes('pdf')) {
            this.extensionFile = null;
            this.urlSafe = await this.sanitizer.bypassSecurityTrustResourceUrl(this.safeDocumentUrl);
        }
    }

    downloadFile() {
        const url = this.safeDocumentUrl
        window.open(url, '_blank');
    }

    setFormArtefacts() {
        this.request = {
            id: this.selectedArtifact.id,
            fields: [],
            requestFields: []
        };
    };

    fieldsPending(): boolean {
        if (this.request.fields.filter(fill => fill.value == null).length > 0) {
            return true
        } else if ((this.request.fields.filter(fill => fill.value == null).length == 0)) {
            return false
        }
    }

    onSave() {
        this.request.fields = this.selectedArtifact.fields.filter(fill => fill.variableField.fieldType.fieldType !== 'entity');
        this.request.fields.forEach(field => {
            switch (field.variableField.fieldType.fieldType) {
                case 'text':
                    const textAnswer: string = field.value;
                    field.value = textAnswer.trim();
                    break;
                case 'datetime':
                    const datetimeAnswer: any = new Date(field.value);
                    field.value = `${datetimeAnswer.getDate()}/${datetimeAnswer.getMonth() + 1}/${datetimeAnswer.getFullYear()}`;
                    break;

                case 'checkbox':
                    const checkboxAnswer: any = field.value;
                    field.value = checkboxAnswer.join(', ');
                    break;
            }
        });
        this.request.fields.forEach(field => {
            const requestArtifact: FieldRequest = {
                fieldId: field.id,
                value: field.value
            };
            this.request.requestFields.push(requestArtifact);
        });
        const request: FillOperationArtifactRequest = {
            artifactId: this.selectedArtifact.id,
            fieldsRequests: this.request.requestFields
        };

        const requestBroker: FillBrokerOperationArtifactRequest = {
            brokerOperationArtifactId: this.selectedArtifact.id,
            fieldsRequests: this.request.requestFields
        };

        this.requestEmitter.emit(request);
        this.requestBrokerEmitter.emit(requestBroker)
    }

    updateUrl() {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.safeDocumentUrl);
    }

    checkSignature(rep: BrokerSignerViewModel): boolean {
        return this.selectedArtifact.brokerSignerHashes.some((hash: string) => hash.includes(rep.representativeSigner.name));
    }
}
