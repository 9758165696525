import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { HttpClientService } from 'src/app/providers/http-client-service';
import { ActivateUserRequest } from './models/requests/activate-user-request';
import { ForgotPasswordUserRequest } from './models/requests/forgot-password-user-request';
import { InviteUserRequest } from './models/requests/invite-user-request';
import { LoginRequest } from './models/requests/login-request';
import { ReactivateUserAccessRequest } from './models/requests/reactivate-user-access-request';
import { ResendOperationCodeRequest } from './models/requests/resend-operation-code-request';
import { ResetPasswordRequest } from './models/requests/reset-password-request';
import { UnblockEntityUserRequest } from './models/requests/unblock-entity-user-request';
import { UpdateUserAccessRequest } from './models/requests/update-user-access-request';
import { UpdateUserContactRequest } from './models/requests/update-user-contact-request';
import { CodeOperationResponseViewModel } from './models/view-models/code-operation-response-view-model';
import { DocumentNumberViewModel } from './models/view-models/document-number-view-model';
import { LoginResponseViewModel } from './models/view-models/login-response-view-model';
import { RoleOptionViewModel } from './models/view-models/role-option-view-model';
import { UserViewModel } from './models/view-models/user-view-model';
import { LoginSingleSignOnUserRequest } from './models/requests/login-sso-request';
import { tr } from 'date-fns/locale';
import { ReLoginUserRequest } from './models/requests/re-login-user-request';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpClient: HttpClientService) { }


  async activate(request: ActivateUserRequest): Promise<void> {
    return this.httpClient.put(`/users`, '/activation', request, false, true, true);
  }

  async inactive(userId: string): Promise<void> {
    return this.httpClient.delete('/users', `/activation/${userId}`, true, true, true);
  }

  async unblocks(userId: UnblockEntityUserRequest): Promise<UserViewModel> {
    return this.httpClient.put(`/users`, `/unblocks`, userId, true, true, true);
  }

  async resendOperationCode(request: ResendOperationCodeRequest): Promise<CodeOperationResponseViewModel> {
    return this.httpClient.put(`/users`, '/codes/recovery', request, true, true, true);
  }

  async getUsersByPlayerTypeAndEntity(playerTypeId: number, entityId: string): Promise<UserViewModel[]> {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}/entities/${entityId}`, true, true, null, true);
  }

  async getAllUsersByPlayerType(playerTypeId: number) {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}`, true, true, null, true);
  }

  async getRolesByPlayerType(playerTypeId: number): Promise<RoleOptionViewModel[]> {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}/roles`, true, true, null, true);
  }

  async get(userId: string, hasToken = true): Promise<UserViewModel> {
    return this.httpClient.get('/users', `/${userId}`, hasToken, true, null, true);
  }

  async userAccesses(request: UpdateUserAccessRequest): Promise<UpdateUserAccessRequest> {
    return this.httpClient.put('/users', `/accesses`, request, true, true, true);
  }

  async invite(request: InviteUserRequest): Promise<UserViewModel> {
    return this.httpClient.post(`/users`, '/invitation', request, true, true, true);
  }

  async decriptDocumentNumber(userId: string): Promise<DocumentNumberViewModel> {
    return this.httpClient.get('/users', `/${userId}/document-number/decrypted`, true, true, null, true)
  }

  async resendEmailOrCellPhone(request: ReactivateUserAccessRequest): Promise<ReactivateUserAccessRequest> {
    return this.httpClient.put('/users', '/activation/recovery', request, true, true, true);
  }

  async resendEmailUser(request: UpdateUserContactRequest): Promise<UserViewModel> {
    return this.httpClient.put('/users', '/accesses/contacts', request, true, true, true);
  }

  async refreshToken(request: ReLoginUserRequest): Promise<LoginResponseViewModel> {
    return this.httpClient.post('/users', '/refresh-login', request, true, true, true);
  }

}
