<div class="p-grid">
    <div class="titleCustomCard overview">
        Painel de Controle
    </div>
    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div class="contentBetween">
            <div>
                <div class="cardHeader text-bold">Operação</div>
            </div>
            <div class="contentButtonActions">
                <button pButton type="button" label="Enviar email para Corretoras" class="p-button-outlined"
                    (click)="sendMailtoManagers()"></button>
                <button pButton type="button" label="Sincronizar Arquivos" (click)="syncOperations()"></button>
            </div>

        </div>
        <div style="border: 1px solid #ccc;" class="content">
            <div class="p-grid">

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p style="margin-top: 35px;">Cadastro de Fundo</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Não iniciados</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{registrationFundOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Preenchendo Formulários</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{registrationFundOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em processo de assinatura</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationFundOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em análise pela Corretora</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationFundOperationBrokerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Pendenciado pela Corretora</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationFundOperationBrokerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Concluídos</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{registrationFundOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Atualização de Fundo</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{updateFundOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{updateFundOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateFundOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateFundOperationBrokerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateFundOperationBrokerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{updateFundOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBuyBox">
                    <div class="text-bold">
                        <p>Cadastro de Instituição</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Não iniciados</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{registrationInstitutionalSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Preenchendo Formulários</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{registrationInstitutionalFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Em processo de assinatura</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationInstitutionalSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Em análise pela Corretora</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationInstitutionalBrokerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Pendenciado pela Corretora</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{registrationInstitutionalBrokerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <!-- <p class="text-bold title-box">Concluídos</p> -->
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{registrationInstitutionalFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBox">
                    <div class="text-bold">
                        <p>Atualização de Instituição</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{updateInstitutionalSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{updateInstitutionalFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateInstitutionalSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateInstitutionalBrokerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{updateInstitutionalBrokerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{updateInstitutionalFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div class="cardHeader text-bold">Total</div>
        <div style="border: 1px solid #ccc;" class="content">
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3 titleOperationBuyBox">
                    <div class="text-bold">
                        <p>Total</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-9 p-lg-9">
                    <div class="p-grid">
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Não iniciado</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft1"></div>
                                    <div class="boxCenter1">
                                        <p>{{allOperationSetup}}</p>
                                    </div>
                                    <div class="boxArrowRight1"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Preenchendo Formulário</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft2"></div>
                                    <div class="boxCenter2">
                                        <p>{{allOperationFillForm}}</p>
                                    </div>
                                    <div class="boxArrowRight2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em processo de assinatura</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationSignatureProcess}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Em Análise Pela Corretora</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationBrokerAnalysis}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Pendenciado pela Corretora</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft3"></div>
                                    <div class="boxCenter3">
                                        <p>{{allOperationBrokerPending}}</p>
                                    </div>
                                    <div class="boxArrowRight3"></div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-2 col-md-2 col-lg-2">
                            <div class="contentBox">
                                <p class="text-bold title-box">Concluídos</p>
                                <div class="boxArea">
                                    <div class="boxArrowLeft4"></div>
                                    <div class="boxCenter4">
                                        <p>{{allOperationFinished}}</p>
                                    </div>
                                    <div class="boxArrowRight4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12 customCard">
        <div
            style="border: 1px solid #ccc; padding: 1rem; display: flex; flex-direction: row; align-items: center; justify-content: space-around; width: 37%; gap: 6rem;">
            <span class="text-bold" style="cursor: pointer; margin-left: 6%;" (click)="handleDisplayAboutToExpireFunds()">Cadastros a
                Expirar</span>
            <span class="text-bold" style="color: var(--primary-color); cursor: pointer;"
                (click)="handleDisplayAboutToExpireFunds()">{{aboutToExpireFunds?.length}}</span>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12 customCard" [hidden]="!displayAboutExpireFunds">
        <p-tabView>
            <p-tabPanel header="A Expirar">
                <p-table [responsive]="true" #dt [value]="aboutToExpireFunds" dataKey="id"
                    styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                    [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                    [globalFilterFields]="['broker.name', 'broker.documentNumber', 'expirationDate', 'notes']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">
                                <div>
                                    Nome Fundo
                                    <p-sortIcon field="broker.name"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="documentNumber">
                                <div>
                                    CNPJ Fundo
                                    <p-sortIcon field="broker.name"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="broker.name">
                                <div>
                                    Corretora
                                    <p-sortIcon field="broker.name"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="broker.documentNumber">
                                <div>
                                    CNPJ
                                    <p-sortIcon field="broker.documentNumber"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="broker.operationType.friendlyName">
                                <div>
                                    Tipo de Operação
                                    <p-sortIcon field="broker.operationType.friendlyName"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="broker.currentCBLC">
                                <div>
                                    CBLC
                                    <p-sortIcon field="broker.currentCBLC"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="notes">
                                <div>
                                    Conta
                                    <p-sortIcon field="notes"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="expirationDate">
                                <div>
                                    Data de expiração
                                    <p-sortIcon field="expirationDate"></p-sortIcon>
                                </div>
                            </th>
                            <th>
                                <div>
                                    Ações
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-fund let-index>
                        <tr style="cursor: pointer" [pSelectableRow]="fund">
                            <td>
                                {{fund?.name}}
                            </td>
                            <td>
                                {{fund?.documentNumber | documentNumber: 'cnpj'}}
                            </td>
                            <td>
                                {{fund?.broker?.name}}
                            </td>
                            <td>
                                {{fund?.broker?.documentNumber | documentNumber: 'cnpj'}}
                            </td>
                            <td>
                                {{fund?.operationType?.friendlyName}}
                            </td>
                            <td>
                                {{fund?.cblc}}
                            </td>
                            <td>
                                {{fund?.notes}}
                            </td>
                            <td>
                                {{fund?.expirationDate | date : 'dd/MM/yyyy'}}
                            </td>
                            <td>
                                <p-button label="Ver" styleClass="p-button-link" (onClick)="fundBrokerView(fund)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">Nenhuma corretora encontrado.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>