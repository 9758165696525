<div *ngIf="typeList == 'Select'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="brokerContacts" dataKey="id"
            (onRowSelect)="selectedCheckbox($event.data)" (onRowUnselect)="unSelectCheckbox($event.data)"
            (onHeaderCheckboxToggle)="onHeaderCheckboxToggled($event.checked)" styleClass="p-datatable-customized"
            [(selection)]="selecetedBrokerContact" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-brokerContact let-index>
                <tr style="cursor: pointer" [pSelectableRow]="brokerContact">
                    <td>
                        <p-tableCheckbox [value]="brokerContact"></p-tableCheckbox>
                    </td>
                    <td>
                        {{brokerContact.name}}
                    </td>
                    <td>
                        {{brokerContact.email}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="typeList == 'None'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="recipients" dataKey="id" styleClass="p-datatable-customized"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th
                        *ngIf="
                            selectedBrokerOperation?.currentStatus?.status?.value == brokerOperationStatusEnum.EmAnalisePelaCorretora || 
                            selectedBrokerOperation?.currentStatus?.status?.value == brokerOperationStatusEnum.PendenciadaPelaCorretora">
                        <div>
                            Ações
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-recipient let-index>
                <tr style="cursor: pointer" [pSelectableRow]="recipient">
                    <td>
                        {{recipient.representative.name}}
                    </td>
                    <td>
                        {{recipient.representative.email}}
                    </td>
                    <td style="display: flex; gap: 5px; justify-content: center;">
                        <div
                            *ngIf="selectedBrokerOperation?.currentStatus?.status.value == brokerOperationStatusEnum.EmAnalisePelaCorretora" [hidden]="brokerHiddenButtons">
                            <button pButton type="button" class="p-button-outlined" label="Reenviar Email"
                                (click)="resendEmail(recipient)"></button>
                        </div>
                        <div
                            *ngIf="
                            (selectedBrokerOperation?.currentStatus?.status.value == brokerOperationStatusEnum.EmAnalisePelaCorretora && 
                            recipient.representative.email !== responseBroker?.representative?.email) || 
                            (selectedBrokerOperation?.currentStatus?.status?.value == brokerOperationStatusEnum.PendenciadaPelaCorretora && 
                            recipient.representative.email !== responseBroker?.representative?.email)" [hidden]="brokerHiddenButtons">
                            <button  pButton type="button" class="p-button-outlined" label="Remover"
                                (click)="removeContact(recipient)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>