import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BrokerRepresentativeViewModel } from 'src/app/services/brokers/models/view-models/broker-representative-view-model';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';


@Component({
    templateUrl: './fund-broker-procurator.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundBrokerProcuratorComponent implements OnInit {

    request = null;
    requestRepresentations: any[] = [];
    selectedFundBroker: FundBrokerViewModel = null;
    requestValidateDate: Date = null;
    allRepresentatives: BrokerRepresentativeViewModel[] = [];

    constructor(
        private fundBrokerService: FundBrokerService,
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
    ) {
    }

    async ngOnInit() {

        this.loadingService.show();
        await this.getFundAdministrator();
        await this.getRepresentativesInFundAdministrator();
        this.loadingService.hide();
    }

    async getFundAdministrator() {
        const fundBrokerId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundBrokerId).then(() => {
            this.selectedFundBroker.documentNumber = this.parseCNPJ(this.selectedFundBroker.documentNumber)
        });
    }

    async getFundAdministratorById(fundAdministratorId: string) {
        this.selectedFundBroker = await this.fundBrokerService.getById(fundAdministratorId);
        console.log(this.selectedFundBroker)
    }

    async getRepresentativesInFundAdministrator() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundBrokerService.getRepresentativesInFundBroker(fundBrokerId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    editFundAdministrator() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-broker/edit/${fundBrokerId}`);
    }

    onCancel() {
        this.router.navigateByUrl(`/app/customer/fund-broker/list`);
    }

    updateRegistrationFormTemplate() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-broker/edit-form-template/${fundBrokerId}`)
    }

}