import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { ContextService } from 'src/app/providers/context-service';
import { VersionService } from 'src/app/providers/version-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { MenuService } from '../../providers/app.menu.service';
import { AppMenuComponent } from '../menu/app.menu.component';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { environment } from 'src/environments/environment';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { DomainWhitelabel } from 'src/app/enums/domain-enum';

enum MenuOrientation {
    STATIC,
    OVERLAY,
    SLIM,
    HORIZONTAL
}

@Component({
    selector: 'app-root',
    templateUrl: './app.root.component.html',
    styleUrls: ['./app.root.component.scss']
})

export class AppRootComponent implements AfterViewInit, OnInit, OnDestroy {

    topbarMenuActive: boolean;

    menuActive: boolean;

    staticMenuDesktopInactive: boolean;

    mobileMenuActive: boolean;

    menuClick: boolean;

    mobileTopbarActive: boolean;

    topbarRightClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: string;

    documentClickListener: () => void;

    configActive: boolean;

    configClick: boolean;

    rightMenuActive: boolean;

    menuHoverActive = false;

    searchClick = false;

    search = false;

    currentInlineMenuKey: string;

    inlineMenuActive: any[] = [];

    inlineMenuClick: boolean;

    user: UserViewModel;

    displayMenu: boolean = false;

    collapse: boolean = true;
    labelCollapsed: boolean = true;

    version: string = ''

    logoSmallStyle = null;

    logoStyle = null;

    smallLogo: boolean = true;

    backgroundWhitelabel: string = '';

    @HostListener('window:resize', ['$event']) onResize(event) {
        if (this.collapse === true) {
            this.labelCollapsed = true;
            this.displayMenu = false;
        } else {
            this.labelCollapsed = false;
        }
    }

    @ViewChild('menuComponent', { static: false }) menuComponent: AppMenuComponent;

    constructor(
        public router: Router,
        public renderer: Renderer2,
        public contextService: ContextService,
        private menuService: MenuService,
        private primengConfig: PrimeNGConfig,
        private versionService: VersionService,
        private elementRef: ElementRef,
        public app: AppComponent) {
        this.user = this.contextService.getUserInfo().user;
        this.version = this.versionService.getVersion();
    }

    ngOnInit() {
        this.menuActive = this.isStatic() && !this.isMobile();
    }

    activeCollapse(event) {
        this.collapse = event
    }


    handleMenu() {
        this.displayMenu = !this.displayMenu;
        if (this.displayMenu === true) {
            this.labelCollapsed = false;
        }
    }

    checkLoginStatus() {
        if (!this.contextService.isLoggedIn()) {
            this.logout();
        }
    }

    logout(event = null) {
        this.contextService.logout();

        if (event != null)
            event.preventDefault();
    }

    goToProfile(event) {
        this.router.navigateByUrl('app/my-profile');
        event.preventDefault();
    }

    goToSignatures(event) {
        this.router.navigateByUrl('app/customer/signature/create');
        event.preventDefault();
    }

    goToSettings(event) {
        this.configClick = true;
        this.configActive = !this.configActive;
        event.preventDefault();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loadTheme();
        }, 0);
        // hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', () => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
                this.menuService.reset();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            if (!this.menuClick) {
                if (this.mobileMenuActive) {
                    this.mobileMenuActive = false;
                }

                if (this.isOverlay()) {
                    this.menuActive = false;
                }

                this.menuHoverActive = false;
                this.unblockBodyScroll();
            }

            if (!this.searchClick) {
                this.search = false;
            }

            if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
                this.inlineMenuActive[this.currentInlineMenuKey] = false;
            }

            this.inlineMenuClick = false;
            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.topbarRightClick = false;
            this.menuClick = false;
        });
        this.menuComponent.loadSiteMap();
    }

    onMenuButtonClick(event) {
        this.menuActive = !this.menuActive;
        this.topbarMenuActive = false;
        this.topbarRightClick = true;
        this.menuClick = true;

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.mobileMenuActive = !this.mobileMenuActive;
            if (this.mobileMenuActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarMobileButtonClick(event) {
        this.mobileTopbarActive = !this.mobileTopbarActive;
        event.preventDefault();
    }

    onRightMenuButtonClick(event) {
        this.rightMenuActive = !this.rightMenuActive;
        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;

        if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false;
        }
    }

    onSearchKeydown(event) {
        if (event.keyCode == 27) {
            this.search = false;
        }
    }

    onInlineMenuClick(event, key) {
        if (key !== this.currentInlineMenuKey) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false;
        }

        this.inlineMenuActive[key] = !this.inlineMenuActive[key];
        this.currentInlineMenuKey = key;
        this.inlineMenuClick = true;
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }

        if (item === 'search') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRTLChange(event) {
        this.app.isRTL = event.checked;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    changeApplication(app: ApplicationResponseViewModel) {
        if (app.application.redirectSufix == '') {
            const whitelabel = this.contextService.getWhiteLabelContext();
            const host = window.location.host;
            const subDomain = host.split('.')[0];
            const newSubDomain = `${whitelabel.style.name}`;

            let newHost = host.replace(subDomain, newSubDomain);
            if (app.profiles.length > 1 ||
                app.profiles[0].playerType.value == PlayerTypeEnum.Liza ||
                app.profiles[0].playerType.value == PlayerTypeEnum.WhiteLabel) {
                if (window.location.host.includes('localhost')) {
                    this.router.navigateByUrl('choose-context');
                    return;
                }
                window.location.href = `https://${newHost}/#/choose-context`;
            } else {
                if (window.location.host.includes('localhost')) {
                    this.router.navigateByUrl('app/customer/home');
                    return;
                }
                window.location.href = `https://${newHost}/#/app/customer/home`
            }
        }
        if (app.application.value == ApplicationTypeEnum.Broker) {
            this.router.navigateByUrl('app/customer/broker-home');
            this.menuComponent.loadSiteMap();
            return;
        }
        if (app.application.value == ApplicationTypeEnum.FoF) {
            this.router.navigateByUrl('app/customer/home');
            this.menuComponent.loadSiteMap();
            return;
        }
        if (app.application.value == ApplicationTypeEnum.Liability) {
            this.router.navigateByUrl('app/customer/liability-home');
            this.menuComponent.loadSiteMap();
            return;
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

    async loadTheme() {
        const theme = this.contextService.getWhiteLabelContext();
        if (theme) {
            this.logoSmallStyle = {
                backgroundImage: theme.style.logoSmallUrl,
                backgroundSize: `contain`,
                aspectRatio: `1 / 1`,
                width: `47px`,
                height: `auto`,
                backgroundPosition: `center`,
            }

            this.logoStyle = {
                backgroundImage: theme.style.logoUrl,
                backgroundSize: `contain`,
                aspectRatio: `16 / 9`,
                width: `145px`,
                height: `auto`,
                backgroundPosition: `center`,
            }
            if (theme.style.name == DomainWhitelabel.iaas || theme.style.name == DomainWhitelabel.legacy) {
                this.backgroundWhitelabel = `#${theme.style.primaryColorHex}`;
            } else {
                this.backgroundWhitelabel = `#${theme.style.secondaryColorHex}`;
            }
        } else {
            this.elementRef.nativeElement.style.visibility = 'hidden';
        }
    }

}
