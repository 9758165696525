<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Envio de Novo Arquivo de Integração
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" iconPos="right" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
            </div>
            <div class="cardHeader text-bold">Tipo de Integração</div>
            <div class="p-grid p-formgrid">
                <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="integrationTypesOptions" placeholder="Selecione"
                                [(ngModel)]="selectedIntegrationTypeId" optionLabel="friendlyName"
                                #integrationTypeId="ngModel" optionValue="value" name="integrationTypeId">
                            </p-dropdown>
                        </span>
                    </div>
                    <mark *ngIf="integrationTypeId.invalid && integrationTypeId.dirty"
                        class="ui-message ui-messages-error ui-corner-all">Campo
                        obrigatório</mark>
                </div>
            </div>
        </div>
        <div class="customCard" *ngIf="selectedIntegrationTypeId != null">
            <div class="cardHeader text-bold">Arquivo</div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label boxFileUpload">
                            <p-fileUpload chooseIcon="pi pi-upload" class="p-fluid" [auto]="false"
                                [customUpload]="true" [files]="componentFiles" chooseLabel="Selecione o arquivo"
                                [multiple]="false" cancelLabel="Limpar" uploadLabel="Upload"
                                invalidFileSizeMessageSummary="O tamanho do arquivo não é permitido"
                                invalidFileSizeMessageDetail="O tamanho máximo do permitido é {0}"
                                invalidFileTypeMessageSummary="O tipo de arquivo não é válido"
                                invalidFileTypeMessageDetail="O tipo de arquivo permitido é {0}"
                                (uploadHandler)="uploadFiles($event.files)" [auto]="true" [maxFileSize]="18000000">
                            </p-fileUpload>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="customCard" *ngIf="componentFiles.length > 0">
            <div class="p-d-flex p-jc-end">
                <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="buttonPlain"
                    (click)="onCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" iconPos="right" class="action-button p-ml-2"
                    (click)="onSave()"></button>
            </div>
        </div>
        
    </div>
</div>