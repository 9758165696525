import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { CancelBrokerOperationRequest } from 'src/app/services/brokers/models/request/cancel-broker-operation-request';
import { GetBrokerOperationsByFilterRequest } from 'src/app/services/brokers/models/request/get-broker-operations-by-filter-request';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { OperationStatusEnum } from '../../../../enums/operation-status-enum';
import { LiabilityOperationViewModel } from 'src/app/services/liabilities/models/view-models/liability-operation-view-model';
import { LiabilityService } from 'src/app/services/liabilities/liability-service';
import { GetLiabilityOperationsByFilterRequest } from 'src/app/services/liabilities/models/request/get-liability-operations-by-filter-request';
import { AnalysisResultViewModel } from 'src/app/services/liabilities/models/view-models/analysis-result-view-model';
import { LiabilityOperationStatusEnum } from 'src/app/enums/liability-operation-status-enum';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';

@Component({
    templateUrl: './liability-operation-consult.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class LiabilityOperationConsultComponent implements OnInit {

    request: GetLiabilityOperationsByFilterRequest = null;
    operationTypes: DefaultEnumViewModel[] = [];
    operationStatus: SelectItem[] = [];
    funds: FundViewModel[] = [];
    shareholderFunds: SelectItem[] = [];
    shareholderFundsResult: string[] = [];
    shareholderFundsDocumentResult: string[] = [];
    investedFunds: SelectItem[] = [];
    investedFundsResult: string[] = [];
    investedFundsDocumentResult: string[] = [];
    OptionBrokers: SelectItem[] = [];
    fundDistributorDocumentNumbers: SelectItem[] = [];
    operations: LiabilityOperationViewModel[] = [];
    operationsExport: any[] = [];
    firstPage: number = 0;

    selectedStatusOperation: number = null;
    selectedShareholderFundType: string = null;
    selectedInvestedFundType: string = null;
    selectedShareholderFund: string = null;
    selectedShareholderFundDocument: string = null;
    selectedInvestedFundDocument: string = null;
    cols: any[] = [];

    selectedBrokerOperation: LiabilityOperationViewModel = null;
    displayOperationDetail: boolean = false;
    displayOperationCancel: boolean = false;
    displayReasonOperation: boolean = false;
    displayExportFull: boolean = false;
    selectedOperationCancel: LiabilityOperationViewModel = null;
    selectedOperationDisplay: LiabilityOperationViewModel = null;
    requestOperationCancel = null;
    selectedOperationSituation: string = null;
    user: UserViewModel = null;

    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    liabilityOperationInfo: AnalysisResultViewModel[] = [];
    administratorsInfo: AnalysisResultViewModel[] = [];
    managerInfo: AnalysisResultViewModel[] = [];
    displayLiabilityOperationDetail: boolean;
    fundOptions: any[] = [];
    investedFundOptions: SelectItem[] = [];
    selectedFund: SelectItem = null;
    selectedInvestedFund: SelectItem = null;

    public operationStatusEnum = OperationStatusEnum;
    public liabilityOperationStatusEnum = LiabilityOperationStatusEnum;
    public applicationTypeEnum = ApplicationTypeEnum;

    constructor(
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private fundService: FundService,
        private contextService: ContextService,
        private fundBrokerService: FundBrokerService,
        private datePipe: DatePipe,
        private router: Router,
        private liabilityService: LiabilityService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.setForms();
        this.getOperationStatus();
        this.setFormCancelOperation();
        await this.getAllFunds();
    }

    async consult() {

        this.operations = [];
        const request: GetLiabilityOperationsByFilterRequest = {
            liabilityOperationSequentialId: this.request.liabilityOperationSequentialId ? Number(this.request.liabilityOperationSequentialId) : null,
            operationStatusId: this.request.operationStatusId ? Number(this.request.operationStatusId) : null,
            shareholderFundId: this.request.shareholderFundId ? this.request.shareholderFundId : null,
            fundId: this.request.fundId ? this.request.fundId : null,
            startDate: this.request.startDate ? this.request.startDate : null,
            endDate: this.request.endDate ? this.request.endDate : null,
        }
        try {
            this.loadingService.show();
            this.operations = await this.liabilityService.getOperationsByFilter(request);
            if (this.selectedStatusOperation !== null && this.selectedStatusOperation == LiabilityOperationStatusEnum.ConcluidoComAlteracao) {
                // console.log(this.operations)
                let filteredOperations = [];
                for (let operation of this.operations) {
                    for (let item of operation.results) {
                        const obj = {
                            'ID': operation?.sequentialId,
                            'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                            'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                                operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                                this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                            'Status': operation?.currentStatus?.status?.friendlyName,
                            'Fundo Investido': `${operation?.fund?.name}`,
                            'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                            'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                            'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                            'Contrato': `${operation?.contract}`,
                            'Razão Social - Antigo': item?.propertyChanged == "Razão Social" ? `${item?.oldValue}` : ``,
                            'Razão Social - Novo': item?.propertyChanged == "Razão Social" ? `${item?.currentValue}` : ``,
                            'CNPJ - Antigo': item?.propertyChanged == "CNPJ" ? `${this.parseCNPJ(item?.oldValue)}` : ``,
                            'CNPJ Social - Novo': item?.propertyChanged == "CNPJ" ? `${this.parseCNPJ(item?.currentValue)}` : ``,
                            'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        }
                        filteredOperations.push(obj);
                    }
                }
                this.operationsExport = filteredOperations;
                console.log(this.operationsExport)
            } else {
                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation?.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                            operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': operation?.currentStatus?.status?.friendlyName,
                        'Fundo Investido': `${operation?.fund?.name}`,
                        'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                        'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                        'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                        'Contrato': `${operation?.contract}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    }
                });
            }
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            // this.setForms();
            this.loadingService.hide();
        }
    }

    openFullConsult() {
        this.displayExportFull = true;
        this.selectedOperationSituation = null;
    }


    existOperationParams(): boolean {
        if (
            this.request.liabilityOperationSequentialId == null &&
            this.request.operationStatusId == null &&
            this.request.fundId == null &&
            this.request.startDate == null &&
            this.request.endDate == null &&
            this.request.shareholderFundId == null &&
            this.selectedShareholderFundType == null) {
            return false
        } else {
            return true
        }
    }

    async exportAllSelectedOperation() {
        this.operations = [];
        const request: GetLiabilityOperationsByFilterRequest = {
            liabilityOperationSequentialId: null,
            operationStatusId: null,
            fundId: null,
            shareholderFundId: null,
            startDate: null,
            endDate: null,
        }
        try {
            this.loadingService.show();
            this.displayExportFull = false;
            this.operations = await this.liabilityService.getOperationsByFilter(request);
            if (this.selectedOperationSituation == 'included') {
                this.operations = this.operations.filter(fill =>
                    fill.currentStatus.status.value == LiabilityOperationStatusEnum.ConcluidaComInclusao)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation?.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                            operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': operation?.currentStatus?.status?.friendlyName,
                        'Fundo Investido': `${operation?.fund?.name}`,
                        'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                        'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                        'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                        'Contrato': `${operation?.contract}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'changed') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Concluída)
                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation?.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                            operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': operation?.currentStatus?.status?.friendlyName,
                        'Fundo Investido': `${operation?.fund?.name}`,
                        'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                        'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                        'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                        'Contrato': `${operation?.contract}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'error') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Cancelada)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation?.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                            operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': operation?.currentStatus?.status?.friendlyName,
                        'Fundo Investido': `${operation?.fund?.name}`,
                        'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                        'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                        'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                        'Contrato': `${operation?.contract}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'all') {

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation?.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                            operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': operation?.currentStatus?.status?.friendlyName,
                        'Fundo Investido': `${operation?.fund?.name}`,
                        'CNPJ F. Investido': `${this.parseCNPJ(operation?.fund?.documentNumber)}`,
                        'Fundo Investidor': `${operation?.shareholderFund?.name}`,
                        'CNPJ F. Investidor': `${this.parseCNPJ(operation?.shareholderFund?.documentNumber)}`,
                        'Contrato': `${operation?.contract}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    }
                });
            }
            this.exportExcel();
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            console.log(error)
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    getOperationStatus() {
        this.operationStatus = [
            { label: 'Concluído com Inclusão', value: LiabilityOperationStatusEnum.ConcluidaComInclusao },
            { label: 'Concluído com Alteração', value: LiabilityOperationStatusEnum.ConcluidoComAlteracao },
            { label: 'Erro Cadastral', value: LiabilityOperationStatusEnum.ErroCadastral },
        ]
    }

    setForms() {
        this.request = {
            liabilityOperationSequentialId: null,
            operationStatusId: null,
            fundId: null,
            startDate: null,
            endDate: null,
            shareholderFundId: null,
        }
    }

    onClear() {
        this.setForms();
    }

    clearFunds() {
        this.selectedShareholderFund = null;
        this.selectedShareholderFundDocument = null;
    }

    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
        this.displayExportFull = false;
    }

    reasonOperation(operation: LiabilityOperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedOperationDisplay = operation;
    }

    async viewLiabilityOperation(liabilityOperation: LiabilityOperationViewModel) {
        let response = await this.liabilityService.getLiabilityOperationById(liabilityOperation.id);
        this.liabilityOperationInfo = response.results;
        this.administratorsInfo = response.results.filter(c => c.entityChanged == "Administradora");
        this.managerInfo = response.results.filter(c => c.entityChanged == "Gestora");
        this.displayLiabilityOperationDetail = true;
    }

    getMenuItems(liabilityOperation: LiabilityOperationViewModel): MenuItem[] {
        if (liabilityOperation?.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral && !this.isUserOperator) {
            return [
                { label: 'Motivo', command: e => this.reasonOperation(liabilityOperation) },
            ]
        } else if (liabilityOperation.results.length == 0) {
            return []
        } else {
            return [
                { label: 'Ver', command: async e => await this.viewLiabilityOperation(liabilityOperation) },
            ]
        }
    }


    viewOperation(operation: OperationViewModel) {
        const url = window.location.origin
        window.open(`${url}/#/app/customer/broker-operation/setup/${operation.id}`, '_blank');
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return x ? !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '') : '';
    }

    reset() {
        this.firstPage = 0
    }

    handleTypeFund(event: string) {
        this.request.fundId = null;
    }

    searchInvestedFund(query: string) {
        this.investedFundsResult = [];
        this.investedFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsResult.push(property);
                return
            }
        })
    }

    searchInvestedFundDocument(query: string) {
        this.investedFundsDocumentResult = [];
        let investedFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);
        investedFundsFilter.forEach(item => {
            const property = item.documentNumber;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsDocumentResult.push(property);
                return
            }
        })
    }


    searchShareholderFund(query: string) {
        this.shareholderFundsResult = [];
        this.shareholderFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.shareholderFundsResult.push(property);
                return
            }
        })
    }

    searchShareholderFundDocument(query: string) {
        this.shareholderFundsDocumentResult = [];
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.forEach(item => {
            const documentNumber = item.documentNumber;
            if (documentNumber.includes(query) ||
                documentNumber.toLowerCase().includes(query) ||
                documentNumber.toUpperCase().includes(query) ||
                documentNumber.toLocaleLowerCase().includes(query) ||
                documentNumber.toLocaleUpperCase().includes(query)) {
                this.shareholderFundsDocumentResult.push(documentNumber);
                return
            }
        })
    }

    onShareholderFund(search: string) {
        this.shareholderFunds.filter(fill => fill.label == search).forEach(item => {
            this.request.fundId = item.value
        })
    }

    onShareholderFundDocument(search: string) {
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.filter(fill => fill.documentNumber == search).forEach(item => {
            this.request.fundId = item.id
        })
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.operationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    async getAllFunds() {
        const set = new Set();
        const setFund = new Set();
        this.fundOptions = [];
        this.investedFundOptions = [];
        let pagedSearchRequest = CreatePagedSearchRequest(null);
        const response = await this.liabilityService.getLiabilityOperationsFinishedPaged(pagedSearchRequest);
        response.searchResult.forEach(c => {
            if (!set.has(c.fund.name)) {
                set.add(c.fund.name);
                this.fundOptions.push({ label: c.fund.name, value: c.fund.id });
            }
            if (!setFund.has(c.shareholderFund.name)) {
                setFund.add(c.shareholderFund.name);
                this.investedFundOptions.push({ label: c.shareholderFund.name, value: c.shareholderFund.id });
            }
        })

    }


}