import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typesOperation'
})

export class TypesOperationPipe implements PipeTransform {
    constructor() { }

    transform(value: string) {
        switch (value) {
            case 'Compra':
                return `Compra`;
            case 'Atualizacao':
                return `Atualização`;
            case 'BoletimSubscricao':
                return `Boletim de Subscrições`;
            case 'AlteracaoDistribuidor':
                return `Alteração de Distribuidor`;
            case 'MovimentacaoDiaria':
                return `Movimentação Diária`;
            case 'Incorporacao':
                return `Incorporação`;
            case 'Cisao':
                return `Cisão`;
            case 'IntegralizacaoId':
                return `Integralização`;
            case 'Masterizacao':
                return `Masterização`;
            case 'TransferenciaAdmin':
                return `Transferências de Administrador`;
            case 'ResgateDeComeCotas':
                return `Resgate Come Cotas`;
            case 'CessaoDeCotasCompra':
                return `Cessão de Cotas - Compra / Venda`;
            case 'CessaoDeCotasDoacao':
                return `Cessão de Cotas - Doação`;
            default:
                break;
        }
    }
}




