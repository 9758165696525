import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { EventHandlerService } from 'src/app/providers/event-handler-service';
import { AppRootComponent } from '../app.root/app.root.component';
import { ContextService } from 'src/app/providers/context-service';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppTopBarComponent implements OnInit {
    applications: ApplicationResponseViewModel[] = [];
    selectedApplication: ApplicationResponseViewModel = null;
    activeItem: number;

    @Output() changeApplicationEmitter: EventEmitter<ApplicationResponseViewModel> = new EventEmitter<ApplicationResponseViewModel>();

    constructor(
        public appMain: AppRootComponent,
        public app: AppComponent,
        private router: Router,
        private contextService: ContextService,
        private eventHandlerService: EventHandlerService) {
    }


    ngOnInit() {
        this.getUserApplications();
        this.getUserApplicationSelected();
    }

    myData() {
        this.router.navigateByUrl('app/my-profile');
    }

    getUserApplications() {
        this.applications = this.contextService.getUserApplications();
        // console.log(this.applications, "All")
    }

    getUserApplicationSelected() {
        this.selectedApplication = this.contextService.getUserApplicationSelected();
        // console.log(this.selectedApplication, "SELECTED");
    }

    selectedApplicationChanged(application: ApplicationResponseViewModel) {
        this.contextService.setUserApplication(application);
        const selectedApplication = this.contextService.getUserApplicationSelected();
        this.changeApplicationEmitter.emit(selectedApplication);
    }
}
