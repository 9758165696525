import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundService } from 'src/app/services/funds/fund-service';
import { UpdateAdhesionTermTemplateRequest } from 'src/app/services/funds/models/request/update-adhesion-term-template-request';
import { UpdateSubscriptionFormTemplateRequest } from 'src/app/services/funds/models/request/update-subscription-form-template-request';
import { BrokerEntryViewModel } from 'src/app/services/funds/models/view-models/broker-entry-view-model';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { FinancialInstitutionViewModel } from 'src/app/services/financial-institutions/models/view-models/financial-institution-view-model';
import { FinancialInstitutionService } from 'src/app/services/financial-institutions/financial-institution-service';

@Component({
    templateUrl: './financial-institution-view.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FinancialInstitutionViewComponent implements OnInit {

    selectedInstitution: FinancialInstitutionViewModel = null;
    selectedApplication: ApplicationResponseViewModel = null;
    requestModelAdhesion: CreateArtifactTemplateRequest = null;
    requestModelSubscription: CreateArtifactTemplateRequest = null;
    displayAdhesionTermTemplate: boolean = false;
    displaySubscriptionFormTemplate: boolean = false;
    // typeUser: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    brokerEntries: BrokerEntryViewModel[] = [];
    expiredBrokerEntries: BrokerEntryViewModel[] = [];
    aboutToExpireBrokerEntries: BrokerEntryViewModel[] = [];
    isNumberPatrimony: boolean = false;
    isNumberLastYearInvoicing: boolean = false;
    isNumberShareCapital: boolean = false;

    public applicationTypeEnum = ApplicationTypeEnum;
    public fundTypeEnum = FundTypeEnum;

    constructor(
        private financialInstitutionService: FinancialInstitutionService,
        private router: Router,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private route: ActivatedRoute) {
    }

    async ngOnInit() {
        this.setForms();
        await this.verifyUserPermissions();
        await this.getFinancialInstituton();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.selectedApplication = this.contextService.getUserApplicationSelected();

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    setForms() {
        this.requestModelAdhesion = null
        this.requestModelSubscription = null
    }


    async getFinancialInstituton() {
        const institutionId = this.route.snapshot.params.id;
        this.selectedInstitution = await this.financialInstitutionService.getById(institutionId);
        this.selectedInstitution.documentNumber = this.parseCNPJ(this.selectedInstitution.documentNumber);
        this.isNumberPatrimony = this.hasNumber(this.selectedInstitution.patrimony);
        this.isNumberLastYearInvoicing = this.hasNumber(this.selectedInstitution.lastYearInvoicing);
        this.isNumberShareCapital = this.hasNumber(this.selectedInstitution.shareCapital);
    }

    hasNumber(value: string) {
        return /\d/.test(value);
    }


    parseCNPJ(value: string): string {
        if (value) {
            var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
            return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
        } else {
            return "-";
        }
    }

    onCancel() {
        // this.router.navigateByUrl('app/customer/fund/list');
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            this.router.navigate([`app/customer/financial-institution/list`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            this.router.navigate([`app/customer/financial-institution/list`]);
        }
    }

    onEdit() {
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            return this.router.navigate([`app/customer/financial-institution/edit/${this.selectedInstitution.id}`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            return this.router.navigate([`app/customer/financial-institution/edit/${this.selectedInstitution.id}`]);
        }
    }

    openChangeAdhesionTermTemplate() {
        this.displayAdhesionTermTemplate = true;
    }

    openChangeSubscriptionTemplate() {
        this.displaySubscriptionFormTemplate = true;
    }

}