import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { ContextService } from 'src/app/providers/context-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './fund-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundListComponent implements OnInit {

    funds: FundViewModel[] = [];
    shareholderFunds: FundViewModel[] = [];
    investedFunds: FundViewModel[] = [];
    wallet: FundViewModel[] = [];
    search: string = null;
    searchInvestedFund: string = null;
    searchWallet: string = null;
    tabIndex: number = 0;
    // typeUser: string = null;
    selectedApplication: ApplicationResponseViewModel = null;

    public applicationTypeEnum = ApplicationTypeEnum;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private fundService: FundService,
        private loadingService: LoadingService,
        private route: ActivatedRoute,
        private contextService: ContextService,
        private documentNumberPipe: DocumentNumberPipe,
        private router: Router) {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        this.getRolesByUser();
        await this.getFunds();
        let filtered = this.route.snapshot.queryParams.search;
        let tab = this.route.snapshot.queryParams.tab;
        if (filtered && tab == 0) {
            this.filter(filtered);
        } else if (filtered && tab == 1) {
            this.tabIndex = tab;
            this.filter(filtered);
        } else if (tab && !filtered) {
            this.tabIndex = tab;
        }
        this.loadingService.hide();
    }

    getRolesByUser() {
        this.selectedApplication = this.contextService.getUserApplicationSelected();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getFunds() {
        this.funds = await this.fundService.getAllFunds(null);
        this.funds = this.funds.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
        this.shareholderFunds = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        this.investedFunds = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);
        this.wallet = this.funds.filter(fill => fill.type.value == FundTypeEnum.Carteira);
    }



    viewFund(fund: FundViewModel) {
        // return this.router.navigateByUrl(`app/customer/fund/view/${fund.id}`);
        if (this.tabIndex == 0 || fund.type.value == FundTypeEnum.Carteira) {
            return this.router.navigate([`app/customer/fund/view/${fund.id}`], { queryParams: { search: this.search, tab: this.tabIndex } });
        } else if (this.tabIndex == 1) {
            return this.router.navigate([`app/customer/fund/view/${fund.id}`], { queryParams: { search: this.searchInvestedFund, tab: this.tabIndex } });
        }
    }

    fundEdit(fund: FundViewModel) {
        if (this.tabIndex == 0 || fund.type.value == FundTypeEnum.Carteira) {
            return this.router.navigate([`app/customer/fund/edit/${fund.id}`], { queryParams: { search: this.search, tab: this.tabIndex } });
        } else if (this.tabIndex == 1) {
            return this.router.navigate([`app/customer/fund/edit/${fund.id}`], { queryParams: { search: this.searchInvestedFund, tab: this.tabIndex } });
        }
    }

    getMenuItems(fund: FundViewModel): MenuItem[] {
        if (this.tabIndex == 0 && this.isUserAdministrator || this.isUserAnalist && !this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewFund(fund) },
                { label: 'Editar', command: (e) => this.fundEdit(fund) },
            ]
        } else if (this.tabIndex == 0 && this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewFund(fund) },
            ]
        } else if (this.tabIndex == 1) {
            return [
                { label: 'Ver', command: (e) => this.viewFund(fund) },
                { label: 'Editar', command: (e) => this.fundEdit(fund) },
            ]
        }
    }

    getMenuWallet(fund: FundViewModel) {
        if (this.isUserAdministrator || this.isUserAnalist && !this.isUserOperator) {
            return [
                { label: 'Ver', command: (e) => this.viewFund(fund) },
                { label: 'Editar', command: (e) => this.fundEdit(fund) },
            ]
        }
    }

    async changeTab(event: number) {
        this.loadingService.show();
        this.tabIndex = event;
        this.loadingService.hide();
    }

    async filter(filtered: string) {
        switch (this.tabIndex) {
            case 0:
                this.search = filtered;
                let filterShareholderFund = [];
                if (this.search) {
                    this.shareholderFunds.forEach(item => {
                        if (item.name.toUpperCase().includes(filtered) ||
                            item.name.toLowerCase().includes(filtered) ||
                            item.documentNumber.includes(filtered) ||
                            this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                            filterShareholderFund.push(item)
                            this.shareholderFunds = filterShareholderFund;
                        }
                    })
                } else if (!this.search) {
                    await this.getFunds();
                }
                break;
            case 1:
                this.searchInvestedFund = filtered;
                let filterInvestedFund = [];
                if (this.searchInvestedFund) {
                    this.investedFunds.forEach(item => {
                        if (item.name.toUpperCase().includes(filtered) ||
                        item.name.toLowerCase().includes(filtered) ||
                        item.documentNumber.includes(filtered) ||
                        this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                            filterInvestedFund.push(item)
                            this.loadingService.show();
                            this.investedFunds = filterInvestedFund;
                            this.loadingService.hide();
                        }
                    })
                } else if (!this.searchInvestedFund) {
                    this.loadingService.show();
                    await this.getFunds();
                    this.loadingService.hide();
                }
                break;
            case 2:
                this.searchWallet = filtered;
                let filterWalletFund = [];
                if (this.searchWallet) {
                    this.wallet.forEach(item => {
                        if (item.name.toUpperCase().includes(filtered) ||
                            item.name.toLowerCase().includes(filtered) ||
                            item.documentNumber.includes(filtered) ||
                            this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                            filterWalletFund.push(item)
                            this.wallet = filterWalletFund;
                        }
                    })
                } else if (!this.search) {
                    await this.getFunds();
                }
                break;

            default:
                break;
        }
    }


}