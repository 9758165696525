<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Nova Instituição
        </div>
        <div class="customCard">
            <form #fundForm="ngForm" novalidate>
                <div class="cardHeader text-bold">Informações</div>
                <div class="p-grid p-fluid p-formgrid">

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="requestFinancialInstitution.name" name="name"
                                    #name="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="documentNumber" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" [(ngModel)]="requestFinancialInstitution.documentNumber"
                                    name="documentNumber" #documentNumber="ngModel" [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                        </div>
                        <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="patrimony" type="text" [(ngModel)]="requestFinancialInstitution.patrimony" name="patrimony"
                                    #patrimony="ngModel" class="inputLiza" required pInputText>
                                <label for="patrimony">Patrimônio Líquido</label>
                            </span>
                        </div>
                        <mark *ngIf="patrimony.invalid && patrimony.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="lastYearInvoicing" type="text" [(ngModel)]="requestFinancialInstitution.lastYearInvoicing" name="lastYearInvoicing"
                                    #lastYearInvoicing="ngModel" class="inputLiza" required pInputText>
                                <label for="lastYearInvoicing">Faturamento últimos 12 meses</label>
                            </span>
                        </div>
                        <mark *ngIf="lastYearInvoicing.invalid && lastYearInvoicing.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="shareCapital" type="text" [(ngModel)]="requestFinancialInstitution.shareCapital" name="shareCapital"
                                    #shareCapital="ngModel" class="inputLiza" required pInputText>
                                <label for="shareCapital">Capital Social</label>
                            </span>
                        </div>
                        <mark *ngIf="shareCapital.invalid && shareCapital.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="nire" type="text" [(ngModel)]="requestFinancialInstitution.nire"
                                    name="nire" #nire="ngModel" class="inputLiza" pInputText>
                                <label for="nire">NIRE</label>
                            </span>
                        </div>
                        <mark *ngIf="nire.invalid && nire.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="requestFinancialInstitution.establishmentDate"
                                    name="establishmentDate" yearRange="1900:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    [required]="true" #establishmentDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="establishmentDate" class="labelOcta">Data de Constituição</label>
                            </span>
                        </div>
                    </div>
                    
                </div>
                <br>
                <div class="cardHeader text-bold">Informações da conta</div>
                <div class="p-grid p-fluid p-formgrid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankName" type="text"
                                    [(ngModel)]="requestFinancialInstitution.bankAccount.bankName" name="bankName"
                                    #bankName="ngModel" class="inputLiza" required pInputText>
                                <label for="bankName">Nome do Banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankName.invalid && bankName.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankCode" type="text"
                                    [(ngModel)]="requestFinancialInstitution.bankAccount.bankCode" name="bankCode"
                                    #bankCode="ngModel" class="inputLiza" required pInputText>
                                <label for="bankCode">Número do Banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankCode.invalid && bankCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="agencyNumber" type="text"
                                    [(ngModel)]="requestFinancialInstitution.bankAccount.agencyNumber" name="agencyNumber"
                                    #agencyNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="agencyNumber">Agência</label>
                            </span>
                        </div>
                        <mark *ngIf="agencyNumber.invalid && agencyNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>


                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountNumber" type="text"
                                    [(ngModel)]="requestFinancialInstitution.bankAccount.accountNumber" name="accountNumber"
                                    #accountNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="accountNumber">Conta Corrente</label>
                            </span>
                        </div>
                        <mark *ngIf="accountNumber.invalid && accountNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountDigit" type="text"
                                    [(ngModel)]="requestFinancialInstitution.bankAccount.accountDigit" name="accountDigit"
                                    #accountDigit="ngModel" class="inputLiza" required pInputText>
                                <label for="accountDigit">Dígito</label>
                            </span>
                        </div>
                        <mark *ngIf="accountDigit.invalid && accountDigit.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Salvar" iconPos="right" class="action-button p-ml-2"
                        (click)="onSave(fundForm)"></button>
                </div>
            </form>

        </div>
    </div>
</div>