import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// Components modules
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
// App Configuration
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CustomerModule } from './components/customer/customer.module';
import { LizaModule } from './components/liza/liza.module';
import { LoadingModule } from './modules/loading/app.loading.module';
// App Modules
import { NavigationModule } from './navigation/navigation.module';
import { PagesModule } from './pages/pages.module';
import { PipesModule } from './pipes/pipes-module';

// Azure AD
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig } from './services/authentication/azure-ad/auth-config';

registerLocaleData(localept, 'pt');


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
    imports: [
        BrowserModule,
        NgxLocalStorageModule.forRoot({ prefix: 'liza-db' }),
        AgmCoreModule.forRoot({ apiKey: 'AIzaSyDlAPUSuMJPIDUxY7NjJkH9Q8ViFUs5caU', libraries: ['places'] }),
        ToastModule,
        DynamicDialogModule,
        LoadingModule,
        RouterModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NavigationModule,
        PagesModule,
        LizaModule,
        PipesModule,
        TableModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        CustomerModule,
        InputMaskModule,
        MsalModule
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }, MessageService,
        {provide: LOCALE_ID, useValue: 'pt'},
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
          },
          {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
          },
          MsalService,
          MsalGuard,
          MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
