import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { CreateShareholderFundRequest } from 'src/app/services/funds/models/request/create-shareholder-fund-request';

@Component({
    templateUrl: './fund-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundCreateComponent implements OnInit {

    requestModelShareholder: CreateShareholderFundRequest = null;
    allTypeFund: SelectItem[] = [];
    allTargetAudience: SelectItem[] = [];
    optionsBovespa: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    optionsBMF: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    allFundOrManagerReversion: SelectItem[] = [{ label: 'Sim', value: 'Sim' }, { label: 'Não', value: 'Não' }];
    selectedBovespa: SelectItem = null;
    selectedBMF: SelectItem = null;

    constructor(
        private router: Router,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private fundService: FundService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setForms();
        this.getAllTargetAudienceANBIMA();
    }

    setForms() {
        this.requestModelShareholder = {
            name: null,
            documentNumber: null,
            establishmentDate: null,
            sac: null,
            isBovespa: null,
            isBMF: null,
            deskConfig: null,
            bankAccount: {
                bankCode: null,
                bankName: null,
                agencyNumber: null,
                accountNumber: null,
                accountDigit: null,
            },
            customProperty: {
                ambimaTargetGroup: null,
                cetipAccount: null,
                stiCode: null,
                giinNumber: null,
                fundOrManagerReversion: null,
            }
        }
    }

    getAllTargetAudienceANBIMA() {
        this.allTargetAudience = [
            { label: 'Todos', value: 'Todos' },
            { label: 'Restritos', value: 'Restritos' },
            { label: 'Exclusivo', value: 'Exclusivo' },
            { label: 'Previdenciário', value: 'Previdenciário' },
        ]
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund/list');
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;

        try {
            this.loadingService.show();
            this.requestModelShareholder.isBovespa = this.selectedBovespa.value;
            this.requestModelShareholder.isBMF = this.selectedBMF.value;
            await this.fundService.create(this.requestModelShareholder);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/fund/list');
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    handleBovespa(event: SelectItem) {
        this.selectedBovespa = event;
    }

    handleBMF(event: SelectItem) {
        this.selectedBMF = event;
    }

}