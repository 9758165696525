import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateFundAdministratorRequest, RepresentativeProperties } from 'src/app/services/fund-administrators/models/requests/create-fund-administrator-request';

@Component({
    templateUrl: './fund-administrator-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundAdministratorCreateComponent implements OnInit {
  
    requestModel: CreateFundAdministratorRequest = null;
    representativeRequestForm: RepresentativeProperties = null;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService,                
        private messageService: MessageService,                
        private formValidationService: FormValidationService) {
    }

    async ngOnInit() {        
        this.setMainRequestForm();        
        this.setRepresentativeForm();        
    }

    setMainRequestForm() {
        this.requestModel = {
            name: null,
            documentNumber: null,
            registrationFormTemplateRequest: null,
            phoneNumber: null,
            email: null,
            giinNumber: null,
            representatives: []
        };
    }

    setRepresentativeForm() {
        this.representativeRequestForm = {
            name: null,
            email: null,
            expireDate: null
        };
    }

    addRepresentativeRequest(representativeRequest: RepresentativeProperties, formComponent: NgForm) {        
        if (!this.formValidationService.validateForm(formComponent.form)) return;

        this.requestModel.representatives.push(representativeRequest);
        this.setRepresentativeForm();
        this.formValidationService.resetFormFields(formComponent.form);
    }

    removeRepresentativeRequest(representativeRequest: RepresentativeProperties) {
        const indexToRemove = this.requestModel.representatives.findIndex(c => c == representativeRequest);
        this.requestModel.representatives.splice(indexToRemove, 1);
    }

    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund-administrator/list');
    }

    async onSave(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        if (this.requestModel.registrationFormTemplateRequest == null) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de ficha cadastral é obrigatório.`, life: 5000 });
            return;
        }

        if (this.requestModel.representatives.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `É necessário informar ao menos um representante.`, life: 5000 });
            return;
        }

        // console.log('request', this.requestModel);

        this.loadingService.show();
        try {
            await this.fundAdministratorService.createFundAdministrator(this.requestModel);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/fund-administrator/list');
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }
}