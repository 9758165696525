import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { CreateFinancialInstitutionRequest } from "./models/request/create-financial-institution-request";
import { FinancialInstitutionViewModel } from "./models/view-models/financial-institution-view-model";
import { UpdateFinancialInstitutionRequest } from "./models/request/update-financial-institution-request";

@Injectable({
    providedIn: 'root'
})

export class FinancialInstitutionService {

    constructor(private httpClient: HttpClientService) { }


    async getAll(isActive: boolean): Promise<any[]> {
        return this.httpClient.get(`/financial-institutions`, `/all/${isActive}`);
    }

    async getById(fundId: string): Promise<any> {
        return this.httpClient.get(`/financial-institutions`, `/${fundId}`);
    }

    async create(request: CreateFinancialInstitutionRequest): Promise<FinancialInstitutionViewModel> {
        return this.httpClient.post(`/financial-institutions`, `/`, request);
    }

    // async createAdhesionTermTemplates(request: UpdateAdhesionTermTemplateRequest): Promise<FundViewModel> {
    //     return this.httpClient.put(`/funds`, `/adhesion-term-templates`, request);
    // }

    // async createSubscriptionFormTemplates(request: UpdateSubscriptionFormTemplateRequest): Promise<FundViewModel> {
    //     return this.httpClient.put(`/funds`, `/subscription-form-templates`, request);
    // }

    async update(request: UpdateFinancialInstitutionRequest): Promise<FinancialInstitutionViewModel> {
        return this.httpClient.put(`/financial-institutions`, `/`, request);
    }

}