import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './operation-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class OperationCreateComponent implements OnInit {

    constructor(
    ) {
    }

    async ngOnInit() {
    }

}