import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { BrokerOperationViewModel } from "../brokers/models/view-models/broker-operation-view-model";
import { OperationViewModel } from "../operations/models/view-models/operation-view-model";
import { GetContextCompanyIntegrationResponse } from "./responses/get-context-company-integration-response";
import { GetOperationAnalysisLinkResponse } from "./responses/get-operation-analysis-link-response";

@Injectable({
    providedIn: 'root'
})

export class IntegratedOperationService {
    constructor(private httpClient: HttpClientService) { }

    async getIntegratedOperationsInBrokers(): Promise<GetContextCompanyIntegrationResponse[]> {
        return this.httpClient.get(`/integrated-operations`, `/brokers`);
    }

    async getIntegratedOperationsInBrokerByDocumentNumber(documentNumber: string, status: string): Promise<BrokerOperationViewModel[]> {
        return this.httpClient.get(`/integrated-operations`, `/brokers/${documentNumber}/operations?status=${status}`);
    }

    async getIntegratedOperationsInBrokerBySource(documentNumber: string, operationId: string, source: string): Promise<GetOperationAnalysisLinkResponse> {
        return this.httpClient.get(`/integrated-operations`, `/brokers/${documentNumber}/operations/${operationId}/sources/${source}/analysis-links`);
    }
    
    async getIntegratedOperationsDetailInBrokerBySource(documentNumber: string, operationId: string, source: string): Promise<BrokerOperationViewModel> {
        return this.httpClient.get(`/integrated-operations`, `/brokers/${documentNumber}/operations/${operationId}/sources/${source}/details`);
    }

    async getIntegratedOperationsInManagers(): Promise<GetContextCompanyIntegrationResponse[]> {
        return this.httpClient.get(`/integrated-operations`, `/managers`);
    }

    async getIntegratedOperationsInManagerByDocumentNumber(documentNumber: string, status: string): Promise<OperationViewModel[]> {
        return this.httpClient.get(`/integrated-operations`, `/managers/${documentNumber}/operations?status=${status}`);
    }

    async getIntegratedOperationsInManagerBySource(documentNumber: string, operationId: string, source: string): Promise<GetOperationAnalysisLinkResponse> {
        return this.httpClient.get(`/integrated-operations`, `/managers/${documentNumber}/operations/${operationId}/sources/${source}/analysis-links`);
    }

    async getIntegratedOperationsDetailInManagerBySource(documentNumber: string, operationId: string, source: string): Promise<OperationViewModel> {
        return this.httpClient.get(`/integrated-operations`, `/managers/${documentNumber}/operations/${operationId}/sources/${source}/details`);
    }

}
