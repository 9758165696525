<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Gestora
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined" (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Excluir" class="p-button-outlined-blue" (click)="confirm(selectedFundManager)"></button>
                </div>
            </div>
            <div class="rowForm flexRow">
                <div class="cardHeader text-bold">Informações</div>
                <br>
            </div>
            <form #managerForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-8 p-lg-8">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="fundManagerName" name="name"
                                    #name="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Gestora</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-inputMask inputId="documentNumber" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" ngModel={{selectedFundManager?.documentNumber}}
                                    name="documentNumber" #documentNumber="ngModel" [readonly]="true"
                                    [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                            <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                                class="ui-message ui-messages-error ui-corner-all markError">Campo
                                obrigatório</mark>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 p-sm-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                                    [(ngModel)]="requestFundManager.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                                    [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="phoneNumber" class="labelLiza">Telefone</label>
                            </span>
                        </div>
                        <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 p-sm-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="email" type="email" [(ngModel)]="requestFundManager.email" name="email" #email="ngModel"
                                    class="inputLiza" required pInputText appValidateEmail>
                                <label for="email">E-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="email.invalid && email.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" class="inputLiza" name="giinNumber"
                                    [(ngModel)]="requestFundManager.giinNumber" name="giinNumber" #giinNumber="ngModel"
                                    pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Endereço</div>
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Endereço: </p>{{ selectedFundManager?.addressStreet }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Bairro: </p>{{ selectedFundManager?.addressNeighborhood }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Cidade: </p>{{ selectedFundManager?.addressCity }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">UF: </p>{{ selectedFundManager?.addressState }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">CEP: </p>{{ selectedFundManager?.addressZipCode }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Número: </p>{{ selectedFundManager?.addressNumber }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Complemento: </p>{{ selectedFundManager?.addressComplement }}
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                    <div class="cardHeader text-bold">Alterar endereço</div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 p-fluid">
                    <app-address-form [editAddressParams]="null" autocompleteId="mainAddress"
                        [isCompleteAddressRequired]="true" (onSelect)="onAddressSelect($event)"></app-address-form>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Adicionar contato e procurador da Gestora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <form #contactsForm="ngForm" novalidate>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3 p-lg-3 customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="name" type="text" [(ngModel)]="requestContacts.name" name="name"
                                            #name="ngModel" class="inputLiza" required pInputText>
                                        <label for="name">Nome</label>
                                    </span>
                                </div>
                                <mark *ngIf="name.invalid && name.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3 p-fluid customCardOutlined">
                                <span class="p-float-label">
                                    <p-inputMask class="inputOctaMask" inputId="documentNumber" type="tel" mask="999.999.999-99"
                                        [(ngModel)]="requestContacts.personalDocumentNumber" name="documentNumber"
                                        #documentNumber="ngModel" [unmask]="true" >
                                    </p-inputMask>
                                    <label class="labelOcta" for="documentNumber">CPF</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3 customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="email" type="text" [(ngModel)]="requestContacts.email" name="email"
                                            #email="ngModel" class="inputLiza" required appValidateEmail pInputText>
                                        <label for="email">E-mail</label>
                                    </span>
                                </div>
                                <mark *ngIf="email.invalid && email.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <p-calendar [(ngModel)]="requestContacts.expireDate" name="expireDate"
                                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                            [yearNavigator]="true" #expireDate="ngModel">
                                        </p-calendar>
                                        <label for="expireDate">Data de Validade</label>
                                    </span>
                                </div>
                            </div>

                            <div class="customCardOutlined">
                                <button pButton pRipple type="button" icon="pi pi-plus"
                                    class="p-button-rounded p-button-outlined"
                                    (click)="addContact(contactsForm)"></button>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="selectedContactsFundManager.length > 0">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="cardHeader text-bold">Contatos e Procuradores da Gestora</div>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">CPF</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">E-mail</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">Data de Validade</p>
                                </div>
                            </div>
                            <div *ngFor="let contact of selectedContactsFundManager" class="p-grid contentAlignItemsCenter">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ contact?.name}}
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    {{ contact?.maskedPersonalDocumentNumber}}
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ contact?.email}}
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    {{ contact?.expireDate | date:
                                    'dd/MM/yyyy' }}
                                </div>
                                <div class="p-col-1 p-md-1 p-lg-1">
                                    <button pButton pRipple type="button" icon="pi pi-user-edit"
                                        class="p-button-rounded p-button-outlined"
                                        (click)="editContact(contact)"></button>
                                </div>
                                <div class="p-col-1 p-md-1 p-lg-1">
                                    <button pButton pRipple type="button" icon="pi pi-trash"
                                        class="p-button-rounded p-button-outlined-blue"
                                        (click)="removeContact(contact)"></button>
                                </div>
                                <div class="p-col-12 p-md-12 p-lg-12">
                                    <div class="line"></div>
                                </div>
                            </div>
                        </div>

                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                                    (click)="onCancel()"></button>
                                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                                    (click)="onSave(managerForm, contactsForm)"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-dialog header="Editar Procurador/Rep Legal" [(visible)]="displayContact" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form #repForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text" ngModel={{selectedContact?.name}} name="name"
                            #name="ngModel" class="inputLiza" required pInputText>
                        <label for="name">Nome</label>
                    </span>
                </div>
                <mark *ngIf="name.invalid && name.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <span class="p-float-label">
                    <p-inputMask class="inputOctaMask" inputId="documentNumber" type="tel" mask="999.999.999-99"
                        ngModel={{decryptedDocumentContactFundManager}} name="documentNumber"
                        #documentNumber="ngModel" [unmask]="true" >
                    </p-inputMask>
                    <label class="labelOcta" for="documentNumber">CPF</label>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="email" type="text" ngModel={{selectedContact?.email}} name="email"
                            #email="ngModel" class="inputLiza" required pInputText>
                        <label for="email">E-mail</label>
                    </span>
                </div>
                <mark *ngIf="email.invalid && email.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <!-- <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="selectedExpirateDateRep" name="expireDate" appendTo="body"
                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            #expireDate="ngModel" [required]="true">
                        </p-calendar>
                        <label for="expireDate">Data de Validade</label>
                    </span>
                </div>
                <mark *ngIf="expireDate.invalid && expireDate.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div> -->
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                    (click)="onEditCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onEditContact(repForm)"></button>
            </div>
        </div>
    </form>

</p-dialog>