import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { DomainWhitelabel } from './enums/domain-enum';
import { ContextService } from './providers/context-service';
import { ReLoginUserRequest } from './services/users/models/requests/re-login-user-request';
import { LoginResponseViewModel } from './services/users/models/view-models/login-response-view-model';
import { UserService } from './services/users/user-service';
import { LoginWhiteLabelContextRequest } from './services/white-label-context/models/requests/login-white-label-context-request';
import { WhiteLabelContextService } from './services/white-label-context/white-label-context-service';

@Component({
    selector: 'ng-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    topbarTheme: string = 'blue';

    menuTheme: string = 'light';

    layoutMode: string = 'light';

    menuMode: string = 'static';

    inlineMenuPosition: string = 'top';

    inputStyle: string = 'filled';

    ripple: boolean = true;

    isRTL: boolean = false;

    domain: string = null;

    constructor(
        private primengConfig: PrimeNGConfig,
        private elementRef: ElementRef,
        private contextService: ContextService,
        private router: Router,
        private userService: UserService,
        private whiteLabelContextService: WhiteLabelContextService,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }


    ngAfterViewInit(): void {
    }

    async ngOnInit() {

        console.log('app.component', 'ngOnInit()');

        const host = window.location.host;
        this.elementRef.nativeElement.style.visibility = 'hidden';

        this.domain = host.split('.')[0].replace('-funds', '');
        if (this.domain.includes('localhost')) {
            this.domain = DomainWhitelabel.app;
        }
        console.log("Dominio", this.domain);

        // console.log('app.component', 'Determine WhiteLabel context.');
        await this.whiteLabelContext();

        this.primengConfig.ripple = true;
        const ptBRTranslate: Translation = {
            startsWith: 'Começa com',
            contains: 'Contém',
            notContains: 'Não contém',
            endsWith: 'Termina com',
            equals: 'Igual a',
            notEquals: 'Diferente de',
            noFilter: 'Sem filtro',
            lt: 'Menor',
            lte: 'Menor ou igual',
            gt: 'Maior',
            gte: 'Maior ou igual',
            is: 'É',
            isNot: 'Não é',
            before: 'Anter',
            after: 'Depois',
            clear: 'Limpar',
            apply: 'Aplicar',
            matchAll: 'Combinar todos',
            matchAny: 'Combinar qualquer',
            addRule: 'Adicionar regra',
            removeRule: 'Remover regra',
            accept: 'Aceitar',
            reject: 'Rejeitar',
            choose: 'Escolher',
            upload: 'Upload',
            cancel: 'Cancelar',
            dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
            monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Aug", "Set", "Out", "Nov", "Dez"],
            today: 'Hoje',
            weekHeader: 'Sem',
        };

        this.primengConfig.setTranslation(ptBRTranslate);
        this.getWhitelabelTitle();
    }

    async whiteLabelContext() {
        // console.log('whiteLabelContext()');
        const request: LoginWhiteLabelContextRequest = {
            domain: this.domain
        };
        try {
            const response = await this.whiteLabelContextService.whiteLabelContextLogin(request);
            this.contextService.setWhiteLabelContext(response);
            await this.initApp();
            await this.loadTheme();
        } catch (error) {
            if (error.statusCode == 401) {
                return this.router.navigate(['unauthorized']);
            }
        }
    }

    async loadTheme() {
        const theme = this.contextService.getWhiteLabelContext();
        if (theme) {
            this.elementRef.nativeElement.style.visibility = 'visible';
            this.elementRef.nativeElement.style.setProperty('--primary-color', `#${theme.style.primaryColorHex}`);
            this.elementRef.nativeElement.style.setProperty('--primary-color-contrast', `#${theme.style.primaryColorContrastHex}`);
            this.elementRef.nativeElement.style.setProperty('--secondary-color', `#${theme.style.secondaryColorHex}`);
            // this.elementRef.nativeElement.style.setProperty('--secondaryColorContrast', `#${theme.style.secondaryColorContrastHex}`);
            let logo = this.elementRef.nativeElement.getElementsByClassName('ocLogo');
            logo[0].style.backgroundImage = `url(${theme.style.logoUrl})`;
        } else {
            this.elementRef.nativeElement.style.visibility = 'hidden';
        }
    }

    async initApp() {
        // Criando um user info com o token recebido apenas para ter o token no context
        const { representative, contact, operation, expirationDate } = this.route.snapshot.queryParams;
        if (representative && operation && expirationDate || contact && operation && expirationDate) {
            return;
        }
        // Obter o token do query params da url
        const { token, app } = this.route.snapshot.queryParams;
        const requestLogin: ReLoginUserRequest = {
            token: token
        };

        const userApplication = this.contextService.getUserApplicationSelected();
        const isLoggedIn = this.contextService.isLoggedIn();
        if (token == undefined && app == undefined && userApplication !== null && isLoggedIn) {
            if (userApplication.application.displayName == 'Corretoras' ||
                userApplication.application.displayName == 'FoF' ||
                userApplication.application.displayName == 'PassivoFundos') {
                window.location.href;
                return;
            }
        }

        const response: LoginResponseViewModel = await this.userService.refreshToken(requestLogin);
        this.contextService.setLoginResponse(response);

        const selectedApplication = response.applications.find(c => c.application.displayName == app);
        this.contextService.setUserApplication(selectedApplication);
        if (app == 'Corretoras') {
            this.router.navigateByUrl('/app/customer/broker-home');
            return;
        }
        if (app == 'FoF') {
            this.router.navigateByUrl('/app/customer/home');
            return;
        }
        if (app == 'PassivoFundos') {
            this.router.navigateByUrl('/app/customer/liability-home');
            return;
        }
    }


    getWhitelabelTitle() {
        const whitelabelContext = this.contextService.getWhiteLabelContext();
        const whitelabelMap = {
            [DomainWhitelabel.app]: `${whitelabelContext.style.name} goLiza`,
            [DomainWhitelabel.iaas]: `iaas! goLiza`,
            [DomainWhitelabel.legacy]: `Legacy goLiza`,
            [DomainWhitelabel.vinci]: `Vinci Partners goLiza`,
        };
    
        const title = whitelabelMap[whitelabelContext.style.name] || 'goLiza';
        this.titleService.setTitle(title);
    }

}