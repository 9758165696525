<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Instituição
            </div>
        </div>
        <div class="customCard">
            <form #fundEditForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações</div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{selectedInstitution?.name}} name="name" #name="ngModel"
                                    class="inputLiza" pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">CNPJ</p>
                        <p>{{selectedInstitution?.documentNumber | documentNumber: 'cnpj' }}</p>
                    </div>
                    <!-- <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Patrimonio Líquido</p>
                        <p>{{selectedInstitution?.patrimony | currency: 'BRL' }}</p>
                    </div> -->

                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid">
                        <div class="p-inputgroup">
                            <!-- <span class="p-float-label">
                                <p-inputNumber ngModel={{selectedInstitution?.patrimony}} inputId="patrimony"
                                    class="inputLiza" mode="currency" currency="BRL" name="patrimony"
                                    #patrimony="ngModel">
                                </p-inputNumber>
                                <label for="patrimony">Patrinômio Líquido</label>
                            </span> -->
                            <span class="p-float-label">
                                <input id="patrimony" type="text" ngModel={{selectedInstitution?.patrimony}} name="patrimony"
                                    #patrimony="ngModel" class="inputLiza" required pInputText>
                                <label for="patrimony">Patrimônio Líquido</label>
                            </span>
                        </div>
                        <mark *ngIf="patrimony.invalid && patrimony.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid">
                        <div class="p-inputgroup">
                            <!-- <span class="p-float-label">
                                <p-inputNumber ngModel={{selectedInstitution?.lastYearInvoicing}} inputId="lastYearInvoicing"
                                    class="inputLiza" mode="currency" currency="BRL" name="lastYearInvoicing"
                                    #lastYearInvoicing="ngModel">
                                </p-inputNumber>
                                <label for="lastYearInvoicing">Faturamento últimos 12 meses</label>
                            </span> -->
                            <span class="p-float-label">
                                <input id="lastYearInvoicing" type="text" ngModel={{selectedInstitution?.lastYearInvoicing}} name="lastYearInvoicing"
                                    #lastYearInvoicing="ngModel" class="inputLiza" required pInputText>
                                <label for="lastYearInvoicing">Faturamento últimos 12 meses</label>
                            </span>
                        </div>
                        <mark *ngIf="lastYearInvoicing.invalid && lastYearInvoicing.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid">
                        <div class="p-inputgroup">
                            <!-- <span class="p-float-label">
                                <p-inputNumber ngModel={{selectedInstitution?.shareCapital}} inputId="shareCapital"
                                    class="inputLiza" mode="currency" currency="BRL" name="shareCapital"
                                    #shareCapital="ngModel">
                                </p-inputNumber>
                                <label for="shareCapital">Capital Social</label>
                            </span> -->
                            <span class="p-float-label">
                                <input id="shareCapital" type="text" ngModel={{selectedInstitution?.shareCapital}} name="shareCapital"
                                    #shareCapital="ngModel" class="inputLiza" required pInputText>
                                <label for="shareCapital">Capital Social</label>
                            </span>
                        </div>
                        <mark *ngIf="shareCapital.invalid && shareCapital.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="nire" type="text" ngModel={{selectedInstitution?.nire}}
                                    name="nire" #nire="ngModel" class="inputLiza" pInputText>
                                <label for="nire">NIRE</label>
                            </span>
                        </div>
                        <mark *ngIf="nire.invalid && nire.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>


                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="establishmentDate" name="dateEstablishment"
                                    yearRange="1920:2099" (onSelect)="changeEstablishmentDate($event)" dataType="string"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yyyy"
                                    [yearNavigator]="true" [required]="true" #dateEstablishment="ngModel"
                                    [required]="true">
                                </p-calendar>
                                <label for="dateEstablishment">Data de constituição</label>
                            </span>
                        </div>
                        <mark *ngIf="dateEstablishment.invalid && dateEstablishment.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">CNAE</p>
                        <p>{{selectedInstitution?.cnaeCode}}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Descrição CNAE</p>
                        <p>{{selectedInstitution?.cnaeDescription}}</p>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <p class="text-bold">Natureza legal</p>
                        <p>{{selectedInstitution?.legalNatureCode}}</p>
                    </div>

                    <!-- <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="ambimaTargetGroup" type="text"
                                    ngModel={{selectedInstitution?.currentCustomProperty?.ambimaTargetGroup}}
                                    name="ambimaTargetGroup" #ambimaTargetGroup="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="ambimaTargetGroup">Público Alvo ANBIMA</label>
                            </span>
                        </div>
                        <mark *ngIf="ambimaTargetGroup.invalid && ambimaTargetGroup.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div> -->
                    <!-- <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="stiCode" type="text" ngModel={{selectedFund?.currentCustomProperty?.stiCode}}
                                    name="stiCode" #stiCode="ngModel" class="inputLiza" required pInputText>
                                <label for="stiCode">Código STI/ Galgo</label>
                            </span>
                        </div>
                        <mark *ngIf="stiCode.invalid && stiCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div> -->
                    <!-- <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="giinNumber" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.giinNumber}} name="giinNumber"
                                    #giinNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                        <mark *ngIf="giinNumber.invalid && giinNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div> -->
                    <!-- <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="cetipAccount" type="text"
                                    ngModel={{selectedFund?.currentCustomProperty?.cetipAccount}} name="cetipAccount"
                                    #cetipAccount="ngModel" class="inputLiza" required pInputText>
                                <label for="cetipAccount">Conta CETIP</label>
                            </span>
                        </div>
                        <mark *ngIf="cetipAccount.invalid && cetipAccount.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div> -->



                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações da Conta</div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankName" type="text" ngModel={{selectedInstitution?.bankName}}
                                    name="bankName" #bankName="ngModel" class="inputLiza" required pInputText>
                                <label for="bankName">Nome do banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankName.invalid && bankName.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" ngModel={{selectedInstitution?.bankCode}}
                                    name="bankCode" #bankCode="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Número do banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankCode.invalid && bankCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="agencyNumber" type="text"
                                    ngModel={{selectedInstitution?.agencyNumber}} name="agencyNumber"
                                    #agencyNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="agencyNumber">Agência</label>
                            </span>
                        </div>
                        <mark *ngIf="agencyNumber.invalid && agencyNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountNumber" type="text"
                                    ngModel={{selectedInstitution?.accountNumber}} name="accountNumber"
                                    #accountNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="accountNumber">Conta corrente</label>
                            </span>
                        </div>
                        <mark *ngIf="accountNumber.invalid && accountNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountDigit" type="text"
                                    ngModel={{selectedInstitution?.accountDigit}} name="accountDigit"
                                    #accountDigit="ngModel" class="inputLiza" required pInputText>
                                <label for="accountDigit">Dígito</label>
                            </span>
                        </div>
                        <mark *ngIf="accountDigit.invalid && accountDigit.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <br>
                <br>

                <br>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                            (click)="onSave(fundEditForm)"></button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>