import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { CancelOperationRequest } from 'src/app/services/operations/models/request/cancel-operation-request';
import { GetOperationsByFilterRequest } from 'src/app/services/operations/models/request/get-operations-by-filter-request';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { OperationStatusEnum } from '../../../../enums/operation-status-enum';

@Component({
    templateUrl: './operation-consult.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class OperationConsultComponent implements OnInit {

    request: GetOperationsByFilterRequest = null;
    operationTypes: DefaultEnumViewModel[] = [];
    operationStatus: SelectItem[] = [];
    funds: FundViewModel[] = [];
    shareholderFunds: SelectItem[] = [];
    shareholderFundsResult: string[] = [];
    shareholderFundsDocumentResult: string[] = [];
    investedFunds: SelectItem[] = [];
    investedFundsResult: string[] = [];
    investedFundsDocumentResult: string[] = [];
    fundManagers: SelectItem[] = [];
    fundAdministrators: SelectItem[] = [];
    fundDistributorDocumentNumbers: SelectItem[] = [];
    operations: OperationViewModel[] = [];
    operationsExport: any[] = [];
    firstPage: number = 0;
    // labelDateOperationStart: string = 'Data de entrada inicial';
    // labelDateOperationEnd: string = 'Data de entrada final';

    selectedStatusOperation: SelectItem = null;
    selectedShareholderFundType: string = null;
    selectedInvestedFundType: string = null;
    selectedShareholderFund: string = null;
    selectedShareholderFundDocument: string = null;
    selectedInvestedFund: string = null;
    selectedInvestedFundDocument: string = null;
    cols: any[] = [];

    // typeUser: string = null;
    selectedOperation: OperationViewModel = null;
    displayOperationDetail: boolean = false;
    displayOperationCancel: boolean = false;
    displayReasonOperation: boolean = false;
    displayExportFull: boolean = false;
    selectedOperationCancel: OperationViewModel = null;
    selectedOperationDisplay: OperationViewModel = null;
    requestOperationCancel = null;
    selectedOperationSituation: string = null;
    user: UserViewModel = null;

    public operationStatusEnum = OperationStatusEnum;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    hasOperationTypeFundOrigin: boolean = false;
    selectedDestinationShareholderFundType: string = null;
    selectedDestinationShareholderFund: string = null;
    selectedDestinationShareholderFundDocument: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private operationService: OperationService,
        private fundService: FundService,
        private fundManagerService: FundManagerService,
        private fundAdministratorService: FundAdministratorService,
        private contextService: ContextService,
        private datePipe: DatePipe,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.setForms();
        this.getOperationStatus();
        this.setFormCancelOperation();
        await this.getOperationTypes();
        await this.getFunds();
        await this.getManagers();
        await this.getAdministrators();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async consult() {

        this.operations = [];
        const request: GetOperationsByFilterRequest = {
            operationSequentialId: this.request.operationSequentialId ? Number(this.request.operationSequentialId) : null,
            operationTypeId: this.request.operationTypeId ? Number(this.request.operationTypeId) : null,
            operationStatusId: this.request.operationStatusId ? Number(this.request.operationStatusId) : null,
            shareholderFundId: this.request.shareholderFundId,
            investedFundId: this.request.investedFundId,
            investedFundManagerId: this.request.investedFundManagerId,
            investedFundAdministradorId: this.request.investedFundAdministradorId,
            investedFundDistributorDocumentNumber: this.request.investedFundDistributorDocumentNumber,
            startDate: this.request.startDate,
            endDate: this.request.endDate,
            destinationShareholderFundId: this.request.destinationShareholderFundId,
        }
        try {
            this.loadingService.show();
            this.operations = await this.operationService.getOperationsByFilter(request);
            this.operationsExport = this.operations.map(operation => {
                return {
                    'ID': operation?.sequentialId,
                    'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                    'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                        operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                        this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Operação': operation?.type?.friendlyName,
                    'Gestor': operation?.investedFund?.manager.name,
                    'Fundo Cotista': `${operation?.shareholderFund?.name}`,
                    'CNPJ F. Cotista': `${operation?.shareholderFund?.documentNumber}`,
                    'Fundo Investido': `${operation?.investedFund?.name}`,
                    'CNPJ F. Investido': `${operation?.investedFund?.documentNumber}`,
                    'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Status': `${operation?.currentStatus?.status?.friendlyName}`

                }
            });
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: `${error.messageError}`, life: 5000 });
        } finally {
            // this.setForms();
            this.loadingService.hide();
        }
    }

    openFullConsult() {
        this.displayExportFull = true;
        this.selectedOperationSituation = null;
    }


    existOperationParams(): boolean {
        if (
            this.request.operationSequentialId == null &&
            this.request.operationStatusId == null &&
            this.request.shareholderFundId == null &&
            this.request.investedFundId == null &&
            this.request.investedFundManagerId == null &&
            this.request.investedFundAdministradorId == null &&
            this.request.investedFundDistributorDocumentNumber == null &&
            this.request.startDate == null &&
            this.request.operationTypeId == null &&
            this.request.endDate == null &&
            this.selectedShareholderFundType == null &&
            this.selectedInvestedFundType == null) {
            return false
        } else {
            return true
        }
    }

    async exportAllSelectedOperation() {
        this.operations = [];
        const request: GetOperationsByFilterRequest = {
            operationSequentialId: null,
            operationTypeId: null,
            operationStatusId: null,
            shareholderFundId: null,
            investedFundId: null,
            investedFundManagerId: null,
            investedFundAdministradorId: null,
            investedFundDistributorDocumentNumber: null,
            startDate: null,
            endDate: null,
            destinationShareholderFundId: null,
        }
        try {
            this.loadingService.show();
            this.displayExportFull = false;
            this.operations = await this.operationService.getOperationsByFilter(request);
            if (this.selectedOperationSituation == 'progress') {
                this.operations = this.operations.filter(fill =>
                    fill.currentStatus.status.value !== 8 &&
                    fill.currentStatus.status.value !== this.operationStatusEnum.Cancelada)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Gestor': operation.investedFund.manager.name,
                        'Fundo Cotista': `${operation.shareholderFund?.name}`,
                        'CNPJ F. Cotista': `${operation.shareholderFund.documentNumber}`,
                        'Fundo Investido': `${operation.investedFund.name}`,
                        'CNPJ F. Investido': `${operation.investedFund.documentNumber}`,
                        'Gestora': `${operation.investedFund.manager.name}`,
                        'CNPJ G.': `${operation.investedFund.manager.documentNumber}`,
                        'Administradora': `${operation.investedFund.administrator.name}`,
                        'CNPJ A.': `${operation.investedFund.administrator.documentNumber}`,
                        'Distribuidora': `${operation.investedFund.fundDistributorName}`,
                        'CNPJ D.': `${operation.investedFund.fundDistributorDocumentNumber}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`

                    }
                });

            } else if (this.selectedOperationSituation == 'completed') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Concluída)
                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Gestor': operation.investedFund.manager.name,
                        'Fundo Cotista': `${operation.shareholderFund?.name}`,
                        'CNPJ F. Cotista': `${operation.shareholderFund.documentNumber}`,
                        'Fundo Investido': `${operation.investedFund.name}`,
                        'CNPJ F. Investido': `${operation.investedFund.documentNumber}`,
                        'Gestora': `${operation.investedFund.manager.name}`,
                        'CNPJ G.': `${operation.investedFund.manager.documentNumber}`,
                        'Administradora': `${operation.investedFund.administrator.name}`,
                        'CNPJ A.': `${operation.investedFund.administrator.documentNumber}`,
                        'Distribuidora': `${operation.investedFund.fundDistributorName}`,
                        'CNPJ D.': `${operation.investedFund.fundDistributorDocumentNumber}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`

                    }
                });

            } else if (this.selectedOperationSituation == 'cancelled') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Cancelada)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Gestor': operation.investedFund.manager.name,
                        'Fundo Cotista': `${operation.shareholderFund?.name}`,
                        'CNPJ F. Cotista': `${operation.shareholderFund.documentNumber}`,
                        'Fundo Investido': `${operation.investedFund.name}`,
                        'CNPJ F. Investido': `${operation.investedFund.documentNumber}`,
                        'Gestora': `${operation.investedFund.manager.name}`,
                        'CNPJ G.': `${operation.investedFund.manager.documentNumber}`,
                        'Administradora': `${operation.investedFund.administrator.name}`,
                        'CNPJ A.': `${operation.investedFund.administrator.documentNumber}`,
                        'Distribuidora': `${operation.investedFund.fundDistributorName}`,
                        'CNPJ D.': `${operation.investedFund.fundDistributorDocumentNumber}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`

                    }
                });

            } else if (this.selectedOperationSituation == 'all') {

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID': operation.sequentialId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Gestor': operation.investedFund.manager.name,
                        'Fundo Cotista': `${operation.shareholderFund?.name}`,
                        'CNPJ F. Cotista': `${operation.shareholderFund.documentNumber}`,
                        'Fundo Investido': `${operation.investedFund.name}`,
                        'CNPJ F. Investido': `${operation.investedFund.documentNumber}`,
                        'Gestora': `${operation.investedFund.manager.name}`,
                        'CNPJ G.': `${operation.investedFund.manager.documentNumber}`,
                        'Administradora': `${operation.investedFund.administrator.name}`,
                        'CNPJ A.': `${operation.investedFund.administrator.documentNumber}`,
                        'Distribuidora': `${operation.investedFund.fundDistributorName}`,
                        'CNPJ D.': `${operation.investedFund.fundDistributorDocumentNumber}`,
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`

                    }
                });
            }

            this.exportExcel();
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async getOperationTypes() {
        this.operationTypes = await this.operationService.getOperationTypes();
    }

    async getFunds() {
        this.funds = await this.fundService.getAllFunds(null);

        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        let investedFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);

        shareholderFundsFilter.map(fund => {
            this.shareholderFunds.push({ label: fund.name, value: fund.id });
            this.shareholderFunds = this.shareholderFunds.sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                } else {
                    return -1
                }
            });
        });

        investedFundsFilter.map(fund => {
            this.investedFunds.push({ label: fund.name, value: fund.id });
            this.investedFunds = this.investedFunds.sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                } else {
                    return -1
                }
            });
        });

        investedFundsFilter.map(fund => {
            this.fundDistributorDocumentNumbers.push({ label: `${fund.fundDistributorName} - ${this.parseCNPJ(fund.documentNumber)}`, value: fund.fundDistributorDocumentNumber });
            this.fundDistributorDocumentNumbers = this.fundDistributorDocumentNumbers.sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                } else {
                    return -1
                }
            });
        });
    }

    async getManagers() {
        let isActive = true;
        let managers = await this.fundManagerService.getFundManagers(isActive);
        managers.map(manager => {
            this.fundManagers.push({ label: manager.name, value: manager.id });
            this.fundManagers = this.fundManagers.sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                } else {
                    return -1
                }
            })
        });
    }

    async getAdministrators() {
        let isActive = true;
        let administrators = await this.fundAdministratorService.getAll(isActive);
        administrators.map(administrator => {
            this.fundAdministrators.push({ label: `${administrator.name} - ${this.parseCNPJ(administrator.documentNumber)}`, value: administrator.id });
            this.fundAdministrators = this.fundAdministrators.sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                } else {
                    return -1
                }
            });
        })
    }

    getOperationStatus() {
        this.operationStatus = [
            { label: 'Em Setup', value: OperationStatusEnum.EmSetup },
            { label: 'Preenchimento Manual Necessário', value: OperationStatusEnum.PreenchimentoManualNecessario },
            { label: 'Preenchimento Concluído', value: OperationStatusEnum.PreenchimentoConcluido },
            { label: 'Aguardando Assinatura', value: OperationStatusEnum.AguardandoAssinatura },
            { label: 'Em Análise Pelo Gestor', value: OperationStatusEnum.EmAnalisePeloGestor },
            { label: 'Pendenciada Pelo Gestor', value: OperationStatusEnum.PendenciadaPeloGestor },
            { label: 'Cancelada', value: OperationStatusEnum.Cancelada },
            { label: 'Concluído', value: OperationStatusEnum.Concluída },
            { label: 'Erro Cadastral', value: OperationStatusEnum.ErroCadastral },
            { label: 'Erro de Preenchimento', value: OperationStatusEnum.ErroDePreenchimento },
        ]
    }

    setForms() {
        this.request = {
            operationSequentialId: null,
            operationTypeId: null,
            operationStatusId: null,
            shareholderFundId: null,
            investedFundId: null,
            investedFundManagerId: null,
            investedFundAdministradorId: null,
            investedFundDistributorDocumentNumber: null,
            startDate: null,
            endDate: null,
            destinationShareholderFundId: null,
        }
    }

    onClear() {
        this.setForms();
        this.selectedShareholderFund = null;
        this.selectedShareholderFundDocument = null;
        this.selectedInvestedFund = null;
        this.selectedInvestedFundDocument = null;
        this.selectedDestinationShareholderFund = null;
        this.selectedDestinationShareholderFundDocument = null;
        this.selectedShareholderFundType = null;
        this.selectedInvestedFundType = null;
        this.selectedDestinationShareholderFundType = null;
        this.request.investedFundId = null;
        this.request.shareholderFundId = null;
        this.request.destinationShareholderFundId = null;
        this.hasOperationTypeFundOrigin = null;
    }

    clearFunds() {
        this.selectedShareholderFund = null;
        this.selectedShareholderFundDocument = null;
        this.selectedInvestedFund = null;
        this.selectedInvestedFundDocument = null;
        this.selectedDestinationShareholderFund = null;
        this.selectedDestinationShareholderFundDocument = null;
        this.request.investedFundId = null;
        this.request.shareholderFundId = null;
        this.request.destinationShareholderFundId = null;
    }



    // getMenuItems(operation: OperationViewModel): MenuItem[] {
    //     return [
    // { label: 'Ver Operação', command: (e) => this.viewOperation(operation) }
    //     ];
    // }

    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
        this.displayExportFull = false;
    }

    async operationDetails(operation: OperationViewModel) {
        console.log(operation)
        this.displayOperationDetail = true;
        // this.selectedOperation = operation;
        try {
            // this.loadingService.show();
            const operationId = operation.id;
            this.selectedOperation = await this.operationService.getOperationById(operationId);
            // this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationDetail = false;
        }
    }

    async retryOperation(operation: OperationViewModel) {
        try {
            this.loadingService.show();
            const operationId = operation.id;
            await this.operationService.retriesOperation(operationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.consult();

            this.loadingService.hide();
        }
    }

    cancelOperation(operation: OperationViewModel) {
        this.displayOperationCancel = true;
        this.selectedOperationCancel = operation;
    }

    reasonOperation(operation: OperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedOperationDisplay = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayOperationCancel = false;
            this.loadingService.show();
            const request: CancelOperationRequest = {
                operationId: this.selectedOperationCancel.id,
                notes: this.requestOperationCancel.notes,
            };
            this.selectedOperation = await this.operationService.cancelOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelOperation();
            await this.consult();
            this.loadingService.hide();
        }
    }

    getMenuItems(operation): MenuItem[] {
        if (operation?.currentStatus?.status?.value == OperationStatusEnum?.ErroCadastral && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum?.ErroDePreenchimento && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum?.Concluída && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.viewOperation(operation) },
                // { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum?.Cancelada && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum?.EmSetup && !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Iniciar', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido ||
            operation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (!this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.viewOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) }
            ]
        }

    }


    viewOperation(operation: OperationViewModel) {
        const url = window.location.origin
        window.open(`${url}/#/app/customer/operation/setup/${operation.id}`, '_blank');
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return x ? !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '') : '';
    }

    reset() {
        this.firstPage = 0
    }


    // handleStatusOperation(value: number) {
    //     console.log(this.selectedStatusOperation)
    //     if (value == OperationStatusEnum.Cancelada || value == OperationStatusEnum.Concluída) {
    //         this.labelDateOperationStart = 'Data de finalização inicial';
    //         this.labelDateOperationEnd = 'Data de finalização final';
    //     } else {
    //         this.labelDateOperationStart = 'Data de entrada inicial';
    //         this.labelDateOperationEnd = 'Data de entrada final';
    //     }
    // }

    handleTypeFund(event: string) {
        this.request.investedFundId = null;
        this.request.shareholderFundId = null;
        this.request.destinationShareholderFundId = null;
    }

    searchInvestedFund(query: string) {
        this.investedFundsResult = [];
        this.investedFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsResult.push(property);
                return
            }
        })
    }

    searchInvestedFundDocument(query: string) {
        this.investedFundsDocumentResult = [];
        let investedFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);
        investedFundsFilter.forEach(item => {
            const property = item.documentNumber;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsDocumentResult.push(property);
                return
            }
        })
    }

    onInvestedFund(search: string) {
        this.investedFunds.filter(fill => fill.label == search).forEach(item => {
            this.request.investedFundId = item.value
        })
    }

    onInvestedFundDocument(search: string) {
        let investedFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);
        investedFundsFilter.filter(fill => fill.documentNumber == search).forEach(item => {
            this.request.investedFundId = item.id
        })
    }


    searchShareholderFund(query: string) {
        this.shareholderFundsResult = [];
        this.shareholderFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.shareholderFundsResult.push(property);
                return
            }
        })
    }

    searchShareholderFundDocument(query: string) {
        this.shareholderFundsDocumentResult = [];
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.forEach(item => {
            const documentNumber = item.documentNumber;
            if (documentNumber.includes(query) ||
                documentNumber.toLowerCase().includes(query) ||
                documentNumber.toUpperCase().includes(query) ||
                documentNumber.toLocaleLowerCase().includes(query) ||
                documentNumber.toLocaleUpperCase().includes(query)) {
                this.shareholderFundsDocumentResult.push(documentNumber);
                return
            }
        })
    }

    onShareholderFund(search: string) {
        this.shareholderFunds.filter(fill => fill.label == search).forEach(item => {
            this.request.shareholderFundId = item.value
        })
    }

    onShareholderFundDocument(search: string) {
        console.log(search)
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.filter(fill => fill.documentNumber == search).forEach(item => {
            this.request.shareholderFundId = item.id
        })
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.operationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    breakTextLine(notes: string) {
        if (notes.includes('/n') || notes.includes('\n\n')) {
            notes = notes.split('\n' || '\n\n').join('<br>');
            return notes;
        }
        return notes;
    }

    handleOperationType(operationType: number) {
        if (operationType == OperationTypeEnum.CessaoDeCotasCompra ||
            operationType == OperationTypeEnum.CessaoDeCotasDoacao ||
            operationType == OperationTypeEnum.Incorporacao ||
            operationType == OperationTypeEnum.Cisao ||
            operationType == OperationTypeEnum.Integralizacao ||
            operationType == OperationTypeEnum.Masterizacao) {
            this.hasOperationTypeFundOrigin = true;
        } else {
            this.hasOperationTypeFundOrigin = false;
            this.selectedDestinationShareholderFundType = null;
        }
    }

    onDestinationShareholderFund(search: string) {
        this.shareholderFunds.filter(fill => fill.label == search).forEach(item => {
            this.request.destinationShareholderFundId = item.value
        })
    }

    onDestinationShareholderFundDocument(search: string) {
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.filter(fill => fill.documentNumber == search).forEach(item => {
            this.request.destinationShareholderFundId = item.id
        })
    }

}