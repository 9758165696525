<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Visualizar Gestora
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined" (click)="onCancel()"></button>
                    <button *ngIf="!isUserOperator" pButton pRipple type="button" label="Excluir" class="p-button-outlined-blue" (click)="confirm(selectedFundManager)"></button>
                </div>
            </div>
            <div class="rowForm flexRow">
                <div class="cardHeader text-bold">Informações</div>
                <br>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-8 p-lg-8">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input id="name" type="text" ngModel={{selectedFundManager?.name}} name="name"
                                #name="ngModel" class="inputLiza" required pInputText readonly>
                            <label for="name">Gestora</label>
                        </span>
                    </div>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" class="inputLiza" name="documentNumber"
                                ngModel={{selectedFundManager?.documentNumber}} name="documentNumber" readonly
                                #documentNumber="ngModel" pInputText readonly>
                            <label for="documentNumber">CNPJ</label>
                        </span>
                    </div>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" class="inputLiza" name="giinNumber"
                                ngModel={{selectedFundManager?.giinNumber}} name="giinNumber" readonly
                                #giinNumber="ngModel" pInputText readonly>
                            <label for="giinNumber">Número GIIN</label>
                        </span>
                    </div>
                </div>

            </div>
            <br>
            <div class="rowForm flexRow">
                <div class="cardHeader text-bold">Endereço</div>
                <br>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Endereço: </p>{{ selectedFundManager?.addressStreet }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Bairro: </p>{{ selectedFundManager?.addressNeighborhood }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Cidade: </p>{{ selectedFundManager?.addressCity }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">UF: </p>{{ selectedFundManager?.addressState }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">CEP: </p>{{ selectedFundManager?.addressZipCode }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Número: </p>{{ selectedFundManager?.addressNumber }}
                </div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Complemento: </p>{{ selectedFundManager?.addressComplement }}
                </div>
            </div>
            <br>
            <div class="p-grid" *ngIf="contatcsOfManager">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Contatos do Gestora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Nome</p>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">CPF</p>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">E-mail</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">Data de Validade</p>
                        </div>
                    </div>
                    <form #managerForm="ngForm" novalidate>
                        <div *ngFor="let rep of contatcsOfManager" class="p-grid">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                {{ rep?.name}}
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                {{ rep?.maskedPersonalDocumentNumber}}
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                {{ rep?.email}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2">
                                {{ rep?.expireDate | date:
                                'dd/MM/yyyy' }}
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="line"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>