<div class="p-grid" *ngIf="selectedOperation">
    <div class="p-col-12 p-md-3 p-lg-3" *ngFor="let file of selectedOperation?.attachments; let i = index">
        <div class="customCardOutlined">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Data</p>
                    <p>{{file?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Descrição</p>
                    <p>{{file?.description}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Usuário</p>
                    <b style="color: var(--primary-color);" *ngIf="file?.user !== null">{{file?.user?.fullName}}</b>
                    <b style="color: #140083;" *ngIf="file?.contact !== null">{{file?.contact?.name}}</b>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div style="display: flex; gap: 0.5rem;">
                        <button (click)="openArtefactUrl(file)" pButton pRipple type="button" label="Ver"
                            class="action-button"></button>

                        <button *ngIf="canRemoveFile" pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined" (click)="removeArtfact(file)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="p-grid" style="margin-left: 0.1rem;">
            <div class="col-12 col-md-4 col-lg-4" [hidden]="hiddenButtons">
                <button (click)="openAddFile()" pButton pRipple type="button" label="Adicionar anexo"
                    class="action-button p-ml-2"></button>
            </div>
            <div class="col-12 col-md-4 col-lg-4"
                *ngIf="!hasFundRegulationFile && (selectedOperation?.currentStatus?.status?.value == operationStatusEnum.EmAnalisePeloGestor || selectedOperation?.currentStatus?.status?.value == operationStatusEnum.PendenciadaPeloGestor)">
                <button (click)="onOperationUploadFileFundRegulation()" pButton pRipple type="button"
                    label="Adicionar Regulamento do Fundo" class="p-button-outlined p-ml-2"></button>
            </div>
        </div>
    </div>
</div>

<!-- Broker Operation -->


<div class="p-grid" *ngIf="selectedBrokerOperation">
    <div class="p-col-12 p-md-3 p-lg-3" *ngFor="let file of selectedBrokerOperation?.attachments; let i = index">
        <div class="customCardOutlined">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Data</p>
                    <p>{{file?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Descrição</p>
                    <p>{{file?.description}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p class="text-bold">Usuário</p>
                    <b style="color: var(--primary-color);" *ngIf="file?.user !== null">{{file?.user?.fullName}}</b>
                    <b style="color: #140083;" *ngIf="file?.representative !== null">{{file?.representative?.name}}</b>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div style="display: flex; gap: 0.5rem;">
                        <button (click)="openArtefactUrl(file)" pButton pRipple type="button" label="Ver"
                            class="action-button"></button>

                        <button *ngIf="canRemoveFile" pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined" (click)="removeArtfact(file)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-col-md-12 p-col-lg-12">
        <div class="p-grid" style="margin-left: 0.1rem;">
            <div class="col-12 col-md-4 col-lg-4" [hidden]="hiddenButtons">
                <button (click)="openAddFile()" pButton pRipple type="button" label="Adicionar anexo"
                    class="action-button p-ml-2"></button>
            </div>
            <div class="col-12 col-md-4 col-lg-4"
                *ngIf="!hasBrokerFundRegulationFile && (selectedBrokerOperation?.currentStatus?.status?.value == brokerOperationStatusEnum.EmAnalisePelaCorretora || selectedBrokerOperation?.currentStatus?.status?.value == brokerOperationStatusEnum.PendenciadaPelaCorretora)">
                <button (click)="onBrokerUploadFileFundRegulation()" pButton pRipple type="button"
                    label="Adicionar Regulamento do Fundo" class="p-button-outlined p-ml-2"></button>
            </div>
        </div>
    </div>
</div>