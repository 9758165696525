import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { AddressFormModule } from 'src/app/modules/address-form/address-form.module';
import { ArtifactTemplateModule } from 'src/app/modules/artifact-template/artifact-template.module';
import { AssessmentModule } from 'src/app/modules/assessment/assessment.module';
import { AttachmentModule } from 'src/app/modules/attachment/attachment.module';
import { BrokerSignersModule } from 'src/app/modules/broker-signers/broker-signers.module';
import { FileUploaderModule } from 'src/app/modules/fileuploader/fileuploader.module';
import { FillTemplatesModule } from 'src/app/modules/fill-templates/fill-template-create/fill-templates.module';
import { InvestedFundModule } from 'src/app/modules/invested-fund/invested-fund.module';
import { LegalRepresentativeModule } from 'src/app/modules/legal-representative/legal-representative.module';
import { ContactManagerModule } from 'src/app/modules/manager-contacts/contact-manager.module';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { ContactBrokerModule } from "../../modules/broker-contacts/contact-broker.module";
import { UserEditComponent } from './account/user/user-edit/user-edit.component';
import { UserInviteComponent } from './account/user/user-invite/user-invite.component';
import { UserListComponent } from './account/user/user-list/user-list.component';
import { UserViewComponent } from './account/user/user-view/user-view.component';
import { BrokerHomeComponent } from './broker-home/broker-home.component';
import { BrokerOperationConsultComponent } from './broker-operation/broker-operation-consult/broker-operation-consult.component';
import { BrokerOperationListComponent } from './broker-operation/broker-operation-list/broker-operation-list.component';
import { BrokerOperationReportComponent } from './broker-operation/broker-operation-report/broker-operation-report.component';
import { BrokerOperationSetupComponent } from './broker-operation/broker-operation-setup/broker-operation-setup.component';
import { CustomerRoutingModule } from './customer.routing.module';
import { FinancialInstitutionCreateComponent } from './financial-institution/financial-institution-create/financial-institution-create.component';
import { FinancialInstitutionEditComponent } from './financial-institution/financial-institution-edit/financial-institution-edit.component';
import { FinancialInstitutionListComponent } from './financial-institution/financial-institution-list/financial-institution-list.component';
import { FinancialInstitutionViewComponent } from './financial-institution/financial-institution-view/financial-institution-view.component';
import { FundAdministratorCreateComponent } from './fund-administrator/fund-administrator-create/fund-administrator-create.component';
import { FundAdministratorEditFormTemplateComponent } from './fund-administrator/fund-administrator-edit-form-template/fund-administrator-edit-form-template.component';
import { FundAdministratorEditComponent } from './fund-administrator/fund-administrator-edit/fund-administrator-edit.component';
import { FundAdministratorListComponent } from './fund-administrator/fund-administrator-list/fund-administrator-list.component';
import { FundAdministratorOperationSetupComponent } from './fund-administrator/fund-administrator-operation-setup/fund-administrator-operation-setup.component';
import { FundAdministratorProcuratorComponent } from './fund-administrator/fund-administrator-procurator/fund-administrator-procurator.component';
import { FundAdministratorViewComponent } from './fund-administrator/fund-administrator-view/fund-administrator-view.component';
import { FundBrokerCreateComponent } from './fund-broker/fund-broker-create/fund-broker-create.component';
import { FundBrokerDeskConfigsComponent } from './fund-broker/fund-broker-desk-configs/fund-broker-desk-configs.component';
import { FundBrokerEditFormTemplateComponent } from './fund-broker/fund-broker-edit-form-template/fund-broker-edit-form-template.component';
import { FundBrokerEditValidationComponent } from './fund-broker/fund-broker-edit-validation/fund-broker-edit-validation.component';
import { FundBrokerEditComponent } from './fund-broker/fund-broker-edit/fund-broker-edit.component';
import { FundBrokerEmailCompletionViewComponent } from './fund-broker/fund-broker-email-completion-view/fund-broker-email-completion-view.component';
import { FundBrokerEmailCompletionComponent } from './fund-broker/fund-broker-email-completion/fund-broker-email-completion.component';
import { FundBrokerListComponent } from './fund-broker/fund-broker-list/fund-broker-list.component';
import { FundBrokerOperationSetupComponent } from './fund-broker/fund-broker-operation-setup/fund-broker-operation-setup.component';
import { FundBrokerProcuratorComponent } from './fund-broker/fund-broker-procurator/fund-broker-procurator.component';
import { FundBrokerViewComponent } from './fund-broker/fund-broker-view/fund-broker-view.component';
import { FundManagerCreateComponent } from './fund-manager/fund-manager-create/fund-manager-create.component';
import { FundManagerEditComponent } from './fund-manager/fund-manager-edit/fund-manager-edit.component';
import { FundManagerListComponent } from './fund-manager/fund-manager-list/fund-manager-list.component';
import { FundManagerViewComponent } from './fund-manager/fund-manager-view/fund-manager-view.component';
import { FundCreateComponent } from './fund/fund-create/fund-create.component';
import { FundEditComponent } from './fund/fund-edit/fund-edit.component';
import { FundListComponent } from './fund/fund-list/fund-list.component';
import { FundViewComponent } from './fund/fund-view/fund-view.component';
import { HomeComponent } from './home/home.component';
import { IntegratedOperationListComponent } from './integrated-operation/integrated-operation-list/integrated-operation-list.component';
import { IntegrationCreateComponent } from './integration/integration-create/integration-create.component';
import { IntegrationListComponent } from './integration/integration-list/integration-list.component';
import { IntegrationViewComponent } from './integration/integration-view/integration-view.component';
import { LiabilityHomeComponent } from './liability-home/liability-home.component';
import { LiabilityOperationConsultComponent } from './liability-operation/liability-operation-consult/liability-operation-consult.component';
import { LiabilityOperationListComponent } from './liability-operation/liability-operation-list/liability-operation-list.component';
import { LiabilityOperationReportComponent } from './liability-operation/liability-operation-report/liability-operation-report.component';
import { OperationConsultComponent } from './operation/operation-consult/operation-consult.component';
import { OperationCreateComponent } from './operation/operation-create/operation-create.component';
import { OperationListComponent } from './operation/operation-list/operation-list.component';
import { OperationReportComponent } from './operation/operation-report/operation-report.component';
import { OperationSetupComponent } from './operation/operation-setup/operation-setup.component';
import { RepresentationCreateComponent } from './representation/representation-create/representation-create.component';
import { RepresentationEditComponent } from './representation/representation-edit/representation-edit.component';
import { RepresentationListComponent } from './representation/representation-list/representation-list.component';
import { RepresentationViewComponent } from './representation/representation-view/representation-view.component';

const COMPONENTS = [
  FundCreateComponent,
  FundEditComponent,
  FundListComponent,
  FundViewComponent,
  FinancialInstitutionCreateComponent,
  FinancialInstitutionEditComponent,
  FinancialInstitutionListComponent,
  FinancialInstitutionViewComponent,
  FundAdministratorCreateComponent,
  FundAdministratorEditComponent,
  FundAdministratorEditFormTemplateComponent,
  FundAdministratorListComponent,
  FundAdministratorOperationSetupComponent,
  FundAdministratorProcuratorComponent,
  FundAdministratorViewComponent,
  FundManagerCreateComponent,
  FundManagerEditComponent,
  FundManagerListComponent,
  FundManagerViewComponent,
  FundBrokerListComponent,
  FundBrokerCreateComponent,
  FundBrokerDeskConfigsComponent,
  FundBrokerViewComponent,
  FundBrokerProcuratorComponent,
  FundBrokerOperationSetupComponent,
  FundBrokerEditFormTemplateComponent,
  FundBrokerEditComponent,
  FundBrokerEditValidationComponent,
  FundBrokerEmailCompletionComponent,
  FundBrokerEmailCompletionViewComponent,
  HomeComponent,
  BrokerHomeComponent,
  LiabilityHomeComponent,
  IntegrationCreateComponent,
  IntegrationListComponent,
  IntegrationViewComponent,
  OperationCreateComponent,
  OperationListComponent,
  OperationSetupComponent,
  OperationConsultComponent,
  OperationReportComponent,
  BrokerOperationListComponent,
  BrokerOperationSetupComponent,
  BrokerOperationConsultComponent,
  BrokerOperationReportComponent,
  LiabilityOperationListComponent,
  LiabilityOperationConsultComponent,
  LiabilityOperationReportComponent,
  RepresentationCreateComponent,
  RepresentationEditComponent,
  RepresentationListComponent,
  RepresentationViewComponent,
  UserInviteComponent,
  UserListComponent,
  UserViewComponent,
  UserEditComponent,
  IntegratedOperationListComponent,
];

@NgModule({
    declarations: COMPONENTS,
    entryComponents: COMPONENTS,
    providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location, DatePipe, DocumentNumberPipe],
    exports: [],
    imports: [
        CustomerRoutingModule,
        CommonModule,
        FormsModule,
        DirectivesModule,
        PipesModule,
        ButtonModule,
        InputTextModule,
        AccordionModule,
        SelectButtonModule,
        InputMaskModule,
        CommonModule,
        FormsModule,
        InputTextModule,
        CalendarModule,
        InputMaskModule,
        TableModule,
        SliderModule,
        PickListModule,
        InputTextareaModule,
        PasswordModule,
        AddressFormModule,
        CheckboxModule,
        ChartModule,
        ToggleButtonModule,
        FileUploaderModule,
        RadioButtonModule,
        SelectButtonModule,
        InputSwitchModule,
        FileUploadModule,
        ListboxModule,
        DividerModule,
        TimelineModule,
        InputNumberModule,
        TabViewModule,
        TooltipModule,
        CarouselModule,
        PanelModule,
        StepsModule,
        ScrollPanelModule,
        CardModule,
        MenuModule,
        DialogModule,
        AddressFormModule,
        FileUploadModule,
        KnobModule,
        GalleriaModule,
        ConfirmDialogModule,
        DropdownModule,
        ChipsModule,
        StepsModule,
        ArtifactTemplateModule,
        LegalRepresentativeModule,
        ContactManagerModule,
        BrokerSignersModule,
        AddressFormModule,
        FillTemplatesModule,
        AttachmentModule,
        AssessmentModule,
        AutoCompleteModule,
        InvestedFundModule,
        ContactBrokerModule,
        MultiSelectModule
    ]
})
export class CustomerModule { }
