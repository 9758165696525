import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { ExtractDocumentVariablesRequest } from 'src/app/services/artifact-templates/models/requests/extract-document-variables-request';
import { DeskConfigViewModel } from 'src/app/services/brokers/models/view-models/desk-config-view-model';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FileUploadResponse } from 'src/app/services/file-uploads/models/responses/file-upload-response';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateFundAdministratorRequest, RepresentativeProperties } from 'src/app/services/fund-administrators/models/requests/create-fund-administrator-request';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { CreateFundBrokerRequest } from '../../../../services/fund-brokers/models/requests/create-fund-broker-request';

@Component({
    templateUrl: './fund-broker-desk-configs.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundBrokerDeskConfigsComponent implements OnInit {

    requestModel: CreateFundBrokerRequest = null;
    representativeRequestForm: RepresentativeProperties = null;
    deskConfigs: File[] = [];
    allDeskConfigs: DeskConfigViewModel[] = [];
    fundBrokerId: string = null;
    fundBroker: FundBrokerViewModel = null;
    // requestFiles = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private fundBrokerService: FundBrokerService,
        private messageService: MessageService,
        private formValidationService: FormValidationService) {
    }

    async ngOnInit() {
        this.setForms()
        this.fundBrokerId = this.route.snapshot.params.id;
        await this.getFundBroker();

    }

    setForms() {
        this.deskConfigs = null;
    }

    async getFundBroker() {
        this.fundBroker = await this.fundBrokerService.getById(this.fundBrokerId);
        this.allDeskConfigs = this.fundBroker.deskConfigs.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
    }


    async deskConfigsTemplate(response: File[]) {
        this.loadingService.show();
        this.deskConfigs = response;
        this.loadingService.hide();

    }

    async onSave() {
        if (this.deskConfigs.length > 0) {
            this.loadingService.show();
            try {
                await this.fundBrokerService.createDeskConfigs(this.fundBrokerId, this.deskConfigs[0]);
                this.messageService.add({ severity: 'success', summary: 'Informação atualizada com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
                this.router.navigateByUrl('app/customer/fund-broker/list');
            } catch (e) {
                this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Tente novamente mais tarde.', life: 5000 });
            }
            finally {
                this.loadingService.hide();
            }
        } else if (this.deskConfigs.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Template não selecionado', life: 5000 });
        }
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund-broker/list');
    }

    // setMainRequestForm() {
    //     this.requestModel = {
    //         name: null,
    //         documentNumber: null,
    //         hasOperationDoneNotification: true,
    //         representatives: [],
    //         registrationFormTemplateRequest: null,
    //         updateFormTemplateRequest: null,
    //         additionTemplateRequest: null,
    //         operationTemplateRequest: null,
    //     };
    // }

    // setRepresentativeForm() {
    //     this.representativeRequestForm = {
    //         name: null,
    //         email: null,
    //         expireDate: null,
    //         personalDocumentNumber: null,
    //     };
    // }

    // addRepresentativeRequest(representativeRequest: RepresentativeProperties, formComponent: NgForm) {
    //     if (!this.formValidationService.validateForm(formComponent.form)) return;

    //     this.requestModel.representatives.push(representativeRequest);
    //     this.setRepresentativeForm();
    //     this.formValidationService.resetFormFields(formComponent.form);
    // }

    // removeRepresentativeRequest(representativeRequest: RepresentativeProperties) {
    //     const indexToRemove = this.requestModel.representatives.findIndex(c => c == representativeRequest);
    //     this.requestModel.representatives.splice(indexToRemove, 1);
    // }

    // registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
    //     this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    // }

    // updateFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
    //     this.requestModel.updateFormTemplateRequest = createArtifactTemplateRequest;
    // }

    // additionTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
    //     this.requestModel.additionTemplateRequest = createArtifactTemplateRequest;
    // }

    // operationTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
    //     this.requestModel.operationTemplateRequest = createArtifactTemplateRequest;
    // }

    // onCancel() {
    //     this.router.navigateByUrl('app/customer/fund-broker/list');
    // }

    // async onSave(formComponent: NgForm) {

    //     if (!this.formValidationService.validateForm(formComponent.form)) return;

    //     if (this.requestModel.registrationFormTemplateRequest == null) {
    //         this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Ficha Cadastral é obrigatório.`, life: 5000 });
    //         return;
    //     }

    //     if (this.requestModel.updateFormTemplateRequest == null) {
    //         this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Ficha Cadastral de atualização é obrigatório.`, life: 5000 });
    //         return;
    //     }

    //     if (this.requestModel.additionTemplateRequest == null) {
    //         this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Aditamento é obrigatório.`, life: 5000 });
    //         return;
    //     }

    //     if (this.requestModel.operationTemplateRequest == null) {
    //         this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template W8 é obrigatório.`, life: 5000 });
    //         return;
    //     }

    //     if (this.requestModel.representatives.length == 0) {
    //         this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `É necessário informar ao menos um representante.`, life: 5000 });
    //         return;
    //     }

    //     // console.log('request', this.requestModel);

    //     this.loadingService.show();
    //     try {
    //         await this.fundBrokerService.createFundBroker(this.requestModel);
    //         this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
    //         this.router.navigateByUrl('app/customer/fund-broker/list');
    //     } catch (error) {
    //         this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Tente novamente mais tarde.', life: 5000 });
    //     } finally {
    //         this.loadingService.hide();
    //     }
    // }
}