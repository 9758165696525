import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { ContextService } from 'src/app/providers/context-service';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { FundManagerViewModel } from 'src/app/services/fund-manager/models/view-models/fund-manager-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';


@Component({
    templateUrl: './fund-manager-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FundManagerListComponent implements OnInit {

    fundManagers: FundManagerViewModel[] = [];
    search: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    // typeUser: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fundManagerService: FundManagerService,
        private loadingService: LoadingService,
        private messageService: MessageService,
        private contextService: ContextService,
        private documentNumberPipe: DocumentNumberPipe,
        private confirmationService: ConfirmationService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.getManagers();
        let filtered = this.route.snapshot.queryParams.search;
        if (filtered) {
            this.globalFilter(filtered);
        }
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async getManagers() {
        let isActive = true;
        this.fundManagers = await this.fundManagerService.getFundManagers(isActive);
        this.fundManagers = this.fundManagers.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })

    }

    confirm(manager: FundManagerViewModel) {
        this.confirmationService.confirm({
            header: `Gestora ${manager.name}`,
            message: `Tem certeza que deseja excluir ${manager.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeManager(manager);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    addManager() {
        return this.router.navigateByUrl(`app/customer/fund-manager/create`);
    }

    editManager(manager: FundManagerViewModel) {
        // return this.router.navigate([`app/customer/fund-manager/edit/${manager.id}`]);
        return this.router.navigate([`app/customer/fund-manager/edit/${manager.id}`], { queryParams: { search: this.search } });
    }

    viewManager(manager: FundManagerViewModel) {
        // return this.router.navigate([`app/customer/fund-manager/view/${manager.id}`]);
        return this.router.navigate([`app/customer/fund-manager/view/${manager.id}`], { queryParams: { search: this.search } })
    }

    async removeManager(manager: FundManagerViewModel) {
        const fundManagerId = manager.id;
        try {
            this.loadingService.show();
            await this.fundManagerService.deleteFundManager(fundManagerId);
            await this.getManagers();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }



    getMenuItems(manager: FundManagerViewModel): MenuItem[] {
        if (this.isUserOperator) {
            return [
                { label: 'Ver', command: e => this.viewManager(manager) },
            ]
        } else if (this.isUserAnalist) {
            return [
                { label: 'Ver', command: e => this.viewManager(manager) },
                { label: 'Editar', command: e => this.editManager(manager) },
            ]
        } else if (this.isUserAdministrator) {
            return [
                { label: 'Ver', command: e => this.viewManager(manager) },
                { label: 'Editar', command: e => this.editManager(manager) },
                { label: 'Excluir', command: e => this.confirm(manager) },
            ]
        }

    }

    async globalFilter(filtered: string) {
        this.search = filtered;
        let filterFundManagers = [];
        if (this.search) {
            this.fundManagers.forEach(item => {
                if (item.name.toUpperCase().includes(filtered) ||
                    item.name.toLowerCase().includes(filtered) ||
                    item.documentNumber.includes(filtered) ||
                    this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                    filterFundManagers.push(item)
                    this.fundManagers = filterFundManagers;
                }
            })
        } else if (!this.search) {
            await this.getManagers();
        }

    }


}