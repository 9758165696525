<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Visualizar Corretora
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Corretora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="borderOutlined">
                        <p><b class="text-bold">Nome:</b> <span> {{fundBroker?.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{fundBroker?.documentNumber |
                                documentNumber:'CNPJ'}}</span></p>
                        <p><b class="text-bold">Email:</b> <span> {{fundBroker?.email}}</span></p>
                        <p><b class="text-bold">Telefone:</b> <span> {{fundBroker?.phoneNumber | phoneNumber }}</span></p>
                        <a (click)="openEditFundBroker()" class="linkPrimary">
                            Editar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="fundBroker?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="fundBroker.registrationFormTemplate.fileUrl" target="_blank" class="linkPrimary">
                            Ver Template
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="fundBroker?.updateFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral de Atualização
                        </div>
                        <a [href]="fundBroker.updateFormTemplate.fileUrl" target="_blank" class="linkPrimary">
                            Ver Template
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="fundBroker?.additionTemplate">
                        <div class="text-bold">
                            Modelo Template de Aditamento
                        </div>
                        <a [href]="fundBroker.additionTemplate.fileUrl" target="_blank" class="linkPrimary">
                            Ver Template
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="fundBroker?.operationTemplate">
                        <div class="text-bold">
                            Modelo Template W8
                        </div>
                        <a [href]="fundBroker.operationTemplate.fileUrl" target="_blank" class="linkPrimary">
                            Ver Template
                        </a>
                    </div>
                </div>

            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                    <div class="cardHeader text-bold">Setups Cadastrados</div>
                    <div class="borderOutlined">
                        <div *ngIf="fundBroker?.operationSetups.length == 0">
                            <span>Não há setups cadastrados</span>
                        </div>
                        <div class="p-grid" *ngIf="fundBroker?.operationSetups.length > 0"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold ">
                                Tipo
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Ficha Cadastral
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Cadastral de Atualização
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Template de Aditamento
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Template W8
                            </div>
                            <div class="p-col-12 p-md-1 p-lg-2 text-bold text-center">
                                Template Extra
                            </div>
                            <!-- <div class="p-col-1 p-md-1 p-lg-1 text-bold">
                                Excluir
                            </div> -->
                        </div>
                        <div *ngFor="let operation of fundBroker?.operationSetups" class="p-grid"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2">
                                {{ operation.operationType.friendlyName}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.hasBrokerRegistrationFormTemplate ? 'Sim' : 'Não'}}</p>
                                
                                <p>{{(operation.hasBrokerRegistrationFormTemplate && operation.isBrokerRegistrationFormTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerRegistrationFormTemplate && !operation.isBrokerRegistrationFormTemplateSignableByRepresentation ? 'Não assinável' : '')}}</p>
                                
                                <p>{{(operation.hasBrokerRegistrationFormTemplate && operation.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerRegistrationFormTemplate && !operation.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative ? 'Corretora: Não assinável' : '')}}</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.hasBrokerUpateFormTemplate ? 'Sim' : 'Não'}}</p>
                                
                                <p>{{(operation.hasBrokerUpateFormTemplate && operation.isBrokerUpdateFormTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerUpateFormTemplate && !operation.isBrokerUpdateFormTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                
                                <p>{{(operation.hasBrokerUpateFormTemplate && operation.isBrokerUpdateFormTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerUpateFormTemplate && !operation.isBrokerUpdateFormTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.hasBrokerAddtionTemplate ? 'Sim' : 'Não'}}</p>
                                
                                <p>{{(operation.hasBrokerAddtionTemplate && operation.isBrokerAddtionTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerAddtionTemplate && !operation.isBrokerAddtionTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                
                                <p>{{(operation.hasBrokerAddtionTemplate && operation.isBrokerAddtionTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerAddtionTemplate && !operation.isBrokerAddtionTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.hasBrokerOperationTemplate ? 'Sim' : 'Não'}}</p>
                                
                                <p>{{(operation.hasBrokerOperationTemplate && operation.isBrokerOperationTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerOperationTemplate && !operation.isBrokerOperationTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                
                                <p>{{(operation.hasBrokerOperationTemplate && operation.isBrokerOperationTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                <p>{{(operation.hasBrokerOperationTemplate && !operation.isBrokerOperationTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.extraTemplate != null ? 'Sim' : 'Não'}}</p>
                                
                                <p>{{(operation.extraTemplate && operation.isExtraTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                <p>{{(operation.extraTemplate && !operation.isExtraTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                
                                <p>{{(operation.extraTemplate && operation.isExtraTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                <p>{{(operation.extraTemplate && !operation.isExtraTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                                <a class="textPrimary" [href]="operation.extraTemplate?.fileUrl">{{
                                    operation.extraTemplate?.fileUrl != null ? 'Ver template' : ''}}</a>
                            </div>
                            <!-- <div class="p-col-12 p-md-1 p-lg-1 text-center">
                                <p>{{ operation.extraTemplate != null ? 'Sim' : 'Não'}}
                                    {{(operation.extraTemplate && operation.isICVMTemplateSignable ? '(Assinável)' :
                                    '')}}
                                    {{(operation.extraTemplate && !operation.isICVMTemplateSignable ? '(Não Assinável)' :
                                    '')}}
                                </p>
                                <a class="textPrimary"
                                    [href]="operation.extraTemplate?.fileUrl">{{operation.icvmTemplate?.fileUrl != null ?
                                    'Ver template' : ''}}</a>
                            </div> -->
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Procuradores / Rep Legal / Contatos da Corretora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">CPF</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">E-mail</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Data de Validade</p>
                                </div>
                            </div>
                            <form #managerForm="ngForm" novalidate>
                                <div class="p-grid" *ngFor="let representative of allRepresentatives">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.name}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.maskedPersonalDocumentNumber}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.email}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.expireDate | date: 'dd/MM/yyyy' }}
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="line"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Editar Informações" [(visible)]="displayEditFundBroker" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form #editForm="ngForm" novalidate>
        <div class="p-grid p-mt-2">
            <div class="p-col-12 p-md-12 p-lg-12 p-sm-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text"
                            [(ngModel)]="request.name" name="name"
                            #name="ngModel" class="inputLiza" pInputText>
                        <label for="name">Nome</label>
                    </span>
                </div>
                <mark *ngIf="name.invalid && name.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-sm-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="representativeEmail" type="text"
                            [(ngModel)]="request.email" name="representativeEmail"
                            #representativeEmail="ngModel" class="inputLiza" pInputText>
                        <label for="representativeEmail">Email</label>
                    </span>
                </div>
                <mark *ngIf="representativeEmail.invalid && representativeEmail.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-22 p-lg-22 p-sm-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                            [(ngModel)]="request.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                            [unmask]="true">
                        </p-inputMask>
                        <label for="phoneNumber" class="labelLiza">Telefone</label>
                    </span>
                </div>
                <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                    (click)="close()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onSaveEditFundAdministrator(editForm)"></button>
            </div>
        </div>
    </form>

</p-dialog>