<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Setup de Operações
            </div>
        </div>
        <div class="customCard" *ngIf="fundBroker">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Corretora de Fundo</div>
                </div>
                <div class="p-col-12 p-md-8 p-lg-12">
                    <div class="borderOutlined">
                        <p><b class="text-bold">Nome:</b> <span> {{fundBroker.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{fundBroker.documentNumber |
                                documentNumber:'CNPJ'}}</span></p>
                        <!-- <p><b class="text-bold">Data de Validade:</b> <span> {{fundBroker?.expirationDate | date
                                : 'dd/MM/yyyy' }}</span></p> -->
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix151"
                        *ngIf="fundBroker?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="fundBroker.registrationFormTemplate.fileUrl" target="_blank" class="linkSecondary">
                            Ver Template
                        </a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p style="text-align: center;">{{fundBroker.registrationFormTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!fundBroker?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo Ficha Cadastral
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix151" *ngIf="fundBroker?.updateFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral de Atualização
                        </div>
                        <a [href]="fundBroker.updateFormTemplate.fileUrl" target="_blank" class="linkSecondary">
                            Ver Template
                        </a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p style="text-align: center;">{{fundBroker.updateFormTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!fundBroker?.updateFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral de Atualização
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix151" *ngIf="fundBroker?.additionTemplate">
                        <div class="text-bold">
                            Modelo Template de Aditamento
                        </div>
                        <a [href]="fundBroker.additionTemplate.fileUrl" target="_blank" class="linkSecondary">
                            Ver Template
                        </a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p style="text-align: center;">{{fundBroker.additionTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="!fundBroker?.additionTemplate">
                        <div class="text-bold">
                            Modelo Template de Aditamento
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix151" *ngIf="fundBroker?.operationTemplate">
                        <div class="text-bold">
                            Modelo Template W8
                        </div>
                        <a [href]="fundBroker.operationTemplate.fileUrl" target="_blank" class="linkSecondary">
                            Ver Template
                        </a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p style="text-align: center;">{{fundBroker.operationTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="!fundBroker?.operationTemplate">
                        <div class="text-bold">
                            Modelo Template W8
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-mt-3">
                    <div class="cardHeader text-bold">Setups Cadastrados</div>
                    <div class="borderOutlined">
                        <div *ngIf="fundBroker.operationSetups.length == 0">
                            <span>Não há setups cadastrados</span>
                        </div>
                        <div class="p-grid" *ngIf="fundBroker.operationSetups.length > 0"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-1 p-lg-1 text-bold ">
                                Tipo
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Ficha Cadastral
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Ficha Cadastral de Atualizacão
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Template de Aditamento
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Template W8
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Template Extra
                            </div>
                            <!-- <div class="p-col-1 p-md-1 p-lg-1 text-bold">
                                Excluir
                            </div> -->
                        </div>
                        <div *ngFor="let operation of fundBroker.operationSetups" class="p-grid"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-1 p-lg-1">
                                {{ operation.operationType.friendlyName}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.hasBrokerRegistrationFormTemplate ? 'Sim' : 'Não'}}</p>
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasBrokerRegistrationFormTemplate')"
                                    [(ngModel)]="operation.hasBrokerRegistrationFormTemplate" name="hasBrokerRegistrationFormTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerRegistrationFormTemplate && operation.isBrokerRegistrationFormTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerRegistrationFormTemplate && !operation.isBrokerRegistrationFormTemplateSignableByRepresentation ? 'Não assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerRegistrationFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerRegistrationFormTemplateSignableByRepresentation')"
                                    [(ngModel)]="operation.isBrokerRegistrationFormTemplateSignableByRepresentation" name="isBrokerRegistrationFormTemplateSignableByRepresentation"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerRegistrationFormTemplate && operation.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerRegistrationFormTemplate && !operation.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative ? 'Corretora: Não assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerRegistrationFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerRegistrationFormTemplateSignableByBrokerRepresentative')"
                                    [(ngModel)]="operation.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative" name="isBrokerRegistrationFormTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.hasBrokerUpateFormTemplate ? 'Sim' : 'Não'}}</p>
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasBrokerUpateFormTemplate')"
                                    [(ngModel)]="operation.hasBrokerUpateFormTemplate" name="hasBrokerUpateFormTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerUpateFormTemplate && operation.isBrokerUpdateFormTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerUpateFormTemplate && !operation.isBrokerUpdateFormTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerUpateFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerUpdateFormTemplateSignableByRepresentation')"
                                    [(ngModel)]="operation.isBrokerUpdateFormTemplateSignableByRepresentation" name="isBrokerUpdateFormTemplateSignableByRepresentation"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerUpateFormTemplate && operation.isBrokerUpdateFormTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerUpateFormTemplate && !operation.isBrokerUpdateFormTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerUpateFormTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerUpdateFormTemplateSignableByBrokerRepresentative')"
                                    [(ngModel)]="operation.isBrokerUpdateFormTemplateSignableByBrokerRepresentative" name="isBrokerUpdateFormTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.hasBrokerAddtionTemplate ? 'Sim' : 'Não'}}</p>
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasBrokerAddtionTemplate')"
                                    [(ngModel)]="operation.hasBrokerAddtionTemplate" name="hasBrokerAddtionTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerAddtionTemplate && operation.isBrokerAddtionTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerAddtionTemplate && !operation.isBrokerAddtionTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerAddtionTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerAddtionTemplateSignableByRepresentation')"
                                    [(ngModel)]="operation.isBrokerAddtionTemplateSignableByRepresentation" name="isBrokerAddtionTemplateSignableByRepresentation"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerAddtionTemplate && operation.isBrokerAddtionTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerAddtionTemplate && !operation.isBrokerAddtionTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerAddtionTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerAddtionTemplateSignableByBrokerRepresentative')"
                                    [(ngModel)]="operation.isBrokerAddtionTemplateSignableByBrokerRepresentative" name="isBrokerAddtionTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                <div>
                                    <p>{{ operation.hasBrokerOperationTemplate ? 'Sim' : 'Não'}}</p>
                                </div>
                                <div>
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'hasBrokerOperationTemplate')"
                                    [(ngModel)]="operation.hasBrokerOperationTemplate" name="hasBrokerOperationTemplate"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerOperationTemplate && operation.isBrokerOperationTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerOperationTemplate && !operation.isBrokerOperationTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerOperationTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerOperationTemplateSignableByRepresentation')"
                                    [(ngModel)]="operation.isBrokerOperationTemplateSignableByRepresentation" name="isBrokerOperationTemplateSignableByRepresentation"></p-inputSwitch>
                                </div>
                                <div>
                                    <p>{{(operation.hasBrokerOperationTemplate && operation.isBrokerOperationTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                    <p>{{(operation.hasBrokerOperationTemplate && !operation.isBrokerOperationTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                                </div>
                                <div [hidden]="!operation.hasBrokerOperationTemplate">
                                    <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isBrokerOperationTemplateSignableByBrokerRepresentative')"
                                    [(ngModel)]="operation.isBrokerOperationTemplateSignableByBrokerRepresentative" name="isBrokerOperationTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center" style="display: flex; flex-direction: column; gap: 10px;">
                                    <div>
                                        <p>{{ operation.extraTemplate != null ? 'Sim' : 'Não'}}</p>
                                    </div>
                                    <div>
                                        <p>{{(operation.extraTemplate && operation.isExtraTemplateSignableByRepresentation ? 'Assinável' : '')}}</p>
                                        <p>{{(operation.extraTemplate && !operation.isExtraTemplateSignableByRepresentation ? 'Não Assinável' : '')}}</p>
                                    </div>
                                    <div [hidden]="!operation.extraTemplate">
                                        <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isExtraTemplateSignableByRepresentation')"
                                        [(ngModel)]="operation.isExtraTemplateSignableByRepresentation" name="isExtraTemplateSignableByRepresentation"></p-inputSwitch>
                                    </div>
                                    <div>
                                        <p>{{(operation.extraTemplate && operation.isExtraTemplateSignableByBrokerRepresentative ? 'Corretora: Assinável' : '')}}</p>
                                        <p>{{(operation.extraTemplate && !operation.isExtraTemplateSignableByBrokerRepresentative ? 'Corretora: Não Assinável' : '')}}</p>
                                    </div>
                                    <div [hidden]="!operation.extraTemplate">
                                        <p-inputSwitch (onChange)="handleCheckOperationSetups($event.checked, operation, 'isExtraTemplateSignableByBrokerRepresentative')"
                                        [(ngModel)]="operation.isExtraTemplateSignableByBrokerRepresentative" name="isExtraTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                                    </div>
                                    <div>
                                        <a class="textPrimary" [href]="operation.extraTemplate?.fileUrl">{{operation.extraTemplate?.fileUrl != null ? 'Ver template' : ''}}</a>
                                    </div>
                                    <div *ngIf="operation.extraTemplate != null" style="margin-top: 1rem;">
                                        <p class="text-bold">Data de inclusão:</p>
                                        <p>{{operation?.extraTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                            <div class="p-col-1 p-md-1 p-lg-1" style="margin-top: 8px;">
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    class="p-button-rounded p-button-outlined-blue"
                                    (click)="removeOperationSetup(operation)"></button>
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">

        <div class="titleCustomCard">
            <div class="titlePage">
                Novo Setup
            </div>
        </div>

        <div class="customCard" *ngIf="requestModel != null">
            <form #setupOperationForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="operationTypesOptions" placeholder="Tipo"
                                    [(ngModel)]="requestModel.operationTypeId" optionLabel="friendlyName"
                                    #operationTypeId="ngModel" optionValue="value" name="operationTypeId">
                                </p-dropdown>
                                <!-- <label for="operationTypeId">Tipo</label> -->
                            </span>
                        </div>
                        <mark *ngIf="operationTypeId.invalid && operationTypeId.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Ficha Cadastral</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundAdministratorResistrationSignable($event.checked)"
                                [(ngModel)]="requestModel.hasBrokerRegistrationFormTemplate"
                                [disabled]="fundBroker?.registrationFormTemplate == null"
                                name="hasBrokerRegistrationFormTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável Pelo Representante </div>
                            <br>
                            <p-inputSwitch
                                [(ngModel)]="requestModel.isBrokerRegistrationFormTemplateSignableByRepresentation"
                                [disabled]="!requestModel.hasBrokerRegistrationFormTemplate"
                                name="isBrokerRegistrationFormTemplateSignableByRepresentation"></p-inputSwitch>

                            <br>
                            <div class="text-bold">Assinável pelo Representante da Corretora</div>
                            <br>
                            <p-inputSwitch
                                [(ngModel)]="requestModel.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative"
                                [disabled]="!requestModel.hasBrokerRegistrationFormTemplate"
                                name="isBrokerRegistrationFormTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Ficha Cadastral de Atualização</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundUpdateSignable($event.checked)"
                                [(ngModel)]="requestModel.hasBrokerUpateFormTemplate"
                                [disabled]="fundBroker?.updateFormTemplate == null"
                                name="hasBrokerUpateFormTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável Pelo Representante </div>
                            <br>
                            <p-inputSwitch [(ngModel)]="requestModel.isBrokerUpdateFormTemplateSignableByRepresentation"
                                [disabled]="!requestModel.hasBrokerUpateFormTemplate"
                                name="isBrokerUpdateFormTemplateSignableByRepresentation"></p-inputSwitch>

                            <br>
                            <div class="text-bold">Assinável pelo Representante da Corretora</div>
                            <br>
                            <p-inputSwitch
                                [(ngModel)]="requestModel.isBrokerUpdateFormTemplateSignableByBrokerRepresentative"
                                [disabled]="!requestModel.hasBrokerUpateFormTemplate"
                                name="isBrokerUpdateFormTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Template de Aditamento</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundAdditionSignable($event.checked)"
                                [(ngModel)]="requestModel.hasBrokerAddtionTemplate"
                                [disabled]="fundBroker?.additionTemplate == null"
                                name="hasBrokerAddtionTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável Pelo Representante </div>
                            <br>
                            <p-inputSwitch [(ngModel)]="requestModel.isBrokerAddtionTemplateSignableByRepresentation"
                                [disabled]="!requestModel.hasBrokerAddtionTemplate"
                                name="isBrokerAddtionTemplateSignableByRepresentation"></p-inputSwitch>
                            <br>
                            <div class="text-bold">Assinável pelo Representante da Corretora</div>
                            <br>
                            <p-inputSwitch
                                [(ngModel)]="requestModel.isBrokerAddtionTemplateSignableByBrokerRepresentative"
                                [disabled]="!requestModel.hasBrokerAddtionTemplate"
                                name="isBrokerAddtionTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="customCardOutlined">
                            <div class="text-bold">Possui Template W8</div>
                            <br>
                            <p-inputSwitch (onChange)="changeFundOperationSignable($event.checked)"
                                [(ngModel)]="requestModel.hasBrokerOperationTemplate"
                                [disabled]="fundBroker?.operationTemplate == null"
                                name="hasBrokerOperationTemplate"></p-inputSwitch>
                            <br>
                            <br>
                            <div class="text-bold">Assinável Pelo Representante </div>
                            <br>
                            <p-inputSwitch [(ngModel)]="requestModel.isBrokerOperationTemplateSignableByRepresentation"
                                [disabled]="!requestModel.hasBrokerOperationTemplate"
                                name="isBrokerOperationTemplateSignableByRepresentation"></p-inputSwitch>
                            <br>
                            <div class="text-bold">Assinável pelo Representante da Corretora</div>
                            <br>
                            <p-inputSwitch
                                [(ngModel)]="requestModel.isBrokerOperationTemplateSignableByBrokerRepresentative"
                                [disabled]="!requestModel.hasBrokerOperationTemplate"
                                name="isBrokerOperationTemplateSignableByBrokerRepresentative"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-12 p-lg-12">
                        <div class="p-grid customCardOutlined"
                            style="display: flex; flex-direction: row; align-items: center;">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="text-bold">Possui Template Extra</div>
                                <br>
                                <p-inputSwitch (onChange)="changeExtraSignable($event.checked)"
                                    [(ngModel)]="operationExtra" name="operationExtra"></p-inputSwitch>
                                <br>
                                <br>
                                <div class="text-bold">Assinável Pelo Representante </div>
                                <br>
                                <p-inputSwitch [(ngModel)]="requestModel.isExtraTemplateSignableByRepresentation"
                                    name="isExtraTemplateSignableByRepresentation" [disabled]="!operationExtra"></p-inputSwitch>
                                <br>
                                <div class="text-bold">Assinável pelo Representante da Corretora</div>
                                <br>
                                <p-inputSwitch [(ngModel)]="requestModel.isExtraTemplateSignableByBrokerRepresentative"
                                    name="isExtraTemplateSignableByBrokerRepresentative" [disabled]="!operationExtra"></p-inputSwitch>
                            </div>
                            <div class="p-col-12 p-md-9 p-lg-9">
                                <div [hidden]="!operationExtra">
                                    <app-artifact-template-create [hasDescription]="true" (onArtifactFilled)="extraTemplateFilled($event)"
                                        #extraTemplate>
                                    </app-artifact-template-create>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton class="p-button-primary" label="Salvar"
                            (click)="saveOperationSetup(setupOperationForm)"></button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>