import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateFundAdministratorRequest, RepresentativeProperties } from 'src/app/services/fund-administrators/models/requests/create-fund-administrator-request';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { BrokerRepresentativeProperties } from 'src/app/services/fund-brokers/models/view-models/broker-representative-properties';
import { CreateFundBrokerRequest } from './../../../../services/fund-brokers/models/requests/create-fund-broker-request';

@Component({
    templateUrl: './fund-broker-create.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundBrokerCreateComponent implements OnInit {

    requestModel: CreateFundBrokerRequest = null;
    representativeRequestForm: BrokerRepresentativeProperties = null;
    contactsOption: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    selectedContact: SelectItem = null;
    currentEmails: string = null;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private fundBrokerService: FundBrokerService,
        private messageService: MessageService,
        private formValidationService: FormValidationService) {
    }

    async ngOnInit() {
        this.setMainRequestForm();
        this.setRepresentativeForm();
    }

    setMainRequestForm() {
        this.requestModel = {
            name: null,
            documentNumber: null,
            // expirationDate: null,
            operationDoneNotificationEmails: [],
            operationDoneNotificationSubject: null,
            operationDoneNotificationTitle: null,
            hasOperationDoneNotification: true,
            representatives: [],
            registrationFormTemplateRequest: null,
            updateFormTemplateRequest: null,
            additionTemplateRequest: null,
            operationTemplateRequest: null,
        };
    }

    setRepresentativeForm() {
        this.representativeRequestForm = {
            name: null,
            email: null,
            expireDate: null,
            personalDocumentNumber: null,
            canSign: false,
        };
    }

    addRepresentativeRequest(representativeRequest: BrokerRepresentativeProperties, formComponent: NgForm) {
        
        if (this.selectedContact == null) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Preencha o campo Representante legal', life: 5000 });
            return;
        }
        representativeRequest.canSign = this.selectedContact?.value

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        this.requestModel.representatives.push(representativeRequest);
        this.setRepresentativeForm();
        this.formValidationService.resetFormFields(formComponent.form);
        this.selectedContact = null;
    }


    removeRepresentativeRequest(representativeRequest: RepresentativeProperties) {
        const indexToRemove = this.requestModel.representatives.findIndex(c => c == representativeRequest);
        this.requestModel.representatives.splice(indexToRemove, 1);
    }

    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }

    updateFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.updateFormTemplateRequest = createArtifactTemplateRequest;
    }

    additionTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.additionTemplateRequest = createArtifactTemplateRequest;
    }

    operationTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.operationTemplateRequest = createArtifactTemplateRequest;
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund-broker/list');
    }

    async onSave(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        // if (this.requestModel.registrationFormTemplateRequest == null) {
        //     this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Ficha Cadastral é obrigatório.`, life: 5000 });
        //     return;
        // }

        // if (this.requestModel.updateFormTemplateRequest == null) {
        //     this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Ficha Cadastral de atualização é obrigatório.`, life: 5000 });
        //     return;
        // }

        // if (this.requestModel.additionTemplateRequest == null) {
        //     this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de Aditamento é obrigatório.`, life: 5000 });
        //     return;
        // }

        // if (this.requestModel.operationTemplateRequest == null) {
        //     this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template W8 é obrigatório.`, life: 5000 });
        //     return;
        // }

        // if (this.requestModel.representatives.length == 0) {
        //     this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `É necessário informar ao menos um representante.`, life: 5000 });
        //     return;
        // }



        this.loadingService.show();
        try {
            await this.fundBrokerService.createFundBroker(this.requestModel);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/fund-broker/list');
        } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: error.messageError, life: 5000 });
        } finally {
            this.loadingService.hide();
        }
    }

    addEmail(form: NgForm) {
        console.log(form)
        if (form?.controls?.currentEmail?.value == null) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'E-mail inválido', life: 5000 });
            return;
        }
        const email = form.controls.currentEmail.value;
        this.requestModel.operationDoneNotificationEmails.push(email);
        form.controls.currentEmail.reset();
    }

    removeEmail(email: string) {
        const indexMail = this.requestModel.operationDoneNotificationEmails.findIndex(c => c == email);
        this.requestModel.operationDoneNotificationEmails.splice(indexMail, 1);
    }
}