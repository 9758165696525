import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { ContextService } from 'src/app/providers/context-service';
import { ReactivateUserAccessRequest } from 'src/app/services/users/models/requests/reactivate-user-access-request';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { UserService } from 'src/app/services/users/user-service';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: 'user-list.component.html',
    styleUrls: ['../../../../../../assets/layout/customized/datatable.scss',
        '../../../../../../assets/layout/customized/custom-card.scss']
})
export class UserListComponent implements OnInit {

    accountId: string = null;
    users: UserViewModel[];
    userId: string = null;
    // verifyUser: boolean = false;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserService,
        private contextService: ContextService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
    ) {
        // this.breadcrumbService.setItems([
        //     { label: 'User', routerLink: ['/app/customer/account/user'] },
        //     { label: 'List' }
        // ]);
    }

    ngOnInit() {
        this.accountId = this.contextService.getUserInfo().user.userProfiles[0].playerEntityId;
        this.loadTableData();
        this.verifyUserPermissions();
        console.log('...')
    }

    async verifyUserPermissions() {
        const user = this.contextService.getUserInfo().user;
        user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async loadTableData() {
        this.loadingService.show();
        const user: UserViewModel = this.contextService.getUserInfo().user;

        this.users = await this.userService.getUsersByPlayerTypeAndEntity(PlayerTypeEnum.Customer, this.accountId);

        this.loadingService.hide();
    }

    addUser() {
        this.router.navigateByUrl('app/customer/user/create');
    }

    viewUser(user) {
        this.router.navigateByUrl(`app/customer/user/view/${user.id}`);
    }

    getUserById(event) {
        this.router.navigateByUrl(`app/customer/user/view/${event.data.id}`);
    }

    editUser(user: UserViewModel) {
        this.router.navigateByUrl(`app/customer/user/edit/${user.id}`);
    }

    confirm(user: UserViewModel) {
        this.confirmationService.confirm({
            header: `Usuário ${user.fullName}`,
            message: `Tem certeza que deseja excluir o usuário ${user.fullName}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.inactiveUser(user);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async inactiveUser(user: UserViewModel) {
        this.loadingService.show()
        try {
            await this.userService.inactive(user.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            this.loadTableData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async activeUserAccount(user: UserViewModel) {
        this.loadingService.show()
        try {
            const objUser: ReactivateUserAccessRequest = {
                emailOrCellPhone: user.email
            }
            await this.userService.resendEmailOrCellPhone(objUser);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            this.loadTableData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    getMenuItems(user): MenuItem[] {
        if (this.isUserOperator) {
            return [
                { label: 'Visualizar', command: e => this.viewUser(user) },
            ];
        }
        else if (user.isAccessActivated == null) {
            return [
                { label: 'Visualizar', command: e => this.viewUser(user) },
            ];
        }
        else if (user.isAccessActivated == false) {
            return [
                { label: 'Visualizar', command: e => this.viewUser(user) },
                { label: 'Destravar usuário', command: e => this.activeUserAccount(user) }
            ];
        }
    }

}
