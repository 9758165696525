<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Consulta
            </div>
        </div>
        <div class="customCard">
            <form #confultForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="contentButtonActions">
                            <button *ngIf="!existOperationParams()" pButton pRipple type="button"
                                label="Exportação Completa" (click)="openFullConsult()"></button>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" class="inputLiza" name="id"
                                    [(ngModel)]="request.liabilityOperationSequentialId" #id="ngModel" pInputText>
                                <label for="id">ID</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="operationStatus" optionValue="value" optionLabel="label"
                                    [showClear]="true" [(ngModel)]="selectedStatusOperation"
                                    [(ngModel)]="request.operationStatusId" placeholder="Status da Operação"
                                    name="label" #operationType="ngModel">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.startDate" name="startDate" yearRange="1920:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" #startDate="ngModel">
                                </p-calendar>
                                <label for="startDate">Data inicial do status</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.endDate" name="endDate" yearRange="1920:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" #endDate="ngModel">
                                </p-calendar>
                                <label for="endDate">Data final do status</label>
                            </span>
                        </div>
                    </div>

                    <!-- <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="fundOptions" placeholder="Selecione o Fundo Investido"
                                    [required]="true" [showClear]="true" [(ngModel)]="request.fundId"
                                    [resetFilterOnHide]="true" optionLabel="label" optionValue="value"
                                    #fundOption="ngModel" name="fundOption">
                                </p-dropdown>
                            </span>
                        </div>
                    </div> -->

                    <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="investedFundOptions" placeholder="Selecione o Fundo Investidor"
                                    [required]="true" [showClear]="true" [(ngModel)]="request.shareholderFundId"
                                    [resetFilterOnHide]="true" optionLabel="label" optionValue="value"
                                    #investedFundOption="ngModel" name="investedFundOption">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2">
                    </div>

                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Limpar" class="p-button-outlined"
                            (click)="onClear()"></button>
                        <button pButton pRipple type="button" label="Consultar" class="action-button p-ml-2"
                            (click)="consult()"></button>
                    </div>

                </div>
            </form>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12" [hidden]="!operations">
        <div class="titleCustomCard">
            <div class="titlePage">
                Operações
            </div>
        </div>
        <div class="customCard">
            <p-table [responsive]="true" #dt [value]="operations" dataKey="id" [(first)]="firstPage"
                [(selection)]="selectedBrokerOperation" styleClass="p-datatable-customized" selectionMode="single"
                [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'shareholderFund.documentNumber', 'investedFund.name', 'investedFund.documentNumber', 'currentStatus.status.friendlyName']">
                <ng-template pTemplate="caption">
                    <div class="contentButtonActions p-fluid">

                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid" [hidden]="!operations.length">
                            <button pButton type="button" class="p-button-outlined" label="Exportar operações"
                                (click)="exportExcel()"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-operation>
                    <tr>
                        <th pSortableColumn="sequentialId">
                            <div>
                                ID
                                <p-sortIcon field="sequentialId"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="createDate">
                            <div>
                                Data da Entrada
                                <p-sortIcon field="createDate"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="currentStatus.status.friendlyName">
                            <div>
                                Status
                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="fund.name">
                            <div>
                                Fundo Investido
                                <p-sortIcon field="fund.name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="fund.documentNumber">
                            <div>
                                CNPJ F. Investido
                                <p-sortIcon field="fund.documentNumber"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.name">
                            <div>
                                Fundo Investidor
                                <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="shareholderFund.documentNumber">
                            <div>
                                CNPJ F. Investidor
                                <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="contract">
                            <div>
                                Contrato
                                <p-sortIcon field="contract"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn=""
                            *ngIf="selectedStatusOperation == liabilityOperationStatusEnum.ConcluidoComAlteracao">
                            <div>
                                Razão Social
                            </div>
                        </th>
                        <th pSortableColumn=""
                            *ngIf="selectedStatusOperation == liabilityOperationStatusEnum.ConcluidoComAlteracao">
                            <div>
                                CNPJ
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-operation let-index>
                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                        <td>
                            {{operation?.sequentialId}}
                        </td>
                        <td>
                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                            {{operation?.currentStatus?.status?.friendlyName}}
                        </td>
                        <td>
                            {{operation?.fund?.name}}
                        </td>
                        <td>
                            {{operation?.fund?.documentNumber | documentNumber: 'cnpj'}}
                        </td>
                        <td>
                            {{operation?.shareholderFund?.name}}
                        </td>
                        <td>
                            {{operation?.shareholderFund?.documentNumber | documentNumber: 'cnpj'}}
                        </td>
                        <td>
                            {{operation?.contract}}
                        </td>
                        <td *ngIf="selectedStatusOperation == liabilityOperationStatusEnum.ConcluidoComAlteracao">
                            <div *ngFor="let item of operation?.results;">
                                {{item?.propertyChanged == "Razão Social" ? "ANTIGO: " + item?.oldValue : ""}}
                                <br>
                                {{item?.propertyChanged == "Razão Social" ? "NOVO: " + item?.currentValue : ""}}
                            </div>
                        </td>
                        <td *ngIf="selectedStatusOperation == liabilityOperationStatusEnum.ConcluidoComAlteracao">
                            <div *ngFor="let item of operation?.results;">
                                {{item?.propertyChanged == "CNPJ" ? "ANTIGO: " + (item?.oldValue | documentNumber: 'cnpj') : ""}}
                                <br>
                                {{item?.propertyChanged == "CNPJ" ? "NOVO: " + (item?.currentValue | documentNumber: 'cnpj') : ""}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Nenhuma operação encontrada.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>


<p-dialog header="Detalhes" [(visible)]="displayLiabilityOperationDetail" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-col-12">
        <p-divider></p-divider>
    </div>
    <div class="p-grid" *ngIf="administratorsInfo?.length > 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Administrador: </p>
            <div *ngFor="let administrator of administratorsInfo; let i = index;">
                <p *ngIf="administrator?.propertyChanged == 'CNPJ'">{{ administrator?.oldValue ?
                    (administrator?.oldValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="administrator?.propertyChanged !== 'CNPJ'">{{ administrator?.entityChanged == 'Administradora'
                    ? administrator.oldValue : "Não houve alteração" }}</p>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Administrador: </p>
            <div *ngFor="let administrator of administratorsInfo; let i = index;">
                <p *ngIf="administrator?.propertyChanged == 'CNPJ'">{{ administrator?.currentValue ?
                    (administrator?.currentValue | documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="administrator?.propertyChanged !== 'CNPJ'">{{ administrator?.currentValue ?
                    administrator?.currentValue : "Não houve alteração" }}</p>
            </div>
        </div>
    </div>
    <div class="p-grid" *ngIf="administratorsInfo?.length == 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Administrador: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Administrador: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
    </div>
    <div class="p-col-12">
        <p-divider></p-divider>
    </div>
    <div class="p-grid" *ngIf="managerInfo?.length > 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Gestor: </p>
            <div *ngFor="let manager of managerInfo; let i = index;">
                <p *ngIf="manager?.propertyChanged == 'CNPJ'">{{ manager?.oldValue ? (manager?.oldValue |
                    documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="manager?.propertyChanged !== 'CNPJ'">{{ manager?.oldValue ? manager?.oldValue : "Não houve
                    alteração" }}</p>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Gestor: </p>
            <div *ngFor="let manager of managerInfo; let i = index;">
                <p *ngIf="manager?.propertyChanged == 'CNPJ'">{{ manager?.currentValue ? (manager?.currentValue |
                    documentNumber: 'cnpj') : "Não houve alteração" }}</p>
                <p *ngIf="manager?.propertyChanged !== 'CNPJ'">{{ manager?.currentValue ? manager?.currentValue : "Não
                    houve alteração" }}</p>
            </div>
        </div>
    </div>
    <div class="p-grid" *ngIf="managerInfo?.length == 0">
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Antigo Gestor: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <p class="text-bold">Novo Gestor: </p>
            <p>{{ "Não houve alteração" }}</p>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Motivo" [(visible)]="displayReasonOperation" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 2 p-lg-12">
            <p class="text-bold">Motivo:</p> <span>{{selectedOperationDisplay?.currentStatus?.notes}}</span>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Exportação Completa" [(visible)]="displayExportFull" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <h4 class="subTitle">Selecione a situação.</h4>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="included" label="Concluído com Inclusão"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="changed" label="Concluído com Alteração"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="error" label="Erro Cadastral"
                [(ngModel)]="selectedOperationSituation"></p-radioButton>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-radioButton name="operation" value="all" label="Todos" [(ngModel)]="selectedOperationSituation">
            </p-radioButton>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" class="p-button-outlined" label="Fechar" (click)="close()"></button>
        <button pButton pRipple type="button" label="Exportar" (click)="exportAllSelectedOperation()"
            [disabled]="!selectedOperationSituation"></button>
    </div>

</p-dialog>