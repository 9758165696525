<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Visualizar Usuário
        </div>
        <div class="customCard">
            <div *ngIf="!isUserOperator" class="contentButtonActions p-grid">
                <!-- <button *ngIf="user.isBlockedByExistingAccount" pButton type="button" label="Aprovar Usuário" class="p-button-primary p-button"
                    (click)="activeUserAccount()"></button> -->
                <button *ngIf="!user.isAccessActivated" pButton type="button" label="Editar" class="p-button-primary p-button"
                    (click)="updateEmailUser()"></button>
                <!-- <button *ngIf="user.isAccessActivated && !user.isBlockedByExistingAccount" pButton type="button" label="Editar usuário" class="p-button-primary p-button"
                    (click)="editUser()"></button> -->
                <button pButton type="button" label="Excluir usuário" class="p-button-danger p-button"
                    (click)="removeUser()"></button>
            </div>
            <div class="p-grid">
                <!-- <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="cardHeader text-bold">Informações Pessoais</div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">Nome: </p>{{ user?.fullName }}
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">E-mail: </p>{{ user?.email }}
                        </div>
                    </div>
                </div> -->
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="cardHeader text-bold">Informações Pessoais</div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">Nome: </p>{{ user?.fullName }}
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">E-mail: </p>{{ user?.email }}
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="cardHeader text-bold">Outras Informções</div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">Tipo: </p>{{user?.userProfiles[0].playerType?.friendlyName}}
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">Ativo: </p>{{ user?.isActive ? 'Sim' : 'Não'}}
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="cardHeader text-bold">Acesso</div>
                    <div class="p-grid" *ngFor="let item of user?.userProfiles; let i = index">
                        <div class="p-col-12 p-md-3 p-lg-12">
                            <p class="text-bold">Função: </p> {{(item.roles[i].friendlyName.replace("", '')) }}
                        </div>
                    </div>
                </div>
            </div>

            <br>


        </div>
    </div>
</div>

<p-dialog header="Alterar e-mail do usuário" [(visible)]="displayChangeUserEmail" [modal]="true" [responsive]="true"
    [style]="{width: '50vw'}">
    <form #userForm="ngForm" (ngSubmit)="changeEmailUser(userForm)">
        <div class="customCard">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-mt-3 p-col-12 customItem">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" class="inputLiza" name="newEmail" id="newEmail"
                                #newEmail="ngModel" appValidateEmail pInputText ngModel>

                            <label for="email">Digite o email</label>
                        </span>
                    </div>
                    <mark *ngIf="newEmail.invalid && newEmail.dirty"
                        class="ui-message ui-messages-error ui-corner-all">Campo obrigatório</mark>
                </div>
            </div>
            <div class="p-d-flex p-jc-end">
                <button pButton pRipple type="button" label="Cancelar" class="buttonPlain"
                    (click)="displayChangeUserEmail=false"></button>
                <button pButton pRipple type="submit" label="Salvar" class="action-button p-ml-2"></button>
            </div>
        </div>
    </form>
</p-dialog>