import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { ContextService } from 'src/app/providers/context-service';
import { ActivateRepresentationRequest } from 'src/app/services/representations/models/requests/activate-representation-request';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';
import { UserService } from 'src/app/services/users/user-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './representation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/datatable.scss', '../../../../../assets/layout/customized/custom-card.scss']
})
export class RepresentationListComponent implements OnInit {

    procuratorsActive: RepresentationViewModel[] = null;
    procuratorsInactive: RepresentationViewModel[] = null;
    // typeUser: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserService,
        private contextService: ContextService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private representationService: RepresentationService,
    ) { }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.loadTableData();
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        
        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async loadTableData() {
        const active = true;
        const inactive = false;
        this.procuratorsActive = await this.representationService.getAllRepresentations(active);
        this.procuratorsInactive = await this.representationService.getAllRepresentations(inactive);
        this.procuratorsActive = this.procuratorsActive.sort((a, b) => {
            if(a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
        this.procuratorsInactive = this.procuratorsInactive.sort((a, b) => {
            if(a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
    }

    addProcurator() {
        this.router.navigateByUrl(`app/customer/representation/create`);
    }

    getProcuratorById(event) {
        this.router.navigateByUrl(`app/customer/representation/edit/${event.data.id}`);
    }

    editProcurator(procurator: RepresentationViewModel) {
        this.router.navigateByUrl(`app/customer/representation/edit/${procurator.id}`);
    }

    viewProcurator(procurator: RepresentationViewModel) {
        this.router.navigateByUrl(`app/customer/representation/view/${procurator.id}`);
    }

    async inactive(procurator: RepresentationViewModel) {
        try {
            this.loadingService.show();
            await this.representationService.deleteRepresentationById(procurator.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadTableData();
            this.loadingService.hide();
        }
    }

    confirm(procurator: RepresentationViewModel) {
        this.confirmationService.confirm({
            header: `Procurador ${procurator.name}`,
            message: `Tem certeza que deseja Inativar ${procurator.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.inactive(procurator);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async activeProcurator(procurator: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: ActivateRepresentationRequest = {
                representationId: procurator.id,
            }
            await this.representationService.activeRepresentation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadTableData();
            this.loadingService.hide();
        }
    }

    getMenuItems(procurator: any): MenuItem[] {
        if(procurator?.isActive && !this.isUserOperator) {
            return [
                { label: 'Editar', command: e => this.editProcurator(procurator) },
                { label: 'Visualizar', command: e => this.viewProcurator(procurator) },
                { label: 'Inativar', command: e => this.confirm(procurator) },
            ];
        } else if(!procurator?.isActive && !this.isUserOperator) {
            return [
                { label: 'Editar', command: e => this.editProcurator(procurator) },
                { label: 'Visualizar', command: e => this.viewProcurator(procurator) },
                { label: 'Ativar', command: e => this.activeProcurator(procurator) },
            ];
        } else if(procurator?.isActive && this.isUserOperator) {
            return [
                { label: 'Visualizar', command: e => this.viewProcurator(procurator) },
            ];
        } else if(!procurator?.isActive && this.isUserOperator) {
            return [
                { label: 'Visualizar', command: e => this.viewProcurator(procurator) },
            ];
        }
    }


}
