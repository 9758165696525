import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { GetOperationsHomeReportResponse } from 'src/app/services/brokers/models/responses/GetOperationsHomeReportResponse';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { UserViewModel } from './../../../services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './broker-home.component.html',
    styleUrls: ['./broker-home.component.scss', '../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BrokerHomeComponent implements OnInit, AfterViewInit {

    registrationFundOperationSetup: number = 0;
    registrationFundOperationFillForm: number = 0;
    registrationFundOperationSignatureProcess: number = 0;
    registrationFundOperationBrokerAnalysis: number = 0;
    registrationFundOperationBrokerPending: number = 0;
    registrationFundOperationFinished: number = 0;

    updateFundOperationSetup: number = 0;
    updateFundOperationFillForm: number = 0;
    updateFundOperationSignatureProcess: number = 0;
    updateFundOperationBrokerAnalysis: number = 0;
    updateFundOperationBrokerPending: number = 0;
    updateFundOperationFinished: number = 0;

    registrationInstitutionalSetup: number = 0;
    registrationInstitutionalFillForm: number = 0;
    registrationInstitutionalSignatureProcess: number = 0;
    registrationInstitutionalBrokerAnalysis: number = 0;
    registrationInstitutionalBrokerPending: number = 0;
    registrationInstitutionalFinished: number = 0;

    updateInstitutionalSetup: number = 0;
    updateInstitutionalFillForm: number = 0;
    updateInstitutionalSignatureProcess: number = 0;
    updateInstitutionalBrokerAnalysis: number = 0;
    updateInstitutionalBrokerPending: number = 0;
    updateInstitutionalFinished: number = 0;

    allOperationSetup: number = 0;
    allOperationFillForm: number = 0;
    allOperationSignatureProcess: number = 0;
    allOperationBrokerAnalysis: number = 0;
    allOperationBrokerPending: number = 0;
    allOperationFinished: number = 0;

    // typeUser: string = null;

    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    aboutToExpireAllFunds: FundViewModel[] = [];
    aboutToExpireFunds: any[] = [];
    displayAboutExpireFunds: boolean = false;

    public operationTypeEnum = OperationTypeEnum;


    constructor(
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private brokerOperationService: BrokerService,
        private integrationFileService: IntegrationFilesService,
        private router: Router,
        private fundService: FundService,
        private elementRef: ElementRef,
    ) { }


    ngAfterViewInit(): void {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.loadData();
        await this.getAllFunds();
        this.loadingService.hide();
    }

    handleDisplayAboutToExpireFunds() {
        this.displayAboutExpireFunds = !this.displayAboutExpireFunds;
    }

    async getAllFunds() {
        const response = await this.fundService.getAllFunds(true);
        this.aboutToExpireAllFunds = response.filter(it => it.aboutToExpireBrokerEntries.length > 0);
        response.forEach(fund => {
            if (fund.aboutToExpireBrokerEntries.length > 0) {
                fund.aboutToExpireBrokerEntries.forEach(it => {
                    const obj = {
                        id: fund.id,
                        name: fund.name,
                        documentNumber: fund.documentNumber,
                        broker: {
                            name: it.broker.name,
                            documentNumber: it.broker.documentNumber,
                        },
                        operationType: it.operationType,
                        cblc: it.cblc,
                        notes: it.notes,
                        expirationDate: it.expirationDate,
                    }
                    this.aboutToExpireFunds.push(obj);
                })
            }
        });
    }

    fundBrokerView(fund: FundViewModel) {
        this.router.navigateByUrl(`app/customer/fund/view/${fund.id}`);
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.user.userProfiles.forEach(it => {
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofOperator ||
                c.value == RoleOptionEnum.CustomerBrokerOperator).length) {
                this.isUserOperator = true;
            } else {
                this.isUserOperator = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAnalist ||
                c.value == RoleOptionEnum.CustomerBrokerAnalist).length) {
                this.isUserAnalist = true;
            } else {
                this.isUserAnalist = false;
            }
            if (it.roles.filter(c =>
                c.value == RoleOptionEnum.CustomerFofAdministrator ||
                c.value == RoleOptionEnum.CustomerBrokerAdministrator)) {
                this.isUserAdministrator = true;
            } else {
                this.isUserAdministrator = false;
            }
        });
    }

    async loadData() {
        let allBrokerOperations: GetOperationsHomeReportResponse = await this.brokerOperationService.getBrokerOperationsHomeReports();

        // Registration fund
        const registrationFund = allBrokerOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Cadastro de Fundo');
        this.registrationFundOperationSetup = registrationFund[0]?.notInitalizedCount;
        this.registrationFundOperationFillForm = registrationFund[0]?.fillingFormCount;
        this.registrationFundOperationSignatureProcess = registrationFund[0]?.inSignatureProcessCount;
        this.registrationFundOperationBrokerAnalysis = registrationFund[0]?.inManagerAnalysisCount;
        this.registrationFundOperationBrokerPending = registrationFund[0]?.pendingCount;
        this.registrationFundOperationFinished = registrationFund[0]?.doneCount;

        // update Fund
        const updateFund = allBrokerOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Atualização de Fundo');
        this.updateFundOperationSetup = updateFund[0]?.notInitalizedCount;
        this.updateFundOperationFillForm = updateFund[0]?.fillingFormCount;
        this.updateFundOperationSignatureProcess = updateFund[0]?.inSignatureProcessCount;
        this.updateFundOperationBrokerAnalysis = updateFund[0]?.inManagerAnalysisCount;
        this.updateFundOperationBrokerPending = updateFund[0]?.pendingCount;
        this.updateFundOperationFinished = updateFund[0]?.doneCount;

        // registration institutonal
        const registrationInstitutional = allBrokerOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Cadastro de Instituição');
        this.registrationInstitutionalSetup = registrationInstitutional[0]?.notInitalizedCount;
        this.registrationInstitutionalFillForm = registrationInstitutional[0]?.fillingFormCount;
        this.registrationInstitutionalSignatureProcess = registrationInstitutional[0]?.inSignatureProcessCount;
        this.registrationInstitutionalBrokerAnalysis = registrationInstitutional[0]?.inManagerAnalysisCount;
        this.registrationInstitutionalBrokerPending = registrationInstitutional[0]?.pendingCount;
        this.registrationInstitutionalFinished = registrationInstitutional[0]?.doneCount;

        // update institutonal
        const updateInstitutional = allBrokerOperations.operationTypeSummaryData.filter(fill => fill.typeName == 'Atualização de Instituição');
        this.updateInstitutionalSetup = updateInstitutional[0]?.notInitalizedCount;
        this.updateInstitutionalFillForm = updateInstitutional[0]?.fillingFormCount;
        this.updateInstitutionalSignatureProcess = updateInstitutional[0]?.inSignatureProcessCount;
        this.updateInstitutionalBrokerAnalysis = updateInstitutional[0]?.inManagerAnalysisCount;
        this.updateInstitutionalBrokerPending = updateInstitutional[0]?.pendingCount;
        this.updateInstitutionalFinished = updateInstitutional[0]?.doneCount;

        // // All operations
        this.allOperationSetup = allBrokerOperations.totalNotInitalizedCount;
        this.allOperationFillForm = allBrokerOperations.totalFillingFormCount;
        this.allOperationSignatureProcess = allBrokerOperations.totalInSignatureProcessCount;
        this.allOperationBrokerAnalysis = allBrokerOperations.totalInManagerAnalysisCount;
        this.allOperationBrokerPending = allBrokerOperations.totalPendingCount;
        this.allOperationFinished = allBrokerOperations.totalDoneCount;
    }

    async syncOperations() {
        try {
            this.loadingService.show();
            await this.integrationFileService.sftp();
            this.messageService.add({ severity: 'success', summary: 'Arquivos sincronizados', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            if (!this.isUserAnalist && !this.isUserOperator) {
                return;
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        } finally {
            this.loadingService.hide();
        }
    }

    async sendMailtoManagers() {
        try {
            this.loadingService.show();
            await this.brokerOperationService.resendEmailAllPendings();
            this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Emails enviados com sucesso.`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }
}